// Action types
export const POI_SET = 'POI_SET';
export const POI_REMOVE = 'POI_REMOVE';
export const POI_MULTIPLE_SEEN = 'POI_MULTIPLE_SEEN';

export const POI_TYPES = {
    SEARCH: 'SEARCH',
    ELEMENT: 'ELEMENT',
    ACTIVITY: 'ACTIVITY',
};
