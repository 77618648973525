// Lib
import { delay, difference, intersection, isEmpty, includes, uniq } from 'lodash';

// Util
import { isDebugEnabled } from '../../debug/debugUtil';
import { getTimestamp } from '../../../common/utils/timeUtil';
import { getMainEditorId, getMainEditorKey } from '../utils/elementEditorUtils';

import { getPermission } from '../../../common/permissions/elementPermissionsUtil';
import { getSelectedElementIds, getSelectedElements } from './selectedElementsSelector';
import { getCurrentlyEditingId } from './currentlyEditingSelector';
import { getCurrentUserQuickNotesRootBoardId, isGuestSelector } from '../../user/currentUserSelector';
import { getElement, getElements } from '../selectors/elementSelector';
import { getMany } from '../../../common/utils/immutableHelper';
import { isCommentThread, isTask } from '../../../common/elements/utils/elementTypeUtils';
import {
    getClosestUpTaskListId,
    getElement as getElementFromElements,
} from '../../../common/elements/utils/elementTraversalUtils';
import { getElementId, getLocationParentId } from '../../../common/elements/utils/elementPropertyUtils';
import { isLocationCanvas, isInColumn, isInUnsortedNotes } from '../../../common/elements/utils/elementLocationUtils';
import { validateElementTypeEditPermission } from '../../../common/elements/utils/elementTypePermissionsUtils';
import { getCurrentBoardId } from '../../reducers/currentBoardId/currentBoardIdSelector';
import { getCurrentFocus } from '../../reducers/focusSelector';
import {
    trashedByCurrentUserElementIdsSelector,
    trashedByOtherUsersElementIdsSelector,
} from '../../workspace/toolbar/trash/store/trashSelector';
import { getAclIdsSelector } from '../../utils/permissions/permissionsSelector';

// Constants
import * as SELECTION_ACTION_TYPES from '../../../common/elements/selectionConstants';
import { WORKSPACE_SECTIONS } from '../../workspace/workspaceConstants';

export const startEditingElement =
    ({ id, editorId, editorKey, transactionId, canUndo }) =>
    (dispatch, getState) => {
        const state = getState();

        const element = getElement(state, { elementId: id });

        if (!isCommentThread(element)) {
            const remoteSelectedElements = state.getIn(['remoteActivity', 'selectedElements']);

            if (remoteSelectedElements.getIn([id, 'userId'])) {
                console.warn('Cannot edit as it is remotely active', id);
                return;
            }
        }

        const elements = getElements(state);
        const updatingElementType = getElements(state).getIn([id, 'elementType']);
        const aclIds = getAclIdsSelector(state);

        if (!element || !validateElementTypeEditPermission(getPermission(elements, id, aclIds), updatingElementType))
            return;

        return dispatch({
            type: SELECTION_ACTION_TYPES.ELEMENT_EDIT_START,
            id,
            editorId,
            editorKey,
            transactionId,
            sync: false,
            canUndo,
        });
    };

export const startEditingElementWithDelay =
    ({ id, editorId, editDelay, editorKey, transactionId }) =>
    (dispatch, getState) => {
        if (getCurrentlyEditingId(getState()) === id) return;

        delay(() => {
            const selectedIds = getSelectedElementIds(getState());
            if (!selectedIds.contains(id)) return;

            dispatch(startEditingElement({ id, editorId, editorKey, transactionId }));
        }, editDelay);
    };

export const startEditingMainEditor = (elementId) => (dispatch, getState) => {
    const state = getState();

    const element = getElement(state, { elementId });

    dispatch(
        startEditingElement({
            id: elementId,
            editorId: getMainEditorId({ element }),
            editorKey: getMainEditorKey({ element }),
        }),
    );
};

export const finishEditingElement = (id) => ({ type: SELECTION_ACTION_TYPES.ELEMENT_EDIT_COMPLETE, sync: false, id });

export const finishEditingCurrentElement = () => (dispatch, getState) => {
    const state = getState();
    const currentlyEditingId = getCurrentlyEditingId(state);

    if (currentlyEditingId) {
        dispatch(finishEditingElement(currentlyEditingId));
    }
};

// ELEMENT SELECTIONS

/**
 * Removes the elements from the list of selected elements.
 */
export const removeSelectedElements =
    ({ ids, transactionId, sync = true }) =>
    (dispatch, getState) => {
        if (isEmpty(ids)) return;

        return dispatch({
            type: SELECTION_ACTION_TYPES.ELEMENTS_DESELECTED,
            ids,
            sync,
            timestamp: getTimestamp(),
            transactionId,
        });
    };

/**
 * Selects the specified elements if they are not already remotely selected.
 */
export const addSelectedElements =
    ({ ids, rangeAnchors, transactionId }) =>
    (dispatch, getState) => {
        const state = getState();
        const remoteSelectedElements = state.getIn(['remoteActivity', 'selectedElements']);
        let elementIdsToSelect = ids.filter((id) => !remoteSelectedElements.getIn([id, 'userId']));

        if (!elementIdsToSelect.length) return;

        // Remove currently selected elements whose parents are being selected
        const currentlySelectedElements = getSelectedElements(state);
        const currentlySelectedChildElementIds = currentlySelectedElements
            .filter((element) => element && includes(ids, element.getIn(['location', 'parentId'])))
            .map((element) => getElementId(element))
            .toJS();
        dispatch(removeSelectedElements({ ids: currentlySelectedChildElementIds, transactionId }));

        // Filter out ids that already have their parents selected (or will have their parents selected by this operation)
        // And filter elements that are already selected
        const elements = getElements(state);
        const currentlySelectedIds = getSelectedElementIds(state).toJS();
        const elementsToSelect = getMany(ids, elements);
        const finalSelectedElementIds = [
            ...elementIdsToSelect,
            ...currentlySelectedElements.map((element) => getElementId(element)).toJS(),
        ];
        elementIdsToSelect = elementsToSelect
            .filter(
                (element) =>
                    element &&
                    !includes(finalSelectedElementIds, element.getIn(['location', 'parentId'])) &&
                    !includes(currentlySelectedIds, getElementId(element)),
            )
            .map((element) => getElementId(element))
            .keySeq()
            .toArray();

        if (!elementIdsToSelect.length) return;

        if (isDebugEnabled()) {
            console.debug(`%c${finalSelectedElementIds.join(', ')}`, 'color: #99A3A4'); // eslint-disable-line no-console
        }

        return dispatch({
            type: SELECTION_ACTION_TYPES.ELEMENTS_SELECTED,
            ids: elementIdsToSelect,
            rangeAnchors,
            timestamp: getTimestamp(),
            sync: true,
            transactionId,
        });
    };

/**
 * Sets the specified elements as selected and deselects other elements which were selected.
 */
export const setSelectedElements =
    ({ ids, rangeAnchors, transactionId }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentSelection = getSelectedElementIds(state);

        if (currentSelection.size) {
            const currentlySelectedIds = currentSelection.toJS();

            const deselectedIds = difference(currentlySelectedIds, ids);
            dispatch(removeSelectedElements({ ids: deselectedIds, transactionId }));

            const newIds = difference(ids, currentlySelectedIds);
            if (!newIds.length) return;
        }

        return dispatch(addSelectedElements({ ids, rangeAnchors, transactionId }));
    };

/**
 * Safely sets selected elements by first checking if the element type is allowed to be selected.
 * If the element is a TASK for example, this will find the parent TASK_LIST and select that instead.
 */
export const safeSetSelectedElements =
    ({ ids, transactionId }) =>
    (dispatch, getState) => {
        const state = getState();
        const elements = getElements(state);

        const safeIds = uniq(
            ids.map((id) => {
                const el = getElementFromElements(elements, id);

                if (!isTask(el)) return id;

                return getClosestUpTaskListId(elements, id);
            }),
        );

        return dispatch(setSelectedElements({ ids: safeIds, transactionId }));
    };

/**
 * Keeps the specified ids selected, if they already are, and deselects all others
 */
export const keepSelectedElements =
    ({ ids, transactionId }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentSelection = getSelectedElementIds(state);

        if (!currentSelection.size) return;

        const currentlySelectedIds = currentSelection.toJS();

        const deselectedIds = difference(currentlySelectedIds, ids);

        if (!deselectedIds.length) return;

        dispatch(removeSelectedElements({ ids: deselectedIds, transactionId }));
    };

/**
 * Selects elements (in the ids array) who are not already selected and deselect elements who are already selected.
 */
export const toggleSelectedElements =
    ({ ids, rangeAnchors, transactionId }) =>
    (dispatch, getState) => {
        const state = getState();
        const currentSelection = getSelectedElementIds(state);

        let selectedIds = ids;
        if (currentSelection.size) {
            const currentlySelectedIds = currentSelection.toJS();

            // Any ID which was selected and is in the ids array that's been toggled should be deselected
            const deselectedIds = intersection(ids, currentlySelectedIds);
            dispatch(removeSelectedElements({ ids: deselectedIds, transactionId }));

            selectedIds = difference(ids, currentlySelectedIds);
        }

        return dispatch(addSelectedElements({ ids: selectedIds, rangeAnchors, transactionId }));
    };

export const deselectAllElements =
    (args = {}) =>
    (dispatch, getState) => {
        const { transactionId, sync = true } = args;
        const state = getState();

        const currentSelection = getSelectedElementIds(state);
        if (currentSelection.size) {
            dispatch({
                type: SELECTION_ACTION_TYPES.ELEMENTS_DESELECT_ALL,
                ids: currentSelection.toJS(),
                sync,
                timestamp: getTimestamp(),
                transactionId,
            });
        }

        const editingElementId = getCurrentlyEditingId(state);
        if (editingElementId) {
            dispatch(finishEditingElement(editingElementId));
        }
    };

export const forceRemoteDeselectElements = (userId, ids) => (dispatch) => {
    dispatch({
        type: SELECTION_ACTION_TYPES.ELEMENTS_FORCE_REMOTE_DESELECT,
        userId,
        ids,
        sync: true,
        timestamp: getTimestamp(),
    });
};

const isChild = (ids) => (el) => ids.indexOf(getLocationParentId(el)) !== -1;

const getElementsToSelectWithSelection = (state) => {
    const selectedElements = getSelectedElements(state);
    const allElements = getElements(state);
    const currentBoardId = getCurrentBoardId(state);

    if (selectedElements.every(isInColumn(allElements))) return allElements.filter(isInColumn(allElements));

    if (selectedElements.every(isLocationCanvas)) return allElements.filter(isLocationCanvas);

    if (selectedElements.every(isInUnsortedNotes(allElements, currentBoardId))) {
        return allElements.filter(isInUnsortedNotes(allElements, currentBoardId));
    }

    return allElements;
};

const getElementsToSelectWithoutSelection = (state) => {
    const currentFocusedSection = getCurrentFocus(state);
    const allElements = getElements(state);
    const currentBoardId = getCurrentBoardId(state);

    if (currentFocusedSection === WORKSPACE_SECTIONS.QUICK_NOTES) {
        const quickNotesId = getCurrentUserQuickNotesRootBoardId(state);
        return allElements.filter((el) => getLocationParentId(el) === quickNotesId);
    }

    return currentFocusedSection === WORKSPACE_SECTIONS.CANVAS
        ? allElements.filter(isLocationCanvas)
        : allElements.filter(isInUnsortedNotes(allElements, currentBoardId));
};

const getParentIdsForSelection = (state) => {
    const currentFocusedSection = getCurrentFocus(state);

    if (currentFocusedSection === WORKSPACE_SECTIONS.QUICK_NOTES) {
        const quickNotesId = getCurrentUserQuickNotesRootBoardId(state);
        return [quickNotesId];
    }

    const selectedElements = getSelectedElements(state);
    const allElements = getElements(state);
    const currentBoardId = getCurrentBoardId(state);

    return selectedElements.size && selectedElements.every(isInColumn(allElements))
        ? selectedElements.map((el) => getLocationParentId(el))
        : [currentBoardId];
};

export const selectAllPersonalTrashElements = () => (dispatch, getState) => {
    const state = getState();
    const trashElementIds = trashedByCurrentUserElementIdsSelector(state);
    dispatch(setSelectedElements({ ids: trashElementIds }));
};

export const selectAllOthersTrashElements = () => (dispatch, getState) => {
    const state = getState();
    const trashElementIds = trashedByOtherUsersElementIdsSelector(state);
    dispatch(setSelectedElements({ ids: trashElementIds }));
};

export const selectAllElements = () => (dispatch, getState) => {
    const state = getState();
    const currentFocusedSection = getCurrentFocus(state);

    if (isGuestSelector(state)) return;

    if (currentFocusedSection === WORKSPACE_SECTIONS.TRASH_PERSONAL) return dispatch(selectAllPersonalTrashElements());
    if (currentFocusedSection === WORKSPACE_SECTIONS.TRASH_OTHERS) return dispatch(selectAllOthersTrashElements());

    const selectedElements = getSelectedElements(state);

    const parentIds = getParentIdsForSelection(state);
    const elementsToSelect = selectedElements.size
        ? getElementsToSelectWithSelection(state)
        : getElementsToSelectWithoutSelection(state);

    const ids = elementsToSelect.filter(isChild(parentIds)).keySeq().toJS();
    dispatch(setSelectedElements({ ids }));
};
