import registerComment from '../../../common/comments/index';
import presentationalComponent from './CommentThread';
import containerComponent from './CommentThreadContainer';

import platformSingleton from '../../platform/platformSingleton';
import { isPlatformLegacyMobileApp } from '../../platform/utils/platformDetailsUtils';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerComment({
        containerComponent,
        presentationalComponent,
        selectedTools: [TOOLBAR_TOOLS.COLOR, TOOLBAR_TOOLS.COLLAPSE, ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT],
        editingTools: isPlatformLegacyMobileApp(platformSingleton) ? [] : [...TOOL_SETS.TEXT_EDITING_INLINE],
    });
