// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Utils
import {
    getSelectedElementColorStatusClass,
    getFirstSelectedSecondaryColor,
    getSelectedElementType,
    getSupportColorBar,
} from './colorToolUtils';
import { isCard } from '../../../../../../common/elements/utils/elementTypeUtils';

// Components
import ToolbarPopupTool from '../../ToolbarPopupTool';
import ColorPopup from './popup/ColorPopup';
import ColorToolIcon from './ColorToolIcon';

// Selectors
import { colorToolSelector } from './colorToolSelector';

// Constants
import { BACKGROUND_COLORS } from '../../../../../../common/colors/colorConstants';

// Styles
import './ColorTool.scss';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';

const ColorToolPopupIcon = ({
    selectedElements,
    selectedColor,
    selectedBackgroundColor,
    selectedBackgroundColorName,
    isPopupOpen,
    supportBackgroundColor,
}) => {
    const selectedType = getSelectedElementType(selectedElements);
    const selectedStatus = getSelectedElementColorStatusClass(selectedElements);
    const supportColorBar = getSupportColorBar(selectedElements);
    const secondaryColor = getFirstSelectedSecondaryColor(selectedElements);

    const backgroundColorClass = BACKGROUND_COLORS[selectedBackgroundColorName]?.className;

    return (
        <ColorToolIcon
            key={`${selectedColor}${selectedBackgroundColor}`}
            className={backgroundColorClass}
            supportColorBar={supportColorBar}
            supportBackgroundColor={supportBackgroundColor}
            selectedType={selectedType}
            selectedStatus={selectedStatus}
            color={selectedColor}
            backgroundColor={selectedBackgroundColor}
            isActive={isPopupOpen}
            secondaryColor={secondaryColor}
        />
    );
};

ColorToolPopupIcon.propTypes = {
    openColorPopup: PropTypes.func,
    isPopupOpen: PropTypes.bool,
    selectedElements: PropTypes.object,
    selectedColor: PropTypes.string,
    selectedBackgroundColor: PropTypes.string,
    selectedBackgroundColorName: PropTypes.string,
    supportBackgroundColor: PropTypes.bool,
};

const ColorTool = (props) => {
    const { selectedElements, item } = props;

    const toolName = selectedElements.every(isCard) ? 'Card color' : 'Color';

    return (
        <ToolbarPopupTool
            {...props}
            buttonSelector=".ColorTool .ColorToolIcon"
            popupId={item?.uid || PopupIds.COLOR}
            toolName="color"
            name={item?.name || toolName}
            IconComponent={ColorToolPopupIcon}
            PopupComponent={ColorPopup}
        />
    );
};

ColorTool.propTypes = {
    selectedElements: PropTypes.object,
    useEditingElementColorTool: PropTypes.bool,
    name: PropTypes.string,
    editorKey: PropTypes.string,
    item: PropTypes.object,
};

const enhance = connect(colorToolSelector);

export default enhance(ColorTool);
