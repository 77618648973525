// Lib
import React, { ReactElement } from 'react';

// Components
import ElementExportPopupButton from './ElementExportPopupButton';

// Constants
import { MIME_TYPES } from '../../../common/media/mimeConstants';
import { BOARD_EXPORT_FORMATS, BOARD_EXPORT_QUALITY } from '../../../common/export/exportConstants';
import { FILE_TYPES_INFO } from '../../../common/files/fileConstants';

// Styles
import './ExportPopup.scss';

type Props = {
    elementId: string;
};

const ExportPopupForDocumentModal = ({ elementId }: Props): ReactElement => (
    <div className="ExportPopup">
        <div className="export-section">
            <div className="export-options">
                <h3>Export as...</h3>
                <ElementExportPopupButton
                    elementId={elementId}
                    mimeType={MIME_TYPES.PDF}
                    fileType={FILE_TYPES_INFO.PDF.type}
                    format={BOARD_EXPORT_FORMATS.linear}
                    quality={BOARD_EXPORT_QUALITY.high}
                >
                    PDF
                </ElementExportPopupButton>
                <ElementExportPopupButton
                    elementId={elementId}
                    mimeType={MIME_TYPES.DOCX}
                    fileType={FILE_TYPES_INFO.DOCX.type}
                    format={BOARD_EXPORT_FORMATS.linear}
                >
                    Word document
                </ElementExportPopupButton>
                <ElementExportPopupButton
                    elementId={elementId}
                    mimeType={MIME_TYPES.MARKDOWN}
                    fileType={FILE_TYPES_INFO.TEXT.type}
                    format={BOARD_EXPORT_FORMATS.linear}
                >
                    Markdown
                </ElementExportPopupButton>
                <ElementExportPopupButton
                    elementId={elementId}
                    mimeType={MIME_TYPES.PLAIN_TEXT}
                    fileType={FILE_TYPES_INFO.TEXT.type}
                    format={BOARD_EXPORT_FORMATS.linear}
                >
                    Plain text
                </ElementExportPopupButton>
            </div>
        </div>
    </div>
);

export default ExportPopupForDocumentModal;
