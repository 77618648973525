import registerColumn from '../../../common/columns/index';
import presentationalComponent from './ColumnPresentationalWrapper';
import containerComponent from './ColumnContainer';

import platformSingleton from '../../platform/platformSingleton';
import { isPlatformApple } from '../../platform/utils/platformDetailsUtils';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerColumn({
        presentationalComponent,
        containerComponent,
        tooltip: isPlatformApple(platformSingleton) ? '⌃⌘G' : 'Ctrl+Alt+G',
        selectedTools: [TOOLBAR_TOOLS.COLOR, ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT],
    });
