// Lib
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

// Selectors
import { getCurrentBoardVisibleDescendants } from '../../selectors/currentBoardSelector';

// Utils
import { getCreationTimestamp, getThreadId, getCommentId } from '../../../../common/comments/commentModelUtil';
import { createDeepSelector, createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';
import { getMany } from '../../../../common/utils/immutableHelper';
import { isCommentThread } from '../../../../common/elements/utils/elementTypeUtils';

// Store selector
export const getAllComments = (state) => state.get('comments');
const getPropsThreadId = (state, { threadId }) => threadId;

// ALL COMMENT THREADS

const sortByCreationTimestamp = (commentA, commentB) => getCreationTimestamp(commentB) - getCreationTimestamp(commentA);

/**
 * Creates a map of threadIds to an array of each thread's comment ids.
 */
export const threadCommentIdsMapSelector = createDeepSelector(getAllComments, (comments) =>
    comments.reduce((map, comment) => {
        const commentId = getCommentId(comment);
        const threadId = getThreadId(comment);

        map[threadId] = map[threadId] || [];
        map[threadId].push(commentId);

        return map;
    }, {}),
);

// INDIVIDUAL COMMENT THREADS
const EMPTY_ARRAY = [];

export const getThreadCommentIdsSelector = () =>
    createShallowSelector(
        getPropsThreadId,
        threadCommentIdsMapSelector,
        (threadId, threadCommentIdsMap) => threadCommentIdsMap[threadId] || EMPTY_ARRAY,
    );

export const getThreadCommentsCountSelector = () =>
    createSelector(getThreadCommentIdsSelector(), (commentIds) => commentIds?.length || 0);

const getThreadCommentsSelector = () => createShallowSelector(getThreadCommentIdsSelector(), getAllComments, getMany);

export const makeGetOrderedThreadComments = () =>
    createShallowSelector(getThreadCommentsSelector(), (comments) => comments.valueSeq().sort(sortByCreationTimestamp));

export const getCurrentBoardCommentIdsSelector = createShallowSelector(
    getCurrentBoardVisibleDescendants,
    threadCommentIdsMapSelector,
    (currentElements, threadIdToCommentsMap) =>
        currentElements.filter(isCommentThread).reduce((commentIdArray, comment) => {
            const threadId = getCommentId(comment);
            const commentIds = threadIdToCommentsMap[threadId];

            if (isEmpty(commentIds)) return commentIdArray;

            commentIdArray.push(...commentIds);

            return commentIdArray;
        }, []),
);

export const getCurrentBoardCommentsSelector = createShallowSelector(
    getCurrentBoardCommentIdsSelector,
    getAllComments,
    getMany,
);
