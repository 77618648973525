import * as Immutable from 'immutable';
import { omit } from 'lodash';

import { getCurrentUserToken } from '../auth/authService';

import { INITIAL_STATE_HYDRATION_OMIT } from './initialStateConstants';

const { requestStateSync } = window.desktopIntegration;

const prepareState = (state) =>
    Immutable.fromJS(omit(state, INITIAL_STATE_HYDRATION_OMIT))
        // the main process doesn't have access to the user's token, so thinks they're a guest
        .setIn(['app', 'currentUser', 'isGuest'], !getCurrentUserToken());

const getElectronInitialState = () => {
    const state = requestStateSync();
    if (!state) return;
    return prepareState(state);
};

export default getElectronInitialState;
