// Lib
import * as Immutable from 'immutable';
import {
    COMMENTS_ADD,
    COMMENTS_DELETE,
    COMMENTS_LOAD,
    COMMENTS_UPDATE,
} from '../../../../common/comments/commentConstants';

const initialState = Immutable.Map();

const commentReducer = (comment, action) => {
    const { contentUpdate: { changes = {} } = {}, text: textUpdate } = action;

    switch (action.type) {
        case COMMENTS_UPDATE:
            return comment
                .update('content', (content) =>
                    content ? content.merge(Immutable.fromJS(changes)) : Immutable.fromJS(changes),
                )
                .set('text', textUpdate ? Immutable.fromJS(textUpdate) : comment.get('text'));
        default:
            return comment;
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case COMMENTS_LOAD:
            return state.merge(action.comments);
        case COMMENTS_UPDATE:
            return state.set(action._id, commentReducer(state.get(action._id), action));
        case COMMENTS_ADD: {
            const { type, isUndo, overrideCreatedAt, ...comment } = action; // eslint-disable-line no-unused-vars
            return state.set(action._id, Immutable.fromJS(comment));
        }
        case COMMENTS_DELETE: {
            return state.remove(action._id);
        }
        default:
            return state;
    }
};
