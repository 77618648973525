// Lib
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import RichTextToolList from '../../../workspace/toolbar/components/selectionTools/richContentTools/RichTextToolList';
import ToolbarMeasure from '../../../workspace/toolbar/components/ToolbarMeasure';

// Constants
import { DOCUMENT_MODAL_EDITOR_KEY } from './documentModalConstants';
import { GRID } from '../../../utils/grid/gridConstants';

// Styles
import './DocumentModalSidebar.scss';

// FIXME Change the editorKey logic
const DocumentModalSidebar = (props) => {
    const [availableHeight, setAvailableHeight] = useState(0);

    return (
        <div className={classNames('DocumentModalSidebar', { 'read-only': !props.isEditable })}>
            <ToolbarMeasure setAvailableHeight={setAvailableHeight} unavailableHeight={0} />

            {props.isEditable && (
                <RichTextToolList
                    {...props}
                    gridSize={10}
                    gridSizeName={GRID.LARGE.name}
                    editorKey={DOCUMENT_MODAL_EDITOR_KEY}
                    availableHeight={availableHeight}
                    unavailableHeight={0}
                />
            )}
        </div>
    );
};

DocumentModalSidebar.propTypes = {
    isEditable: PropTypes.bool,
};

export default DocumentModalSidebar;
