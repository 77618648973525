// Lib
import { compose, withProps } from '../../../node_module_clones/recompose';
import { head } from 'lodash/fp';

// Utils
import { propIn } from '../../../common/utils/immutableHelper';

// Components
import poiDecorator from '../../components/pointsOfInterest/poiDecorator';

// Constants
import { POI_TYPES } from '../../components/pointsOfInterest/poiConstants';

export default compose(
    withProps((ownProps) => {
        const { poiData = {}, elementId, commentId } = ownProps;

        const mergedPoiData = {
            count: 1,
            ...poiData,
        };

        if (elementId) {
            mergedPoiData.elementId = elementId;
        }

        if (commentId) {
            mergedPoiData.commentId = commentId;
        }

        const isCommentActivity = !!commentId;
        const activityProperty = isCommentActivity ? 'commentActivity' : 'elementActivity';

        if (isCommentActivity) {
            mergedPoiData.isCommentActivity = true;
        }

        const isElementChange = propIn([activityProperty, 'isElementChange'], ownProps);

        if (isElementChange) {
            mergedPoiData.isElementChange = isElementChange;
        }

        if (isCommentActivity || isElementChange) {
            mergedPoiData.notificationId = head(propIn([activityProperty, 'notificationIds'], ownProps));
        }

        return {
            poiType: POI_TYPES.ACTIVITY,
            poiData: mergedPoiData,
        };
    }),
    poiDecorator,
);
