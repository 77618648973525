export const TEXT_COLOR_PREFIX = 'TEXT';
export const BACKGROUND_COLOR_PREFIX = 'BG';
export const CUSTOM_TEXT_COLOR_HEX_PREFIX = 'TEXT_HEX';

export const CUSTOM_SELECTION_STYLE = 'PSEUDO_SELECTION';

export const TEXT_COLOR_PRESETS = {
    BLUE: `${TEXT_COLOR_PREFIX}-blue`,
    GREEN: `${TEXT_COLOR_PREFIX}-green`,
    ORANGE: `${TEXT_COLOR_PREFIX}-orange`,
    RED: `${TEXT_COLOR_PREFIX}-red`,
    GREY: `${TEXT_COLOR_PREFIX}-grey`,
};

export const TEXT_BACKGROUND_PRESETS = {
    DARK_GREY: `${BACKGROUND_COLOR_PREFIX}-dark-grey`,
    BLUE: `${BACKGROUND_COLOR_PREFIX}-blue`,
    GREEN: `${BACKGROUND_COLOR_PREFIX}-green`,
    ORANGE: `${BACKGROUND_COLOR_PREFIX}-orange`,
    PINK: `${BACKGROUND_COLOR_PREFIX}-pink`,
    GREY: `${BACKGROUND_COLOR_PREFIX}-grey`,
};
