import Core from 'handsontable/core';
import { CellType, CellValueDetailedType } from 'hyperformula';
import { cloneDeep, get, isNil, union } from 'lodash';
import { ContentState, convertFromRaw, RawDraftContentState } from 'draft-js';

// Utils
import rawGetText from '../../../../common/utils/editor/rawUtils/rawGetText';
import hyperFormulaInstance from '../manager/hyperFormulaInstance';
import getContentInlineStyle from '../../../components/editor/customRichUtils/contentState/getContentInlineStyle';
import applyInlineStylesThroughoutContent from '../../../components/editor/customRichUtils/applyInlineStylesThroughoutContent';
import { getColorDisplayValue } from '../../../../common/colors/elementColorFormatUtil';
import { getIsLightElementColor } from '../../../../common/colors/colorComparisonUtil';
import { getIsColorDarkerThan, mixColorsForOverlayEffect } from '../../../../common/colors/coreColorUtil';
import { getBackgroundColorClasses } from '../../utils/elementColorStyleUtils';
import { getColumnCount, getRowCount } from './tableDataUtils';
import { getDefaultTypeObject, shouldUpdateCellType } from './tableCellTypeUtils';
import { isFormula } from './tableFormulaUtils';
import { isCellTypeNumeric } from '../../../../common/table/utils/tableCellDataPropertyUtils';
import {
    getRawTextFromCellContentString,
    parseCellContentString,
} from '../../../../common/table/utils/tableCellContentStringUtils';
import { getRenderCellValueFromNormalisedHotCellValue } from '../../../../common/table/utils/tableCellFormattingUtils';
import { getNumericDateTimeStringFromCellValue } from '../../../../common/table/utils/tableInputDateTimeUtils';
import { getLocalNumberFormattingSymbols } from '../../../../common/utils/localLanguageUtils';
import { stringShouldFormatAsType } from '../../../../common/table/utils/tableInputGeneralUtils';
import { getIsInputNumber } from '../../../../common/table/utils/tableInputNumberUtils';
import { getCurrencyFromString } from '../../../../common/table/utils/tableInputCurrencyUtils';

// Types
import { CellData, DateTimeTypeObject } from '../../../../common/table/TableTypes';

// Constants
import {
    CellTypeConstants,
    CellTypeNames,
    DateStringFormatOptions,
    FormatOptions,
    TextAlignment,
    TimeStringFormatOptions,
} from '../../../../common/table/CellTypeConstants';
import {
    TABLE_CELL_DEFAULT_TEXT_ALIGNMENT,
    TABLE_DEFAULT_COLORS,
    TABLE_DEFAULT_HEADER_BACKGROUND_COLOR,
} from '../../../../common/table/tableConstants';
import { STYLE_COMMANDS } from '../../../components/editor/richText/richTextConstants';

/**************************
 * TABLE CELL RENDERING
 **************************/

/**
 * Convert render value calculated by HyperFormula to a more readable value.
 *
 * This covers cases such as:
 * - If formula is calculated to be a date by HyperFormula, it will be represented in numbers of days from X date,
 *    we want to render this in a readable format.
 */
export const getNormalisedHotCellValue = (
    hotCellValue: string | number,
    locale: string,
    options: { hfType?: string; hfDetailedType?: string } = {},
): string | number => {
    const { hfType, hfDetailedType } = options;

    if (
        typeof hotCellValue === 'number' &&
        hfType === CellType.FORMULA &&
        (hfDetailedType === CellValueDetailedType.NUMBER_DATE ||
            hfDetailedType === CellValueDetailedType.NUMBER_DATETIME ||
            hfDetailedType === CellValueDetailedType.NUMBER_TIME)
    ) {
        const dateTime = hyperFormulaInstance.numberToDateTime(hotCellValue);

        let year = 'year' in dateTime ? dateTime.year : undefined;
        let month = 'month' in dateTime ? dateTime.month : undefined;
        let day = 'day' in dateTime ? dateTime.day : undefined;
        const hours = 'hours' in dateTime ? dateTime.hours : undefined;
        const minutes = 'minutes' in dateTime ? dateTime.minutes : undefined;
        const seconds = 'seconds' in dateTime ? dateTime.seconds : undefined;

        if (hfDetailedType === CellValueDetailedType.NUMBER_TIME) {
            const today = new Date();
            day = today.getDate();
            month = today.getMonth() + 1;
            year = today.getFullYear();
        }

        if (year === undefined || month === undefined) return hotCellValue;

        const date = new Date(year, month - 1, day, hours, minutes, seconds);

        const hasTime =
            hfDetailedType === CellValueDetailedType.NUMBER_DATETIME ||
            hfDetailedType === CellValueDetailedType.NUMBER_TIME;

        const epochTime = date.getTime();
        const dateTypeObject = {
            ...getDefaultTypeObject<DateTimeTypeObject>(CellTypeNames.DATE_TIME),

            [FormatOptions.HAS_TIME]: hasTime,
            [FormatOptions.HAS_SECONDS]: hasTime,
            [FormatOptions.TIME_FORMAT]: TimeStringFormatOptions.TWENTY_FOUR_HOUR_SECONDS,

            ...(hfDetailedType === CellValueDetailedType.NUMBER_TIME
                ? { [FormatOptions.DATE_FORMAT]: DateStringFormatOptions.NONE }
                : {}),
        };

        const numericDateTimeString = getNumericDateTimeStringFromCellValue(
            epochTime.toString(),
            dateTypeObject,
            locale,
        );
        if (numericDateTimeString) return numericDateTimeString;
    }

    return hotCellValue;
};

/**
 * Returns a hotCellValue with formula date results converted to numeric strings
 * Useful for knowing what type a cell will be rendered as
 */
export const getNormalisedHotCellValueFromHot = (
    elementId: string,
    row: number | undefined,
    col: number | undefined,
    hot: Core,
) => {
    if (isNil(row) || isNil(col)) return null;

    const hotCellValue = hot.getDataAtCell(row, col);
    const cellValue = hot.getCellMeta(row, col)?.cellData?.value;
    if (!isFormula(cellValue)) return hotCellValue;

    const sheet = hyperFormulaInstance.getSheetId(elementId);
    if (isNil(sheet)) return hotCellValue;

    const options = {
        hfType: hyperFormulaInstance.getCellType({ sheet, row, col }),
        hfDetailedType: hyperFormulaInstance.getCellValueDetailedType({ sheet, row, col }),
    };

    // @ts-ignore - custom property
    const { locale } = hot.milanoteProps;

    return getNormalisedHotCellValue(hotCellValue, locale, options);
};

/**
 * Returns the renderCellValue to be displayed in the cell
 * @param cellData
 * @param hotCellValue - if this value is not passed in, formulas will NOT be resolved
 * @param options
 * @param locale
 */
export const getRenderCellValueFromRawHotCellValue = (
    cellData: CellData,
    locale: string,
    hotCellValue: string | number | null = null,
    options: { hfType?: string; hfDetailedType?: string } = {},
): string | number => {
    const value = !isNil(hotCellValue) ? hotCellValue : cellData?.value;
    const rawText = getRawTextFromCellContentString(value ?? '');
    const normalisedHotCellValue = getNormalisedHotCellValue(rawText, locale, options);

    return getRenderCellValueFromNormalisedHotCellValue(cellData, locale, normalisedHotCellValue);
};

export const getCellRenderContentState = (
    cellData: CellData,
    locale: string,
    hotCellValue?: string | number | null,
    options: { hfType?: CellType; hfDetailedType?: CellValueDetailedType } = {},
): ContentState | null => {
    if (isNil(hotCellValue)) return null;

    const parsedContentString = parseCellContentString(hotCellValue);
    const rawText =
        typeof parsedContentString === 'object' ? (rawGetText(parsedContentString) as string) : parsedContentString;

    // Get the render value for different cell types
    const renderCellValue = getRenderCellValueFromRawHotCellValue(cellData, locale, rawText, options);

    // if the value is in draftJS format and is unchanged, use the original value
    const contentState =
        typeof parsedContentString === 'object' && rawText === renderCellValue
            ? convertFromRaw(parsedContentString)
            : ContentState.createFromText(renderCellValue.toString());

    return applyInlineStylesThroughoutContent(contentState, cellData.textStyle);
};

/**
 * Make any changes to convert cellValue -> editCellValue
 * currently converting numbers to use local formatting e,g. 1,000.00 -> 1.000,00
 */
export const getLocalisedContent = (
    content: string | RawDraftContentState,
    cellTypeName: CellTypeNames = CellTypeNames.AUTO,
    locale: string,
): string | number | RawDraftContentState => {
    // We only need to make the change for numeric cell types
    if (!isCellTypeNumeric(cellTypeName) || typeof content === 'object') return content;

    // We don't show thousands separators while editing, so just replace the
    // english decimal separator with the local decimal separator
    const { decimalSeparator } = getLocalNumberFormattingSymbols(locale);
    return content.replaceAll('.', decimalSeparator);
};

/**************************
 * TEXT ALIGNMENT
 **************************/

/**
 * Returns the alignment for a cell based on the text content, otherwise gets
 * the default alignment for the given cell type, or defaults to left
 *
 * NOTE: alignmentOverride will only be applied if the cell type is not text
 */
export const getCellTextAlignment = (
    cellData: CellData,
    locale: string,
    hotCellValue?: string | boolean | number | null,
): TextAlignment => {
    if (!cellData) return TABLE_CELL_DEFAULT_TEXT_ALIGNMENT;

    const { value, type, textAlignment } = cellData;

    if (textAlignment) return textAlignment;

    const cellTypeName = type?.name || CellTypeNames.AUTO;
    const cellTypeDefaultAlignment =
        (CellTypeConstants[cellTypeName].alignment as TextAlignment) || TABLE_CELL_DEFAULT_TEXT_ALIGNMENT;

    // If the cell is empty or is of cell type text, set alignment based on the cell type default
    if (!value || cellTypeName === CellTypeNames.TEXT) return cellTypeDefaultAlignment;

    // Formula results
    if (!isNil(hotCellValue) && typeof hotCellValue === 'number') return TextAlignment.RIGHT;
    if (!isNil(hotCellValue) && typeof hotCellValue === 'boolean') return TextAlignment.LEFT;

    // Strings that should be formatted as numbers
    const text = getRawTextFromCellContentString(value);
    const { newCellType } = stringShouldFormatAsType(String(text), false, locale) || {};

    // return alignment for the new cell type only if there is a new cell type AND
    // current cell type is auto OR new cell type is a valid change from current cell type
    // OR new cell type is the same as current cell type
    if (newCellType && (!type || shouldUpdateCellType(newCellType, type.name) || newCellType === type.name)) {
        return CellTypeConstants[newCellType].alignment as TextAlignment;
    }

    // Text content is a string, align left
    // Display value is either the rendered value in the renderer, or the raw value in the editor
    const displayValue = !isNil(hotCellValue)
        ? getRenderCellValueFromRawHotCellValue(cellData, locale, hotCellValue)
        : text;
    if (isNaN(Number(displayValue))) return TextAlignment.LEFT;

    // Align based on cell type, or default to left
    return cellTypeDefaultAlignment;
};

/**
 * Given the current editCellValue, returns the alignment for the cell IF it should be updated
 * Otherwise returns null (i.e. retain the current alignment)
 * @param editCellValue
 * @param cellData
 * @param locale
 */
export const getCellEditingAlignment = (
    editCellValue: string,
    cellData: CellData,
    locale: string,
): TextAlignment | null => {
    // Max length of 5 characters
    // This is to allow for a 3 letter currency symbol and a space before the decimal e.g. lei 2
    if (editCellValue.length > 5) return null;

    const { currencySymbol } = getCurrencyFromString(editCellValue);
    // This is a specific alignment override for editing
    // If the currency symbol is not a letter, then we want to start aligning right even
    // though without a number it wouldn't normally be converted to a currency type yet
    // e.g. '$' alone will be right aligned because it's not a letter
    const symbolIsNotLetter = currencySymbol && !currencySymbol?.match(/^[a-zA-Z]+$/);
    if (symbolIsNotLetter) return TextAlignment.RIGHT;

    // single character OR a currency symbol that also has a number e.g. K 2.5
    const isCurrencyValue = currencySymbol && getIsInputNumber(editCellValue, true, locale);

    // Return null if we don't want to update the alignment
    const updateAlignment = editCellValue.length === 1 || isCurrencyValue;
    if (!updateAlignment) return null;

    return getCellTextAlignment({ ...cellData, value: editCellValue }, locale);
};

/**************************
 * BACKGROUND AND FORMATTING
 **************************/

export const getCellBackgroundColor = (cellData: CellData): string => {
    const { background = null } = cellData || {};

    const colorValue = getColorDisplayValue(background);

    // if hex value, return it
    if (colorValue && colorValue.match(/^#([0-9a-f]{3}){1,2}$/i)) return colorValue;

    // if the default grey, return the variable
    if (background === TABLE_DEFAULT_HEADER_BACKGROUND_COLOR) return 'var(--ws-table-header-row-background)';

    // if no value, return default element color value
    return 'var(--ws-element-background-primary)';
};

export const getBackgroundColorClass = (cellData: CellData): {} | undefined => {
    const { background = null } = cellData || {};
    if (!background) return;

    if (background === TABLE_DEFAULT_HEADER_BACKGROUND_COLOR) return { 'colored-background': true };

    return getBackgroundColorClasses(background);
};

/**
 * This function determines the background and text style for a new cell based on the neighbouring cells
 * Returns a partial cell data object with the background and text style
 */
const getCellFormatFromNeighbours = (
    row: number,
    col: number,
    dataArray: Array<Array<CellData>>,
    numberAdded: number,
    isAddingRows: boolean,
): Partial<CellData> => {
    const twoBefore = isAddingRows ? { row: row - 2, col } : { row, col: col - 2 };
    const oneBefore = isAddingRows ? { row: row - 1, col } : { row, col: col - 1 };
    const oneAfter = isAddingRows ? { row: row + numberAdded, col } : { row, col: col + numberAdded };
    const twoAfter = isAddingRows ? { row: row + numberAdded + 1, col } : { row, col: col + numberAdded + 1 };

    const twoBeforeCell = get(dataArray, [twoBefore.row, twoBefore.col]);
    const oneBeforeCell = get(dataArray, [oneBefore.row, oneBefore.col]);
    const oneAfterCell = get(dataArray, [oneAfter.row, oneAfter.col]);
    const twoAfterCell = get(dataArray, [twoAfter.row, twoAfter.col]);

    const nRows = getRowCount(dataArray);
    const nCols = getColumnCount(dataArray);

    const previousCount = isAddingRows ? nRows - numberAdded : nCols - numberAdded;

    const newCellDetails: { background?: string; textStyle?: string[]; textAlignment?: TextAlignment } = {};

    // Compare the cell before against the cell 2 before and the cell after
    // or if a single cell, copy the background and text style from that cell
    // e.g. [x][x][NEW] or [][x][NEW][x] or [x][NEW]
    if (oneBeforeCell) {
        const {
            background: beforeBackground,
            textStyle: beforeTextStyle,
            textAlignment: beforeTextAlignment,
        } = oneBeforeCell;

        // if there was only one row or column, return the same background and text style
        if (previousCount === 1) return { background: beforeBackground, textStyle: beforeTextStyle };

        if (twoBeforeCell?.background === beforeBackground || oneAfterCell?.background === beforeBackground) {
            newCellDetails.background = beforeBackground;
        }

        // Check text alignment
        if (
            twoBeforeCell?.textAlignment === beforeTextAlignment ||
            oneAfterCell?.textAlignment === beforeTextAlignment
        ) {
            newCellDetails.textAlignment = beforeTextAlignment;
        }

        // apply all text styles that are present in the two cells before or each cell either side
        newCellDetails.textStyle = beforeTextStyle?.filter(
            (style) => twoBeforeCell?.textStyle?.includes(style) || oneAfterCell?.textStyle?.includes(style),
        );
    }

    // Compare the cell after against the cell 2 after
    // or if a single cell, copy the background and text style from that cell
    // e.g. [][NEW][x][x] or [NEW][x]
    if (oneAfterCell) {
        const {
            background: afterBackground,
            textStyle: afterTextStyle,
            textAlignment: afterTextAlignment,
        } = oneAfterCell;

        // if there was only one row or column, return the same background and text style
        if (previousCount === 1) return { background: afterBackground, textStyle: afterTextStyle };

        // Check background colour
        if (twoAfterCell?.background === afterBackground && !newCellDetails.background) {
            newCellDetails.background = afterBackground;
        }

        // Check text alignment
        if (twoAfterCell?.textAlignment === afterTextAlignment && !newCellDetails.textAlignment) {
            newCellDetails.textAlignment = afterTextAlignment;
        }

        // apply all text styles that are present in both cells after
        const textStylesFromCellsAfter = afterTextStyle?.filter((style) => twoAfterCell?.textStyle?.includes(style));
        // Get common text styles from before and after cells
        newCellDetails.textStyle = union(newCellDetails.textStyle, textStylesFromCellsAfter);
    }

    return newCellDetails;
};

/**
 * This function checks cells nearby to the newly added cells to determine if they should have the same
 * background color or text styling
 * This function also updates hotTable if instance passed
 */
export const continueCellFormatting = (args: {
    dataArray: Array<Array<CellData>>;
    colIndex: number;
    nColsAdded: number;
    rowIndex: number;
    nRowsAdded: number;
    hotTableInstanceRef?: any;
}): Array<Array<CellData>> => {
    const { dataArray, colIndex, nColsAdded, rowIndex, nRowsAdded, hotTableInstanceRef } = args;
    const newData = cloneDeep(dataArray);

    const nRows = getRowCount(newData);
    const nCols = getColumnCount(newData);
    if (nColsAdded > 0) {
        for (let row = 0; row < nRows; row++) {
            for (let col = colIndex; col < colIndex + nColsAdded; col++) {
                // Add the new styles, but don't override any existing styles
                const newCellData = {
                    ...getCellFormatFromNeighbours(row, col, newData, nColsAdded, false),
                    ...newData[row][col],
                };
                newData[row][col] = newCellData;
                if (hotTableInstanceRef?.current)
                    hotTableInstanceRef.current?.setCellMetaObject(row, col, { cellData: newCellData });
            }
        }
    }
    if (nRowsAdded > 0) {
        for (let row = rowIndex; row < rowIndex + nRowsAdded; row++) {
            for (let col = 0; col < nCols; col++) {
                // Add the new styles, but don't override any existing styles
                const newCellData = {
                    ...getCellFormatFromNeighbours(row, col, newData, nRowsAdded, true),
                    ...newData[row][col],
                };
                newData[row][col] = newCellData;
                if (hotTableInstanceRef?.current)
                    hotTableInstanceRef.current?.setCellMetaObject(row, col, { cellData: newCellData });
            }
        }
    }
    return newData;
};

/**
 * This function takes in a color name and returns the hex value of the color
 */
export const getBackgroundColorHex = (color: string, isDarkMode: boolean): string => {
    // these are the light and dark values of --ws-element-background-primary
    if (!color) return isDarkMode ? '#333333' : '#ffffff';

    // these are the light and dark values of --ws-table-header-row-background
    if (color === TABLE_DEFAULT_HEADER_BACKGROUND_COLOR) return isDarkMode ? '#4D4D4D' : '#D2D3D6';

    // @ts-ignore - if it's not a valid key then we just return the color value
    return TABLE_DEFAULT_COLORS[color]?.hex || color;
};

/**
 * This function takes in cell coords and determines if the border should overlap the current cell
 * returns { color, shouldOverlapCurrentCell }
 */
const getNeighbourCellColorInfo = (
    row: number,
    col: number,
    currentColor: string,
    instance: any,
): { color: string | null; shouldOverlapCurrentCell: boolean } => {
    if (row >= instance.countRows() || row < 0 || col >= instance.countCols() || col < 0)
        return { color: null, shouldOverlapCurrentCell: false };

    const cellData = instance.getCellMeta(row, col)?.cellData;
    if (!cellData || !cellData.background) return { color: null, shouldOverlapCurrentCell: false };

    const isDarkMode = instance.milanoteProps.isDarkMode || false;
    const hexColor = getBackgroundColorHex(cellData.background, isDarkMode);

    const isDarker = cellData.background && getIsColorDarkerThan(hexColor, currentColor);

    // should show border for neighbour cell instead of current cell if neighbouring cell is darker
    // than current cell in light mode, or lighter in dark mode
    return {
        color: hexColor,
        shouldOverlapCurrentCell: (isDarkMode ? !isDarker : isDarker) && !!hexColor,
    };
};

/**
 * if cell has a background color, set the renderer border color so that we see border on the top and left edges
 */
export const colorBorders = (hot: Core, row: number, col: number) => {
    const cellData = hot?.getCellMeta(row, col)?.cellData;
    if (!cellData?.background || !hot) return;

    // @ts-ignore - Custom property
    const hexColor = getBackgroundColorHex(cellData.background, hot.milanoteProps.isDarkMode || false);

    // Calculate the new border colour that looks like a white/grey opacity over the background colour
    const defaultBorderColor = getIsLightElementColor(hexColor) ? 'rgb(50, 59, 74)' : 'rgb(255, 255, 255)';
    const borderColor = mixColorsForOverlayEffect(hexColor, defaultBorderColor).toString();

    // Check neighbouring cells for color
    const cellAbove = getNeighbourCellColorInfo(row - 1, col, hexColor, hot);
    const shouldColorTopBorder = !cellAbove.shouldOverlapCurrentCell;

    const cellLeft = getNeighbourCellColorInfo(row, col - 1, hexColor, hot);
    const shouldColorLeftBorder = !cellLeft.shouldOverlapCurrentCell && col !== 0;

    // ********** Set values in the dom **********

    // Wrap in requestAnimationFrame to ensure that the contents of the cell has been rendered properly
    requestAnimationFrame(() => {
        if (!hot || hot.isDestroyed) return;

        // Get the TD and cell renderer elements
        const td = hot.getCell(row, col);
        if (!td) return;

        const renderer = td.querySelector('.MilanoteCellRendererComponent') as HTMLElement;
        if (!renderer) return;

        // remove existing border classes
        renderer.classList.remove(
            'colored-border-top-only',
            'colored-border-left-only',
            'colored-no-border-top-right-corner',
            'colored-border-bottom-right-corner',
        );

        // set the td border color so that it matches if we use a border colour that is different to the standard border colour
        // e.g. a dark coloured cell will have a light border even in light mode
        td.style.borderColor = 'transparent';
        if (col !== hot.countCols() - 1) td.style.borderRightColor = borderColor;
        td.style.borderBottomColor = borderColor;

        renderer.style.borderColor = 'transparent';
        if (shouldColorTopBorder && shouldColorLeftBorder) {
            renderer.style.borderColor = borderColor;
        } else if (shouldColorTopBorder) {
            renderer.style.borderTopColor = borderColor;
            renderer.classList.add('colored-border-top-only');
        } else if (shouldColorLeftBorder) {
            renderer.style.borderLeftColor = borderColor;
            renderer.classList.add('colored-border-left-only');
        }

        // ********** add extra classes for fixing overlapping corners **********
        // By adjusting the top right and bottom right corners on each cell, we can set the colour
        // of all the corners of the table so that they look like they are overlapping in the correct way

        // add a class so that we DON'T cover the top right corner of the current cell
        const cellTopRight = getNeighbourCellColorInfo(row - 1, col + 1, hexColor, hot);
        if (cellTopRight.shouldOverlapCurrentCell) {
            renderer.classList.add('colored-no-border-top-right-corner');
        }

        // add a class so that we DO cover the bottom right corner of the current cell
        const cellBottomRight = getNeighbourCellColorInfo(row + 1, col + 1, hexColor, hot);
        if (!!cellBottomRight.color && !cellBottomRight.shouldOverlapCurrentCell) {
            renderer.classList.add('colored-border-bottom-right-corner');
        }
    });
};

/**
 * This function returns all the text styling that are applied to the whole text inside a cell
 *
 * NOTE: Will exclude styles that are not supported by table cells, or cannot be applied to
 *       cell text to using toolbar
 */
export const getCellLevelTextStyles = (content: ContentState): string[] => {
    return Array.from(getContentInlineStyle(content) || []).filter(
        (style) =>
            ![
                STYLE_COMMANDS.LARGE_HEADING,
                STYLE_COMMANDS.HEADING,
                STYLE_COMMANDS.SMALL_TEXT,
                STYLE_COMMANDS.CODE,
                STYLE_COMMANDS.BLOCKQUOTE,
            ].includes(style),
    );
};
