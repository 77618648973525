// This file contains utility functions for subscription plan properties
// these will replace subscriptionPropertyUtil.js

// Utils
import { ImMap, prop, propIn } from '../../utils/immutableHelper';
import { MNPaymentMethod } from '../customers/customerTypes';
import { CheckoutProduct, ProductLocaleTextKey, ProductPricingScheme } from '../products/productTypes';

// Types
import { MNFullSubscriptionDetail, MNSubscription, MNSubscriptionPlan } from './subscriptionTypes';
import Stripe from 'stripe';

export const getPlanName = (plan: MNSubscriptionPlan | ImMap<MNSubscriptionPlan> | undefined): string =>
    prop('name', plan) || 'Pro';
export const getPlanQuantity = (plan: MNSubscriptionPlan | ImMap<MNSubscriptionPlan> | undefined): number =>
    prop('quantity', plan) || 1;
export const getPlanMaxSeats = (plan: MNSubscriptionPlan | ImMap<MNSubscriptionPlan> | undefined): number =>
    prop('maxSeats', plan) || 1;

export const getPlanPricingScheme = (
    plan: MNSubscriptionPlan | ImMap<MNSubscriptionPlan> | undefined,
): ProductPricingScheme | undefined => propIn(['product', 'pricingScheme'], plan);

export const getPlanLocaleTextKey = (
    plan: MNSubscriptionPlan | ImMap<MNSubscriptionPlan> | undefined,
): ProductLocaleTextKey => propIn(['product', 'localeTextKey'], plan);

export const getSubscriptionPlan = (
    subscription: MNSubscription | ImMap<MNSubscription> | ImMap<MNFullSubscriptionDetail> | undefined,
): MNSubscriptionPlan => prop('plan', subscription);

export const getSubscriptionPlanProduct = (
    plan: MNSubscription | ImMap<MNSubscription> | undefined,
): CheckoutProduct | undefined => prop('product', plan);

export const getPlanProductId = (plan: MNSubscriptionPlan): string | undefined => propIn(['product', '_id'], plan);
export const getPlanPriceId = (plan: MNSubscriptionPlan): string | undefined => propIn(['price', '_id'], plan);
export const getPlanStatus = (plan: MNSubscriptionPlan): Stripe.Subscription.Status | number => prop('status', plan);
export const getPlanActive = (plan: MNSubscriptionPlan): boolean => prop('active', plan) || false;
export const getPlanCancelAtPeriodEnd = (plan: MNSubscriptionPlan): boolean | undefined =>
    propIn(['cancelStatus', 'cancelAtPeriodEnd'], plan);
export const getPlanCancelAt = (plan: MNSubscriptionPlan): number | undefined =>
    propIn(['cancelStatus', 'cancelAt'], plan);

export const getPlanCancelStatus = (plan: MNSubscriptionPlan): MNSubscriptionPlan['cancelStatus'] | undefined =>
    prop('cancelStatus', plan);

export const getPlanPaymentMethodCard = (plan: MNSubscriptionPlan): MNPaymentMethod['card'] | undefined =>
    propIn(['paymentMethod', 'card'], plan);
