// Lib
import React from 'react';
import PropTypes from 'prop-types';

export default (DecoratedComponent) => {
    class focusEditorDecorator extends React.Component {
        cardEditorRef = (c) => {
            const { cardEditorRef } = this.props;
            this.editorElement = c;
            cardEditorRef && cardEditorRef(c);
        };

        focusOnEditor = () => {
            if (!this.editorElement) return;
            this.editorElement.focusOnEditor && this.editorElement.focusOnEditor();
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    focusOnEditor={this.focusOnEditor}
                    cardEditorRef={this.cardEditorRef}
                />
            );
        }
    }

    focusEditorDecorator.propTypes = {
        cardEditorRef: PropTypes.func,
    };

    return focusEditorDecorator;
};
