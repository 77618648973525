import React from 'react';
import PropTypes from 'prop-types';
import rawGetText from '../../../../common/utils/editor/rawUtils/rawGetText';
import Checkbox from '../../../components/editor/plugins/checklist/components/Checkbox';
import { stopPropagationOnly } from '../../../utils/domUtil';
import './CellCheckboxRenderer.scss';
import { parseCellContentString } from '../../../../common/table/utils/tableCellContentStringUtils';

const CellCheckboxRenderer = ({ row, col, textContent, hotTableInstance, isEditable = true }) => {
    const content = parseCellContentString(textContent);
    const plainText = rawGetText(content);
    const value = textContent === true || plainText.toLowerCase() === 'true';

    return (
        <div className="CellCheckboxRenderer">
            <Checkbox
                checked={value}
                onPointerDown={(e) => {
                    e.handled = true;

                    if (!isEditable) return;

                    // Since checkbox can be immediately toggled (without the cell being selected first), there is a
                    // possibility of the checkbox being clicked while another cell is being edited. This will cause
                    // `beforeChange` to be called twice very quickly, causing changes in the edited cell to be reverted.
                    //
                    // The delay here is to give time for other cells to finish editing before we update the checkbox.
                    setTimeout(() => {
                        hotTableInstance.setDataAtCell(row, col, (!value).toString());
                    }, 50);
                }}
                onClick={stopPropagationOnly}
            />
        </div>
    );
};

CellCheckboxRenderer.propTypes = {
    row: PropTypes.number,
    col: PropTypes.number,
    textContent: PropTypes.any,
    hotTableInstance: PropTypes.object,
    isEditable: PropTypes.bool,
};

export default CellCheckboxRenderer;
