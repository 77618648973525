import { useEffect } from 'react';
import useLocalStorage from '../../../../utils/services/localStorage/useLocalStorage';

import { CHECKOUT_NAVIGATION_STACK } from '../checkoutConstants';

const MAX_NAVIGATION_STACK_SIZE = 5;

const useCheckoutNavigationStack = (location: { pathname: string }): string[] => {
    const [navigationStack, setNavigationStack] = useLocalStorage<string[]>(CHECKOUT_NAVIGATION_STACK, []);

    // each time the location changes, add the new path to the navigation stack
    const pathName = location.pathname;
    useEffect(() => {
        const pathIndex = navigationStack.indexOf(pathName);

        const updatedStack =
            pathIndex === -1
                ? // if we haven't visited this path before, add it to the stack
                  [...navigationStack, pathName].slice(-MAX_NAVIGATION_STACK_SIZE)
                : // otherwise, remove all paths after this one
                  navigationStack.slice(0, pathIndex + 1);

        setNavigationStack(updatedStack);
    }, [pathName]);

    // on unmount, clear the navigation stack
    useEffect(() => {
        return () => {
            setNavigationStack([]);
        };
    }, []);

    return navigationStack;
};

export default useCheckoutNavigationStack;
