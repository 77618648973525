import registerDocument from '../../../common/documents/index';
import presentationalComponent from './Document';
import containerComponent from './DocumentContainer';
import modalComponent from './modal/DocumentModal';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';
import { noSelectedElementsInIconViewMode } from '../../workspace/toolbar/config/toolbarToolConfigUtils';

const onlyInCardView = ({ selectedElements }) => noSelectedElementsInIconViewMode(selectedElements);

export default () =>
    registerDocument({
        containerComponent,
        presentationalComponent,
        modalComponent,
        selectedTools: [
            TOOLBAR_TOOLS.COLOR,
            TOOLBAR_TOOLS.DISPLAY_MODE,
            { ...TOOLBAR_TOOLS.CAPTION, availabilityPredicate: onlyInCardView },
            { ...TOOLBAR_TOOLS.REACTION, availabilityPredicate: onlyInCardView },
            ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
        ],
        editingTools_CAPTION: TOOL_SETS.TEXT_EDITING_CAPTION,
    });
