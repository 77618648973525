/**
 * This object can be used when characters need to be added to the next mounted editor, but
 * the editor is not available yet.
 * For example:  The document cards can accept key-presses which will open the card modal
 * and insert those characters into the modal editor.
 */

let typingBuffer = '';

export const clear = () => {
    typingBuffer = '';
};

export const addChar = (char) => {
    if (!char) return;

    typingBuffer += char;
};

export const hasChars = () => !!typingBuffer.length;

export const getChars = () => typingBuffer;
