const { registerRemoteListener, sendClientAction } = window.desktopIntegration;

export default (store) => {
    registerRemoteListener((event, action) => {
        store.dispatch(action);
    });

    return (next) => (action) => {
        if (!action.mainProcess && !action.clientProcess) {
            // Safety guard against potentially sending Immutable objects through the 'ipcRenderer'
            //   Unfortunately the ipcRenderer doesn't completely JSON stringify the action object, as a result
            //   any immutable objects sent via this method will appear to be complex Immutable objects instead
            //   of simple JSON objects.  To avoid this just in case we accidentally send an Immutable object we
            //   can force serialisation & parsing before sending the action object
            const parsedAction = JSON.parse(JSON.stringify(action));
            sendClientAction(parsedAction);
        }

        return next(action);
    };
};
