import { localStorageFeatureTest } from '../../utils/services/localStorage/localStorage';

/**
 * Utility functions to detect platform features.
 * Ideally these should be used in the platform initialisation process to determine the platform details,
 * and then redux or the platformSingleton should be used to retrieve them within code.
 */
export const getIsTouchPlatform = (): boolean =>
    global.window && 'ontouchstart' in window && ((global.navigator && navigator.maxTouchPoints) || 0) > 2;
// Media query used to determine if the device is on a retina display

const RETINA_MEDIA =
    '(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)';
export const getIsRetinaPlatform = (): boolean =>
    global.window && (window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia(RETINA_MEDIA).matches));

export const getPlatformSupportsMouse = (): boolean => global.window?.matchMedia('(pointer:fine)').matches;

export const getPlatformSupportsWebSockets = (): boolean => {
    if (!global.window || !global.location) return false;

    const protocol = 'https:' == global.location?.protocol ? 'wss' : 'ws'; // eslint-disable-line

    if ('WebSocket' in global?.window) {
        const protoBin = 'binaryType' in WebSocket.prototype;
        if (protoBin) {
            return protoBin;
        }
        try {
            return !!new WebSocket(`${protocol}://.`).binaryType;
        } catch (e) {
            // Do nothing
        }
    }
    return false;
};

export const platformSupportsSvg = (): boolean =>
    !!global.document?.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect;

// Content editable
export const platformSupportsContentEditable = () => {
    if (!global.document) return false;

    if (!('contentEditable' in global.document.documentElement)) return false;

    // some mobile browsers (android < 3.0, iOS < 5) claim to support
    // contentEditable, but but don't really. This test checks to see
    // confirms whether or not it actually supports it.

    const div = document.createElement?.('div');
    // @ts-ignore This is an intentional setting of a boolean
    div.contentEditable = true;
    return div.contentEditable === 'true';
};

export const platformSupportsLocalStorage = localStorageFeatureTest;

export const platformSupportsIndexedDb = (): boolean => !!global.indexedDB && typeof global.indexedDB === 'object';

export const platformSupportsWebWorkers = (): boolean => !!global.Worker;
