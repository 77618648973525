// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { identity, constant } from 'lodash';
import { compose } from '../../../node_module_clones/recompose';

// Utils
import dontUpdateForKeys from '../../utils/milanoteRecompose/dontUpdateForKeys';
import { gridSizeSelector } from '../../utils/grid/gridSizeSelector';
import { getCaption, getElementId, getShowCaption } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import newElementDecorator from '../newElementDecorator';
import focusEditorDecorator from './focusEditorDecorator';
import elementLineEdgeDropTarget from '../line/elementLineEdgeDropTarget';
import captionContainerDecorator from '../../components/caption/captionContainerDecorator';
import elementResizeDecorator from '../resizing/elementResizeDecorator';
import cardSaveHeightResizeDecorator from './resizable/cardSaveHeightResizeDecorator';
import cardClipperStateManager from './clipper/cardClipperStateManager';

// Actions
import { updateElement, createAndEditElement } from '../actions/elementActions';
import { moveElementsToTrash } from '../actions/elementShortcutActions';
import { analyticsEvent } from '../../analytics';
import { updateElementType } from './cardActions';
import {
    getNextClipperOperationCompleteTransactionId,
    getNextRedoClipperOperationCompleteTransactionId,
    getNextRedoTransactionId,
    getNextUndoTransactionId,
    redoAction,
    undoAction,
} from '../../utils/undoRedo/undoRedoActions';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import { ElementType } from '../../../common/elements/elementTypes';

// Styles
import Card from './Card';

const mapDispatchToProps = (dispatch) => ({
    dispatchSaveTextContent: ({ id, textContent, transactionId }) =>
        dispatch(updateElement({ id, changes: { textContent }, transactionId })),
    dispatchCreateAndEditNewElement: ({ location, currentBoardId, element, creationSource, content }) => {
        analyticsEvent('created-card-cmd-return');

        const caption = getCaption(element);

        return dispatch(
            createAndEditElement({
                creationSource,
                elementType: ElementType.CARD_TYPE,
                location,
                currentBoardId,
                content: {
                    caption: caption && caption.toJS(),
                    showCaption: getShowCaption(element),
                    ...content,
                },
                select: true,
            }),
        );
    },
    dispatchMoveElementsToTrash: ({ currentBoardId, elementIds }) =>
        dispatch(moveElementsToTrash({ currentBoardId, elementIds })),
    dispatchUpdateElementType: (args) => dispatch(updateElementType(args)),
    onAppUndo: () => {
        const nextUndoTransactionId = dispatch(getNextUndoTransactionId());
        const nextUndoClipperOperationTransactionId = dispatch(getNextClipperOperationCompleteTransactionId());

        if (nextUndoTransactionId && nextUndoClipperOperationTransactionId === nextUndoTransactionId) {
            dispatch(undoAction());
        }
    },
    onAppRedo: () => {
        const nextRedoTransactionId = dispatch(getNextRedoTransactionId());
        const nextRedoClipperOperationTransactionId = dispatch(getNextRedoClipperOperationCompleteTransactionId());

        if (nextRedoTransactionId && nextRedoClipperOperationTransactionId === nextRedoTransactionId) {
            dispatch(redoAction());
        }
    },
});

const resizeDecorators = compose(
    elementResizeDecorator({ getMinWidth: constant(ELEMENT_DEFAULT_WIDTH / 2) }),
    cardSaveHeightResizeDecorator,
    cardClipperStateManager,
    focusEditorDecorator,
);

@connect(gridSizeSelector, mapDispatchToProps)
@elementLineEdgeDropTarget
@dontUpdateForKeys(['dragModifierKeys'])
@captionContainerDecorator
@resizeDecorators
@newElementDecorator
class CardContainer extends React.Component {
    saveContent = (textContent, transactionId) => {
        const { isEditable, element, dispatchSaveTextContent } = this.props;
        if (!isEditable) return;

        dispatchSaveTextContent({ id: getElementId(element), textContent, transactionId });
    };

    moveElementsToTrash = () => {
        const { currentBoardId, element, dispatchMoveElementsToTrash } = this.props;
        dispatchMoveElementsToTrash({ currentBoardId, elementIds: [getElementId(element)] });
    };

    updateElementType = ({ elementType, data }) => {
        const { element, dispatchUpdateElementType } = this.props;
        dispatchUpdateElementType({ id: getElementId(element), elementType, data });
    };

    shouldComponentUpdate(nextProps) {
        return !nextProps.isElementOpenInModal || !this.props.isElementOpenInModal;
    }

    render() {
        const { connectLineEdgeDropTarget, canConnectLineEdge, createNewElement } = this.props;

        const lineEdgeConnector =
            connectLineEdgeDropTarget && canConnectLineEdge ? connectLineEdgeDropTarget : identity;

        return lineEdgeConnector(
            <div>
                <Card
                    {...this.props}
                    updateElementType={this.updateElementType}
                    saveContent={this.saveContent}
                    createNewCard={createNewElement}
                    moveElementsToTrash={this.moveElementsToTrash}
                />
            </div>,
        );
    }
}

CardContainer.propTypes = {
    element: PropTypes.object.isRequired,
    elementId: PropTypes.string,
    currentBoardId: PropTypes.string.isRequired,
    createNewElement: PropTypes.func,
    inList: PropTypes.string,
    canConnectLineEdge: PropTypes.bool,
    gridSize: PropTypes.number,
    isElementOpenInModal: PropTypes.bool,
    connectDropTarget: PropTypes.func,
    dispatchSaveTextContent: PropTypes.func,
    dispatchMoveElementsToTrash: PropTypes.func,
    isEditable: PropTypes.bool,
    connectLineEdgeDropTarget: PropTypes.func,
    dispatchUpdateElementType: PropTypes.func,
};

export default CardContainer;
