// Lib
import { convertFromRaw, EditorState } from 'draft-js';
import { cloneDeep, get, isNil } from 'lodash';
import Core from 'handsontable/core';

// Utils
import { getNormalisedHotCellValueFromHot, getRenderCellValueFromRawHotCellValue } from './tableCellFormattingUtils';
import rawGetText from '../../../../common/utils/editor/rawUtils/rawGetText';
import { isFormula } from './tableFormulaUtils';
import { stringShouldFormatAsType } from '../../../../common/table/utils/tableInputGeneralUtils';
import {
    getDateFormattingOptions,
    getDateTimeValuesFromInputString,
} from '../../../../common/table/utils/tableInputDateTimeUtils';
import { isCellTypeNumeric } from '../../../../common/table/utils/tableCellDataPropertyUtils';

// Types
import {
    AutoCellTypeObject,
    CellCoordsObj,
    CellData,
    CellTypeObject,
    DateTimeTypeObject,
} from '../../../../common/table/TableTypes';

// Constants
import {
    CellTypeConstants,
    CellTypeNames,
    CellTypeObjectNames,
    FormatOptions,
} from '../../../../common/table/CellTypeConstants';
import {
    getRawTextFromCellContentString,
    parseCellContentString,
} from '../../../../common/table/utils/tableCellContentStringUtils';

/**
 * This function just checks the config of the cell type to see if it allows editing, default is true
 * Most cell types are editable, but some like the checkbox are not
 */
export const cellIsReadOnly = (cellData: CellData | undefined): boolean => {
    if (!cellData || !cellData.type) return false;

    return CellTypeConstants[cellData.type.name]?.allowCellEditing === false && !isFormula(cellData.value);
};

export const getDefaultTypeObject = <T extends CellTypeObject>(cellTypeName: CellTypeObjectNames): T => {
    const { formattingOptions = {} } = CellTypeConstants[cellTypeName];
    const defaultOptions: { [key: string]: number | boolean | string | null } = { name: cellTypeName };

    // Loop through the formatting options and set the default value for each
    (Object.keys(formattingOptions) as Array<FormatOptions>).forEach((option) => {
        const value = formattingOptions[option]?.defaultValue;
        if (value === undefined) return;
        defaultOptions[option] = value;
    });
    // Necessary to cast to CellTypeObject object here because it will only match the type after the foreach loop
    return defaultOptions as T;
};

/**
 * Returns an object with all the shared formatting values between the selected cells
 * e.g. if all cells are currency format, object will return {name: 'currency'} but if they
 * don't share the SAME currency (like if one has 'AUD' and another has 'NZD'),
 * then that will not be added to the object, and it will still just return {name: 'currency'}
 * if they all have the accounting format, it will return {name: 'currency', accounting: true}
 */
export const getSharedFormattingValues = (
    dataArray: Array<Array<CellData>>,
    allSelectedCells: Array<CellCoordsObj>,
): Partial<CellTypeObject> | AutoCellTypeObject | undefined => {
    if (!allSelectedCells || allSelectedCells.length === 0) return;

    const selectedCellsTypes = allSelectedCells.map(({ row, col }) => get(dataArray, [row, col])?.type);
    if (selectedCellsTypes.length === 1) return selectedCellsTypes[0] || { name: CellTypeNames.AUTO };

    const sharedCellFormat: { [key: string]: string | number | boolean } = {};

    // Check if all the selected cells have the same cell type name
    const names = new Set<CellTypeNames>();
    selectedCellsTypes.forEach((typeObject) => {
        names.add(typeObject?.name || CellTypeNames.AUTO);
    });
    if (names.size === 1) {
        sharedCellFormat.name = [...names][0];
    }

    // Loop through each of the format options and check if they are the same for all the selected cells
    Object.values(FormatOptions).forEach((option) => {
        const values = new Set<string | number | boolean>();
        selectedCellsTypes.forEach((typeObject) => {
            if (!typeObject) return;

            // Type assertion here is not ideal, but couldn't find a way to make it work otherwise
            // All the format options are valid keys of the CellTypeObject, but for some reason typescript doesn't recognise this
            const value = typeObject[option as unknown as keyof CellTypeObject];
            if (!isNil(value)) values.add(value);
        });

        // if there is a single value in the set, it means all the selected cells have either the same
        // value for that option, or some are undefined
        if (values.size === 1) {
            sharedCellFormat[option] = [...values][0];
        }
    });
    return sharedCellFormat;
};

export const cellCanBeDraftFormatted = (cellData: CellData): boolean => {
    return !isFormula(cellData.value) && (!cellData.type || cellData.type.name === CellTypeNames.TEXT);
};

/**************************
 * UPDATE TABLE CELL TYPE DATA
 **************************/

export const cellTypeUpdateShouldHappen = (
    normalisedHotCellValue: string | number | null,
    newCellType: CellTypeNames,
    locale: string,
): boolean => {
    // Always allow all changes:
    // - to TEXT and AUTO type
    // - for null values
    if ([CellTypeNames.TEXT, CellTypeNames.AUTO].includes(newCellType) || isNil(normalisedHotCellValue)) return true;

    const rawText = rawGetText(parseCellContentString(normalisedHotCellValue)) || String(normalisedHotCellValue);

    // Allow changes to CHECKBOX type, if value is null or boolean
    if (newCellType === CellTypeNames.CHECKBOX) {
        // Return true if value is boolean, if value is null true has already been returned above
        // else we won't allow the change so return false
        return rawText?.toLowerCase() === 'true' || rawText?.toLowerCase() === 'false';
    }

    // Infer type from the current value
    const { newCellType: inferredType } = stringShouldFormatAsType(rawText, false, locale) || {};

    // If the value can't be formatted as a number type or DATE_TIME, return false
    // (e.g. if the value is text)
    if (!inferredType) return false;

    // If the inferred type is the same as the type we are changing to, return true
    if (newCellType === inferredType) return true;

    // Numeric types can be switched between each other
    // If the inferred type is a numeric type and the new type is a numeric type, return true
    // otherwise return false for all other scenarios
    return isCellTypeNumeric(newCellType) && isCellTypeNumeric(inferredType);
};

export const updateCellType = (
    prevCellData: CellData,
    cellTypeName: CellTypeNames,
    currencyPreference: string,
    elementId: string,
    row: number | undefined,
    col: number | undefined,
    hot: Core | null,
): CellData => {
    const newCellData = cloneDeep(prevCellData);

    // If clicking the same type again, don't make any changes
    if (prevCellData.type?.name === cellTypeName) return newCellData;

    if (cellTypeName === CellTypeNames.AUTO) {
        // If the cell type is being changed to auto, just remove the type object
        newCellData.type = undefined;
        return newCellData;
    }

    if (!hot) return prevCellData;

    // @ts-ignore - custom property
    const { locale } = hot.milanoteProps;

    // check that the value makes sense with the new type, if not, return existing type
    // Even though we disable some options, it's important to still do the check here in case
    // we are updating multiple different types, and they can have different results
    const normalisedHotCellValue = getNormalisedHotCellValueFromHot(elementId, row, col, hot);
    if (!cellTypeUpdateShouldHappen(normalisedHotCellValue, cellTypeName, locale)) return prevCellData;

    newCellData.type = {
        ...getDefaultTypeObject(cellTypeName),
        // If the type is currency, set currency to currencyPreference instead of the default value
        ...(cellTypeName === CellTypeNames.CURRENCY && { [FormatOptions.CURRENCY]: currencyPreference }),
    };

    // If the new type is TEXT, use the display value of the previous type
    if (cellTypeName === CellTypeNames.TEXT && prevCellData.type?.name) {
        newCellData.value = getRenderCellValueFromRawHotCellValue(prevCellData, locale).toString();
    }

    // If the CURRENT cell type is dateTime, convert the value to raw and calculate the formatting options
    if (newCellData.type.name === CellTypeNames.DATE_TIME) {
        const rawText = rawGetText(parseCellContentString(newCellData.value || ''));
        const dateValues = getDateTimeValuesFromInputString(rawText, locale);

        // if the current content can be converted to a date time, update the value and formatting options
        if (dateValues) {
            const { hasTime, hasSeconds, dateFormat } = dateValues;
            newCellData.value = rawText;

            // It's necessary to add these values to the type object here so that they are present
            // when we pass it to the getDateFormattingOptions function
            newCellData.type = { ...newCellData.type, hasTime, hasSeconds, dateFormat };

            // Calculate formatting options based on the new value (e.g. should show time or not)
            const format = getDateFormattingOptions(newCellData.value, newCellData.type, {
                prevCellValue: null,
                prevTypeObject: prevCellData.type,
                locale,
            });
            newCellData.type = { ...newCellData.type, ...format };
        }
    }

    // e.g. TEXT -> CURRENCY
    if (cellCanBeDraftFormatted(prevCellData) && !cellCanBeDraftFormatted(newCellData)) {
        const content = newCellData.value && parseCellContentString(newCellData.value);

        if (content && typeof content === 'object') {
            newCellData.value = rawGetText(content);
            newCellData.textStyle = EditorState.createWithContent(convertFromRaw(content))
                .getCurrentInlineStyle()
                .toArray();
        }
    }

    return newCellData;
};

/**
 * Returns a boolean value indicating whether the cell type should be updated based on new input
 * Returns true if current type is auto, or new types are currency or percentage
 * or if the current type is DateTime and the new type is Number
 * And the new type is not the same as the current type
 */
export const shouldUpdateCellType = (
    existingTypeName: CellTypeNames | undefined,
    newTypeName: CellTypeNames,
): boolean =>
    existingTypeName !== newTypeName &&
    (!existingTypeName ||
        newTypeName === CellTypeNames.PERCENTAGE ||
        newTypeName === CellTypeNames.CURRENCY ||
        newTypeName === CellTypeNames.DATE_TIME ||
        (existingTypeName === CellTypeNames.DATE_TIME && newTypeName === CellTypeNames.NUMBER));

/**
 * If the user enters some content related to a certain cell type (such as
 * a currency symbol in front of a number), update the cell type to match and return the number value
 */
export const formatCellAsType = (
    cellData: CellData,
    oldCellValue: string | null = null,
    locale: string,
    currencyPreference?: string,
): CellData => {
    const { type: existingType, value = '' } = cellData;
    const { name: existingTypeName } = existingType || {};
    const { NUMBER, CURRENCY, PERCENTAGE, TEXT, CHECKBOX } = CellTypeNames;

    // Don't format these types
    if (existingTypeName === TEXT || existingTypeName === CHECKBOX) return cellData;

    const cellValue = value || '';

    const rawText = getRawTextFromCellContentString(cellValue);

    // Return if empty or formula result
    if (!rawText || typeof rawText === 'number') return cellData;

    const formatType = stringShouldFormatAsType(rawText.toString(), true, locale, currencyPreference);
    if (!formatType) return cellData;

    // **************************************************
    // Determine whether to update just the value, or both type and value

    const { newCellType, newCellValue, otherTypeOptions } = formatType;

    // Update cell type if current type is auto, or new types are currency or percentage
    // or if the current type is DateTime and the new type is Number
    const shouldUpdateType = shouldUpdateCellType(existingTypeName, newCellType);

    // Update cell value if we are updating the type, new type matches current, or
    // current type is currency/percentage and the new type is number
    const shouldUpdateValue =
        shouldUpdateType ||
        existingTypeName === newCellType ||
        ((existingTypeName === CURRENCY || existingTypeName === PERCENTAGE) && newCellType === NUMBER);

    // If neither value nor type&value update are allowed, don't update anything
    if (!shouldUpdateValue && !shouldUpdateType) return cellData;

    // **************************************************
    // Apply the new cell, type and formatting values

    const newCellData = cloneDeep(cellData);

    if (shouldUpdateType) newCellData.type = getDefaultTypeObject(newCellType);

    // add any newly calculated type options to the type object, even if the type wasn't updated
    // this is because it's most likely formatting options based on the new input
    newCellData.type = { ...newCellData.type, ...otherTypeOptions } as CellTypeObject;

    // Update the value, either in draftjs format or as a string
    newCellData.value = cellCanBeDraftFormatted(newCellData)
        ? cellValue.replace(rawText, newCellValue.toString())
        : newCellValue.toString();

    // Set some dateTime specific format options
    if (newCellData.type?.name === CellTypeNames.DATE_TIME) {
        newCellData.type = {
            ...newCellData.type,
            ...getDateFormattingOptions(newCellValue, newCellData.type as DateTimeTypeObject, {
                prevCellValue: oldCellValue,
                prevTypeObject: existingType,
                rawInput: rawText.toString(),
                locale,
            }),
        } as DateTimeTypeObject;
    }

    return newCellData;
};
