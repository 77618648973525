// Lib
import { uniq } from 'lodash/fp';

// Selectors
import { getNotificationsSelector } from './notificationsSelector';
import { getElements } from '../element/selectors/elementsSelector';
import { getAllComments } from '../element/comment/store/commentsSelector';
import { parentIdMapSelector } from '../element/selectors/elementGraphSelector';
import {
    getNotificationCommentIds,
    getNotificationCommentThreadIds,
    getNotificationElementChanges,
    getNotificationElementId,
    getNotificationMentionCommentId,
    getNotificationMentionCommentThreadId,
    getNotificationMentionElementId,
    getNotificationReminderElementId,
    getNotificationReminders,
    getNotificationType,
} from '../../common/notifications/notificationModelUtils';
import {
    getClosestAncestorBoardId,
    getElement,
    isElementOrAncestorInTrash,
} from '../../common/elements/utils/elementTraversalUtils';
import { isLocationTrash } from '../../common/elements/utils/elementLocationUtils';
import { isSkeleton, isTaskList } from '../../common/elements/utils/elementTypeUtils';

// Utils
import { createShallowSelector } from '../utils/milanoteReselect/milanoteReselect';
import { getMany, isEmpty } from '../../common/utils/immutableHelper';
import { getAllAncestorIds } from '../../common/dataStructures/treeUtils';
import {
    getNotificationReferencedElementIds,
    getNotificationsReferencedCommentIds,
} from '../../common/notifications/notificationsListUtils';
import { getThreadId } from '../../common/comments/commentModelUtil';

// Constants
import { NOTIFICATION_TYPES } from '../../common/notifications/notificationConstants';

/**
 * Element updates might be for elements that have been deleted already, or cannot be shown to the user,
 * so we should filter these out of the notification.
 */
const canShowElementUpdate = (element) => {
    if (!element) return false;
    if (isLocationTrash(element)) return false;
    if (isSkeleton(element)) return false;
    // Task List notifications are not rendered, so they should be ignored.
    // This can happen when a card is converted to a task list using the [] shortcut
    if (isTaskList(element)) return false;
    return true;
};

const mapBoardUpdateNotificationData = (elements, comments, notification) => {
    const boardId = getNotificationElementId(notification);

    const elementChanges = getNotificationElementChanges(notification);

    // A share reminder without element changes is still valid
    if (!elementChanges) return notification;

    const existingElementChanges = elementChanges.filter((elementChange, elementId) => {
        const element = getElement(elements, elementId);

        // Deleted elements should be removed from the changes
        if (!canShowElementUpdate(element)) return false;

        const parentBoardId = getClosestAncestorBoardId(elements, elementId);

        // Element still exists but has been moved to a new board
        return parentBoardId === boardId;
    });

    // All elements have been deleted or moved out, so don't show this notification
    if (existingElementChanges.size === 0) return null;

    // No elements have been deleted or moved out, so just return the notification as is
    if (existingElementChanges.size === elementChanges.size) return notification;

    return notification.setIn(['details', 'elementChanges'], existingElementChanges);
};

const mapCommentNotificationData = (elements, comments, notification) => {
    const boardId = getNotificationElementId(notification);

    const commentThreadIds = getNotificationCommentThreadIds(notification);
    const commentIds = getNotificationCommentIds(notification);

    if (!commentThreadIds || !commentIds) return null;

    const existingCommentThreadIds = commentThreadIds.filter((commentThreadId) => {
        const commentElement = getElement(elements, commentThreadId);

        if (!commentElement) return false;

        const parentBoardId = getClosestAncestorBoardId(elements, commentThreadId);
        return parentBoardId === boardId;
    });

    if (existingCommentThreadIds.isEmpty()) return null;

    const existingCommentIds = commentIds.filter((commentId) => {
        const comment = comments.get(commentId);
        const threadId = getThreadId(comment);
        return !!comment && existingCommentThreadIds.includes(threadId);
    });

    if (existingCommentIds.isEmpty()) return null;

    // The comments haven't changed, so just return the current comment notification
    if (existingCommentThreadIds.size === commentThreadIds.size && existingCommentIds.size === commentIds.size) {
        return notification;
    }

    return notification
        .setIn(['details', 'commentThreadIds'], existingCommentThreadIds)
        .setIn(['details', 'commentIds'], existingCommentIds);
};

const mapMentionNotificationData = (elements, comments, notification) => {
    const boardId = getNotificationElementId(notification);

    // If we don't have the referenced element then don't show
    const elementId =
        getNotificationMentionElementId(notification) || getNotificationMentionCommentThreadId(notification);

    const element = getElement(elements, elementId);
    if (!element || isLocationTrash(element) || isSkeleton(element)) return null;

    const closestBoardId = getClosestAncestorBoardId(elements, elementId);

    if (!closestBoardId || closestBoardId !== boardId) return null;

    const commentId = getNotificationMentionCommentId(notification);
    if (commentId && !comments.has(commentId)) return null;

    return notification;
};

const mapBoardTasksReminderNotificationData = (elements, comments, notification) => {
    const reminders = getNotificationReminders(notification);

    if (!reminders) return null;

    // Ensure the reminder element exists and isn't in the trash
    const existingReminders = reminders.filter((reminder) => {
        const reminderElementId = getNotificationReminderElementId(reminder);
        return !!getElement(elements, reminderElementId) && !isElementOrAncestorInTrash(elements, reminderElementId);
    });

    if (isEmpty(existingReminders)) return null;

    // Return the original notification if it has all of the reminders unchanged
    if (existingReminders.size === reminders.size) return notification;

    return notification.setIn(['details', 'reminders'], existingReminders);
};

export const mapNotificationData = (elements, comments) => (notification) => {
    const type = getNotificationType(notification);

    // If a notification references a boardId which is no longer accessible, then remove the notification
    const boardId = getNotificationElementId(notification);
    if (boardId) {
        const board = getElement(elements, boardId);

        // If the board has been deleted, don't modify this notification, just return it as is
        if (!board || isLocationTrash(board) || isSkeleton(board)) return null;
    }

    switch (type) {
        case NOTIFICATION_TYPES.BOARD_UPDATE:
        case NOTIFICATION_TYPES.BOARD_TASKS_UPDATE:
        case NOTIFICATION_TYPES.SHARE_REMINDER:
            return mapBoardUpdateNotificationData(elements, comments, notification);
        case NOTIFICATION_TYPES.COMMENT:
            return mapCommentNotificationData(elements, comments, notification);
        case NOTIFICATION_TYPES.MENTION:
            return mapMentionNotificationData(elements, comments, notification);
        case NOTIFICATION_TYPES.BOARD_TASKS_REMINDER:
            return mapBoardTasksReminderNotificationData(elements, comments, notification);
        // Share, unshare, board view
        default:
            return notification;
    }
};

/**
 * The following selectors are used to get only the elements and comments that are relevant to
 * the notifications.
 * By doing this, the cleansedNotificationsSelector will only recalculate when there's been
 * changes to the elements or comments it references.
 */
const notificationReferencedElementIdsSelector = createShallowSelector(getNotificationsSelector, (notifications) =>
    getNotificationReferencedElementIds(notifications.toArray()),
);

const referenceNotificationElementIdsAndAncestorIdsSelector = createShallowSelector(
    notificationReferencedElementIdsSelector,
    parentIdMapSelector,
    (referencedElementIds, parentIdMap) => {
        const allElementIds = referencedElementIds.reduce((acc, elementId) => {
            const ancestorIds = getAllAncestorIds(parentIdMap, elementId);
            acc.push(elementId, ...ancestorIds);
            return acc;
        }, []);

        return uniq(allElementIds);
    },
);

const referencedElementsSelector = createShallowSelector(
    referenceNotificationElementIdsAndAncestorIdsSelector,
    getElements,
    getMany,
);

const notificationReferencedCommentIdsSelector = createShallowSelector(getNotificationsSelector, (notifications) =>
    getNotificationsReferencedCommentIds(notifications.toArray()),
);
const referencedCommentsSelector = createShallowSelector(
    notificationReferencedCommentIdsSelector,
    getAllComments,
    getMany,
);

/**
 * This selector "cleanses" notifications by removing notifications & data which no longer exists within
 * the boards they refer to.
 * For example, if a mention notification refers to an element that's now in the trash, the mention
 * notification is removed from the list.
 */
export default createShallowSelector(
    getNotificationsSelector,
    referencedElementsSelector,
    referencedCommentsSelector,
    (notifications, elements, comments) =>
        notifications.map(mapNotificationData(elements, comments)).filter((notification) => !!notification),
);
