// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import EditableTitle from '../../../components/editableTitle/EditableTitle';
import ExportPopupForDocumentModal from '../../../workspace/export/ExportPopupForDocumentModal';

// Utils
import ExportUtil from '../../../workspace/export/ExportUtil';

// Constants
import { PopupIds, PopupSections } from '../../../components/popupPanel/popupConstants';
import { DOCUMENT_DEFAULT_TITLE } from '../../../../common/documents/documentConstants';

// Styles
import './DocumentModalHeader.scss';

const DocumentModalHeader = (props) => {
    const { element, elementId, isEditable, editorKey, onStopEditing } = props;

    return (
        <div className="DocumentModalHeader">
            <div className="header-left" />
            <div className="header-center">
                <EditableTitle
                    selectAllOnEdit
                    element={element}
                    elementId={elementId}
                    isEditable={isEditable}
                    editorKey={editorKey}
                    initialValue={DOCUMENT_DEFAULT_TITLE}
                    onStopEditing={onStopEditing}
                />
            </div>
            <div className="header-right">
                <ExportUtil
                    popupId={PopupIds.EXPORT_DOCUMENT}
                    popupSection={PopupSections.MODAL_HEADER}
                    exportPopupElement={<ExportPopupForDocumentModal elementId={elementId} />}
                />
            </div>
        </div>
    );
};

DocumentModalHeader.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,

    editorKey: PropTypes.string,

    isEditable: PropTypes.bool,

    onStopEditing: PropTypes.func,
};

export default DocumentModalHeader;
