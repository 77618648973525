// Lib
import { pick, get, has } from 'lodash';

// Utils
import { sanitiseEventTypeName } from './amplitudeEventTypesUtil';
import { toDateString, toMonthString } from '../utils/timeUtil';
import { isCommentThread, isDocument, isFile, isTaskLike } from '../elements/utils/elementTypeUtils';
import {
    getStripeSubscriptionDetailCustomerId,
    getStripeSubscriptionDetailStart,
    getSubscriptionAdminId,
    getSubscriptionCanceled,
    getSubscriptionId,
    getSubscriptionPlanCanceledAt,
    getSubscriptionPlanCreatedAt,
    getSubscriptionPlanName,
    getSubscriptionPlanPriceAmount,
    getSubscriptionPlanPriceInterval,
    getSubscriptionPlanProviderPriceId,
    getSubscriptionPlanQuantity,
    getSubscriptionProvider,
    getSubscriptionStatus,
} from '../payment/subscriptionPropertyUtil';
import { safeObjectIdToString } from '../utils/objectIdUtil';

// Constants
import {
    ELEMENT_CREATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_UPDATE,
    ELEMENT_CREATION_SOURCES,
    ELEMENT_UPDATE_TYPE,
} from '../elements/elementConstants';
import { BoardSections } from '../boards/boardConstants';
import {
    FETCH_ME,
    SUBSCRIPTION_ADD,
    SUBSCRIPTION_CANCEL,
    SUBSCRIPTION_REMOVE,
    SUBSCRIPTION_START,
    USER_INVITE,
    USER_REGISTER,
    USER_REFERRAL,
    AMPLITUDE_USER_PROPS,
    TRACKED_FEATURES,
    MENTIONED_A_USER,
    MENTIONED_BY_USER,
    USER_EMAIL_VERIFIED,
    ASSIGNED_TASK_TO_A_USER,
    ASSIGNED_TASK_BY_A_USER,
} from './statsConstants';
import { GUEST_NAVIGATE, USER_NAVIGATE, PLATFORMS } from '../users/userConstants';
import { ELEMENT_SEARCH_SUBMIT } from '../../client/components/search/searchPopupConstants';
import { COMMENTS_ADD } from '../comments/commentConstants';
import { PUSH_NOTIFICATION_SENT } from '../../server/app/components/notifications/pushNotifications/pushNotificationActions';
import { ELEMENT_FEATURES } from '../../client/element/feature/elementFeatureConstants';

const getIsHeaderCardElementCreate = (event) =>
    get(event, ['content', 'textContent', 'blocks', 0, 'data', 'header-title']);

const getMultiMoveEventProperties = (event) => ({
    count: event.moves.length,
    fromTrash: event.moves.filter((move) => get(move, ['from', 'section']) === BoardSections.TRASH).length,
    toTrash: event.moves.filter((move) => get(move, ['location', 'section']) === BoardSections.TRASH).length,
});

const getSubscriptionEventProperties = (event) => {
    const subscription = get(event, ['fullSubscriptionDetail']);
    return {
        id: getSubscriptionId(subscription),
        adminId: safeObjectIdToString(getSubscriptionAdminId(subscription)),
        provider: getSubscriptionProvider(subscription),
        status: getSubscriptionStatus(subscription),
        created: getSubscriptionPlanCreatedAt(subscription),
        canceled: getSubscriptionCanceled(subscription),
        canceled_at: getSubscriptionPlanCanceledAt(subscription),
        quantity: getSubscriptionPlanQuantity(subscription),
        plan: {
            id: getSubscriptionPlanProviderPriceId(subscription),
            amount: getSubscriptionPlanPriceAmount(subscription),
            name: getSubscriptionPlanName(subscription),
            interval: getSubscriptionPlanPriceInterval(subscription),
            created: getSubscriptionPlanCreatedAt(subscription),
        },

        // For Stripe Only
        start: getStripeSubscriptionDetailStart(subscription),
        customer: getStripeSubscriptionDetailCustomerId(subscription),
    };
};

const getElementCreationTypeFeatureUsed = (event) => {
    if (isFile(event)) return { [TRACKED_FEATURES.FILES]: true };
    if (isCommentThread(event)) return { [TRACKED_FEATURES.COMMENTS]: true };
    if (isTaskLike(event)) return { [TRACKED_FEATURES.CHECKLIST]: true };
    if (isDocument(event)) return { [ELEMENT_FEATURES.DOCUMENT]: true };
    if (getIsHeaderCardElementCreate(event)) return { [ELEMENT_FEATURES.HEADER]: true };
};

const getElementCreationSourceFeatureUsed = (event) => {
    switch (event.creationSource) {
        case ELEMENT_CREATION_SOURCES.CMD_ENTER:
            return { [TRACKED_FEATURES.CMD_ENTER_NOTE]: true };
        case ELEMENT_CREATION_SOURCES.IMAGE_SEARCH:
            return { [TRACKED_FEATURES.IMAGE_SEARCH]: true };
        case ELEMENT_CREATION_SOURCES.DOUBLE_CLICK:
            return { [TRACKED_FEATURES.DOUBLE_CLICK_NOTE]: true };
        case ELEMENT_CREATION_SOURCES.FILE_UPLOAD_POPUP:
            return { [TRACKED_FEATURES.FILE_UPLOAD_VIA_POPUP]: true };
        case ELEMENT_CREATION_SOURCES.IMAGE_UPLOAD_POPUP:
            return { [TRACKED_FEATURES.IMAGE_UPLOAD_VIA_POPUP]: true };
        case ELEMENT_CREATION_SOURCES.QUICK_LINE:
            return { [TRACKED_FEATURES.QUICK_LINE]: true };
        default:
            break;
    }
};

const getElementCreateUserProperties = (event) => {
    const featuresUsed = {
        ...getElementCreationTypeFeatureUsed(event),
        ...getElementCreationSourceFeatureUsed(event),
    };

    if (!Object.keys(featuresUsed).length) return;

    return { [AMPLITUDE_USER_PROPS.FEATURE]: featuresUsed };
};

const getAmplitudeEventProperties = (event) => {
    switch (event.type) {
        case ELEMENT_CREATE: {
            const eventProps = pick(event, [
                'duplicate',
                'elementType',
                'location.section',
                'content.drawingMode',
                'content.longForm',
                'content.linkType',
                'creationSource',
            ]);

            if (getIsHeaderCardElementCreate(event)) {
                const content = eventProps.content || {};
                eventProps.content = {
                    ...content,
                    headerCard: true,
                };
            }

            return eventProps;
        }
        case ELEMENT_MOVE_MULTI:
            return getMultiMoveEventProperties(event);
        case ELEMENT_UPDATE: {
            const count = event.updates ? event.updates.length : 1;
            const props = { count };
            if (has(event, ['updates', 0, 'changes', 'file', 'size'])) {
                props.fileSize = get(event, ['updates', 0, 'changes', 'file', 'size']);
                props.fileExt = get(event, ['updates', 0, 'changes', 'file', 'ext']);
            }
            return props;
        }
        case USER_NAVIGATE:
        case GUEST_NAVIGATE:
            return pick(event, ['isDemo']);
        case USER_INVITE:
            return pick(event, ['referrerId']);
        case SUBSCRIPTION_START:
        case SUBSCRIPTION_ADD:
            return getSubscriptionEventProperties(event);
        case ELEMENT_SEARCH_SUBMIT:
            return pick(event, ['query']);
        case PUSH_NOTIFICATION_SENT:
            return {
                type: get(event, 'pushType'),
            };
        case USER_REGISTER: {
            const eventProps = {
                platform: get(event, 'platform'),
            };

            const registrationEntryPoint = get(event, 'registrationEntryPoint');

            if (registrationEntryPoint) {
                eventProps.registrationEntryPoint = registrationEntryPoint;
            }

            return eventProps;
        }
        default:
            return {};
    }
};

export const getAmplitudeUserProperties = (event, time) => {
    switch (event.type) {
        case ELEMENT_CREATE:
            return getElementCreateUserProperties(event);
        case FETCH_ME:
            return { userAgent: event.userAgent };
        case USER_INVITE:
            return {
                [AMPLITUDE_USER_PROPS.STATUS]: 'INVITED',
                [AMPLITUDE_USER_PROPS.EMAIL]: get(event, ['user', 'email']),
                [AMPLITUDE_USER_PROPS.INVITATION_DATE]: toDateString(time),
                [AMPLITUDE_USER_PROPS.INVITED_FROM_BOARD_FLAG]: true,
                [AMPLITUDE_USER_PROPS.REFERRED_BY_USER]: !!event.referrerId,
            };
        case USER_REGISTER: {
            const userProperties = {
                [AMPLITUDE_USER_PROPS.STATUS]: 'REGISTERED',
                [AMPLITUDE_USER_PROPS.EMAIL]: get(event, ['user', 'email']),
                [AMPLITUDE_USER_PROPS.REGISTRATION_DATE]: toDateString(time),
                [AMPLITUDE_USER_PROPS.REGISTRATION_MONTH]: toMonthString(time),
                [AMPLITUDE_USER_PROPS.REFERRED_BY_USER]: !!event.referrerCode,
                [AMPLITUDE_USER_PROPS.REGISTERED_WITH_REFERRAL_CODE]: !!event.referrerCode,
                [AMPLITUDE_USER_PROPS.DEFAULT_CONTENT_LIMIT]: get(event, ['user', 'contentLimit', 'default']),
                [AMPLITUDE_USER_PROPS.MAX_REFERRAL_BONUS]: get(event, ['user', 'contentLimit', 'maxReferralBonus']),
            };

            const registrationEntryPoint = get(event, 'registrationEntryPoint');
            if (registrationEntryPoint) {
                userProperties[AMPLITUDE_USER_PROPS.INVITED_FROM_BOARD_FLAG] = true;
                userProperties[AMPLITUDE_USER_PROPS.REGISTRATION_ENTRY_POINT] = registrationEntryPoint;
            }

            return userProperties;
        }
        case USER_EMAIL_VERIFIED:
            return {
                [AMPLITUDE_USER_PROPS.EMAIL_VERIFIED]: get(event, ['emailVerifiedDate']),
            };
        case USER_REFERRAL:
            return { [AMPLITUDE_USER_PROPS.REFERRED_A_FRIEND]: true };
        case GUEST_NAVIGATE: {
            if (event.isDemo) {
                return {
                    [AMPLITUDE_USER_PROPS.EXAMPLE_BOARDS_FLAG]: true,
                    [AMPLITUDE_USER_PROPS.EXAMPLE_BOARDS]: event.newBoardId,
                };
            }

            return { [AMPLITUDE_USER_PROPS.GUEST_VIEWED_PUBLIC_BOARDS]: true };
        }
        case SUBSCRIPTION_START:
        case SUBSCRIPTION_ADD:
            return { [AMPLITUDE_USER_PROPS.SUBSCRIPTION]: 'Active' };
        case SUBSCRIPTION_REMOVE:
        case SUBSCRIPTION_CANCEL:
            return { [AMPLITUDE_USER_PROPS.SUBSCRIPTION]: 'Cancelled' };
        case ELEMENT_SEARCH_SUBMIT:
            return { [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.SEARCH]: true } };
        case COMMENTS_ADD:
            return { [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.COMMENTS]: true } };
        case PUSH_NOTIFICATION_SENT:
            return { [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.RECEIVED_PUSH_NOTIFICATIONS]: true } };
        case MENTIONED_A_USER:
            return { [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.MENTIONED_A_USER]: true } };
        case MENTIONED_BY_USER:
            return { [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.MENTIONED_BY_USER]: true } };
        case ASSIGNED_TASK_TO_A_USER:
            return { [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.ASSIGNED_TASK_TO_A_USER]: true } };
        case ASSIGNED_TASK_BY_A_USER:
            return { [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.ASSIGNED_TASK_BY_A_USER]: true } };
        case ELEMENT_UPDATE:
            return event.updateType === ELEMENT_UPDATE_TYPE.DUE_DATE
                ? { [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.DUE_DATE]: true } }
                : undefined;
        default:
            return undefined;
    }
};

const getRegistrationPlatform = (event) => {
    const platform = get(event, 'platform');

    switch (platform) {
        case PLATFORMS.IPHONE:
            return 'iOS';
        case PLATFORMS.ANDROID:
            return 'Android';
        default:
            return undefined;
    }
};

const getOtherAmplitudeProperties = (event) => {
    switch (event.type) {
        case USER_REGISTER:
            return {
                platform: getRegistrationPlatform(event),
            };
        case FETCH_ME:
            return { ip: event.ip, app_version: event.version };
        default:
            return undefined;
    }
};

const getAmplitudeEventData = (event, time) => ({
    event_properties: getAmplitudeEventProperties(event),
    user_properties: getAmplitudeUserProperties(event, time),
    ...getOtherAmplitudeProperties(event),
});

export const constructAmplitudeEvent = ({ userId, amplitudeDeviceId, event, eventId, time = Date.now() }) => ({
    event_id: eventId,
    user_id: userId,
    device_id: amplitudeDeviceId,
    event_type: sanitiseEventTypeName(event.type),
    time,
    ...getAmplitudeEventData(event, time),
});
