// Lib
import * as Immutable from 'immutable';

// Utils
import { getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Constants
import * as SELECTION_ACTION_TYPES from '../../../common/elements/selectionConstants';
import { DRAG_START } from '../../reducers/draggingConstants';
import { COMMENTS_START_EDITING } from '../../../common/comments/commentConstants';
import { FOCUS_MODE_INACTIVE } from '../../workspace/presentation/presentationConstants';
import { SOCKET_DISCONNECT } from '../../utils/socket/socketConstants';

const initialState = Immutable.fromJS({
    id: null,
});

/**
 * When an element is selected, check if it's the same element that's being edited, otherwise stop editing.
 */
const handleElementSelect = (state, action) => {
    const currentlyEditingId = getElementId(state);
    return currentlyEditingId && action.ids.length === 1 && action.ids.indexOf(currentlyEditingId) !== -1
        ? state
        : initialState;
};

export default function currentlyEditing(state = initialState, action) {
    switch (action.type) {
        case SELECTION_ACTION_TYPES.ELEMENT_EDIT_START:
            return state.merge({
                id: action.id,
                editorId: action.editorId,
                editorKey: action.editorKey,
            });
        case FOCUS_MODE_INACTIVE:
        case SELECTION_ACTION_TYPES.ELEMENT_EDIT_COMPLETE:
        case SOCKET_DISCONNECT:
            return initialState;
        case SELECTION_ACTION_TYPES.ELEMENTS_SELECTED:
            return handleElementSelect(state, action);
        case COMMENTS_START_EDITING:
        case DRAG_START:
            return action.finishEditing ? initialState : state;
        default:
            return state;
    }
}
