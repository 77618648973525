// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash/fp';

// Utils
import platformSingleton from '../../../../platform/platformSingleton';

// Components
import SearchClearButton from '../../../form/SearchClearButton';

// Styles
import './PopupModeMenuSearchForm.scss';
import '../PopupTextInput.scss';

export default class PopupModeMenuSearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            forceActive: false,
        };

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        // Need to delay this, for some reason focus wasn't being set when opening the icon picker
        requestAnimationFrame(() => {
            if (!platformSingleton.features.isTouch) {
                this.mounted && this.focusInput();
            }
        });
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleSubmit = (event) => {
        const { submitSearch, clearSearchOnSubmit = true } = this.props;
        event.preventDefault();

        if (platformSingleton.features.isTouch) {
            this.blurInput();
        } else {
            this.focusInput();
        }

        submitSearch && submitSearch(this.state.search);

        if (clearSearchOnSubmit) {
            this.setState({ search: '' });
        }
    };

    handleChange = (event) => {
        const { handleSearchChange } = this.props;
        const search = event.target.value;

        this.setState({ search });
        handleSearchChange && handleSearchChange(search);
    };

    onClear = () => {
        const { handleSearchChange } = this.props;

        this.setState({ search: '' });
        handleSearchChange && handleSearchChange('');

        this.focusInput();
    };

    onClearMouseDown = () => {
        this.focusInput();
        this.setState({ forceActive: true });

        document.addEventListener('mouseup', this.onClearMouseUp);
    };

    onClearMouseUp = () => {
        document.removeEventListener('mouseup', this.onClearMouseUp);

        if (!this.mounted) return;

        this.setState({ forceActive: false });
    };

    focusInput = () => {
        this.inputRef.current && this.inputRef.current.focus();
    };

    blurInput = () => {
        this.inputRef.current && this.inputRef.current.blur();
    };

    render() {
        const { forceActive, search } = this.state;

        return (
            <div className="PopupModeMenuSearchForm">
                <form onSubmit={this.handleSubmit}>
                    <input
                        ref={this.inputRef}
                        className={classNames('PopupTextInput', 'PopupModeMenuSearchForm', { active: forceActive })}
                        placeholder={this.props.searchPlaceholder}
                        enterkeyhint="Search"
                        value={search}
                        onChange={this.handleChange}
                    />
                    <SearchClearButton
                        show
                        canClear={!isEmpty(search)}
                        onMouseDown={this.onClearMouseDown}
                        onClick={this.onClear}
                    />
                </form>
            </div>
        );
    }
}

PopupModeMenuSearchForm.propTypes = {
    submitSearch: PropTypes.func,
    handleSearchChange: PropTypes.func,
    clearSearchOnSubmit: PropTypes.bool,
    selectedElements: PropTypes.object,
    searchPlaceholder: PropTypes.string,
};
