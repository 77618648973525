export const COMMENT_THREAD_DISPLAY_NAME = 'Comment';

// Synced and persisted actions
export const COMMENTS_LOAD = 'COMMENTS_LOAD';
export const COMMENTS_ADD = 'COMMENTS_ADD';
export const COMMENTS_UPDATE = 'COMMENTS_UPDATE';
export const COMMENTS_DELETE = 'COMMENTS_DELETE';

// Local actions that shouldn't be synced
export const COMMENTS_STOP_EDITING = 'COMMENTS_STOP_EDITING';
export const COMMENTS_START_EDITING = 'COMMENTS_START_EDITING';
export const COMMENTS_CANCEL_EDITING = 'COMMENTS_CANCEL_EDITING';
export const COMMENTS_PRE_COMMIT = 'COMMENTS_PRE_COMMIT';
export const COMMENTS_CLEAR_PRE_COMMIT = 'COMMENTS_CLEAR_PRE_COMMIT';

// Remote actions for tracking typing activity
export const COMMENTS_TYPING_PING = 'COMMENTS_TYPING_PING';
export const COMMENTS_TYPING_STOP = 'COMMENTS_TYPING_STOP';

export const COMMENT_ID_PREFIX = 'cm_';
