import registerTable from '../../../common/table/index';
import presentationalComponent from './Table';
import containerComponent from './TableContainer';

import platformSingleton from '../../platform/platformSingleton';
import { isPlatformApple } from '../../platform/utils/platformDetailsUtils';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

const TABLE_CELL_TOOLS = [
    TOOLBAR_TOOLS.TEXT_STYLES,
    TOOLBAR_TOOLS.TABLE_CELL_TYPE,
    TOOLBAR_TOOLS.TABLE_CELL_COLOR,
    TOOLBAR_TOOLS.TABLE_CELL_FORMULA,
    TOOLBAR_TOOLS.TABLE_CELL_ALIGNMENT,
    TOOLBAR_TOOLS.TABLE_ADD_COLUMN,
    TOOLBAR_TOOLS.TABLE_ADD_ROW,
];

export default () => {
    return registerTable({
        containerComponent,
        presentationalComponent,
        selectedTools: [TOOLBAR_TOOLS.TITLE, TOOLBAR_TOOLS.CAPTION, ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT],
        'editingTools_TABLE-CELL': TABLE_CELL_TOOLS,
        'editingTools_TABLE-GRID': TABLE_CELL_TOOLS,
        'editingTools_TABLE-TITLE': [TOOLBAR_TOOLS.TITLE, TOOLBAR_TOOLS.CAPTION],
        editingTools_CAPTION: TOOL_SETS.TEXT_EDITING_CAPTION,
        'editingRangeTools_TABLE-CELL': [
            TOOLBAR_TOOLS.TEXT_STYLES,
            TOOLBAR_TOOLS.TEXT_BOLD,
            TOOLBAR_TOOLS.TEXT_ITALIC,
            TOOLBAR_TOOLS.TEXT_STRIKETHROUGH,
        ],
        tooltip: isPlatformApple(platformSingleton) ? '⌃⌘N' : 'Ctrl+Alt+N',
    });
};
