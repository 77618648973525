// Utils
import getSvgImageDimensions from './getSvgImageDimensions';
import { loadImageFromDataUrl } from './loadImage';

// Constants
import { MIME_TYPES } from '../../../../common/media/mimeConstants';

export default async (imageDataUri, mimeType) => {
    if (mimeType === MIME_TYPES.SVG) {
        const svgDimensions = getSvgImageDimensions(imageDataUri);
        if (svgDimensions) {
            return { size: svgDimensions };
        }
    }

    const imageLoadEvent = await loadImageFromDataUrl(imageDataUri);
    const img = imageLoadEvent.target;

    const size = {
        width: img.width,
        height: img.height,
    };

    return {
        img,
        size,
    };
};
