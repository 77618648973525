import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sum } from 'lodash';

// Utils
import { elementClassNames, getDomElementId } from '../utils/elementUtil';
import { getBorderRenderedWidth } from './utils/tableSizeUtils';
import {
    getCaption,
    getElementId,
    getShowCaption,
    getShowTitle,
    getTableContentColWidthsGU,
    getWidth,
} from '../../../common/elements/utils/elementPropertyUtils';
import { getElementStyle } from '../../utils/grid/gridUtils';
import { getIsFeatureEnabled } from '../feature/elementFeatureUtils';
import { getShowQuickLineTool } from '../quickLine/quickLineUtil';
import { asObject } from '../../../common/utils/immutableHelper';
import { parseCssPx } from '../../utils/cssUtil';
import { isInDocumentModeList } from '../../../common/inList/inListUtils';

// Components
import Caption from '../../components/caption/Caption';
import QuickLineCreationTool from '../quickLine/QuickLineCreationTool';
import ElementReactions from '../reactions/ElementReactions';
import AttachedCommentsDropTarget from '../dnd/elementDropTargets/AttachedCommentsDropTarget';
import AttachedCommentsContainer from '../attachedComment/AttachedCommentsContainer';
import ElementActivityIndicator from '../activity/ElementActivityIndicator';
import ElementUserTag from '../../user/userActivity/ElementUserTag';
import TableTitleEditor from './TableTitleEditor';
import PresentationalTable from './PresentationalTable';
import HotTable from './HotTable';
import ElementResizeHandle from '../resizing/ElementResizeHandle';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import { ELEMENT_FEATURES } from '../feature/elementFeatureConstants';
import { TABLE_CAPTION_EDITOR_KEY } from '../../../common/table/tableConstants';

// Styles
import './Table.scss';

const Table = (props) => {
    const {
        element,
        gridSize,
        inList,
        inTrash,
        isLocked,
        isEditable,
        elementEvents,
        isSelected,
        isDragPreview,
        isPresentational,
        currentBoardId,
        permissions,
        setParentHoveredChildAcceptsDrop,
        getContextZoomScale,
        getContextZoomTranslationPx,
        dispatchSetCellSelection,
        currentCellSelections,
    } = props;

    const tableRef = useRef(null);
    const tableTitleRef = useRef(null);

    const hotTableInstanceRef = useRef(null);
    const hotTableContainerRef = useRef(null);

    const [isGridMoving, setIsGridMoving] = useState(false);

    const showTitle = getShowTitle(element);
    const showCaption = getShowCaption(element);
    const isTransparencyEnabled = getIsFeatureEnabled(ELEMENT_FEATURES.TRANSPARENT)(element);

    const elementId = getElementId(element);
    const captionContent = getCaption(element);

    const hotTableWidthGU = sum(asObject(getTableContentColWidthsGU(element)));
    const tableWidth = getWidth(element) || ELEMENT_DEFAULT_WIDTH;

    const transparent = isTransparencyEnabled;

    // Currently the only time we use the readOnly prop is when the table is in mobile view
    const isMobileView = isInDocumentModeList(inList);
    const isReadOnly = isMobileView || !isEditable;
    const showPresentational = isPresentational && !!document.querySelector(`.Table#el-${elementId}`);

    const removeElementBorder = currentCellSelections?.size;

    const tableClasses = useMemo(
        () =>
            classNames('Table drag-handle contrast-shadow', {
                'background-color-target': isSelected,
                'background-color-contrast-target': isSelected,
                'show-title': showTitle,
                'show-caption': showCaption,
                'remove-element-selection-border': removeElementBorder,
                'read-only': isReadOnly,
            }),
        [showTitle, showCaption, isSelected, isReadOnly, removeElementBorder],
    );

    const classes = elementClassNames(tableClasses, { ...props, transparent });

    const elementStyle = getElementStyle(tableWidth, gridSize, inList);

    const calculateTitleHeight = useCallback(() => {
        if (isMobileView || !tableRef.current || !tableTitleRef.current) return;

        // nLines * 2.5rem (line height) + 3.5rem (padding) = total title height
        // if there is an even number of lines of text in the title, we will end up with 0.5rem off the grid points
        // we are adding this class to adjust the padding in this case
        const contentElement = tableTitleRef.current.querySelector('.public-DraftEditor-content').firstChild;
        const contentHeight = parseCssPx(getComputedStyle(contentElement).height);
        const lineHeight = parseCssPx(getComputedStyle(contentElement).lineHeight);
        const nLines = contentHeight / lineHeight;
        const isEven = nLines % 2 === 0;
        tableTitleRef.current.firstChild.classList.toggle('title-even-lines', isEven);

        const currentTitleHeight = parseCssPx(getComputedStyle(tableTitleRef.current).height);
        tableRef.current.style.setProperty('--table-title-height', `${currentTitleHeight}px`);
    }, [gridSize]);

    useEffect(() => {
        if (showTitle) {
            tableRef.current.style.setProperty('--table-title-height', `${5 * gridSize}px`);
            calculateTitleHeight();
        } else {
            tableRef.current.style.setProperty('--table-title-height', '0px');
        }
    }, [showTitle, hotTableWidthGU]);

    useEffect(() => {
        calculateTitleHeight();
    }, [gridSize]);

    const jumpToFirstCell = useCallback(
        () => dispatchSetCellSelection(elementId, [0, 0, 0, 0]),
        [elementId, dispatchSetCellSelection],
    );

    useEffect(() => {
        if (!hotTableInstanceRef.current || !tableRef.current) return;

        const firstTdElement = hotTableInstanceRef.current.rootElement.querySelector('td');
        tableRef.current.style.setProperty(
            '--table-cell-border-rendered-width',
            `${getBorderRenderedWidth(firstTdElement)}px`,
        );
    }, []);

    useEffect(() => {
        if (!hotTableInstanceRef.current) return;

        const scrollElement = hotTableInstanceRef.current.rootElement.querySelector('.wtHolder');
        const scrollBarHeight = scrollElement.offsetHeight - scrollElement.clientHeight;

        // This value should be 6px or 0px depending on whether the scrollbar is visible or not
        tableRef.current.style.setProperty('--scroll-bar-height', `${scrollBarHeight}px`);
    }, []);

    const showResizeHandle = !isLocked && isEditable && !inList && !isReadOnly;

    return (
        <div id={getDomElementId(elementId)} ref={tableRef} className={classes} {...elementEvents} style={elementStyle}>
            {showTitle && (
                <TableTitleEditor
                    {...props}
                    ref={tableTitleRef}
                    onChange={() => {
                        requestAnimationFrame(calculateTitleHeight);
                    }}
                    jumpToFirstCell={jumpToFirstCell}
                />
            )}
            {showPresentational && (
                <PresentationalTable
                    element={element}
                    gridSize={gridSize}
                    inList={inList}
                    isDragPreview={isDragPreview}
                    inTrash={inTrash}
                />
            )}
            {!showPresentational && (
                <HotTable
                    {...props}
                    hotTableInstanceRef={hotTableInstanceRef}
                    hotTableContainerRef={hotTableContainerRef}
                    showTitle={showTitle}
                    showCaption={showCaption}
                    isReadOnly={isReadOnly}
                    isGridMoving={isGridMoving}
                    setIsGridMoving={setIsGridMoving}
                />
            )}

            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementActivityIndicator {...props} />
            <ElementUserTag {...props} />
            <ElementReactions {...props} />
            <Caption
                {...props}
                padTop
                placeholder="Add a description"
                textContent={captionContent}
                captionVisible={showCaption}
                editorId={`${elementId}-${TABLE_CAPTION_EDITOR_KEY}`}
                editorKey={TABLE_CAPTION_EDITOR_KEY}
            />
            <AttachedCommentsDropTarget
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                gridSize={gridSize}
                isPresentational={isPresentational}
                element={element}
                elementId={elementId}
                getContextZoomScale={getContextZoomScale}
            />
            <AttachedCommentsContainer
                gridSize={gridSize}
                elementId={elementId}
                element={element}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />

            <ElementResizeHandle {...props} showHandle={showResizeHandle} />
        </div>
    );
};

Table.propTypes = {
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    inList: PropTypes.string,
    inTrash: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSelected: PropTypes.bool,
    isEditable: PropTypes.bool,
    isPresentational: PropTypes.bool,
    isDragPreview: PropTypes.bool,
    isRemotelySelected: PropTypes.bool,
    permissions: PropTypes.number,
    currentBoardId: PropTypes.string,
    elementEvents: PropTypes.object,
    tempSize: PropTypes.object,
    setParentHoveredChildAcceptsDrop: PropTypes.func,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,

    dispatchStartEditingTitle: PropTypes.func,
    dispatchSetCellSelection: PropTypes.func,
    hotTableInstanceRef: PropTypes.object,
    hotTableContainerRef: PropTypes.object,
    currentCellSelections: PropTypes.object,
};

export default Table;
