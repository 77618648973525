// Lib
import React from 'react';
import classNames from 'classnames';

// Components
import ToolbarPopup from '../toolbarPopup/ToolbarPopup';

// Types
import { MoreToolsSettings, ToolbarItemConfig } from '../../toolbarTypes';

// Styles
import './MoreToolPopup.scss';
import { ImMNUser } from '../../../../../common/users/userModelTypes';
import ToolbarItemsList from '../ToolbarItemsList';

type MoreToolProps = {
    item: ToolbarItemConfig;
    popupId: string;
    className: string;
    closePopup: () => void;

    gridSizeName: string;
    toolListId: string;
    selectedElementIds: Immutable.List<string>;
    currentUser: ImMNUser;
    currentUserId: string;
    currentBoardId: string;
    gridSize: number;
    permissions: number;
    editorKey?: string;
    hasEditorSelection: boolean;
};

type MorePopupContentsProps = MoreToolProps & {
    item: ToolbarItemConfig;
};

const MorePopupContents = ({ item, closePopup, toolListId, ...rest }: MorePopupContentsProps): React.ReactElement => {
    React.useEffect(() => {
        return () => {
            closePopup();
        };
    }, []);

    const settings = item.toolSettings as MoreToolsSettings;

    return (
        <div
            className="MoreToolPopupContents"
            style={{ '--popup-columns': Math.ceil(settings.items.length / settings.groupSize) } as React.CSSProperties}
        >
            <ToolbarItemsList {...rest} toolListId={item.uid || toolListId} items={settings.items} />
        </div>
    );
};

const MorePopup = (props: MoreToolProps): React.ReactElement => {
    return (
        <ToolbarPopup
            ignoredClicksSelector={`#${props.item.uid}`}
            popupId={props.popupId}
            buttonSelector={`#${props.item.uid} .Icon.toolbar-more`}
            className={classNames('MoreToolPopup', `toolbar-size-${props.gridSizeName}`, props.className)}
            arrowColor="var(--ws-canvas-background)"
            closePopup={props.closePopup}
        >
            <MorePopupContents {...props} />
        </ToolbarPopup>
    );
};

export default MorePopup;
