// Lib
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Utils
import * as typingBuffer from '../../../components/editor/milanoteEditor/typingBuffer';
import {
    hasAltModifier,
    hasArrowKeyCode,
    hasCommandModifier,
    hasControlModifier,
    hasRemovalKeyCode,
} from '../../../utils/keyboard/keyboardUtility';
import { getElementId } from '../../../../common/elements/utils/elementPropertyUtils';

// Constants
import { KEY_CODES } from '../../../utils/keyboard/keyConstants';

// Styles
import './HiddenInput.scss';

const HiddenInput = (props) => {
    const {
        element,
        disabled,
        isSingleSelected,
        dispatchNavigateToElement,
        dispatchHandleArrowKeys,
        dispatchStartEditingTitle,
        moveElementsToTrash,
        isElementOpenInModal,
    } = props;

    const hiddenInput = useRef(null);

    const focusHiddenInput = useCallback(() => {
        hiddenInput.current.focus();
    }, []);

    // Focus on input if element is just selected
    useEffect(() => {
        if (isSingleSelected && !disabled) {
            focusHiddenInput();
        }
    }, [isSingleSelected, disabled]);

    // Focus on input if modal has just closed and element is still selected
    useEffect(() => {
        if (!isElementOpenInModal && isSingleSelected && !disabled) {
            focusHiddenInput();
        }
    }, [isElementOpenInModal]);

    /**
     * Open the element modal on any key press.
     */
    const onKeyDown = useCallback(
        (event) => {
            event.persist();

            // Only open the document editor on key press if only this element is selected
            if (!isSingleSelected) return;

            if (hasRemovalKeyCode(event)) return moveElementsToTrash();

            if (hasArrowKeyCode(event)) return dispatchHandleArrowKeys(event);

            if (event.keyCode === KEY_CODES.ENTER) {
                // Prevent a new line from being added to title
                event.preventDefault();
                return dispatchStartEditingTitle(element);
            }

            // Don't open the modal when the shift key is pressed
            if (event.keyCode === KEY_CODES.SHIFT) return;

            // Ignore anything when it's a command modifier key down
            if (hasCommandModifier(event)) return;
            if (hasControlModifier(event)) return;
            if (hasAltModifier(event)) return;

            const elementId = getElementId(element);

            event.stopPropagation();
            event.preventDefault();

            dispatchNavigateToElement({ elementId });
        },
        [element, isSingleSelected],
    );

    /**
     * This adds any characters pressed to a buffer so that they can be inserted into the
     * editor state when the editor loads.
     */
    const onBeforeInput = useCallback((event) => {
        // Prevent the character from being added to the DOM
        event.preventDefault();
        typingBuffer.addChar(event.data);
    }, []);

    // By adding `enable-workspace-clipboard` class name, we are ensuring that the workspace clipboard shortcut
    // handlers would still work when this input field is focused.
    return (
        <div
            type="text"
            className="HiddenInput enable-workspace-clipboard"
            tabIndex={-1}
            ref={hiddenInput}
            onKeyDown={onKeyDown}
            onBeforeInput={onBeforeInput}
        />
    );
};

HiddenInput.propTypes = {
    element: PropTypes.object.isRequired,
    isSingleSelected: PropTypes.bool,
    disabled: PropTypes.bool,
    moveElementsToTrash: PropTypes.func,
    isElementOpenInModal: PropTypes.bool,

    dispatchNavigateToElement: PropTypes.func,
    dispatchHandleArrowKeys: PropTypes.func,
    dispatchStartEditingTitle: PropTypes.func,
};

export default HiddenInput;
