import { last, set } from 'lodash';
import { isFormula } from '../utils/tableFormulaUtils';
import { reorderCellSelection } from '../utils/tableCellSelectionUtils';
import { createTableCellEntry } from '../utils/tableInitialisationUtils';
import { getColumnCount, getRowCount } from '../utils/tableDataUtils';
import { getRawTextFromCellContentString } from '../../../../common/table/utils/tableCellContentStringUtils';

let copySource;
let autofillSource;

/**
 * The HotTableClipboardManager stores information regarding the last copied table content.
 * For more information see https://app.milanote.com/1QxWqE0Tq84aee/clipboard
 * Scenarios:
 * 1. Copy from Milanote, paste to Milanote
 * 2. Copy from Milanote, paste to External
 * 3. Copy from external, paste to Milanote
 */

const getSourceCellDataArray = (hotTableInstanceRef, cellSelection) => {
    const [fromRow, fromCol, toRow, toCol] = reorderCellSelection(cellSelection);

    const sourceCellDataArray = [];
    for (let row = fromRow; row <= toRow; row++) {
        sourceCellDataArray.push([]);
        for (let col = fromCol; col <= toCol; col++) {
            const { cellData = createTableCellEntry() } = hotTableInstanceRef.current.getCellMeta(row, col);
            const renderCellValue = hotTableInstanceRef.current.getCopyableData(row, col);

            last(sourceCellDataArray).push({ renderCellValue, cellData });
        }
    }

    return sourceCellDataArray;
};

// This is used to copy from Milanote
const setTableCopySource = (hotTableInstanceRef, cellSelection) => {
    copySource = getSourceCellDataArray(hotTableInstanceRef, cellSelection);
};

const setTableAutofillSource = (hotTableInstanceRef, cellSelection) => {
    autofillSource = getSourceCellDataArray(hotTableInstanceRef, cellSelection);
};

// This is used for pasting into milanote
const getTableCopiedDataForValues = (cellValues) => {
    if (!copySource) return;

    const nRowsOfValues = getRowCount(cellValues);
    const nColsOfValues = getColumnCount(cellValues);
    const nRowsOfCopySource = getRowCount(copySource);
    const nColsOfCopySource = getColumnCount(copySource);

    // Check first if the source data matches the changes. This should match if data is copied from Milanote table,
    // but will not match if copying from external application (e.g. Apple Numbers).
    const isValidCopy =
        nRowsOfCopySource === nRowsOfValues &&
        nColsOfCopySource === nColsOfValues &&
        copySource.every((rowData, row) =>
            rowData.every(
                ({ renderCellValue }, col) => renderCellValue === getRawTextFromCellContentString(cellValues[row][col]),
            ),
        );

    if (!isValidCopy) return;

    return copySource.map((rowData, row) =>
        rowData.map(({ renderCellValue, cellData }) => {
            if (isFormula(cellData.value)) return { ...cellData, value: renderCellValue };

            return cellData;
        }),
    );
};

const getTableCopiedDataForChanges = (changes) => {
    const minChangeRow = Math.min(...changes.map(([row]) => row));
    const minChangeCol = Math.min(...changes.map(([, col]) => col));

    const cellValues = [];

    changes.forEach(([row, col, , value]) => set(cellValues, [row - minChangeRow, col - minChangeCol], value));

    return getTableCopiedDataForValues(cellValues);
};

const getTableAutofillData = () => {
    return autofillSource.map((rowData) => rowData.map(({ cellData }) => cellData));
};

const HotTableClipboardManager = {
    setTableCopySource,
    getTableCopiedDataForValues,
    getTableCopiedDataForChanges,
    getTableAutofillData,
    setTableAutofillSource,
};

export default HotTableClipboardManager;
