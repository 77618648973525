// Utils
import { BoardSections } from '../../../common/boards/boardConstants';

// Types
import { MNElementSummary } from '../../../common/elements/elementModelTypes';
import { IdGraph, IdTree } from '../../../common/dataStructures/graphTypes';
import { ElementType } from '../../../common/elements/elementTypes';

// To keep it consistent with the original file, we'll use the same type names as they might be important
// We might want to revisit this in the future
type IndexParentId = string | null | undefined;

export type ElementGraphSelectorData = {
    elementGraph: IdGraph;
    boardVisibleElementGraph: IdGraph;
    aliasIdToPhysicalIdMap: IdTree;
    elementIdToVirtualParentIdsMap: IdGraph;
    columnIdToParentBoardIdMap: IdTree;
    elementIdToAncestorBoardIdMap: IdTree;
    parentIdMap: IdTree;
    boardIdToAliasIdsMap: IdGraph;
};

// Performance improvement - as this is very "hot" code
const getParentBoardAndLocationDetailsForDeepChild = (
    elements: Map<string, MNElementSummary>,
    deepChild: MNElementSummary | undefined,
): { parentBoardId: IndexParentId; isInParentBoardInbox: boolean } => {
    let element = deepChild;
    let parentId = element?.location?.parentId;
    let parent = parentId ? elements.get(parentId) : null;

    if (!parent) {
        return {
            parentBoardId: null,
            isInParentBoardInbox: false,
        };
    }

    const visitedAncestors = new Map();

    while (parent && parent.elementType !== ElementType.BOARD_TYPE) {
        element = parent;
        parentId = element.location?.parentId || '';
        parent = elements.get(parentId);

        if (visitedAncestors.has(parentId)) {
            console.warn(`Infinite loop in getParentBoardAndLocationDetailsForDeepChild for: ${element._id}`, element);
            break;
        }
        visitedAncestors.set(parentId, true);
    }

    const elementLocation = element?.location?.section;

    const isInParentBoardInbox = elementLocation === BoardSections.INBOX;

    return {
        parentBoardId: parentId,
        isInParentBoardInbox,
    };
};

/**
 * This function is designed to perform the minimum amount of iterations over the elements
 * collection as possible in order to satisfy the "elementGraphSelector" functions.
 *
 * Because these functions will be executed every time an element changes, we want them to execute as
 * quickly as possible, thus performing the least amount of element iterations as possible.
 *
 * NOTE: This combines 3 functions (plus a little extra) from the "elementGraphUtils" into 1.
 *  - buildPhysicalIdToVirtualIdMap
 *  - buildEntireElementGraph
 *  - buildBoardVisibleElementGraph
 *
 * See each of those functions for more clarity about what this function achieves.
 */
export const calculateElementGraphSelectorData = (
    elements: Map<string, MNElementSummary>,
): ElementGraphSelectorData => {
    // TOP DOWN MAPS
    // A map of element ID to its immediate children
    const elementGraph: IdGraph = {};
    // A map of board ID to its visible descendants
    const boardVisibleElementGraph: IdGraph = {};
    // A map of element ID to its accessible parents if it's been linked to by an alias
    const elementIdToVirtualParentIdsMap: IdGraph = {};
    // A map of alias ID to their physical board ID
    const aliasIdToPhysicalIdMap: IdTree = {};
    // A map of a board ID to alias IDs that link to it
    const boardIdToAliasIdsMap: IdGraph = {};

    // BOTTOM UP MAPS
    // A map of element ID to their actual physical *PARENT* ID
    const parentIdMap: IdTree = {};
    // A map of element ID to its physical parent *BOARD* ID
    const elementIdToAncestorBoardIdMap: IdTree = {};

    // HELPERS
    // A map of board IDs to parent alias IDs
    const linkedIdToParentIdsMap: IdGraph = {};
    // Map of boardID to true - if the element is a board
    const physicalBoardIdMap: Record<string, boolean> = {};
    // A map of column IDs to their parent board ID
    const columnIdToParentBoardIdMap: IdTree = {};

    // Performance improvement.  First get all the boardIds so we can short circuit more expensive checks
    // First:
    //  - Map all column IDs to their parent board IDs
    //  - Find the board IDs that are linked to by aliases and associate the alias's parent ID to that board
    //  - Keep track of boards that are part of the physical tree, as we don't want to change to an aliases parent ID
    //      in this case
    for (const entry of elements) {
        const [elementId, element] = entry;

        if (!element) continue;

        // Don't add elements that are in the trash into the maps
        if (element.location.section === BoardSections.TRASH) continue;

        const parentId = element.location.parentId;
        parentIdMap[elementId] = parentId;

        const elementType = element.elementType;

        // Attempt performance improvement
        if (elementType === ElementType.COLUMN_TYPE) {
            // If the parent ID of an element is a column, map that ID to the column's parent which will be a board.
            columnIdToParentBoardIdMap[elementId] = parentId;
        } else if (elementType === ElementType.ALIAS_TYPE) {
            const linkedBoardId = element.content?.linkTo;

            if (!linkedBoardId) continue;

            linkedIdToParentIdsMap[linkedBoardId] = linkedIdToParentIdsMap[linkedBoardId] || [];

            if (parentId) {
                linkedIdToParentIdsMap[linkedBoardId].push(parentId);
            }

            boardIdToAliasIdsMap[linkedBoardId] = boardIdToAliasIdsMap[linkedBoardId] || [];
            boardIdToAliasIdsMap[linkedBoardId].push(elementId);

            aliasIdToPhysicalIdMap[elementId] = linkedBoardId;
        } else if (elementType === ElementType.BOARD_TYPE) {
            physicalBoardIdMap[elementId] = true;
        }
    }

    // Now we can loop through the elements again and using the previously calculated maps, we can quickly
    // find board ancestors, etc.
    for (const entry of elements) {
        const [elementId, element] = entry;

        if (!element) continue;

        // Don't add elements that are in the trash into the maps
        if (element.location.section === BoardSections.TRASH) continue;

        const parentId = element.location.parentId || '';

        const mappedAliasParentIds = linkedIdToParentIdsMap[elementId];

        if (mappedAliasParentIds) {
            // If the parent of this board is in the physical tree, then include it in the parents list
            // e.g. If it's a skeleton (so in another user's hierarchy) then ignore it
            if (physicalBoardIdMap[parentId]) mappedAliasParentIds.push(parentId);
            elementIdToVirtualParentIdsMap[elementId] = mappedAliasParentIds;
        }

        // Element Graph
        elementGraph[parentId] = elementGraph[parentId] || [];
        elementGraph[parentId].push(elementId);

        const immediateParentIsBoard = physicalBoardIdMap[parentId];

        let parentBoardId;

        if (immediateParentIsBoard) {
            parentBoardId = parentId;
            // Specifically written like this for performance
        } else {
            const result = getParentBoardAndLocationDetailsForDeepChild(elements, element);
            parentBoardId = result.parentBoardId; // eslint-disable-line prefer-destructuring
        }

        // If the parent elements haven't been retrieved yet, don't add this child to the map
        if (parentBoardId === undefined) continue;

        if (parentBoardId) {
            boardVisibleElementGraph[parentBoardId] = boardVisibleElementGraph[parentBoardId] || [];
            boardVisibleElementGraph[parentBoardId].push(elementId);
        }

        elementIdToAncestorBoardIdMap[elementId] = parentBoardId;
    }

    return {
        elementGraph,
        boardVisibleElementGraph,
        aliasIdToPhysicalIdMap,
        elementIdToVirtualParentIdsMap,
        columnIdToParentBoardIdMap,
        elementIdToAncestorBoardIdMap,
        parentIdMap,
        boardIdToAliasIdsMap,
    };
};
