import {
    COMMENTS_PRE_COMMIT,
    COMMENTS_CLEAR_PRE_COMMIT,
    COMMENTS_STOP_EDITING,
    COMMENTS_START_EDITING,
    COMMENTS_CANCEL_EDITING,
} from '../../../../common/comments/commentConstants';

// Actions
import { keepSelectedElements } from '../../selection/selectionActions';

// Un-synced actions
export const stopEditingComment = ({ _id }) => ({ type: COMMENTS_STOP_EDITING, _id });
export const startEditingComment =
    ({ _id, elementId }) =>
    (dispatch) => {
        dispatch(keepSelectedElements({ ids: [elementId] }));
        dispatch({ type: COMMENTS_START_EDITING, _id });
    };
export const cancelEditingComment = ({ _id }) => ({ type: COMMENTS_CANCEL_EDITING, _id });
export const preCommitComment = ({ _id, text }) => ({ type: COMMENTS_PRE_COMMIT, _id, text });
export const clearPreCommitComment = ({ _id, text }) => ({ type: COMMENTS_CLEAR_PRE_COMMIT, _id });
