// Lib
import { isEmpty, get, uniq, map, flow, head } from 'lodash/fp';

// Utils
import { getElementType } from '../../common/elements/utils/elementPropertyUtils';
import { isChangeMeaningful } from '../../common/elements/utils/elementMetadataUtil';
import {
    canGiveFeedbackOnBoard,
    getElementCanHaveSharedContributors,
} from '../../common/permissions/elementPermissionsUtil';
import { getClosestUpBoardId, getElement } from '../../common/elements/utils/elementTraversalUtils';

// Selectors
import { currentBoardCanHaveSharedContributors } from '../utils/permissions/elementPermissionsSelector';
import { isGuestSelector } from '../user/currentUserSelector';
import { getElements } from '../element/selectors/elementsSelector';
import { getCurrentBoardId } from '../reducers/currentBoardId/currentBoardIdSelector';
import { getAclIdsSelector } from '../utils/permissions/permissionsSelector';

// Constants
import { COMMENTS_ADD, COMMENTS_UPDATE, COMMENTS_DELETE } from '../../common/comments/commentConstants';
import {
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_DIFF_UPDATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_UPDATE,
    ELEMENT_UPDATE_TYPE,
} from '../../common/elements/elementConstants';
import { USER_NAVIGATE } from '../../common/users/userConstants';
import { BATCH_ACTION_TYPE } from '../store/reduxBulkingMiddleware';

const buildElementTypesMap = (elements, arrayWithIds) =>
    arrayWithIds.reduce((acc, { id }) => {
        const element = getElement(elements, id);

        if (!element) return acc;

        acc[id] = getElementType(element);

        return acc;
    }, {});

const getCommentActivityProperties = (store, action) => {
    const state = store.getState();

    // If we're not on a shared board there's nobody to notify about comments
    const track = currentBoardCanHaveSharedContributors(state);

    const currentBoardId = getCurrentBoardId(state);

    return {
        track,
        boardId: currentBoardId,
    };
};

export const getElementCreateActivityProperties = (store, action) => {
    const state = store.getState();

    const track = currentBoardCanHaveSharedContributors(state);

    const currentBoardId = getCurrentBoardId(state);

    return {
        track,
        boardId: currentBoardId,
    };
};

const getElementUpdateActivityProperties = (store, action) => {
    const state = store.getState();
    const { updates = [], updateType, id } = action;

    // reaction updates are not counted as "meaningful", but should create an activity
    const hasReactionUpdates =
        updateType === ELEMENT_UPDATE_TYPE.REACTION || updateType === ELEMENT_UPDATE_TYPE.TOGGLE_REACTION;

    const track = currentBoardCanHaveSharedContributors(state) && (isChangeMeaningful(action) || hasReactionUpdates);

    const currentBoardId = getCurrentBoardId(state);
    const elements = getElements(state);

    const updatesArray = updates?.length > 0 ? updates : [{ id }];

    const elementTypes = buildElementTypesMap(elements, updatesArray);

    return {
        track,
        boardId: currentBoardId,
        elementTypes,
    };
};

export const getUserNavigateActivityProperties = (store, action) => {
    const { newBoardId, previousBoardId } = action;

    const state = store.getState();
    const elements = getElements(state);

    const activityProperties = {
        track: !!newBoardId || !!previousBoardId,
        isPreviousBoardShared: false,
        isNewBoardShared: false,
    };

    if (!newBoardId && !previousBoardId) return activityProperties;

    const aclIds = getAclIdsSelector(state);
    // Only mark view navigations if there's actually specific shared users on the board
    if (previousBoardId) {
        activityProperties.isPreviousBoardShared =
            canGiveFeedbackOnBoard(elements, previousBoardId, aclIds) &&
            getElementCanHaveSharedContributors({ elements, elementId: previousBoardId });
    }

    // Only mark view navigations if there's actually specific shared users on the board
    if (newBoardId) {
        activityProperties.isNewBoardShared =
            canGiveFeedbackOnBoard(elements, newBoardId, aclIds) &&
            getElementCanHaveSharedContributors({ elements, elementId: newBoardId });
    }

    return activityProperties;
};

const getMoveSourceParentId = get(['from', 'parentId']);
const getMoveDestinationParentId = get(['location', 'parentId']);

const getSourceParentIds = flow(map(getMoveSourceParentId), uniq);

const getDestinationParentIds = flow(map(getMoveDestinationParentId), uniq);

const getFirstUnique = flow(uniq, head);

// ASSUMPTION: moves will only ever happen from a single board to another single board.
// If this is no longer the case this logic will need to be revisited
const getElementMoveActivityProperties = (store, action) => {
    if (isEmpty(action.moves)) return null;

    const state = store.getState();
    const elements = getElements(state);

    // Get from Board ID
    const sourceParentIds = getSourceParentIds(action.moves);
    const sourceBoardId = getFirstUnique(
        sourceParentIds.map((elementId) => getClosestUpBoardId(elements, elementId) || elementId),
    );

    // Get to Board ID
    const destinationParentIds = getDestinationParentIds(action.moves);
    const destinationBoardId = getFirstUnique(
        destinationParentIds.map((elementId) => getClosestUpBoardId(elements, elementId) || elementId),
    );

    // If moving within the same board, don't track this action
    const movingWithinSameBoard = sourceBoardId === destinationBoardId;

    // Get from shared
    const isSourceShared = getElementCanHaveSharedContributors({ elements, elementId: sourceBoardId });

    // Get to shared
    const isDestinationShared = getElementCanHaveSharedContributors({ elements, elementId: destinationBoardId });

    const track = !movingWithinSameBoard && (isSourceShared || isDestinationShared);

    const elementTypes = buildElementTypesMap(elements, action.moves);

    // if from shared or to shared track move action
    return {
        track,
        sourceBoardId,
        isSourceShared,
        destinationBoardId,
        isDestinationShared,
        elementTypes,
    };
};

const getElementDeleteActivityProperties = (store, action) => {
    const state = store.getState();

    const track = currentBoardCanHaveSharedContributors(state);

    const currentBoardId = getCurrentBoardId(state);
    const elements = getElements(state);

    const elementTypes = buildElementTypesMap(elements, [action]);

    return {
        track,
        boardId: currentBoardId,
        elementTypes,
    };
};

export const getActivityProperties = (store, action) => {
    switch (action.type) {
        case BATCH_ACTION_TYPE: {
            action.payload = action.payload.map((a) => {
                const activityProperties = getActivityProperties(store, a);
                if (activityProperties) {
                    a.activity = activityProperties;
                }
                return a;
            });

            return null;
        }
        case COMMENTS_ADD:
        case COMMENTS_UPDATE:
        case COMMENTS_DELETE:
            return getCommentActivityProperties(store, action);
        case ELEMENT_CREATE:
            return getElementCreateActivityProperties(store, action);
        case ELEMENT_UPDATE:
        case ELEMENT_DIFF_UPDATE:
            return getElementUpdateActivityProperties(store, action);
        case USER_NAVIGATE:
            return getUserNavigateActivityProperties(store, action);
        case ELEMENT_MOVE_MULTI:
            return getElementMoveActivityProperties(store, action);
        case ELEMENT_DELETE:
            return getElementDeleteActivityProperties(store, action);
        default:
            return null;
    }
};

const addActivityProperties = (store, action) => {
    const state = store.getState();

    // Remote actions don't need to be handled here
    if (action.remote) return action;

    // Guests shouldn't be able to make changes that notify anybody
    if (isGuestSelector(state)) return action;

    const activityProperties = getActivityProperties(store, action);

    if (!activityProperties) return action;

    action.activity = activityProperties;

    return action;
};

export default (store) => (next) => (action) => {
    const updatedAction = addActivityProperties(store, action);
    return next(updatedAction);
};
