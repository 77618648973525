// Lib
import { RichUtils } from 'draft-js';

// Utils
import isEmptyEditor from '../../customRichUtils/isEmptyEditor';
import isListBlockType from '../../customRichUtils/blocks/isListBlockType';

// Constants
import { EMPTY_BACKSPACE, EMPTY_DELETE, REMOVE_BLOCK_STYLE } from './commandConstants';
import { KEY_CODES } from '../../dependencies';
import { BLOCK_METADATA, STYLE_COMMANDS } from '../../richText/richTextConstants';

const isEmptyEditorStyle = (editorState) => {
    // Default card
    if (RichUtils.getCurrentBlockType(editorState) === STYLE_COMMANDS.UNSTYLED) return true;

    // Handle styled blocked with an IGNORE_FOR_DELETE tag
    const contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock();
    if (!firstBlock) return true;

    return firstBlock.getData()?.get(BLOCK_METADATA.IGNORE_FOR_DELETE);
};

const getDeleteCommandForPopulatedEditor = (editorState) => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    // If the current block is un-styled don't do anything special
    if (currentBlockType === STYLE_COMMANDS.UNSTYLED) return;

    // If we're selecting many characters, then don't do anything special
    const selectionState = editorState.getSelection();
    if (!selectionState.isCollapsed()) return;

    const selectionOffset = selectionState.getStartOffset();
    if (selectionOffset !== 0) return;

    const currentBlockKey = selectionState.getStartKey();
    const currentContent = editorState.getCurrentContent();

    const prevBlock = currentContent.getBlockBefore(currentBlockKey);

    // If is the first block then remove its block style
    if (!prevBlock) return REMOVE_BLOCK_STYLE;

    // If the current block is a list and the preceding block is a different style, delete the list.
    if (isListBlockType(currentBlockType) && prevBlock.getType() !== currentBlockType) return REMOVE_BLOCK_STYLE;
};

/**
 * If the editor is empty then fire the empty delete command.
 */
const getDeleteCommand = (editorState, keyCode, commandName) => {
    if (!isEmptyEditor(editorState)) {
        return keyCode === KEY_CODES.BACKSPACE ? getDeleteCommandForPopulatedEditor(editorState) : undefined;
    }

    return isEmptyEditorStyle(editorState) ? commandName : REMOVE_BLOCK_STYLE;
};

export default (event, { getEditorState }) => {
    switch (event.keyCode) {
        case KEY_CODES.BACKSPACE:
            return getDeleteCommand(getEditorState(), event.keyCode, EMPTY_BACKSPACE);
        case KEY_CODES.DELETE:
            return getDeleteCommand(getEditorState(), event.keyCode, EMPTY_DELETE);
        default:
            break;
    }
};
