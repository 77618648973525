// Lib
import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';

// Utils
import { getColorToPersist } from '../../../../../../../common/colors/elementColorFormatUtil';
import { getFirstSelectedSecondaryColor, getSupportColorBar } from '../colorToolUtils';
import setTemporarySelectedElementsColorStyles from '../setTemporarySelectedElementsColorStyles';
import setTemporarySelectedElementsBackgroundColorStyles from '../setTemporarySelectedElementsBackgroundColorStyles';
import { isColumn, isSketch } from '../../../../../../../common/elements/utils/elementTypeUtils';
import { elementAllowsDualColors } from '../dualColors/dualColorListUtils';

// Selectors
import { getCurrentBoard } from '../../../../../../element/board/boardSelector';
import currentBoardSuggestedColorsSelector from '../currentBoardSuggestedColorsSelector';

// Actions
import { closePopup } from '../../../../../../components/popupPanel/popupActions';
import {
    updateSelectedElementsBackgroundColors,
    updateSelectedElementsColors,
    updateSelectedElementsSecondaryColors,
} from '../colorActions';

// Components
import ToolbarPopup from '../../../toolbarPopup/ToolbarPopup';
import ColorPopupContent from './ColorPopupContent';
import CustomColorInput from '../CustomColorInput';

// Hooks
import useActualColorPopupMode from './useActualColorPopupMode';

// Constants
import { COLOR_POPUP_MODE } from './colorPopupConstants';
import {
    BACKGROUND_COLORS,
    COLORS,
    COLUMN_COLORS,
    SKETCH_BACKGROUND_COLORS,
} from '../../../../../../../common/colors/colorConstants';

// Styles
import './ColorPopup.scss';

const COLORS_ARRAY = Object.values(COLORS);
const BACKGROUND_COLORS_ARRAY = Object.values(BACKGROUND_COLORS);
const SKETCH_BACKGROUND_COLORS_ARRAY = Object.values(SKETCH_BACKGROUND_COLORS);
const COLUMN_BACKGROUND_COLORS_ARRAY = Object.values(COLUMN_COLORS);

const getDefaultColors = ({ colorPopupMode, elementType }) => {
    if (colorPopupMode !== COLOR_POPUP_MODE.BACKGROUND) return COLORS_ARRAY;

    if (isSketch(elementType)) return SKETCH_BACKGROUND_COLORS_ARRAY;

    if (isColumn(elementType)) return COLUMN_BACKGROUND_COLORS_ARRAY;

    return isSketch(elementType) ? SKETCH_BACKGROUND_COLORS_ARRAY : BACKGROUND_COLORS_ARRAY;
};

const colorPopupSelector = createStructuredSelector({
    currentBoard: getCurrentBoard,
    suggestedColors: currentBoardSuggestedColorsSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    closeColorPopup: () => dispatch(closePopup(ownProps.popupId)),
    setTempColor: setTemporarySelectedElementsColorStyles,
    setTempBackgroundColor: setTemporarySelectedElementsBackgroundColorStyles,
    setColor: (colorValue, secondaryColorValue) => {
        const color = getColorToPersist(colorValue);
        const secondaryColor = secondaryColorValue && getColorToPersist(secondaryColorValue);
        dispatch(updateSelectedElementsColors({ color, secondaryColor }));
    },
    setSecondaryColor: (secondaryColorValue) => {
        const secondaryColor = getColorToPersist(secondaryColorValue);
        dispatch(updateSelectedElementsSecondaryColors({ secondaryColor }));
    },
    setBackgroundColor: (colorValue, secondaryColorValue) => {
        const color = getColorToPersist(colorValue);
        const secondaryColor = secondaryColorValue && getColorToPersist(secondaryColorValue);
        dispatch(updateSelectedElementsBackgroundColors(color, secondaryColor));
    },
});

const ColorPopup = (props) => {
    const {
        isPopupOpen,
        closeColorPopup,

        popupId,
        buttonSelector,

        selectedElements,
        elementType,

        selectedColor,
        selectedColorHex,
        selectedBackgroundColor,
        selectedBackgroundColorHex,

        setTempColor,
        setColor,
        setSecondaryColor,
        setBackgroundColor,
        setTempBackgroundColor,

        suggestedColors,
        supportBackgroundColor,

        customDefaultColors,
        customColorHandler,
        customTempColorHandler,
    } = props;

    const [colorPopupMode, setColorPopupMode] = useState(COLOR_POPUP_MODE.BACKGROUND);
    const customColorInputRef = useRef();

    const supportColorBar = getSupportColorBar(selectedElements);
    const isBackgroundMode = colorPopupMode === COLOR_POPUP_MODE.BACKGROUND && supportBackgroundColor;

    const supportDualColors = elementAllowsDualColors(selectedElements.first());
    const secondaryColor = getFirstSelectedSecondaryColor(selectedElements);

    const actualPopupMode = useActualColorPopupMode({
        isPopupOpen,
        isBackgroundMode,

        supportColorBar,

        selectedColor,
        selectedBackgroundColor,

        setColorPopupMode,
    });

    const defaultColors = getDefaultColors({
        colorPopupMode: actualPopupMode,
        elementType,
    });

    const setColorHandler = isBackgroundMode ? setBackgroundColor : setColor;

    const setTempColorHandler = isBackgroundMode ? setTempBackgroundColor : setTempColor;

    const handleTempColor = (color) => {
        // if there is a secondary color while using the color picker, set it to null on the first input.
        // Needs to happen before setTempColor so that the css for the icon color gets set to black/white
        if (supportDualColors && secondaryColor && actualPopupMode !== COLOR_POPUP_MODE.COLOR_BAR) {
            setSecondaryColor(null);
        }
        setTempColorHandler(color);
    };

    const popupColor = isBackgroundMode ? selectedBackgroundColor : selectedColor;

    const popupColorHex = isBackgroundMode ? selectedBackgroundColorHex : selectedColorHex;

    const openCustomColorPicker = useCallback(() => {
        if (customColorInputRef && customColorInputRef.current) {
            customColorInputRef.current.open();
        }
    }, [customColorInputRef]);

    return (
        <div className={classNames('ColorPopup', { 'is-open': isPopupOpen })}>
            <CustomColorInput
                className="custom-color-picker-input"
                ref={customColorInputRef}
                initialColor={popupColorHex}
                setTempColor={customTempColorHandler || handleTempColor}
                setColor={customColorHandler || setColorHandler}
                onBlur={debounce(closeColorPopup, 200)}
            />
            <ToolbarPopup
                popupId={popupId}
                className="color-popup"
                buttonSelector={buttonSelector}
                closePopup={closeColorPopup}
            >
                <ColorPopupContent
                    {...props}
                    defaultColors={customDefaultColors || defaultColors}
                    supportColorBar={supportColorBar}
                    setColor={customColorHandler || setColorHandler}
                    selectedColor={popupColor}
                    openCustomColorPicker={openCustomColorPicker}
                    colorPopupMode={actualPopupMode}
                    setColorPopupMode={setColorPopupMode}
                    supportDualColors={supportDualColors}
                    suggestedColors={suggestedColors}
                />
            </ToolbarPopup>
        </div>
    );
};

ColorPopup.propTypes = {
    selectedElements: PropTypes.object,
    elementType: PropTypes.string,

    setTempColor: PropTypes.func.isRequired,
    setColor: PropTypes.func.isRequired,
    setSecondaryColor: PropTypes.func,
    setBackgroundColor: PropTypes.func,
    setTempBackgroundColor: PropTypes.func,

    closeColorPopup: PropTypes.func,
    selectedColor: PropTypes.string,
    selectedColorHex: PropTypes.string,
    selectedBackgroundColor: PropTypes.string,
    selectedBackgroundColorHex: PropTypes.string,

    dispatchAddColorHistoryEntry: PropTypes.func,
    currentUserId: PropTypes.string,
    isPopupOpen: PropTypes.bool,
    supportBackgroundColor: PropTypes.bool,

    currentBoard: PropTypes.object,
    suggestedColors: PropTypes.array,
    setAppStateSuggestedColors: PropTypes.func,

    popupId: PropTypes.string.isRequired,
    buttonSelector: PropTypes.string,

    customDefaultColors: PropTypes.array,
    customColorHandler: PropTypes.func,
    customTempColorHandler: PropTypes.func,
};

export default connect(colorPopupSelector, mapDispatchToProps)(ColorPopup);
