// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';

// Utils
import { hasChanged } from '../../../utils/react/propsComparisons';
import platformSingleton from '../../../platform/platformSingleton';

// Components
import TooltipSource from '../../../components/tooltips/TooltipSource';

// Constants
import { TooltipPositions } from '../../../components/tooltips/tooltipConstants';

// Styles
import './Tool.scss';

const onClickHasChanged = hasChanged('onClick');
const onPointerDownHasChanged = hasChanged('onPointerDown');

class Tool extends React.Component {
    constructor(props) {
        super(props);

        this.createDebouncedMouseHandlers(props);
    }

    componentWillReceiveProps(nextProps) {
        if (onClickHasChanged(this.props, nextProps) || onPointerDownHasChanged(this.props, nextProps)) {
            this.createDebouncedMouseHandlers(nextProps);
        }
    }

    createDebouncedMouseHandlers = (props) => {
        this.onMouseDown = props.onMouseDown && debounce(props.onMouseDown, 250, { leading: true, trailing: false });
        this.onPointerDown =
            props.onPointerDown && debounce(props.onPointerDown, 250, { leading: true, trailing: false });
        this.onClick = props.onClick && debounce(props.onClick, 250, { leading: true, trailing: false });
    };

    render() {
        const {
            id,
            isDragging,
            isActive,
            isDraggable,
            disabled,
            name,
            className,
            children,
            tooltipEnabled,
            tooltipText,
            shortcut,
        } = this.props;

        const classes = {
            dragging: isDragging,
            active: isActive,
            draggable: isDraggable,
            hoverable: platformSingleton.features.supportsMouse,
            disabled,
        };

        const toolNameElement = name ? <span className="tool-name">{name}</span> : null;

        return (
            <div
                id={id}
                className={classNames('Tool', className, classes)}
                onClick={this.onClick}
                onMouseDown={this.onMouseDown}
                onPointerDown={this.onPointerDown}
            >
                <div className="contents">
                    <TooltipSource
                        enabled={tooltipEnabled}
                        tooltipText={tooltipText}
                        shortcut={shortcut}
                        position={TooltipPositions.RIGHT}
                        delay={700}
                        pollPosition
                        triggerOnMouseEnter
                    >
                        <div className="icon">
                            {isDraggable && <div className="draggable-shadow">{children}</div>}
                            {children}
                        </div>
                    </TooltipSource>
                    {toolNameElement}
                </div>
            </div>
        );
    }
}

Tool.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
    name: PropTypes.string,
    iconName: PropTypes.string,
    isDraggable: PropTypes.bool,
    isDragging: PropTypes.bool,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    onPointerDown: PropTypes.func,
    className: PropTypes.string,
    id: PropTypes.string,
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    tooltipEnabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    shortcut: PropTypes.array,
};

export default Tool;
