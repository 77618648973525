import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { isFeatureEnabled } from '../../../common/users/utils/userPropertyUtils';
import { getCurrentUser } from '../../user/currentUserSelector';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';

/**
 * @param {string} feature Feature Flag ID
 * @param {boolean} defaultValue Default value if the isFeatureEnabled is undefined (e.g. guest)
 */
export const getIsFeatureEnabledForCurrentUser = (feature: ExperimentId, defaultValue = false) =>
    createSelector(getCurrentUser, (currentUser) => {
        const isFeatureEnabledForCurrentUser = isFeatureEnabled(feature, currentUser);

        return isFeatureEnabledForCurrentUser ?? defaultValue;
    });

/**
 * Determines if the current user has the "clientPersistence" feature flag enabled.
 */
export const getIsClientPersistenceEnabledForCurrentUser = getIsFeatureEnabledForCurrentUser(
    ExperimentId.clientPersistence,
);

/**
 * HOC to swap between two components based on a feature flag.
 * (will try to use the prop typechecking for the flag-enabled component)
 */
export const createFeatureToggledComponent = <T extends {}>( // "extends {}" just so that TS doesn't think this is a JSX expression
    featureId: ExperimentId,
    EnabledComponent: React.ComponentClass<T, any> | React.FC<T>,
    DisabledComponent: React.ComponentClass<any, any> | React.FC<any>,
) =>
    React.forwardRef((props: T, ref) => {
        const isFeatureEnabled = useSelector(getIsFeatureEnabledForCurrentUser(featureId));
        const Component = isFeatureEnabled ? EnabledComponent : DisabledComponent;

        return <Component {...props} ref={ref} />;
    });
