// Lib
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';

// Components
import RouteModalRootAuthenticated from '../../../components/modal/RouteModalRootAuthenticated';
import AccountPlanContainer from '../accountModal/plan/AccountPlanContainer';
import Spinner from '../../../components/loaders/Spinner';

// Selector
import { shouldUseCheckoutV2 } from '../accountModal/plan/payment/paymentSelector';

// Actions
import { redirectTo } from '../../../reducers/navigationActions';

// Constants
import { CHECKOUT_V1_TO_V2_PATH_MAPPINGS } from '../checkout/checkoutConstants';

// Styles
import './PlanModal.scss';

const PlanModal = (props) => {
    const { children, route } = props;
    const className = route && route.className;
    const hideBack = get(['query', 'no_back'], props.location);

    const useCheckoutV2 = useSelector(shouldUseCheckoutV2);

    const dispatch = useDispatch();
    const dispatchRedirectTo = (path) => dispatch(redirectTo(path));

    useEffect(() => {
        const checkoutV2Pathname = CHECKOUT_V1_TO_V2_PATH_MAPPINGS[location.pathname];
        if (useCheckoutV2 === true && checkoutV2Pathname) {
            dispatchRedirectTo(checkoutV2Pathname);
        }
    }, [useCheckoutV2]);

    // If we haven't determined whether to use checkout v2 yet, show a spinner
    if (useCheckoutV2 === undefined) {
        return (
            <RouteModalRootAuthenticated {...props} className={classNames('PlanModal auto-width', className)}>
                <Spinner show />
            </RouteModalRootAuthenticated>
        );
    }

    return (
        <RouteModalRootAuthenticated
            {...props}
            showBack={!hideBack}
            className={classNames('PlanModal auto-width', className)}
        >
            <AccountPlanContainer showSubscriptionDetails={false}>{children}</AccountPlanContainer>
        </RouteModalRootAuthenticated>
    );
};

PlanModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    route: PropTypes.object,
    location: PropTypes.object,
};

export default PlanModal;
