// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';

// Utils
import { length, prop } from '../../../common/utils/immutableHelper';
import { isReadOnly } from '../../../common/permissions/permissionUtil';

// Components
import AnchorButton from '../../components/buttons/AnchorButton';
import ElementReactionAddButton from '../reactions/ElementReactionAddButton';

// Styles
import './CommentTools.scss';

const SaveButton = (props) => {
    const onSave = (e) => {
        e.stopPropagation();

        const { updateComment, editorStateRef } = props;

        const editorState = editorStateRef?.current;

        if (!editorState) return;

        const contentState = editorState.getCurrentContent();

        if (!contentState || !contentState.hasText()) return;

        const text = convertToRaw(contentState);

        updateComment(text);
    };

    return <AnchorButton onMouseDown={onSave}>Save</AnchorButton>;
};

SaveButton.propTypes = {
    updateComment: PropTypes.func,
    editorStateRef: PropTypes.object,
};

const CommentTools = ({
    _id,
    currentUserId,
    userId,
    startEditing,
    content,
    setKeepToolsVisible,
    permissions,
    isUpdating,
    updateComment,
    editorStateRef,
}) => {
    const canEditComment = currentUserId === userId;
    const hasReactions = length(prop('reactions', content) || []) > 0;

    if (isReadOnly(permissions)) return null;

    return (
        <div className="CommentTools">
            {canEditComment &&
                (isUpdating ? (
                    <SaveButton updateComment={updateComment} editorStateRef={editorStateRef} />
                ) : (
                    <AnchorButton onClickFn={startEditing}>Edit</AnchorButton>
                ))}

            {!canEditComment && !hasReactions && (
                <ElementReactionAddButton elementId={_id} onPopupToggleCb={setKeepToolsVisible} />
            )}
        </div>
    );
};

CommentTools.propTypes = {
    _id: PropTypes.string,
    userId: PropTypes.string.isRequired,
    currentUserId: PropTypes.string,
    content: PropTypes.object,
    startEditing: PropTypes.func,
    setKeepToolsVisible: PropTypes.func,
    permissions: PropTypes.number,
    isUpdating: PropTypes.bool,
    updateComment: PropTypes.func,
    editorStateRef: PropTypes.object,
};

export default CommentTools;
