// Lib
import { get, uniq, compact } from 'lodash/fp';

// Manager
import {
    enableCollisionDetection,
    disableCollisionDetection,
    enableCollisionDetectionTemporarily,
    unblockCollisionDetectionAfterNavigation,
} from './collisionDetectionManager';

// Utils
import {
    hasVisibleDescendantsExcludingAttachments,
    isCard,
    isCommentThread,
    isTask,
    isTaskLike,
} from '../../../common/elements/utils/elementTypeUtils';
import { isLocationInbox } from '../../../common/elements/utils/elementLocationUtils';

// Selectors
import { getElements } from '../../element/selectors/elementSelector';
import { getElement } from '../../../common/elements/utils/elementTraversalUtils';

// Constants
import { USER_NAVIGATE } from '../../../common/users/userConstants';
import { ELEMENT_EDIT_COMPLETE, ELEMENT_EDIT_START } from '../../../common/elements/selectionConstants';
import {
    COMMENTS_ADD,
    COMMENTS_CANCEL_EDITING,
    COMMENTS_DELETE,
    COMMENTS_STOP_EDITING,
    COMMENTS_TYPING_STOP,
} from '../../../common/comments/commentConstants';
import {
    ELEMENT_CREATE,
    ELEMENT_CREATION_SOURCES,
    ELEMENT_MOVE_MULTI,
    ELEMENT_MOVE_OPERATIONS,
    ELEMENT_SET_TYPE,
    ELEMENT_UPDATE,
} from '../../../common/elements/elementConstants';

/**
 * Ensure the element move is something we want to allow collision detection
 * for, e.g. move into or out of columns/tasks.
 */
const handleElementMoveMulti = (state, action) => {
    if (action.moveOperation === ELEMENT_MOVE_OPERATIONS.COLLISION) return;

    const { moves } = action;

    const parentIds = compact(
        uniq(moves.flatMap((move) => [get(['from', 'parentId'], move), get(['location', 'parentId'], move)])),
    );

    // If the parent is staying the same there's no need to run collision detection
    if (parentIds.length <= 1) return;

    const elements = getElements(state);

    // Only check if the move is into or out of a column or task
    const shouldEnableCollisionDetection = parentIds.some((parentId) => {
        const parent = getElement(elements, parentId);
        return hasVisibleDescendantsExcludingAttachments(parent);
    });

    if (!shouldEnableCollisionDetection) return;

    // If there's a height change it should happen within the same javascript
    // event loop.
    enableCollisionDetectionTemporarily();
};

const handleElementCreate = (state, action) => {
    const { elementType } = action;

    const shouldEnableCollisionDetection =
        // Creating new tasks in a task list by pressing return
        isTask(elementType) ||
        // Element has been dropped into a column
        isLocationInbox(action) ||
        // Creating new cards, task lists or comment threads by pressing Cmd+return
        (action.creationSource === ELEMENT_CREATION_SOURCES.CMD_ENTER &&
            (isCard(elementType) || isTaskLike(elementType) || isCommentThread(elementType)));

    if (!shouldEnableCollisionDetection) return;

    enableCollisionDetectionTemporarily();
};

export default (store) => (next) => (action) => {
    // Don't enable collision detection for any remote actions or undo/redo actions
    if (action.remote || action.isUndo || action.isRedo) return next(action);

    const state = store.getState();

    switch (action.type) {
        case COMMENTS_ADD:
        case COMMENTS_CANCEL_EDITING:
        case COMMENTS_STOP_EDITING:
        case COMMENTS_DELETE:
        case COMMENTS_TYPING_STOP:
        case ELEMENT_UPDATE:
        case ELEMENT_SET_TYPE:
            enableCollisionDetectionTemporarily();
            break;
        case ELEMENT_CREATE:
            handleElementCreate(state, action);
            break;
        case ELEMENT_MOVE_MULTI:
            handleElementMoveMulti(state, action);
            break;
        // These events allow title edits to cause collision detection
        case ELEMENT_EDIT_START:
            enableCollisionDetection();
            break;
        case ELEMENT_EDIT_COMPLETE:
            disableCollisionDetection();
            break;
        case USER_NAVIGATE:
            unblockCollisionDetectionAfterNavigation();
            break;
        default:
            break;
    }

    return next(action);
};
