// Lib
import * as Immutable from 'immutable';
import { zipObject, flow, get } from 'lodash/fp';

// Utils
import { getUserId } from '../../../../../../../common/users/utils/userPropertyUtils';
import { canWrite } from '../../../../../../../common/permissions/permissionUtil';
import { getUserIdFromAction } from '../../../../../../../common/actionUtils';
import { getTimestamp } from '../../../../../../../common/utils/timeUtil';

// Constants
import {
    ELEMENT_UPDATE_PERMISSIONS,
    ELEMENT_UPDATE_PERMISSIONS_ERROR,
} from '../../../../../sharing/boardSharingConstants';
import { CURRENT_BOARD_ID_SET } from '../../../../../../reducers/currentBoardId/currentBoardIdConstants';
import {
    ELEMENT_CREATE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_UPDATE,
    ELEMENT_UPDATE_ACL,
} from '../../../../../../../common/elements/elementConstants';
import { BOARD_EDITORS_LOAD } from './boardEditorConstants';
import { USER_NAVIGATE } from '../../../../../../../common/users/userConstants';
import { COMMENTS_ADD } from '../../../../../../../common/comments/commentConstants';

const initialState = Immutable.Map({
    permissionsUpdatingUsers: Immutable.Map(),
    editorTimestamps: Immutable.Map(),
});

const handleBoardEditorsLoad = (state, action) =>
    state.setIn(['editorTimestamps', action.elementId], Immutable.fromJS(action.editors));

const setUpdatingUsersState = (message, userIds) => (state) => {
    if (!userIds) return state;

    return state.set(
        'permissionsUpdatingUsers',
        state.get('permissionsUpdatingUsers').merge(
            zipObject(
                userIds,
                userIds.map(() => message),
            ),
        ),
    );
};

const handleUpdatePermissions = (state, { addUserIds, addNewUserEmails, updateUserIds, removeUserIds }) =>
    flow(
        setUpdatingUsersState('Adding', addUserIds),
        setUpdatingUsersState('Adding', addNewUserEmails),
        setUpdatingUsersState('Updating', updateUserIds),
        setUpdatingUsersState('Removing', removeUserIds),
    )(state);

const handleUpdatePermissionsError = (state, { userIds }) => setUpdatingUsersState('error', userIds)(state);

/**
 * Remove the temporary state for user IDs in the editors list.
 * Also add or remove user IDs as necessary from the editorTimestamps map.
 */
const handleUpdateAcl = (state, action) => {
    const { addUserIds = [], addNewUserEmails = [], removeUserIds = [], updateUserIds = [], addedUsers = [] } = action;

    return state.withMutations((mutableState) => {
        const noLongerUpdatingKeys = [...addUserIds, ...addNewUserEmails, ...removeUserIds, ...updateUserIds];

        noLongerUpdatingKeys.forEach((key) => mutableState.deleteIn(['permissionsUpdatingUsers', key]));

        removeUserIds.forEach((removedUserId) => mutableState.deleteIn(['editorTimestamps', action.id, removedUserId]));

        if (addedUsers) {
            addedUsers.forEach((addedUser) => {
                const userId = getUserId(addedUser);
                mutableState.updateIn(
                    ['editorTimestamps', action.id, userId],
                    (existingTimestamp) =>
                        existingTimestamp ||
                        Immutable.fromJS({
                            lastEdit: 0,
                            lastView: 0,
                        }),
                );
            });
        }
    });
};

const handleUserNavigate = (state, action) => {
    if (!action.remote) return state;
    if (!get(['activity', 'isNewBoardShared'], action)) return state;

    const { newBoardId, permission, userId, timestamp } = action;

    if (!canWrite(permission)) return state;

    return state.setIn(['editorTimestamps', newBoardId, userId, 'lastView'], timestamp);
};

const handleElementMove = (state, action) => {
    const destinationBoardId = get(['activity', 'destinationBoardId'], action);
    const sourceBoardId = get(['activity', 'sourceBoardId'], action);
    const userId = getUserIdFromAction(action);

    if (!userId) return state;

    const timestamp = getTimestamp();

    return state
        .setIn(['editorTimestamps', destinationBoardId, userId, 'lastEdit'], timestamp)
        .setIn(['editorTimestamps', sourceBoardId, userId, 'lastEdit'], timestamp);
};

const handleElementAction = (state, action) => {
    const boardId = get(['activity', 'boardId'], action);
    const userId = getUserIdFromAction(action);

    if (!boardId || !userId) return state;

    return state.setIn(['editorTimestamps', boardId, userId, 'lastEdit'], getTimestamp());
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BOARD_EDITORS_LOAD:
            return handleBoardEditorsLoad(state, action);
        case ELEMENT_UPDATE_PERMISSIONS:
            return handleUpdatePermissions(state, action);
        case ELEMENT_UPDATE_PERMISSIONS_ERROR:
            return handleUpdatePermissionsError(state, action);
        case ELEMENT_UPDATE_ACL:
            return handleUpdateAcl(state, action);
        case CURRENT_BOARD_ID_SET:
            return state.set('permissionsUpdatingUsers', Immutable.Map());
        case USER_NAVIGATE:
            return handleUserNavigate(state, action);
        case ELEMENT_CREATE:
        case ELEMENT_UPDATE:
        case COMMENTS_ADD:
            return handleElementAction(state, action);
        case ELEMENT_MOVE_MULTI:
            return handleElementMove(state, action);
        default:
            return state;
    }
};
