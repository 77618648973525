// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Selectors
import { getMilanoteApplicationModeSelector, getPlatformFormFactorSelector } from '../../platform/platformSelector';

// Components
import ConnectedQuickLineCreationTool from './ConnectedQuickLineCreationTool';
import { DeviceFormFactor, MilanoteApplicationMode } from '../../platform/platformTypes';

const QuickLineCreationTool = (props) => {
    const { show } = props;

    const deviceFormFactor = useSelector(getPlatformFormFactorSelector);
    const appMode = useSelector(getMilanoteApplicationModeSelector);

    if (!show) return null;

    // NOTE: It was much easier to perform these checks here rather than in every parent component
    if (deviceFormFactor === DeviceFormFactor.phone) return null;
    if (appMode === MilanoteApplicationMode.mobile) return null;
    if (appMode === MilanoteApplicationMode.mobileLegacy) return null;

    return <ConnectedQuickLineCreationTool {...props} />;
};

QuickLineCreationTool.propTypes = {
    show: PropTypes.bool,
};

export default QuickLineCreationTool;
