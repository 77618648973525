// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Constants
import {
    BACKGROUND_COLOR_PREFIX,
    TEXT_COLOR_PREFIX,
} from '../../../../../../components/editor/plugins/textColor/textColorConstants';

// Styles
import './TextColorButton.scss';

const TextColorButton = (props) => {
    const { currentStyle, preset, onTextColorClick, customTextColor, customBackgroundColor } = props;

    const active = preset
        ? currentStyle.has(preset)
        : !currentStyle.some(
              (style) => style.startsWith(TEXT_COLOR_PREFIX) || style.startsWith(BACKGROUND_COLOR_PREFIX),
          );

    const onPointerDown = (event) => {
        // If we don't want to show the standard blue text selection style, then don't prevent default
        event.preventDefault();

        onTextColorClick(preset);
    };

    const presetClassname = preset ? preset.toLowerCase() : 'text-standard';

    const style = {};
    if (customTextColor) style.color = customTextColor;
    if (customBackgroundColor) style.backgroundColor = customBackgroundColor;

    return (
        <div
            className={classNames('TextColorButton', presetClassname, { active })}
            onPointerDown={onPointerDown}
            style={style}
        >
            A
        </div>
    );
};

TextColorButton.propTypes = {
    currentStyle: PropTypes.object,
    preset: PropTypes.string,
    className: PropTypes.string,
    onTextColorClick: PropTypes.func,
    customTextColor: PropTypes.string,
    customBackgroundColor: PropTypes.string,
};

export default TextColorButton;
