// Lib
import { useSelector } from 'react-redux';

// Hooks
import useBodyClass from '../../utils/react/useBodyClass';

// Selectors
import { getBrowserEngineSelector, getIsTouchSelector, getPlatformTypeSelector } from '../platformSelector';

/**
 * This hook is responsible for adding platform related classes
 * to the body element (e.g. 'browser-engine-chrome', 'platform-ios' etc).
 */
export default function usePlatformBodyClassManager() {
    const browserEngine = useSelector(getBrowserEngineSelector);
    useBodyClass(`browser-engine-${browserEngine}`);

    const platform = useSelector(getPlatformTypeSelector);
    useBodyClass(`platform-${platform}`);

    const isTouch = useSelector(getIsTouchSelector);
    useBodyClass(isTouch ? 'touch-device' : 'not-touch-device');
}
