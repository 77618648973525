// Constants
import { POI_REMOVE, POI_MULTIPLE_SEEN, POI_SET } from './poiConstants';

export const poiSet = ({ id, poiType, currentBoardId, section, targetRect, data }) => ({
    type: POI_SET,
    id,
    currentBoardId,
    section,
    poiType,
    targetRect,
    data,
});
export const poiRemove = ({ id }) => ({ type: POI_REMOVE, id });

export const markPoisAsSeen = ({ ids }) => ({ type: POI_MULTIPLE_SEEN, ids });
