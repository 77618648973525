import platformSingleton from '../../platform/platformSingleton';

import registerCard from '../../../common/cards/index';
import presentationalComponent from './Card';
import containerComponent from './CardContainer';

import { isPlatformApple, isPlatformLegacyMobileApp } from '../../platform/utils/platformDetailsUtils';

import { ELEMENT_FEATURES } from '../feature/elementFeatureConstants';
import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerCard({
        containerComponent,
        presentationalComponent,
        features: [ELEMENT_FEATURES.DOCUMENT, ELEMENT_FEATURES.DESCRIPTION, ELEMENT_FEATURES.TRANSPARENT],
        selectedTools: [
            { ...TOOLBAR_TOOLS.COLOR, name: 'Card color' },
            TOOLBAR_TOOLS.REACTION,
            ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
        ],
        editingTools: isPlatformLegacyMobileApp(platformSingleton) ? [] : TOOL_SETS.TEXT_EDITING_INLINE_AND_BLOCK,
        tooltip: isPlatformApple(platformSingleton) ? '⌃⌘N' : 'Ctrl+Alt+N',
    });
