// Lib
import * as Immutable from 'immutable';
import { unset, get as getIn, isEmpty } from 'lodash';

// Utils
import globalLogger, { LoggerComponents } from '../../../logger';
import {
    isAsyncEntityError,
    isAsyncEntityFetching,
} from '../../../utils/services/http/asyncResource/asyncResourceUtils';
import { SOCKET_CONNECTION_STATUS, SOCKET_RECONNECTION_MODE } from '../../../utils/socket/socketConstants';

// Constants
import { ResourceTypes } from '../../../utils/services/http/asyncResource/asyncResourceConstants';

const logger = globalLogger.createChannel(LoggerComponents.INSTANT_APP);

const POJO_STATE_PROPERTIES = [
    'app.boardSummaries.summaries',
    'app.boardHierarchies',
    'app.cms.emoji.data',
    'app.cms.ai-assistant-shortcuts.data',
    'asyncResources',
    'cache.selectors',
    'platform',
];

/**
 * Removes resource entity state if it's fetching or errored.
 */
const clearFetchingResources = (persistedState: any) => {
    if (!persistedState?.asyncResources) return persistedState;

    const resourceTypes = Object.keys(persistedState.asyncResources);

    for (const resourceType of resourceTypes) {
        const entityIds = Object.keys(persistedState.asyncResources?.[resourceType]) || [];

        for (const entityId of entityIds) {
            const entity = persistedState.asyncResources[resourceType][entityId];

            if (!isAsyncEntityFetching(entity) && !isAsyncEntityError(entity)) continue;

            logger.debug(`%c- Removing fetching/errored entity`, 'color: grey', resourceType, entityId, entity);

            delete persistedState.asyncResources[resourceType][entityId];
        }
    }

    return persistedState;
};

/**
 *
 */
const setSocketDisconnectionTimes = (persistedState: any, cacheTimestamp: number) => {
    if (!persistedState?.app) return persistedState;

    persistedState.app.socketConnection = {
        ...persistedState.app.socketConnection,
        status: SOCKET_CONNECTION_STATUS.DISCONNECTED,
        reconnectionMode: SOCKET_RECONNECTION_MODE.REHYDRATION,
        interruptionTime: cacheTimestamp,
        disconnectTime: cacheTimestamp,
        channels: [],
        boardChannels: [],
    };

    return persistedState;
};

/**
 * Removes resource entity state if it's in the trash.
 */

const clearTrashResources = (persistedState: any) => {
    if (!persistedState?.asyncResources) return persistedState;

    const trashResource = persistedState.asyncResources[ResourceTypes.trash];

    if (trashResource && !isEmpty(trashResource)) {
        delete persistedState.asyncResources[ResourceTypes.trash];
    }

    return persistedState;
};

/**
 * Converts the state persisted in IndexedDB to the ImmutableJS structure
 * expected by the redux store.
 */
export const rebuildPersistedState = (
    persistedState: any,
    cacheTimestamp: number,
    cacheVersion: string,
): Immutable.Map<string, any> => {
    persistedState = setSocketDisconnectionTimes(persistedState, cacheTimestamp);
    persistedState = clearFetchingResources(persistedState);
    persistedState = clearTrashResources(persistedState);

    // Initialise the rehydration state.
    // It's simpler to do this here rather than firing a redux action to set the state
    persistedState.localCache = {
        hydration: {
            hydrated: true,
            hydrationTimestamp: Date.now(),
            cacheTimestamp,
            cacheVersion,
        },
    };

    const pojoValues: { [key: string]: any } = {};

    // set values of POJO fields in temp object and delete rehydrated POJO fields from persisted state
    POJO_STATE_PROPERTIES.forEach((field) => {
        pojoValues[field] = getIn(persistedState, field);
        unset(persistedState, field);
    });

    const immutableState = Immutable.fromJS(persistedState);

    const rehydratedState = immutableState.withMutations((mutableState: Immutable.Map<string, any>) => {
        for (const field in pojoValues) {
            const path = field.split('.');
            mutableState.setIn(path, pojoValues[field]);
        }
    });

    return rehydratedState;
};
