// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Icon from '../../components/icons/Icon';

// Styles
import './TaskDragHandle.scss';

const TaskDragHandle = ({ dragHandleWrapper }) =>
    dragHandleWrapper(
        <div className="TaskDragHandle">
            <Icon className="Icon" name="task-drag" />
        </div>,
    );

TaskDragHandle.propTypes = {
    dragHandleWrapper: PropTypes.func,
};

export default TaskDragHandle;
