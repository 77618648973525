// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './AccountPlanSection.scss';

const AccountPlanSection = (props) => {
    const { className, title, details, action } = props;

    return (
        <div className={classNames('AccountPlanSection settings-section', className)}>
            <div className="title">{title}</div>
            <div className="details">{details}</div>
            <div className="section-action">{action}</div>
        </div>
    );
};

AccountPlanSection.propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
    action: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    details: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default AccountPlanSection;
