// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getHasClones, getTextContent } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import CardEditor from './CardEditor';
import CloneCardEditor from './CloneCardEditor';

const BasicCardEditor = (props) => <CardEditor textContent={getTextContent(props.element)} {...props} />;

const CardEditorSwitch = (props) => {
    const { element, isClone } = props;

    if (getHasClones(element) || isClone) return <CloneCardEditor {...props} />;

    return <BasicCardEditor {...props} />;
};

CloneCardEditor.propTypes =
    BasicCardEditor.propTypes =
    CardEditorSwitch.propTypes =
        {
            element: PropTypes.object,
            isClone: PropTypes.bool,
            isEditing: PropTypes.bool,
        };

export default CardEditorSwitch;
