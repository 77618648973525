// Constants
import { BOARD_EXPORT_FORMATS } from './exportConstants';
import { MIME_TYPES } from '../media/mimeConstants';

const mapToOldCanvasExportFormat = (fileType) => {
    switch (fileType) {
        case MIME_TYPES.PDF:
            return 'pdf-html';
        case MIME_TYPES.PNG:
        default:
            return 'png-html';
    }
};

const mapToOldLinearExportFormat = (fileType) => {
    switch (fileType) {
        case MIME_TYPES.PDF:
            return 'pdf';
        case MIME_TYPES.DOCX:
            return 'docx';
        case MIME_TYPES.PLAIN_TEXT:
            return 'txt';
        case MIME_TYPES.MARKDOWN:
        default:
            return 'md';
    }
};

/**
 * We need to do a mapping to keep amplitude data consistent.
 *
 * Previously "format" used to be one of: pdf-html | png=html | pdf | md | docx | txt
 * Now the format is: canvas | linear
 * And the fileType is: application/pdf | text/plain; charset=UTF-8 | ...
 */
export const mapToOldExportFormat = (format, fileType) =>
    format === BOARD_EXPORT_FORMATS.canvas
        ? mapToOldCanvasExportFormat(fileType)
        : mapToOldLinearExportFormat(fileType);
