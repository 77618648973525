// Utils
import { getSubscriptionProvider } from '../subscriptionPropertyUtil';

// Types
import {
    MNAppStoreSubscription,
    MNStripeSubscription,
    MNSubscription,
    MNSubscriptionProvider,
} from './subscriptionTypes';

export const isStripeSubscription = (subscription: MNSubscription): subscription is MNStripeSubscription =>
    getSubscriptionProvider(subscription) === MNSubscriptionProvider.STRIPE;

export const isAppStoreSubscription = (subscription: MNSubscription): subscription is MNAppStoreSubscription =>
    getSubscriptionProvider(subscription) === MNSubscriptionProvider.APPLE;
