// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Utils
import { getElementId } from '../../../../../../../../../common/elements/utils/elementPropertyUtils';
import { sendBoardIconSetEvent } from '../../../../../../../../components/elementIcon/elementIconAnalyticsUtils';

// Components
import Button from '../../../../../../../../components/buttons/Button';
import ElementIcon from '../../../../../../../../components/elementIcon/ElementIcon';

// Actions
import { cleanElementAttachment } from '../../../../../../../../element/attachments/attachmentActions';
import { updateSelectedElements } from '../../../../../../../../element/actions/elementActions';

// Constants
import { ICON_SEARCH_MODES } from '../../../../../../../../../common/icons/iconConstants';

// Styles
import './IconButton.scss';

const updateElementsIcon = (icon, selectedElements) => (dispatch, getState) => {
    dispatch(updateSelectedElements({ changes: { icon, image: null } }));
    dispatch(cleanElementAttachment({ ids: selectedElements.map(getElementId) }));
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    selectIcon: (event) => {
        const { icon, selectedElements, popupQuery } = ownProps;

        const iconJs = icon.toJS();

        sendBoardIconSetEvent({
            icon: iconJs,
            boardId: getElementId(selectedElements.first()),
            searchQuery: popupQuery,
            setMode: ICON_SEARCH_MODES.USER_ACTION,
        });

        dispatch(updateElementsIcon(iconJs, selectedElements));
    },
});

const IconButton = (props) => (
    <Button className={classNames('IconButton', { active: props.isActive })} onClickFn={props.selectIcon}>
        <ElementIcon icon={props.icon} />
    </Button>
);

IconButton.propTypes = {
    icon: PropTypes.object,
    selectedElements: PropTypes.object,
    selectIcon: PropTypes.func,
    isActive: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(IconButton);
