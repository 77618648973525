import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const TickIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={22} height={19} viewBox="0 0 22 19" className="Icon tick" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#B5B5B5"
                d="M8 15.414 18.707 4.707l-1.414-1.414L8 12.575 4.707 9.293l-1.414 1.414z"
                className="tick-line"
            />
        </g>
    </svg>
);
const Memo = memo(TickIcon);
export default Memo;
