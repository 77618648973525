// Lib
import { get } from 'lodash/fp';

// Util
import delay from '../../common/utils/lib/delay';

// Services
import { http } from '../utils/services/http';

// Actions
import { trackUsers } from './userActions';
import { updateCurrentUser } from './currentUserActions';

import { refreshMe } from './userService';
import { getSubscriptionId } from '../../common/users/utils/userPropertyUtils';
import { manuallyReportError } from '../analytics/rollbarService';
import { getUserId } from '../../common/comments/commentModelUtil';

// Constants
import { ROLLBAR_LEVELS } from '../analytics/rollbarConstants';

// Selectors
import { getCurrentUserId } from './currentUserSelector';
import { getUsers } from './usersSelector';
import { refreshUser } from '../../common/users/userActions';

let isFetchingSubscription = false;
export const fetchCurrentUserSubscription = () => async (dispatch, getState) => {
    if (isFetchingSubscription) return;
    isFetchingSubscription = true;

    try {
        const { data } = await http({
            url: 'users/me/subscription',
            timeout: 30000,
        });

        isFetchingSubscription = false;

        const userId = getCurrentUserId(getState());
        const { subscription, customer } = data;

        if (!subscription && !customer) return;

        dispatch(
            updateCurrentUser({
                userId,
                changes: {
                    subscriptionId: subscription?._id,
                    subscription,
                    customer,
                },
                sync: false,
            }),
        );

        const teamMembers = subscription?.teamMemberIds || [];
        if (teamMembers.length > 1) {
            const trackedUserIds = getUsers(getState());

            const untrackedTeamMembers = teamMembers.filter((teamMemberId) => !trackedUserIds.has(teamMemberId));

            const limitedUntrackedTeamMembers = untrackedTeamMembers.slice(0, 10);

            dispatch(trackUsers(limitedUntrackedTeamMembers));
        }

        return subscription;
    } catch (error) {
        isFetchingSubscription = false;
        console.error(error);
    }
};

const getResponseUser = get(['data', 'user']);
// This function ensures the contentLimit is updated when the user's subscription changes
export const refreshCurrentUserOnSubscriptionChange =
    ({ subscriptionId }) =>
    async (dispatch) => {
        let response = await refreshMe();
        let user = getResponseUser(response);
        let userSubscriptionId = getSubscriptionId(user);

        if (userSubscriptionId !== subscriptionId) {
            manuallyReportError({
                errorMessage: 'First attempt at refreshing the user failed',
                error: { userId: getUserId(user), subscriptionId },
                sensitive: false,
                level: ROLLBAR_LEVELS.ERROR,
            });

            await delay(500);

            // Try fetching the user again
            response = await refreshMe();
            user = getResponseUser(response);
            userSubscriptionId = getSubscriptionId(user);
        }

        if (userSubscriptionId !== subscriptionId) {
            manuallyReportError({
                errorMessage: 'Final attempt at refreshing the user failed',
                error: { userId: getUserId(user), subscriptionId },
                sensitive: false,
                level: ROLLBAR_LEVELS.CRITICAL,
            });
        }

        dispatch(refreshUser({ userId: user._id, user }));
    };
