// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Utils
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';
import getSelectionRect from '../../../components/editor/domUtils/getSelectionRect';
import { addMargins } from '../../../../common/maths/geometry/rect';
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';
import animateScrollIntoView from '../../../utils/animation/animateScrollIntoView';
import { getElementId, getTextContent } from '../../../../common/elements/utils/elementPropertyUtils';
import {
    shouldScroll,
    translateDOMRectIntoScrollableElementCoordinates,
} from '../../../utils/dom/scrollableElementUtils';

// Selectors
import getGridSize, { getGridSizeName } from '../../../utils/grid/gridSizeSelector';
import { isEditingSelector } from '../../selectors/elementSelector';
import { getIsFeatureEnabledForCurrentUser } from '../../feature/elementFeatureSelector';
import { getPlatformDetailsSelector } from '../../../platform/platformSelector';

// Actions
import {
    getNextRedoTransactionId,
    getNextUndoLocationChangeTransactionId,
    popUndoStackTo,
    redoAction,
    undoAction,
} from '../../../utils/undoRedo/undoRedoActions';
import { deselectAllElements, finishEditingElement, startEditingElement } from '../../selection/selectionActions';
import { updateElementContentDiff } from '../../../../common/elements/elementActions';
import { updateElement } from '../../actions/elementActions';

// Components
import DocumentModalSidebar from './DocumentModalSidebar';
import editorEditingHeightObserver from './editorEditingHeightObserver';
import textEditorStoreConnector from '../../../components/editor/store/components/textEditorStoreConnector';
import createRichTextEditor from '../../../components/editor/richText/createRichTextEditor';
import DocumentModalHeader from './DocumentModalHeader';

// Constants
import { DOCUMENT_MODAL_EDITOR_KEY, DOCUMENT_MODAL_TITLE_EDITOR_KEY } from './documentModalConstants';
import { ExperimentId } from '../../../../common/experiments/experimentsConstants';

// Styles
import './DocumentModal.scss';

const MINIMUM_REMAINING_SPACE_GRID_UNITS = 7;
const HEIGHT_PADDING_GRID_UNITS = 6;

const HeightObservedRichTextEditor = editorEditingHeightObserver(createRichTextEditor({ clickableLinks: true }));

const getModalEditorKey = (elementId) => `${elementId}-${DOCUMENT_MODAL_EDITOR_KEY}`;

const mapStateToProps = createShallowSelector(
    (state, ownProps) => getModalEditorKey(ownProps.elementId),
    isEditingSelector(),
    getGridSize,
    getGridSizeName,
    getIsFeatureEnabledForCurrentUser(ExperimentId.documentDiffSyncing),
    getPlatformDetailsSelector,
    (editorId, editingProps, gridSize, gridSizeName, isDocumentDiffSyncingEnabled, platformDetails) => ({
        editorId,
        ...editingProps,
        gridSize,
        gridSizeName,
        isDocumentDiffSyncingEnabled,
        platformDetails,
    }),
);

const mapDispatchToProps = (dispatch) => ({
    dispatchSaveTextContent: (id, textContent, isDocumentDiffSyncingEnabled) => {
        if (isDocumentDiffSyncingEnabled) {
            dispatch(updateElementContentDiff({ id, changes: { textContent } }));
        } else {
            dispatch(updateElement({ id, changes: { textContent } }));
        }
    },

    dispatchPopUndoStackTo: (transactionId) => dispatch(popUndoStackTo(transactionId)),
    dispatchUndoAction: () => dispatch(undoAction()),
    dispatchRedoAction: () => dispatch(redoAction()),

    // This is used to rollback to the modal open undo action
    dispatchGetNextUndoTransactionId: () => dispatch(getNextUndoLocationChangeTransactionId()),
    dispatchGetNextRedoTransactionId: () => dispatch(getNextRedoTransactionId()),

    dispatchStartEditingElement: ({ id, editorId, editorKey }) =>
        dispatch(startEditingElement({ id, editorId, editorKey })),
    dispatchStopEditing: (id) => dispatch(finishEditingElement(id)),
    dispatchDeselectAllElements: () => dispatch(deselectAllElements()),
});

@connect(mapStateToProps, mapDispatchToProps)
@textEditorStoreConnector
class DocumentModal extends React.Component {
    constructor(props) {
        super(props);

        const { dispatchGetNextUndoTransactionId, dispatchGetNextRedoTransactionId, elementInstanceModalRef } = props;

        // On mount this is the transaction of the opening of the modal and should be rolled back to on the app undo
        // If the modal was opened on load this will be 0
        this.modalOpenUndoTransactionId = dispatchGetNextUndoTransactionId();
        // If there is a redo transaction when this modal is opened then it was opened via an undo action
        this.openedViaUndo = !!dispatchGetNextRedoTransactionId();

        // Don't scroll the text field on load
        this.isHeightInitialised = false;

        this.modalDomRef = React.createRef();

        elementInstanceModalRef && elementInstanceModalRef(this);
    }

    componentWillMount() {
        const { isEditable } = this.props;

        window.addEventListener('beforeunload', this.onBeforeUnload);

        if (isEditable) this.onEditStart(this.props);
    }

    /**
     * This ensures that the editor can save its contents before the close event.  Otherwise the close event will be
     * before the update in the undo order and it will feel very strange when undoing.
     */
    willClose = () => {
        // eslint-disable-line react/sort-comp
        this.editorComponent && this.editorComponent.saveCurrentContent();
    };

    componentWillUnmount() {
        const { dispatchEditorUnmount, dispatchStopEditing, dispatchDeselectAllElements } = this.props;

        // Here we want to remove the modal editor data from the editor store on unmount to avoid
        // Draft undo / redo issues & overridden state (i.e. element is updated but the editor state
        // in the editor store is used as the source of truth when viewing the modal)
        dispatchEditorUnmount && dispatchEditorUnmount();

        dispatchStopEditing();
        dispatchDeselectAllElements();

        window.removeEventListener('beforeunload', this.onBeforeUnload);

        if (this.scrollAnimation) this.scrollAnimation();
    }

    onBeforeUnload = () => {
        this.editorComponent.saveCurrentContent();
    };

    onEditStart = (props) => {
        const { dispatchStartEditingElement, elementId } = props;

        setTimeout(() => {
            dispatchStartEditingElement({
                id: elementId,
                editorKey: DOCUMENT_MODAL_EDITOR_KEY,
                editorId: getModalEditorKey(elementId),
            });
        }, 0);
    };

    onAppUndo = () => {
        const { dispatchPopUndoStackTo, dispatchUndoAction } = this.props;

        // If there are no transactions to undo then this modal must have been opened on page load, so don't
        // do anything on undo.
        if (!this.modalOpenUndoTransactionId) return;

        // This prevents the final editor update from clearing the redo stack when the editor unmounts
        this.editorComponent.saveCurrentContent();

        // If the modal was opened via an undo, then there won't be any Draft undo entries so the next undo should
        // behave in the same way as the redo (stop editing the editor so that element updates can be shown)
        // Otherwise, Draft will have already handled all the undos, so rollback the undo stack to the modal open
        // transaction and then undo that
        if (!this.openedViaUndo) {
            dispatchPopUndoStackTo(this.modalOpenUndoTransactionId);
        }

        // Allow the editor to update before dispatching the undo action
        requestAnimationFrame(() => dispatchUndoAction());
    };

    onAppRedo = () => {
        const { dispatchRedoAction, dispatchGetNextRedoTransactionId } = this.props;

        const redoTransactionId = dispatchGetNextRedoTransactionId();
        if (!redoTransactionId) return;

        // Allow the editor to update before dispatching the redo action
        requestAnimationFrame(() => dispatchRedoAction());
    };

    saveContent = (textContent) => {
        const { element, dispatchSaveTextContent, isDocumentDiffSyncingEnabled } = this.props;

        dispatchSaveTextContent(getElementId(element), textContent, isDocumentDiffSyncingEnabled);
    };

    stopEditing = () => {
        const { close } = this.props;
        this.editorComponent.saveCurrentContent();
        close();
    };

    onHeightChange = (height) => {
        if (!this.isHeightInitialised) return this.onHeightInitialisation(height);

        const { gridSize } = this.props;

        const selectionLineRect = getSelectionRect();

        if (!selectionLineRect) return;

        const scrollableElement = document.querySelector('#document-modal-content .public-DraftEditor-content');

        if (!scrollableElement) return;

        const selectionRectWithMargins = addMargins(
            {
                top: 0,
                left: 0,
                bottom: MINIMUM_REMAINING_SPACE_GRID_UNITS * gridSize,
                right: 0,
            },
            selectionLineRect,
        );

        const targetRect = translateDOMRectIntoScrollableElementCoordinates(
            scrollableElement,
            selectionRectWithMargins,
        );

        if (!shouldScroll(scrollableElement, targetRect)) return;

        this.scrollAnimation = animateScrollIntoView({ scrollableElement, targetRect, interpolationFactor: 0.2 });
    };

    onHeightInitialisation = (height) => {
        const { gridSize } = this.props;

        this.isHeightInitialised = true;

        if (!this.modalDomRef.current) return;

        const boundingRect = this.modalDomRef.current.getBoundingClientRect();

        if (!boundingRect?.height) return;

        if (height < boundingRect.height - HEIGHT_PADDING_GRID_UNITS * gridSize) return;

        // If the editor is larger than the modal, the editor cursor will be off screen.
        // so instead blur the editor so the user has to click on the text to start editing.
        this.editorComponent.forceEditorBlur();
    };

    onStopEditingTitle = () => {
        const { isEditable } = this.props;
        if (isEditable) this.onEditStart(this.props);
    };

    render() {
        const { element, elementId, isEditable, isEditing, spellCheck, currentEditorId, isPreview, platformDetails } =
            this.props;

        const classes = classNames('DocumentModal', { preview: isPreview, 'read-only': !isEditable });

        const showSidebar = !isPreview && !isPlatformLegacyMobileApp(platformDetails);

        return (
            <div className={classes} ref={this.modalDomRef}>
                {!isPreview && (
                    <DocumentModalHeader
                        element={element}
                        elementId={elementId}
                        editorKey={DOCUMENT_MODAL_TITLE_EDITOR_KEY}
                        isEditable={isEditable}
                        onStopEditing={this.onStopEditingTitle}
                    />
                )}
                <div className="document-modal-body">
                    {showSidebar && <DocumentModalSidebar {...this.props} />}
                    <div id="document-modal-content" className="content editing">
                        <HeightObservedRichTextEditor
                            editorRef={(c) => {
                                this.editorComponent = c;
                            }}
                            className="documentContent"
                            placeholder="Start typing..."
                            element={element}
                            textContent={getTextContent(element)}
                            isEditing={isEditing}
                            isEditable={isEditable}
                            saveContent={this.saveContent}
                            startEditing={() => this.onEditStart(this.props)}
                            stopEditing={this.stopEditing}
                            editorId={getModalEditorKey(getElementId(element))}
                            editorKey={DOCUMENT_MODAL_EDITOR_KEY}
                            currentEditorId={currentEditorId}
                            currentEditorKey={DOCUMENT_MODAL_EDITOR_KEY}
                            onHeightChange={this.onHeightChange}
                            onAppUndo={this.onAppUndo}
                            onAppRedo={this.onAppRedo}
                            spellCheck={spellCheck}
                        />
                    </div>
                    <div id="document-modal-side" />
                </div>
            </div>
        );
    }
}

DocumentModal.propTypes = {
    element: PropTypes.object,
    elementId: PropTypes.string,
    editorState: PropTypes.object,

    currentEditorId: PropTypes.string,

    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isPreview: PropTypes.bool,
    isDocumentDiffSyncingEnabled: PropTypes.bool,

    dispatchGetNextUndoTransactionId: PropTypes.func,
    dispatchGetNextRedoTransactionId: PropTypes.func,

    dispatchPopUndoStackTo: PropTypes.func,
    dispatchUndoAction: PropTypes.func,
    dispatchRedoAction: PropTypes.func,

    dispatchStartModalEditOperation: PropTypes.func,
    dispatchEndModalEditOperation: PropTypes.func,

    dispatchEditorUnmount: PropTypes.func,

    dispatchSaveTextContent: PropTypes.func,
    close: PropTypes.func,
    gridSize: PropTypes.number,

    elementInstanceModalRef: PropTypes.func,
    spellCheck: PropTypes.bool,

    dispatchStartEditingElement: PropTypes.func,
    dispatchStopEditing: PropTypes.func,
    dispatchDeselectAllElements: PropTypes.func,

    platformDetails: PropTypes.object,
};

export default DocumentModal;
