// Lib
import { EditorState, RichUtils } from 'draft-js';

// Utils
import insertSoftNewline from '../../customRichUtils/insertSoftNewline';
import insertNewline from '../../customRichUtils/editorState/insertNewline';
import { hasCommandModifier, hasShiftKey } from '../../../../utils/keyboard/keyboardUtility';
import editorGetCurrentBlock from '../../customRichUtils/editorState/editorGetCurrentBlock';

// Constants
import { STYLE_COMMANDS } from '../../richText/richTextConstants';
import { EditorChangeType, HANDLER_RETURN_VALUES } from '../../draftjsConstants';

/**
 * This attempts to remove block styles when in a list block type.
 * If the current line is empty and there is no next line, or the next line is a different block type, then we should
 * try to remove the style.
 */
const tryToRemoveBlockStyle = (editorState) => {
    // Only clear current block style if there's no text in the current block
    const currentBlock = editorGetCurrentBlock(editorState);

    // If the current line has text, don't try to remove the block type
    if (currentBlock && currentBlock.getLength() >= 1) return editorState;

    const currentKey = currentBlock.getKey();
    const contentState = editorState.getCurrentContent();

    const blockAfter = contentState.getBlockAfter(currentKey);

    let tryToRemoveBlockStyles = false;

    // If there's no next block, then try to remove
    if (!blockAfter) {
        tryToRemoveBlockStyles = true;
    } else {
        const currentBlockType = currentBlock.getType();
        const blockAfterType = blockAfter.getType();

        // If the next block is of a different type, then try to remove
        tryToRemoveBlockStyles = currentBlockType !== blockAfterType;
    }

    if (!tryToRemoveBlockStyles) return editorState;

    const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
    if (newContentState) {
        return EditorState.push(editorState, newContentState, EditorChangeType.CHANGE_BLOCK_TYPE);
    }

    return editorState;
};

const getNewStateOnReturn = (event, editorState) => {
    if (hasCommandModifier(event)) return editorState;

    if (hasShiftKey(event)) return insertSoftNewline(editorState);

    const updatedEditorState = tryToRemoveBlockStyle(editorState);
    if (updatedEditorState !== editorState) return updatedEditorState;

    const currentBlockType = RichUtils.getCurrentBlockType(editorState);

    const forceUnstyledNewline =
        currentBlockType.indexOf(STYLE_COMMANDS.HEADING_PREFIX) !== -1 ||
        currentBlockType === STYLE_COMMANDS.BLOCKQUOTE;
    const newLineStyle = forceUnstyledNewline ? STYLE_COMMANDS.UNSTYLED : null;

    return insertNewline(editorState, newLineStyle);
};

export default (event, editorState, { setEditorState }) => {
    const newEditorState = getNewStateOnReturn(event, editorState);

    if (newEditorState !== editorState) {
        setEditorState(newEditorState);
        return HANDLER_RETURN_VALUES.handled;
    }
    return HANDLER_RETURN_VALUES.notHandled;
};
