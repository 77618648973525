// Lib
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, onlyUpdateForPropTypes } from '../../../node_module_clones/recompose';

// Utils
import { getCaptionEditorId, getCaptionEditorKey } from '../../element/utils/elementEditorUtils';
import { getCaptionModified, getElementId } from '../../../common/elements/utils/elementPropertyUtils';

// Components
import SimpleTextEditor from '../editor/simpleText/SimpleTextEditor';

// Actions
import { updateElementType } from '../../element/card/cardActions';

// Styles
import './Caption.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateElementType: ({ id, elementType, data }) => {
        dispatch(updateElementType({ id, elementType, data }));
    },
});

class Caption extends React.Component {
    constructor(props) {
        super(props);

        this.wrappedSaveCaptionContent = this.wrappedSaveCaptionContent.bind(this);
    }

    wrappedSaveCaptionContent(caption, sync) {
        // Don't save if the caption isn't visible.  This helps prevent empty operations from being added to the
        // undo stack
        const { captionVisible, saveCaptionContent } = this.props;
        if (!captionVisible) return null;

        saveCaptionContent(caption, sync);
    }

    updateElementType = ({ elementType, data }) => {
        const { element, dispatchUpdateElementType } = this.props;
        dispatchUpdateElementType({ id: getElementId(element), elementType, data });
    };

    render() {
        const { captionVisible, element, spellCheck, isEditing, currentEditorKey, onDoubleClick, style, padTop } =
            this.props;

        if (!captionVisible) return null;

        const classes = classNames('Caption', {
            'caption-modified': getCaptionModified(element),
            'pad-top': padTop,
        });

        const editorId = getCaptionEditorId(this.props);
        const editorKey = getCaptionEditorKey(this.props);

        return (
            <div className={classes} onDoubleClick={onDoubleClick} style={style}>
                <SimpleTextEditor
                    {...this.props}
                    isEditing={isEditing && currentEditorKey === editorKey}
                    updateElementType={this.updateElementType}
                    saveContent={this.wrappedSaveCaptionContent}
                    editorId={editorId}
                    editorKey={editorKey}
                    spellCheck={spellCheck}
                />
            </div>
        );
    }
}

Caption.propTypes = {
    element: PropTypes.object.isRequired,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    spellCheck: PropTypes.bool,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    saveCaptionContent: PropTypes.func,
    onEmptyBackspace: PropTypes.func,
    textContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    captionVisible: PropTypes.bool,
    currentEditorKey: PropTypes.string,
    currentEditorId: PropTypes.string,
    createNewCard: PropTypes.func,
    filterQuery: PropTypes.string,
    dispatchUpdateElementType: PropTypes.func,
    onDoubleClick: PropTypes.func,
    style: PropTypes.object,
    padTop: PropTypes.bool,
    isSelected: PropTypes.bool,
};

const enhance = compose(connect(null, mapDispatchToProps), onlyUpdateForPropTypes);

export default enhance(Caption);
