// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

// Utils
import { getIsLowContrastColorWithInterface } from '../../../../../../../common/colors/colorComparisonUtil';
import { getColorCssValue } from '../../../../../../element/utils/elementColorStyleUtils';

// Components
import Button from '../../../../../../components/buttons/Button';
import DualColorIcon from '../DualColorIcon';

// Constants
import { COLOR_POPUP_MODE } from './colorPopupConstants';

// Styles
import './ColorButton.scss';

const ColorButton = ({
    color,
    secondaryColor,
    isActive,
    setColor,
    selectedType,
    selectedStatus,
    isContextMenu,
    className,
    colorPopupMode,
    isDarkModeTheme,
    preventUpdateSecondaryColor,
}) => {
    const onColorClick = (event) => {
        if (!setColor) return;

        const colorName = isUndefined(color && color.name) ? color : color.name;

        // For scenarios like updating the color bar, ensure the secondary color is not updated
        if (preventUpdateSecondaryColor) {
            setColor(colorName);
            return;
        }

        // Update secondary to the provided secondaryColor or null, undefined will NOT be updated
        setColor(colorName, secondaryColor || null);
    };

    const colorCss = color && color.css ? color.css : getColorCssValue(color);

    const classes = classNames('ColorButton', className, selectedType, selectedStatus, colorPopupMode, {
        active: isActive,
        'no-color': !colorCss,
        'low-contrast-color': getIsLowContrastColorWithInterface(colorCss, isDarkModeTheme),
        'high-contrast-color': getIsLowContrastColorWithInterface(colorCss, !isDarkModeTheme),
        'remove-color': !colorCss && colorPopupMode === COLOR_POPUP_MODE.COLOR_BAR,
    });

    return (
        <Button
            className={classes}
            style={{ '--ui-color-button-background': colorCss }}
            onClickFn={onColorClick}
            onContextMenu={isContextMenu && onColorClick}
            onMouseUp={isContextMenu && onColorClick}
        >
            {secondaryColor && <DualColorIcon secondaryColor={secondaryColor} selectedType={selectedType} />}
            <div className="selection-border" />
        </Button>
    );
};

ColorButton.propTypes = {
    selectedType: PropTypes.string,
    selectedStatus: PropTypes.string,
    className: PropTypes.string,
    colorPopupMode: PropTypes.string,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    secondaryColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isActive: PropTypes.bool,
    isContextMenu: PropTypes.bool,
    isDarkModeTheme: PropTypes.bool,
    setColor: PropTypes.func,
    disabled: PropTypes.bool,
    preventUpdateSecondaryColor: PropTypes.bool,
};

export default ColorButton;
