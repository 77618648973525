// Lib
import { combineReducers } from 'redux-immutable';

// Reducers
import boardEditors from '../workspace/header/currentBoardHeader/sharing/popup/editing/boardEditorReducer';
import clipboard from '../workspace/shortcuts/clipboard/clipboardReducer';
import publishedBoardViewers from '../workspace/header/currentBoardHeader/sharing/popup/publish/viewers/publishedBoardViewersReducer';
import elements from '../element/local/localElementsReducer';
import mobile from '../mobile/mobileReducer';

/**
 * This is similar to the app reducer, in that it holds local application state, however it can be influenced
 * by remote user actions.
 */
export default combineReducers({
    boardEditors,
    clipboard,
    publishedBoardViewers,
    elements,
    mobile,
});
