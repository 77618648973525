import { COMMENTS_TYPING_PING, COMMENTS_TYPING_STOP } from '../../../../common/comments/commentConstants';
import { getTimestamp } from '../../../../common/utils/timeUtil';

export const commentsTypingPing = ({ threadId }) => ({
    type: COMMENTS_TYPING_PING,
    threadId,
    timestamp: getTimestamp(),
    sync: true,
});

export const commentsTypingStop = ({ threadId }) => ({
    type: COMMENTS_TYPING_STOP,
    threadId,
    sync: true,
});
