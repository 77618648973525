// Lib
import { cloneDeep, isEmpty } from 'lodash';

// Utils
import { getMaxCellSelection, getMinCellSelection } from './tableCellSelectionUtils';

// Types
import { CellCoordsObj, CellData, CellSelection } from '../../../../common/table/TableTypes';

// Constants
import { TABLE_DEFAULT_HEADER_BACKGROUND_COLOR } from '../../../../common/table/tableConstants';
import { formatCellAsType } from './tableCellTypeUtils';
import { getColumnCount } from './tableDataUtils';

/**************************
 * TABLE DATA PROVISIONING
 **************************/

export const createTableCellEntry = (value: string | null = null, locale = 'en-US'): CellData => {
    if (isEmpty(value) || !value) return { value: null };

    return formatCellAsType({ value }, null, locale) || { value };
};

export const createEmptyRowEntries = (nCols: number): Array<CellData> => {
    return Array.from({ length: nCols }, () => createTableCellEntry());
};

export const createEmptyTableEntries = (nRows: number, nCols: number): Array<Array<CellData>> => {
    return Array.from({ length: nRows }, (_, i) => createEmptyRowEntries(nCols));
};

export const createTableContentDataFromValues = (
    values: Array<Array<string | null>>,
    locale: string,
): Array<Array<CellData>> => {
    return values.map((row) => row.map((value) => createTableCellEntry(value, locale)));
};

export const applyInitialCellValues = (dataArray: Array<Array<CellData>>): Array<Array<CellData>> => {
    const newDataArray = cloneDeep(dataArray);
    const nCols = getColumnCount(newDataArray);

    for (let col = 0; col < nCols; col++) {
        // Set header formatting for first row
        newDataArray[0][col] = {
            textStyle: ['BOLD'],
            background: TABLE_DEFAULT_HEADER_BACKGROUND_COLOR,
            ...newDataArray[0][col],
        };
    }
    return newDataArray;
};

/**************************
 * TABLE EXCEL NAMING
 **************************/

/**
 * Get excel style naming of a column (e.g. A, B, AA, BB)
 */
const getColName = (col: number): string => {
    const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[col % 26];
    if (col <= 26) return res;

    return getColName(Math.floor(col / 26) - 1) + res;
};

/**
 * Get excel style naming of a cell coordinate (e.g. A1, B1, AA2, BB2)
 */
export const getCellCoordsName = (coord1: CellCoordsObj): string => {
    const { row, col } = coord1;

    return `${getColName(col)}${row + 1}`;
};

/**
 * Get excel style naming of a cell range (e.g. A1:B1, AA2:BB5)
 */
export const getCellRangeName = (cellSelection: CellSelection): string => {
    const { row: minRow, col: minCol } = getMinCellSelection(cellSelection);
    const { row: maxRow, col: maxCol } = getMaxCellSelection(cellSelection);

    if (minRow === maxRow && minCol === maxCol)
        return getCellCoordsName({
            row: minRow,
            col: minCol,
        });

    return `${getCellCoordsName({ row: minRow, col: minCol })}:${getCellCoordsName({ row: maxRow, col: maxCol })}`;
};

const parseCellColName = (colName: string): number => {
    const colNameUpper = colName.toUpperCase();
    let colNumber = 0;
    for (let i = 0; i < colNameUpper.length; i++) {
        const char = colNameUpper.charCodeAt(i);
        colNumber += (char - 64) * 26 ** (colNameUpper.length - i - 1);
    }

    return colNumber - 1;
};

/**
 * Converts a cell range name (e.g. A1:B1, AA2:BB5) into a cell selection
 */
export const parseCellName = (str: string): CellSelection | null => {
    const cellSelection = str.match(/^\$?([a-z]+)\$?([0-9]+)(?::\$?([a-z]+)\$?([0-9]+))?$/i);
    if (!cellSelection) return null;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, startColName, startRowName, endColName, endRowName] = cellSelection;

    const startRow = parseInt(startRowName, 10) - 1;
    const startCol = parseCellColName(startColName);
    const endRow = endRowName ? parseInt(endRowName, 10) - 1 : startRow;
    const endCol = endColName ? parseCellColName(endColName) : startCol;

    return [startRow, startCol, endRow, endCol];
};
