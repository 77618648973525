// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import ToolbarTool from '../../ToolbarTool';
import Icon from '../../../../../components/icons/Icon';

// Styles
import './RichTextTool.scss';

const RichTextTool = (props) => {
    const { iconName, item, isActive, disabled, children, tooltipEnabled = true } = props;

    const styleCommand = item?.toolSettings?.styleCommand || props.styleCommand || '';

    return (
        <>
            <ToolbarTool
                {...props}
                name={null}
                isActive={isActive}
                className={classNames('RichTextTool', styleCommand.toLowerCase())}
                disabled={disabled}
                tooltipEnabled={tooltipEnabled}
            >
                <Icon {...props} name={iconName} />
            </ToolbarTool>
            {children}
        </>
    );
};

RichTextTool.propTypes = {
    name: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    styleCommand: PropTypes.string,
    tooltipText: PropTypes.string,
    isActive: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltipEnabled: PropTypes.bool,
    shortcut: PropTypes.array,
    item: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default RichTextTool;
