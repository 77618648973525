import rawGetText from './rawGetText';

const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed

export default (rawState) => {
    const rawText = rawGetText(rawState);

    // The following has been taken from the Draft JS Plugins' Counter Plugin
    // https://github.com/draft-js-plugins/draft-js-plugins/tree/master/draft-js-counter-plugin
    const cleanString = rawText.replace(regex, ' ').trim(); // replace above characters w/ space
    const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
    return wordArray ? wordArray.length : 0;
};
