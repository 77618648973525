/**
 * Simply separates the store connection logic out of the TextEditor components as they are already very large.
 */
// Lib
import { createSelector } from 'reselect';

// Utils
import editorStoreConnect from './editorStoreConnect';
import { getEditorState, getEditorId, getSavedEditorSelection } from '../reducers/editorStoreSelector';
import { createCustomEqualSmartSelector } from '../../../../utils/milanoteReselect/milanoteReselect';

// Actions
import {
    getEditorContextThunk,
    removeEditorContext,
    setEditorContext,
} from '../reducers/editorContext/editorContextActions';
import { clearEditorUndoRedoStack, editorSelectionSave, editorStateChanged, editorUnmount } from '../editorActions';

const mapEditorDispatchToProps = (dispatch) => ({
    editorStateChanged: ({ editorId, originalEditorId, editorState }) =>
        dispatch(editorStateChanged({ editorId, originalEditorId, editorState })),
    dispatchEditorUnmount: () => dispatch(editorUnmount()),
    dispatchSaveEditorSelection: ({ editorId, selection }) => dispatch(editorSelectionSave({ editorId, selection })),
    dispatchClearEditorUndoRedoStack: () => dispatch(clearEditorUndoRedoStack()),
    dispatchSetEditorContext: (contextName, contextKey, context) =>
        dispatch(setEditorContext(contextName, contextKey, context)),
    dispatchRemoveEditorContext: (contextName, contextId) => dispatch(removeEditorContext(contextName, contextId)),
    dispatchGetEditorContextThunk: (contextName, contextId) => dispatch(getEditorContextThunk(contextName)),
});

const isEditingThisEditorSelector = (state, ownProps) => getEditorId(state) === ownProps.editorId;

const makeEditorStateSelector = () =>
    createSelector(isEditingThisEditorSelector, getEditorState, (isEditing, editorState) =>
        isEditing ? editorState : null,
    );

/**
 * The selector will be executed when:
 * - The saved selection has changed
 * - The editor is editing and:
 *      - It wasn't previously editing, or
 *      - The editor state has changed, or
 *      - The saved selection has changed
 */
const editorStateSelectorEqualityChecker = (
    [prevIsEditing, prevEditorState, prevSavedSelection],
    [nextIsEditing, nextEditorState, nextSavedSelection],
) => {
    if (prevSavedSelection !== nextSavedSelection) return false;

    if (nextIsEditing) {
        return prevIsEditing && prevEditorState === nextEditorState && prevSavedSelection === nextSavedSelection;
    }

    return true;
};

const createCustomEditorStateSelector = createCustomEqualSmartSelector(editorStateSelectorEqualityChecker);

const makeMapEditorStateToProps = () =>
    createCustomEditorStateSelector(
        isEditingThisEditorSelector,
        makeEditorStateSelector(),
        getSavedEditorSelection,
        (isEditing, editorState, savedSelection) => ({
            editorState: isEditing ? editorState : null,
            savedSelection: isEditing ? savedSelection : null,
        }),
    );

export default editorStoreConnect(makeMapEditorStateToProps, mapEditorDispatchToProps);
