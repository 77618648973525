// Lib
import { createStructuredSelector } from 'reselect';
import * as Immutable from 'immutable';

// Utils
import { getEmojiData } from '../emojiDataUtil';
import { getMany } from '../../../../common/utils/immutableHelper';
import { getReactions } from '../../../../common/elements/utils/elementPropertyUtils';
import { getReactionUserId, getReactionEmojiCode } from '../../../element/reactions/reactionUtil';
import {
    getUserReactionsRecentlyUsed,
    getUserReactionsSkinTone,
} from '../../../../common/users/utils/userPropertyUtils';

// Selectors
import { createShallowSelector } from '../../../utils/milanoteReselect/milanoteReselect';
import { getCurrentUser, getCurrentUserId } from '../../../user/currentUserSelector';
import { getRenderedElements } from '../../../element/selection/selectedElementsSelector';

// Constants
import { RECENTLY_USED_DEFAULTS } from './reactionPopupConstants';

const selectedReactionIdsSelector = createShallowSelector(
    (_, { elementIds }) => elementIds,
    (state) => state.get('elements'),
    (state) => state.get('comments'),
    getCurrentUserId,
    (selectedElementIds, elements, comments, currentUserId) => {
        const selectedElements = Immutable.fromJS(getRenderedElements(selectedElementIds, elements));
        const selectedComments = getMany(selectedElementIds, comments).toList();

        const selectedItems = selectedElements.concat(selectedComments);

        return selectedItems
            .flatMap(getReactions)
            .filter((reaction) => getReactionUserId(reaction) === currentUserId)
            .map(getReactionEmojiCode)
            .toArray();
    },
);

const reactionPopupSelector = createStructuredSelector({
    emojiData: getEmojiData,
    recentlyUsed: (state) => {
        const recent = getUserReactionsRecentlyUsed(getCurrentUser(state));

        return recent ? recent.toJS() : RECENTLY_USED_DEFAULTS;
    },
    skinTone: (state) => getUserReactionsSkinTone(getCurrentUser(state)),
    selectedReactionIds: selectedReactionIdsSelector,
});

export default reactionPopupSelector;
