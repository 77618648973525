// Lib
import { Capacitor } from '@capacitor/core';

// Services
import localStorageService from '../utils/services/localStorage/localStorage';

// Plugin
import { MNToken } from '../../capacitor_plugins/userTokenManager';

// Constants
import { USER_LOCAL_STORAGE_TOKEN_KEY } from './authConstants';
import { CAPACITOR_MN_TOKEN_PLUGIN_NAME } from '../../capacitor_plugins/pluginConstants';

export const getCurrentUserToken = () => localStorageService.getItem(USER_LOCAL_STORAGE_TOKEN_KEY);

export const setCurrentUserToken = (value) => {
    localStorageService.setItem(USER_LOCAL_STORAGE_TOKEN_KEY, value);

    // Update token stored on Electron main process
    // eslint-disable-next-line no-unused-expressions
    window.desktopIntegration?.setMnToken?.(value);

    // Update token stored in the Capacitor native apps.
    if (Capacitor.isPluginAvailable(CAPACITOR_MN_TOKEN_PLUGIN_NAME)) {
        MNToken.setMnToken({ token: value });
    }
};

export const removeCurrentUserToken = () => {
    localStorageService.removeItem(USER_LOCAL_STORAGE_TOKEN_KEY);

    // Update token stored on Electron main process
    // eslint-disable-next-line no-unused-expressions
    window.desktopIntegration?.setMnToken?.(undefined);

    // Update token stored in the Capacitor native apps.
    if (Capacitor.isPluginAvailable(CAPACITOR_MN_TOKEN_PLUGIN_NAME)) {
        MNToken.removeMnToken();
    }
};
