// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Selectors
import {
    getCommentContent,
    getCommentId,
    getCreationTimestamp,
    getText,
    getUserId,
} from '../../../common/comments/commentModelUtil';

// Components
import Comment from './Comment';

class Thread extends React.Component {
    render() {
        const { commentList } = this.props;

        if (!commentList || !commentList.size) return null;

        return (
            <div className="Thread">
                {commentList.reverse().map((comment) => (
                    <Comment
                        {...this.props}
                        key={getCommentId(comment)}
                        _id={getCommentId(comment)}
                        userId={getUserId(comment)}
                        text={getText(comment)}
                        content={getCommentContent(comment)}
                        createdAt={getCreationTimestamp(comment)}
                    />
                ))}
            </div>
        );
    }
}

Thread.propTypes = {
    commentList: PropTypes.object,
    currentUserId: PropTypes.string,
};

export default Thread;
