/* eslint-disable react/sort-comp */
// Lib
import React from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import { get } from 'lodash/fp';

// Utils
import { noLonger, now } from '../../../utils/react/propsComparisons';
import { getMainEditorId } from '../../utils/elementEditorUtils';
import { noLongerEditingThisEditor } from '../../../components/editor/milanoteEditor/editorPropComparisons';
import {
    getCardDimensionPropertiesPx,
    getIsAutoHeight,
    getIsEditorOverThreshold,
    isHeightRestricted,
} from '../cardSizeUtil';

// Constants

import { ElementType } from '../../../../common/elements/elementTypes';

// Prop comparison functions
const isNowResizing = now('isResizing');
const isNoLongerResizing = noLonger('isResizing');

export default (DecoratedComponent) => {
    class cardEditorHeightObserver extends React.Component {
        constructor(props) {
            super(props);

            this.resizeObserverAttached = false;

            this.editorHeight = null;

            this.state = {
                editorOverflowed: false,
            };
        }

        componentDidMount() {
            if (isHeightRestricted(this.props.element)) {
                this.manuallyCheckEditorSize(this.props);
            }
        }

        componentWillReceiveProps(nextProps) {
            if (!this.resizeObserverAttached && isNowResizing(this.props, nextProps)) {
                this.attachResizeObserver();
            }

            if (this.resizeObserverAttached && isNoLongerResizing(this.props, nextProps)) {
                this.removeResizeObserver();
            }

            const editorId = getMainEditorId(nextProps);
            if (
                isHeightRestricted(nextProps.element) &&
                noLongerEditingThisEditor({ ...this.props, editorId }, { ...nextProps, editorId })
            ) {
                this.manuallyCheckEditorSize(nextProps);
            }
        }

        componentWillUnmount() {
            this.removeResizeObserver();
        }

        attachResizeObserver = () => {
            if (this.resizeObserverAttached) return;

            this.resizeObserver = new ResizeObserver(this.onEditorResize);

            this.editorContentsElement = this.editorElement.getTextDomElement();

            this.resizeObserver.observe(this.editorContentsElement);
            this.resizeObserver.observe(this.containerElement);

            this.resizeObserverAttached = true;
        };

        removeResizeObserver = () => {
            if (!this.resizeObserverAttached || !this.resizeObserver) return;

            this.resizeObserver.disconnect(this.editorContentsElement);
            this.resizeObserverAttached = false;
        };

        // Note: This stack overflow thread recommends using a requestAnimationFrame to avoid
        //      the loop limit exceeded exception, however I won't change it for now as they
        //      also say the error can be safely ignored, and I don't want to introduce a regression
        //      https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        onEditorResize = (entries) => {
            const { tempContainerHeight, gridSize } = this.props;

            entries.forEach((resizeObserverEntry) => {
                if (this.editorContentsElement === resizeObserverEntry.target) {
                    this.editorHeight = get(['contentRect', 'height'], resizeObserverEntry);
                }
            });

            if (!this.editorHeight || !tempContainerHeight) return;

            const editorOverflowed = getIsEditorOverThreshold(tempContainerHeight, this.editorHeight, gridSize);
            this.setState({ editorOverflowed });
        };

        manuallyCheckEditorSize = (props) => {
            if (!this.editorElement) return;

            if (!isHeightRestricted(props.element)) return;

            const { element, gridSize } = props;

            const editorHeight = this.editorElement.getBoundingClientRect().height;
            const { maxHeight } = getCardDimensionPropertiesPx({ element, gridSize });

            const editorOverflowed = getIsEditorOverThreshold(maxHeight, editorHeight, gridSize);
            this.setState({ editorOverflowed });
        };

        editorRef = (c) => {
            const { editorRef } = this.props;
            this.editorElement = c;
            editorRef && editorRef(c);
        };

        containerRef = (c) => {
            const { containerRef } = this.props;
            this.containerElement = c;
            containerRef && containerRef(c);
        };

        render() {
            const { isResizing, isEditing, currentEditorKey } = this.props;
            const { editorOverflowed } = this.state;

            const isEditingMainCardContent = isEditing && currentEditorKey === ElementType.CARD_TYPE;

            const showEditorOverflow =
                editorOverflowed && (isResizing || (!isEditingMainCardContent && !getIsAutoHeight(this.props.element)));

            return (
                <DecoratedComponent
                    {...this.props}
                    containerRef={this.containerRef}
                    editorRef={this.editorRef}
                    editorOverflowed={showEditorOverflow}
                />
            );
        }
    }

    cardEditorHeightObserver.propTypes = {
        element: PropTypes.object,
        editorRef: PropTypes.func,
        containerRef: PropTypes.func,

        tempContainerHeight: PropTypes.number,
        gridSize: PropTypes.number,

        isResizing: PropTypes.bool,
        isEditing: PropTypes.bool,
        isOverflowed: PropTypes.bool,
        currentEditorKey: PropTypes.string,
    };

    return cardEditorHeightObserver;
};
