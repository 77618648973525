import colorPalette from './colorPalette';

export const COLORS = {
    // hex is used within activity digest emails as
    NONE: { name: null, css: null, hex: null },
    GREY: { name: 'GREY', css: 'var(--color-element-grey)', hex: '#5f6672' },
    CYAN: { name: 'CYAN', css: 'var(--color-element-cyan)', hex: '#62dbc8' },
    GREEN: { name: 'GREEN', css: 'var(--color-element-green)', hex: '#7cd651' },
    BROWN: { name: 'BROWN', css: 'var(--color-element-brown)', hex: '#d58558' },
    YELLOW: { name: 'YELLOW', css: 'var(--color-element-yellow)', hex: '#ffd14d' },
    ORANGE: { name: 'ORANGE', css: 'var(--color-element-orange)', hex: '#ff8d48' },
    RED: { name: 'RED', css: 'var(--color-element-red)', hex: '#ff5757' },
    PINK: { name: 'PINK', css: 'var(--color-element-pink)', hex: '#ff6ed4' },
    PURPLE: { name: 'PURPLE', css: 'var(--color-element-purple)', hex: '#ad6fff' },
    BLUE: { name: 'BLUE', css: 'var(--color-element-blue)', hex: '#4ebafd' },
    DEEP_BLUE: { name: 'DEEP_BLUE', css: 'var(--color-element-deep-blue)', hex: '#5882f8' },
};

export const ICON_COLORS = {
    ...COLORS,
    GREY: { name: 'GREY', css: 'var(--color-element-grey)', hex: '#bbbec3' },
};

export const BACKGROUND_COLORS = {
    NONE: { ...COLORS.NONE },
    GREY: { ...COLORS.GREY, hex: '#bbbec3' },
    CYAN: { ...COLORS.CYAN },
    GREEN: { ...COLORS.GREEN },
    BROWN: { ...COLORS.BROWN },
    YELLOW: { ...COLORS.YELLOW },
    ORANGE: { ...COLORS.ORANGE },
    RED: { ...COLORS.RED },
    PINK: { ...COLORS.PINK },
    PURPLE: { ...COLORS.PURPLE },
    BLUE: { ...COLORS.BLUE },
    DEEP_BLUE: { ...COLORS.DEEP_BLUE },
    TRANSPARENT: { name: 'TRANSPARENT', css: 'rgba(0,0,0,0)', hex: '#00000000', className: 'transparent' },
};

export const SKETCH_BACKGROUND_COLORS = {
    WHITE: { name: 'WHITE', css: 'rgba(255,255,255,1)', hex: '#ffffff' },
    CHARCOAL: { name: 'CHARCOAL', css: 'rgba(51,51,51,1)', hex: '#333333' },
    GREY: { ...COLORS.GREY },
    CYAN: { ...COLORS.CYAN },
    GREEN: { ...COLORS.GREEN },
    BROWN: { ...COLORS.BROWN },
    YELLOW: { ...COLORS.YELLOW },
    ORANGE: { ...COLORS.ORANGE },
    RED: { ...COLORS.RED },
    PINK: { ...COLORS.PINK },
    PURPLE: { ...COLORS.PURPLE },
    BLUE: { ...COLORS.BLUE },
    DEEP_BLUE: { ...COLORS.DEEP_BLUE },
};

export const COLUMN_COLORS = {
    NONE: { ...COLORS.NONE },
    GREY: { ...COLORS.GREY, ...colorPalette.element.element_light_grey_900 },
    CYAN: { ...COLORS.CYAN, ...colorPalette.element.element_teal_900 },
    GREEN: { ...COLORS.GREEN, ...colorPalette.element.element_green_900 },
    YELLOW: { ...COLORS.YELLOW, ...colorPalette.element.element_yellow_900 },
    ORANGE: { ...COLORS.ORANGE, ...colorPalette.element.element_orange_900 },
    RED: { ...COLORS.RED, ...colorPalette.element.element_red_900 },
    PINK: { ...COLORS.PINK, ...colorPalette.element.element_pink_900 },
    PURPLE: { ...COLORS.PURPLE, ...colorPalette.element.element_purple_900 },
    BLUE: { ...COLORS.BLUE, ...colorPalette.element.element_blue_900 },
    DEEP_BLUE: { ...COLORS.DEEP_BLUE, ...colorPalette.element.element_royal_blue_900 },
};

export const DEFAULT_BOARD_COLORS_ARRAY = Object.values(COLORS)
    .filter((color) => color.name !== COLORS.NONE.name)
    .map((color) => color.name);

export const DEFAULT_BOARD_COLORS_HEX_ARRAY = Object.values(COLORS)
    .filter((color) => color.hex !== COLORS.NONE.hex)
    .map((color) => color.hex);

export const SKETCH_LINE_COLORS = {
    ...COLORS,
    WHITE: { name: 'WHITE', css: 'rgba(255,255,255,1)', hex: '#ffffff' },
};

export const SKETCH_LINE_COLORS_ARRAY = Object.values(SKETCH_LINE_COLORS);

export const DEFAULT_BOARD_COLOR = '#cacaca';
export const DEFAULT_ELEMENT_COLOR = '#ffffff';

export const CANVAS_COLORS = {
    NONE: { name: null, css: null, displayName: 'Grey' },
    BLUE: { name: 'BLUE', css: 'var(--color-canvas-blue)' },
    GREEN: { name: 'GREEN', css: 'var(--color-canvas-green)' },
    YELLOW: { name: 'YELLOW', css: 'var(--color-canvas-yellow)' },
    WARM_GREY: { name: 'WARM_GREY', css: 'var(--color-canvas-warm-grey)' },
};

export const COLOR_SPACE = {
    HEX: 'HEX',
    RGB: 'RGB',
    HSL: 'HSL',
};

// Used by color swatch to indicate format of color values displayed
export const COLOR_DISPLAY = {
    ...COLOR_SPACE,
    OFF: 'Off',
};

export const TEXT_COLOUR_DARK = '#3d4554';
export const TEXT_COLOUR_LIGHT = '#ffffff';
