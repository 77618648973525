// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import { capitalize, kebabCase } from 'lodash/fp';

// Selectors
import { activePopupSelector } from '../../../components/popupPanel/popupOpenSelector';

// Components
import ToolbarTool from './ToolbarTool';
import PopupTrigger from '../../../components/popupPanel/PopupTrigger';
import Icon from '../../../components/icons/Icon';

// Actions
import { closePopup, togglePopup } from '../../../components/popupPanel/popupActions';

const StandardPopupToolIcon = ({ toolName, gridSize }) => (
    <Icon gridSize={gridSize} name={`toolbar-${kebabCase(toolName)}`} />
);

StandardPopupToolIcon.propTypes = {
    gridSize: PropTypes.number,
    toolName: PropTypes.string,
};

const mapStateToProps = createSelector(
    (_, props) => props.popupId || props.toolName,
    activePopupSelector,
    (popupId, activePopup) => ({
        isPopupOpen: activePopup.has(popupId),
    }),
);

const mapDispatchToProps = (dispatch) => ({
    dispatchOpenPopup: (toolName) => dispatch(togglePopup(toolName)),
    dispatchClosePopup: (toolName) => dispatch(closePopup(toolName, false)),
});

class ToolbarPopupTool extends React.Component {
    openPopup = (event) => {
        const { dispatchOpenPopup, toolName, popupId } = this.props;

        event.preventDefault();
        dispatchOpenPopup(popupId || toolName);
    };

    closePopup = (event) => {
        const { dispatchClosePopup, toolName, popupId } = this.props;
        dispatchClosePopup(popupId || toolName);
    };

    eventProps = {
        openPopup: this.openPopup,
        closePopup: this.closePopup,
    };

    render() {
        const {
            name,
            toolName,
            IconComponent,
            PopupComponent,
            id,
            show = true,
            disabled,
            popupId,
            stayActivePredicate,
            preventFocus,
            isPopupOpen,
            children,
        } = this.props;

        if (!show) return null;

        const ToolIcon = IconComponent || StandardPopupToolIcon;

        return (
            <div className={classNames('ToolbarPopupTool', `${capitalize(toolName)}Tool`)} id={id}>
                <PopupTrigger
                    popupId={popupId || toolName}
                    stayActivePredicate={stayActivePredicate}
                    preventFocus={preventFocus}
                    disabled={disabled}
                >
                    {(popupIsOpen) => (
                        <ToolbarTool {...this.props} name={name} isOpen={popupIsOpen}>
                            {children || <ToolIcon {...this.props} {...this.eventProps} />}
                        </ToolbarTool>
                    )}
                </PopupTrigger>
                <PopupComponent {...this.props} {...this.eventProps} popupIsOpen={isPopupOpen} />
            </div>
        );
    }
}

ToolbarPopupTool.propTypes = {
    id: PropTypes.string,
    toolName: PropTypes.string.isRequired,
    name: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.string,

    preventFocus: PropTypes.bool,

    PopupComponent: PropTypes.elementType.isRequired,
    IconComponent: PropTypes.elementType,

    openPopup: PropTypes.func,
    dispatchOpenPopup: PropTypes.func,
    dispatchClosePopup: PropTypes.func,
    popupId: PropTypes.string,

    stayActivePredicate: PropTypes.func,
    isPopupOpen: PropTypes.bool,
    show: PropTypes.bool,
    disabled: PropTypes.bool,
    distance: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarPopupTool);
