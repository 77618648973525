// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { delay } from 'lodash';

// Utils
import { getFetching } from '../attachments/attachmentsSelector';
import { prop } from '../../../common/utils/immutableHelper';
import { getElementId, getLinkType } from '../../../common/elements/utils/elementPropertyUtils';
import { getLocalError, getLocalShouldLoadPreview, getLocalUrl } from '../local/elementLocalDataUtils';
import { noLonger, now } from '../../utils/react/propsComparisons';

// Constants
import { KEY_CODES } from '../../utils/keyboard/keyConstants';
import { LINK_ELEMENT_LINK_TYPES } from '../../../common/links/linkConstants';
import { ElementType } from '../../../common/elements/elementTypes';

// Styles
import './LinkInput.scss';

const noLongerEditing = noLonger('isEditing');
const noLongerSelected = noLonger('isSelected');
const beganEditing = now('isEditing');
const beganSelected = now('isSelected');

const getLinkInputPlaceholder = (element) => {
    switch (getLinkType(element)) {
        case LINK_ELEMENT_LINK_TYPES.VIDEO:
            return 'Paste a link from YouTube or Vimeo';
        case LINK_ELEMENT_LINK_TYPES.AUDIO:
            return 'Paste a link from Spotify or Soundcloud';
        case LINK_ELEMENT_LINK_TYPES.MAP:
            return 'Paste a link from Google Maps';
        default:
            return 'Enter a link URL';
    }
};

class LinkInput extends React.Component {
    constructor(props) {
        super(props);

        this.linkInputRef = React.createRef();
        this.linkContainerRef = React.createRef();
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillReceiveProps(nextProps) {
        const { setLocalData } = this.props;

        // handle error
        const url = getLocalUrl(this.props.elementLocalData);
        const error = getLocalError(this.props.elementLocalData);

        const nextError = getLocalError(nextProps.elementLocalData);

        if (!error && nextError) {
            this.linkContainerRef.current && this.linkContainerRef.current.focus();

            this.errorTime = setTimeout(() => {
                setLocalData({ error: null, url });
                this.errorTime = null;
            }, 2000);
        }
    }

    componentDidUpdate(oldProps) {
        // focus on mount
        const error = getLocalError(this.props.elementLocalData);
        const oldError = getLocalError(oldProps.elementLocalData);

        if (beganEditing(oldProps, this.props) || beganSelected(oldProps, this.props)) {
            delay(() => {
                // If already unmounted don't attempt focus
                if (!this.mounted) return;

                if (!this.props.shouldFocusOnlyWhenSelected && this.props.isSelected) this.focus();

                // focus after its already selected
                if (this.props.shouldFocusOnlyWhenSelected && oldProps.isSelected) this.focus();
            }, 10);
        }

        const shouldLoadPreview = getLocalShouldLoadPreview(this.props.elementLocalData);
        if (shouldLoadPreview || noLongerEditing(oldProps, this.props) || noLongerSelected(oldProps, this.props)) {
            this.linkInputRef.current && this.linkInputRef.current.blur();

            const url = getLocalUrl(this.props.elementLocalData);
            if (url) this.loadPreview();
        }

        if (!error && oldError && this.props.isSelected) {
            this.focus();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    startEditingElement = () => {
        const { startEditing, element } = this.props;

        startEditing &&
            startEditing({
                editorId: `${getElementId(element)}-${ElementType.LINK_TYPE}`,
                editorKey: ElementType.LINK_TYPE,
            });
    };

    onClick = () => {
        if (
            !this.props.shouldFocusOnlyWhenSelected ||
            (this.props.shouldFocusOnlyWhenSelected && this.props.isSingleSelected)
        )
            this.startEditingElement();
    };

    focus = () => {
        this.linkInputRef.current && this.linkInputRef.current.focus();
    };

    handleMouseDown = (event) => {
        const { isEditing } = this.props;

        if (isEditing) {
            event.stopPropagation();
        }
    };

    loadPreview = () => {
        const { setLinkUrl, setLocalData, elementLocalData } = this.props;

        const url = getLocalUrl(elementLocalData);

        const validationResponse = setLinkUrl(url);

        if (validationResponse) {
            setLocalData({ error: validationResponse, url, shouldLoadPreview: false });
        }
    };

    handleSubmit = (event) => {
        const { deselectAll } = this.props;

        event && event.preventDefault();

        this.loadPreview();

        deselectAll && deselectAll();
    };

    handleChange = (event) => {
        const { setLocalData } = this.props;
        setLocalData({ url: event.target.value });
    };

    handleKey = (event) => {
        event.stopPropagation();
        const { elementLocalData, setLocalData, stopEditing, moveElementsToTrash } = this.props;

        if (event.which === KEY_CODES.ESC) {
            return stopEditing();
        }

        const url = getLocalUrl(elementLocalData);
        const error = getLocalError(elementLocalData);

        if (!url && (event.which === KEY_CODES.BACKSPACE || event.which === KEY_CODES.DELETE)) {
            event.preventDefault();
            moveElementsToTrash && moveElementsToTrash();
        }

        if (error) {
            if (this.errorTime) {
                clearTimeout(this.errorTime);
                this.errorTime = null;
            }

            return setLocalData({ error: null, url });
        }
    };

    render() {
        const { isEditing, element, elementLocalData, attachment } = this.props;
        const url = getLocalUrl(elementLocalData);
        const error = getLocalError(elementLocalData);
        const fetching = getFetching(attachment);

        const inputError = <div className="error-message">{(error && prop('message', error)) || 'URL not found'}</div>;

        const input = (
            <div className="input-wrapper" onClick={this.onClick}>
                <input
                    onChange={this.handleChange}
                    onKeyDown={this.handleKey}
                    value={url || ''}
                    ref={this.linkInputRef}
                    placeholder={getLinkInputPlaceholder(element)}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    enterkeyhint="Done"
                    disabled={!isEditing || fetching}
                />
            </div>
        );

        return (
            <div
                ref={this.linkContainerRef}
                className="LinkInput"
                tabIndex="-1"
                onKeyDown={this.handleKey}
                onMouseDown={this.handleMouseDown}
            >
                <form className="link-form" onSubmit={this.handleSubmit}>
                    {error ? inputError : input}
                </form>
            </div>
        );
    }
}

// NB: when converting this to typescript, reuse LinkEditorProps
LinkInput.propTypes = {
    element: PropTypes.object,
    elementLocalData: PropTypes.object,
    attachment: PropTypes.object,
    isSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    shouldFocusOnlyWhenSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    setLinkUrl: PropTypes.func,
    setLocalData: PropTypes.func,
    startEditing: PropTypes.func,
    stopEditing: PropTypes.func,
    moveElementsToTrash: PropTypes.func,
    deselectAll: PropTypes.func,
};

export default LinkInput;
