import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { createSelector } from 'reselect';

import PopupPanelMouseManager from './PopupPanelMouseManager';
import PopupErrorBoundary from './errorBoundary/PopupErrorBoundary';

// Selectors
import { isMobileSize } from '../../utils/grid/gridSizeSelector';
import { activePopupSelector } from './popupOpenSelector';

import { closePopup as closePopupAction } from './popupActions';

import { ARROW_DIR } from './popupConstants';

// Styles
import './TabbedPopupPanel.scss';
import './PopupPanel.scss';
import './PopupPanel.tooltip.scss';

const popupSelector = createSelector(
    activePopupSelector,
    (state, ownProps) => ownProps.popupId,
    (state, ownProps) => ownProps.forceVisible,
    isMobileSize,
    (popupState, popupId, forceVisible, isMobile) => ({
        visible: forceVisible || popupState.has(popupId),
        isMobile,
    }),
);

const mapDispatchToProps = (dispatch, ownProps) => ({
    closePopup: () => dispatch(closePopupAction(ownProps.popupId, false)),
});

const enhance = connect(popupSelector, mapDispatchToProps);

const PopupPanel = (props) => {
    const { visible, className, arrowDir, children, padded, isMobile, stayOpen } = props;
    if (!visible) return null;

    return (
        <div className={classNames('PopupPanel', className, `arrow-${arrowDir || ARROW_DIR.top}`, { padded })}>
            <PopupPanelMouseManager {...props} stayOpen={isMobile || stayOpen}>
                <PopupErrorBoundary {...props}>
                    <div className="popup-content">{children}</div>
                </PopupErrorBoundary>
            </PopupPanelMouseManager>
        </div>
    );
};

PopupPanel.propTypes = {
    visible: PropTypes.bool,
    padded: PropTypes.bool,
    isMobile: PropTypes.bool,
    stayOpen: PropTypes.bool,
    className: PropTypes.string,
    arrowDir: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default enhance(PopupPanel);
