// Util
import registerTaskList from '../../../common/taskLists/index';
import { isTask } from '../../../common/elements/utils/elementTypeUtils';

// Components
import containerComponent from './TaskListContainer';
import presentationalComponent from './TaskListPresentationalWrapper';

// Constants
import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

const taskToolAvailabilityPredicate = ({ editorKey, selectedElements, selectedChildElements }) => {
    if (editorKey) return false;

    return selectedChildElements.filter(isTask).size === 1;
};

export default () => {
    return registerTaskList({
        containerComponent,
        presentationalComponent,
        selectedTools: [
            TOOLBAR_TOOLS.COLOR,
            TOOLBAR_TOOLS.TITLE,
            {
                ...TOOLBAR_TOOLS.DUE_DATE,
                availabilityPredicate: taskToolAvailabilityPredicate,
            },
            { ...TOOLBAR_TOOLS.ASSIGNMENT, availabilityPredicate: taskToolAvailabilityPredicate },
            { ...TOOLBAR_TOOLS.REACTION, availabilityPredicate: taskToolAvailabilityPredicate },
            ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
        ],
    });
};
