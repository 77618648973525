// Lib
import { debounce } from 'lodash';

// Selectors
import { getCurrentUser } from '../../user/currentUserSelector';

// Cache
import {
    clearCachedReduxState,
    isJSConversionTimeViolation,
    setCachedReduxState,
    SUPPORTS_LOCAL_CACHE,
} from './localCache';

// Util
import globalLogger, { LoggerComponents } from '../../logger';
import rIC, { cancelIC } from '../../../common/utils/lib/rIC';
import { sendAmplitudeUserProperties } from '../../analytics/amplitudeServiceJS';
import {
    NewRelicCustomAttributes,
    NewRelicPageActions,
    sendNewRelicPageAction,
    setNewRelicCustomAttribute,
} from '../../analytics/newRelicUtils';

// Constants
import {
    ELEMENT_CREATE,
    ELEMENT_DELETE,
    ELEMENT_DIFF_UPDATE,
    ELEMENT_MOVE,
    ELEMENT_MOVE_AND_UPDATE,
    ELEMENT_SET_TYPE,
    ELEMENT_MOVE_MULTI,
    ELEMENT_UPDATE,
    ELEMENT_UPDATE_ACL,
} from '../../../common/elements/elementConstants';
import { USER_UPDATE } from '../../../common/users/userConstants';
import { COMMENTS_ADD, COMMENTS_DELETE, COMMENTS_UPDATE } from '../../../common/comments/commentConstants';
import { CURRENT_BOARD_ID_SET } from '../../reducers/currentBoardId/currentBoardIdConstants';
import { APP_SET_INITIALISED } from '../../app/initialisation/initialisationActions';
import { LOGIN_SUCCESS, LOGOUT, REGISTER_SUCCESS } from '../../auth/authConstants';
import { CURRENT_USER_SET } from '../../user/currentUserConstants';
import { LOCAL_CACHE_FORCE_CLEAR } from './localCacheActions';
import { DND_3D_EFFECTS_SET } from '../../user/account/accountModal/interface/interfaceSettingsConstants';
import { THEME_MODE_SET } from '../../user/account/accountModal/interface/themeSettings/themeConstants';
import { AMPLITUDE_USER_PROPS, TRACKED_FEATURES } from '../../../common/analytics/statsConstants';
import { TRASH_CLEAR_COMPLETE } from '../../workspace/toolbar/trash/store/trashConstants';

// Types
import { AnyAction } from 'redux';
import { ReduxStore } from '../../types/reduxTypes';
import { isFeatureEnabled } from '../../../common/users/utils/userPropertyUtils';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';
import {
    RESOURCES_FETCH_ERROR,
    RESOURCES_FETCHED,
    RESOURCES_FETCHING,
    RESOURCES_INVALIDATED,
    RESOURCES_MARKED_AS_STALE,
    RESOURCES_CACHED,
} from '../../utils/services/http/asyncResource/asyncResourceConstants';

const ACTIONS_TO_PERSIST = new Map();
ACTIONS_TO_PERSIST.set(ELEMENT_CREATE, true);
ACTIONS_TO_PERSIST.set(ELEMENT_MOVE, true);
ACTIONS_TO_PERSIST.set(ELEMENT_DELETE, true);
ACTIONS_TO_PERSIST.set(ELEMENT_UPDATE, true);
ACTIONS_TO_PERSIST.set(ELEMENT_DIFF_UPDATE, true);
ACTIONS_TO_PERSIST.set(ELEMENT_SET_TYPE, true);
ACTIONS_TO_PERSIST.set(ELEMENT_MOVE_AND_UPDATE, true);
ACTIONS_TO_PERSIST.set(ELEMENT_MOVE_MULTI, true);
ACTIONS_TO_PERSIST.set(ELEMENT_UPDATE_ACL, true);
ACTIONS_TO_PERSIST.set(CURRENT_BOARD_ID_SET, true);
ACTIONS_TO_PERSIST.set(COMMENTS_UPDATE, true);
ACTIONS_TO_PERSIST.set(COMMENTS_ADD, true);
ACTIONS_TO_PERSIST.set(COMMENTS_DELETE, true);
ACTIONS_TO_PERSIST.set(USER_UPDATE, true);
ACTIONS_TO_PERSIST.set(CURRENT_USER_SET, true);
ACTIONS_TO_PERSIST.set(CURRENT_BOARD_ID_SET, true);
ACTIONS_TO_PERSIST.set(APP_SET_INITIALISED, true);
ACTIONS_TO_PERSIST.set(DND_3D_EFFECTS_SET, true);
ACTIONS_TO_PERSIST.set(THEME_MODE_SET, true);
ACTIONS_TO_PERSIST.set(RESOURCES_FETCHING, true);
ACTIONS_TO_PERSIST.set(RESOURCES_FETCHED, true);
ACTIONS_TO_PERSIST.set(RESOURCES_CACHED, true);
ACTIONS_TO_PERSIST.set(RESOURCES_MARKED_AS_STALE, true);
ACTIONS_TO_PERSIST.set(RESOURCES_FETCH_ERROR, true);
ACTIONS_TO_PERSIST.set(RESOURCES_INVALIDATED, true);
ACTIONS_TO_PERSIST.set(TRASH_CLEAR_COMPLETE, true);

const ACTIONS_TO_CLEAR = new Map();
ACTIONS_TO_CLEAR.set(LOGOUT, true);
ACTIONS_TO_CLEAR.set(REGISTER_SUCCESS, true);
ACTIONS_TO_CLEAR.set(LOGIN_SUCCESS, true);
ACTIONS_TO_CLEAR.set(LOCAL_CACHE_FORCE_CLEAR, true);

const logger = globalLogger.createChannel(LoggerComponents.INSTANT_APP);

/**
 * This middleware listens for actions that would result in the store being persisted to the
 * local cache (IndexedDB).
 */
export default (store: ReduxStore) => (next: Function) => {
    // ignore for unsupported platforms
    if (!SUPPORTS_LOCAL_CACHE) return (action: AnyAction) => next(action);

    const initialState = store.getState();
    const initialCurrentUser = getCurrentUser(initialState);

    let idleCallbackId: number | null = null;
    let changesToSave = false;
    let cachePersistenceEnabled = isFeatureEnabled(ExperimentId.clientLocalCache, initialCurrentUser);

    const disableInstantAppFeature = () => {
        changesToSave = false;
        cachePersistenceEnabled = false;
        idleCallbackId && cancelIC(idleCallbackId);

        clearCachedReduxState();

        setNewRelicCustomAttribute(NewRelicCustomAttributes.CLIENT_LOCAL_CACHE, false);
    };

    const persistCache = async () => {
        if (!changesToSave) return;

        changesToSave = false;
        const state = store.getState();

        try {
            await setCachedReduxState(state);
        } catch (e) {
            if (!isJSConversionTimeViolation(e)) throw e;

            disableInstantAppFeature();

            logger.warn('JS conversion time violation, disabling instant app', e);

            sendNewRelicPageAction(NewRelicPageActions.FEATURE_FORCE_DISABLE, {
                feature: ExperimentId.clientLocalCache,
                code: e.code,
            });
        }
    };

    const debouncedPersistCache = debounce(persistCache, 1000);

    /**
     * Persists the redux store to the local cache (IndexedDB) when the window closes.
     */
    const persistOnBrowserClose = () => {
        if (!changesToSave) return;

        persistCache();
    };

    /**
     * Attach the persistence when the browser window closes.
     */
    const attachBrowserOnClosePersistence = () => {
        window.addEventListener('beforeunload', persistOnBrowserClose);
    };

    /**
     * Remove the persistence on browser close if the user logs out or isn't feature enabled.
     */
    const removeBrowserOnClosePersistence = () => {
        window.removeEventListener('beforeunload', persistOnBrowserClose);
    };

    const checkUserIsLocalCacheEnabled = (action: AnyAction) => {
        const { user } = action;

        if (!isFeatureEnabled(ExperimentId.clientLocalCache, user)) {
            removeBrowserOnClosePersistence();
            return;
        }

        sendAmplitudeUserProperties({
            userProperties: {
                [AMPLITUDE_USER_PROPS.FEATURE]: { [TRACKED_FEATURES.CLIENT_LOCAL_CACHE]: true },
            },
        });

        setNewRelicCustomAttribute(NewRelicCustomAttributes.CLIENT_LOCAL_CACHE, true);

        attachBrowserOnClosePersistence();
        cachePersistenceEnabled = true;
    };

    return (action: AnyAction) => {
        if (action.type === CURRENT_USER_SET) checkUserIsLocalCacheEnabled(action);

        // If the current user isn't localCache feature flag enabled, don't persist anything
        if (!cachePersistenceEnabled) return next(action);

        if (ACTIONS_TO_PERSIST.has(action.type)) {
            changesToSave = true;
            idleCallbackId = rIC(debouncedPersistCache);
        }

        if (ACTIONS_TO_CLEAR.has(action.type)) {
            disableInstantAppFeature();
            debouncedPersistCache.cancel();
            removeBrowserOnClosePersistence();
        }

        return next(action);
    };
};
