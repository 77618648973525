// Lib
import { DraftHandleValue, EditorState, EntityInstance, ContentBlock } from 'draft-js';
// @ts-ignore - no types
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';

// Utils
import getLinksFromText, { getLinkifyMatchesFromText } from '../../customRichUtils/links/getLinksFromText';
import { length } from '../../../../../common/utils/immutableHelper';
import handleEntityChange from '../../store/reducers/handleEntityChange';

// Constants
import { ENTITIES } from '../../richText/richTextConstants';
import { DRAFT_ENTITY_MUTABILITY } from '../../draftjsConstants';

// Types
import { PluginProps } from '../pluginTypes';

/**
 * Ensure that the pasted link covers all the characters in the text, otherwise, paste the full content not the link.
 */
const entityCoversAllCharacters = (entityKey: string, contentBlock: ContentBlock): boolean => {
    if (!entityKey) return false;
    if (!contentBlock) return false;

    const numCharacters = contentBlock.getText()?.length || 0;

    if (numCharacters === 0) return false;

    const characterList = contentBlock.getCharacterList();

    if (length(characterList) !== numCharacters) return false;

    for (let i = 0; i < numCharacters; i++) {
        const characterMetadata = characterList.get(i);
        if (characterMetadata.getEntity() !== entityKey) return false;
    }

    return true;
};

/**
 * Gets a URL from pasted HTML.
 */
const getPastedUrlFromHtml = (html?: string): string | null => {
    if (!html) return null;

    // There's a bug in the DraftPasteProcessor where returns the previous state if the provided HTML
    //  doesn't contain any links.
    // So first we ensure that there are links within the HTML based on linkify, then use the DraftPasteProcessor
    //  to extract the link.
    // NOTE: We could potentially use the linkify matches alone if we find it to be more accurate
    const linkifyMatches = getLinkifyMatchesFromText(html);

    if (linkifyMatches.length !== 1) return null;

    const fragment = DraftPasteProcessor.processHTML(html);

    if (fragment.contentBlocks?.length !== 1) return null;

    const entityKey = fragment.entityMap.getLastCreatedEntityKey();

    if (!entityKey) return null;

    const entity: EntityInstance = fragment.entityMap.get(entityKey);

    if (!entity) return null;

    if (entity.getType() !== ENTITIES.LINK) return null;

    if (!entityCoversAllCharacters(entityKey, fragment.contentBlocks[0])) return null;

    return entity.getData()?.url;
};

/**
 * Gets a URL from pasted text.
 */
const getPastedUrlFromText = (text: string): string | null => {
    if (!text) return null;

    const links = getLinksFromText(text);

    return links[0] || null;
};

/**
 * Gets a URL from pasted text or HTML.
 */
const getPastedUrl = (text: string, html?: string): string | null =>
    getPastedUrlFromHtml(html) || getPastedUrlFromText(text);

/**
 * Replaces selected text with a link if the pasted content is a link.
 */
const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState,
    { setEditorState }: PluginProps,
): DraftHandleValue => {
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) return 'not-handled';

    const url = getPastedUrl(text, html);

    if (!url) return 'not-handled';

    const updatedEditorState = handleEntityChange(editorState, {
        entityType: ENTITIES.LINK,
        mutability: DRAFT_ENTITY_MUTABILITY.MUTABLE,
        data: { url },
    });

    if (!updatedEditorState) return 'not-handled';

    setEditorState(updatedEditorState);

    return 'handled';
};

export default handlePastedText;
