// Lib
import { get } from 'lodash/fp';

// Utils
import { isCard, isDocument, isImage } from '../../../../common/elements/utils/elementTypeUtils';
import { getElementId, getImageProp } from '../../../../common/elements/utils/elementPropertyUtils';
import { canGiveFeedbackOnBoard } from '../../../../common/permissions/elementPermissionsUtil';
import { getUserOnboardingStep } from '../../../../common/users/utils/userPropertyUtils';

// Selectors
import { getCurrentBoardId, getCurrentBoardIdFromState } from '../../../reducers/currentBoardId/currentBoardIdSelector';
import { getCurrentUser, isGuestSelector } from '../../../user/currentUserSelector';
import { getShouldShowSuggestionsPopup } from '../../header/workspaceToolsHeader/notifications/pushPopup/pushSuggestionPopupSelector';
import { getGridSizeName } from '../../../utils/grid/gridSizeSelector';
import { getCurrentBoardChildren } from '../../../element/selectors/currentBoardSelector';

// Services
import {
    arePushNotificationsAlreadyPermitted,
    arePushNotificationsDenied,
    arePushNotificationsSupported,
} from '../../../notifications/push/pushNotificationsManager';
import { getHasPushBeenPermanentlyDismissed } from '../../header/workspaceToolsHeader/notifications/pushPopup/pushSuggestionService';
import { getOnboardingProgressStep } from './onboardingProgressService';

// Constants
import { ONBOARDING_EDUCATION_CODES } from '../../../../common/users/userEducationConstants';
import { PUBLIC_USER_ID } from '../../../../common/users/userConstants';
import { GRID } from '../../../utils/grid/gridConstants';
import { ELEMENT_CREATION_SOURCES } from '../../../../common/elements/elementConstants';

const getCardCreateHeaderTitle = (action) => action?.content?.textContent?.blocks?.[0].data?.['header-title'];

const ONBOARDING_LISTENERS = [
    // This listener will kick off an onboarding flow if the user has their "onboarding" property set.
    {
        id: 'enter-onboarding',
        // If we have a stored progress step on the user, start from there
        // The user should always be available at this point, because the listeners are attached after
        // the CURRENT_USER_SET action.
        attachmentPredicate: (state) => {
            const currentUser = getCurrentUser(state);
            return !!getUserOnboardingStep(currentUser);
        },
        predicate: (action) => action.type === 'ONBOARDING_STEPS_LOADED',
        gotoStep: (state) => {
            const currentUser = getCurrentUser(state);
            return getUserOnboardingStep(currentUser);
        },
    },
    // This listener will kick off an onboarding flow if the browser has a "milanote.onboarding.progress" local
    // storage property set.  This is used to continue a previously unfinished onboarding flow.
    {
        id: 're-enter-onboarding',
        // If we have a stored progress step, start from there
        attachmentPredicate: (state) => {
            const onboardingProgressStep = getOnboardingProgressStep();
            const currentUser = getCurrentUser(state);

            // If the current user has an onboarding step, then don't overwrite it
            return !getUserOnboardingStep(currentUser) && !!onboardingProgressStep;
        },
        predicate: (action) => action.type === 'ONBOARDING_STEPS_LOADED',
        gotoStep: () => getOnboardingProgressStep(),
    },
    {
        id: 'create-note',
        predicate: (action) =>
            !action.remote && action.type === 'ELEMENT_CREATE' && isCard(action) && !getCardCreateHeaderTitle(action),
        gotoStep: 'explain-note',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_NOTE,
        preloadImages: [require('../tooltips/explain-note.png')],
    },
    {
        id: 'create-document',
        predicate: (action) => !action.remote && action.type === 'ELEMENT_CREATE' && isDocument(action),
        gotoStep: 'explain-document',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_DOCUMENT,
        preloadImages: [require('../tooltips/explain-document.png')],
    },
    {
        id: 'create-audio',
        predicate: (action) =>
            !action.remote &&
            action.type === 'ELEMENT_CREATE' &&
            action.elementType === 'LINK' &&
            action.content?.linkType === 'AUDIO',
        gotoStep: 'explain-audio',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_AUDIO,
        preloadImages: [require('../tooltips/explain-audio.png')],
    },
    {
        id: 'create-map',
        predicate: (action) =>
            !action.remote &&
            action.type === 'ELEMENT_CREATE' &&
            action.elementType === 'LINK' &&
            action.content?.linkType === 'MAP',
        gotoStep: 'explain-map',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_MAP,
        preloadImages: [require('../tooltips/explain-map.png')],
    },
    {
        id: 'create-video',
        predicate: (action) =>
            !action.remote &&
            action.type === 'ELEMENT_CREATE' &&
            action.elementType === 'LINK' &&
            action.content?.linkType === 'VIDEO',
        gotoStep: 'explain-video',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_VIDEO,
        preloadImages: [require('../tooltips/explain-video.png')],
    },
    {
        id: 'create-sketch',
        predicate: (action) => !action.remote && action.type === 'ELEMENT_CREATE' && action.elementType === 'SKETCH',
        gotoStep: 'explain-sketch',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_SKETCH,
        preloadImages: [require('../tooltips/explain-sketch.png')],
    },
    {
        id: 'create-swatch',
        predicate: (action) =>
            !action.remote && action.type === 'ELEMENT_CREATE' && action.elementType === 'COLOR_SWATCH',
        gotoStep: 'explain-swatch',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_SWATCH,
        preloadImages: [require('../tooltips/explain-swatch.png')],
    },
    {
        id: 'create-title',
        predicate: (action) =>
            !action.remote &&
            action.type === 'ELEMENT_CREATE' &&
            action.elementType === 'CARD' &&
            !!getCardCreateHeaderTitle(action),
        gotoStep: 'explain-title',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_TITLE,
        preloadImages: [require('../tooltips/explain-title.png')],
    },
    {
        id: 'create-task-list',
        predicate: (action) => !action.remote && action.type === 'ELEMENT_CREATE' && action.elementType === 'TASK_LIST',
        gotoStep: 'explain-task-list',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_TASK_LIST,
        preloadImages: [require('../tooltips/explain-task-list.png')],
    },
    {
        id: 'create-line',
        predicate: (action) => !action.remote && action.type === 'ELEMENT_CREATE' && action.elementType === 'LINE',
        gotoStep: 'explain-line',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_LINE,
        preloadImages: [require('../tooltips/explain-line.png')],
    },
    {
        id: 'create-link',
        predicate: (action) =>
            !action.remote &&
            action.type === 'ELEMENT_CREATE' &&
            action.elementType === 'LINK' &&
            !action.content?.linkType,
        gotoStep: 'explain-link',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_LINK,
        preloadImages: [require('../tooltips/explain-link.png')],
    },
    {
        id: 'create-column',
        predicate: (action) => !action.remote && action.type === 'ELEMENT_CREATE' && action.elementType === 'COLUMN',
        gotoStep: 'explain-column',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_COLUMN,
        preloadImages: [require('../tooltips/explain-column-bg.png'), require('../tooltips/explain-column-drag.png')],
    },
    {
        id: 'create-board',
        predicate: (action, state) => {
            if (action.remote) return false;
            if (action.type !== 'ELEMENT_CREATE') return false;
            if (action.elementType !== 'BOARD') return false;

            const currentBoardId = getCurrentBoardIdFromState(state);
            return !state.getIn(['elements', currentBoardId, 'location', 'rootBoard']);
        },
        gotoStep: 'explain-board',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_BOARD,
        preloadImages: [require('../tooltips/explain-board.png')],
    },
    {
        id: 'used-template-with-image-placeholder',
        predicate: (action, state) => {
            if (action.type !== 'ONBOARDING_STEP_PASSED') return false;

            const imagePlaceholders = getCurrentBoardChildren(state)
                .filter(isImage)
                .filter((imageElement) => !getImageProp(imageElement));

            if (imagePlaceholders.size === 0) return;

            action.imageElementId = getElementId(imagePlaceholders.first());

            return true;
        },
        gotoStep: 'explain-image-placeholder',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_IMAGE_PLACEHOLDER,
    },
    {
        id: 'create-card-double-click',
        predicate: (action) =>
            !action.remote &&
            action.type === 'ELEMENT_CREATE' &&
            action.elementType === 'CARD' &&
            action.creationSource === ELEMENT_CREATION_SOURCES.DOUBLE_CLICK,
        gotoStep: 'explain-double-click',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_DOUBLE_CLICK_CARD,
    },
    {
        id: 'create-comment-thread',
        predicate: (action) =>
            !action.remote && action.type === 'ELEMENT_CREATE' && action.elementType === 'COMMENT_THREAD',
        gotoStep: 'explain-comment-thread',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_COMMENT_THREAD,
        preloadImages: [require('../tooltips/explain-comment-thread.png')],
    },
    {
        id: 'explain-comment',
        predicate: (action, state) => {
            if (action.remote) return false;

            if (action.type !== 'CURRENT_BOARD_ID_SET' && action.type !== '@@router/LOCATION_CHANGE') return false;

            if (
                action.type === '@@router/LOCATION_CHANGE' &&
                get(['locationBeforeTransitions', 'pathname'], state.get('routing')) !== '/share'
            ) {
                return false;
            }

            const currentBoardId =
                action.type === '@@router/LOCATION_CHANGE' ? getCurrentBoardId(state) : action.boardId;

            const currentBoard = state.getIn(['elements', currentBoardId]);

            if (!currentBoard) return false;
            if (currentBoard.getIn(['location', 'rootBoard'])) return false;

            const currentUserId = state.getIn(['app', 'currentUser', '_id']);
            const aclIds = state.getIn(['permissions', 'aclIds']);
            if (!canGiveFeedbackOnBoard(state.get('elements'), currentBoardId, [currentUserId, ...aclIds])) {
                return false;
            }

            const currentBoardAcl = currentBoard.get('acl');
            return (
                currentBoardAcl &&
                (currentBoardAcl.size > 1 || (currentBoardAcl.size && !currentBoardAcl.get(PUBLIC_USER_ID)))
            );
        },
        gotoStep: 'explain-comment',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_COMMENT,
    },
    {
        id: 'suggest-push-notifications',
        // This determines if the listener should be attached
        attachmentPredicate: (state) => {
            // Only attach if the user has had the
            const gridSizeName = getGridSizeName(state);

            return (
                gridSizeName !== GRID.MOBILE.name &&
                arePushNotificationsSupported() &&
                !arePushNotificationsAlreadyPermitted() &&
                !arePushNotificationsDenied() &&
                !getHasPushBeenPermanentlyDismissed()
            );
        },
        // This determines if the step should be executed
        predicate: (action, state) => {
            const shouldShowSuggestPushPopup = getShouldShowSuggestionsPopup(state);
            const onboardingStepsLoaded = state.getIn(['app', 'onboarding', 'loaded']);
            return shouldShowSuggestPushPopup && onboardingStepsLoaded;
        },
        gotoStep: 'open-suggest-push-popup',
    },
    {
        id: 'create-table',
        predicate: (action) => !action.remote && action.type === 'ELEMENT_CREATE' && action.elementType === 'TABLE',
        gotoStep: 'explain-table',
        educationCode: ONBOARDING_EDUCATION_CODES.EXPLAIN_TABLE,
        preloadImages: [require('../tooltips/explain-table.png')],
    },
    {
        id: 'announce-table',
        // This determines if the listener should be attached
        attachmentPredicate: (state) => !isGuestSelector(state),
        // This determines if the step should be executed
        predicate: (action, state) => {
            if (action.remote) return false;

            return state.getIn(['app', 'onboarding', 'loaded']);
        },
        gotoStep: 'announce-table-1-modal',
        educationCode: ONBOARDING_EDUCATION_CODES.ANNOUNCE_TABLE,
    },
];

export default ONBOARDING_LISTENERS;
