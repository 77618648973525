import { capitalize } from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';
import {
    getCurrentUser,
    getCurrentUserSubscription,
    getCurrentUserSubscriptionDetail,
    getCurrentUserSubscriptionPlan,
} from '../../../../currentUserSelector';
import { asObject, propIn } from '../../../../../../common/utils/immutableHelper';
import { getPlanPaymentMethodCard } from '../../../../../../common/payment/subscriptions/subscriptionPlanPropertyUtils';
import { getIsFeatureEnabledForCurrentUser } from '../../../../../element/feature/elementFeatureSelector';
import { getSubscriptionPlanPriceId } from '../../../../../../common/payment/subscriptionPropertyUtil';

import { ExperimentId } from '../../../../../../common/experiments/experimentsConstants';
import { LEGACY_PRICE_IDS } from '../../../../../../common/payment/paymentConstants';

const CARD_DEFAULT = {
    name: ' ',
    number: '•••• •••• •••• ••••',
    expiry: '••/••',
    cvc: '•••',
    postCode: ' ',
    cardPrefilled: false,
};

const getCustomerPaymentMethodCard = propIn(['paymentMethod', 'card']);

const customerSelector = createSelector(getCurrentUser, (currentUser) => currentUser.get('customer'));

export const cardSelector = createSelector(
    getCurrentUserSubscriptionPlan,
    customerSelector,
    (subscriptionPlan, customer) => {
        if (!subscriptionPlan && !customer) return CARD_DEFAULT;

        const card =
            asObject(getPlanPaymentMethodCard(subscriptionPlan)) || asObject(getCustomerPaymentMethodCard(customer));
        if (!card) return CARD_DEFAULT;

        return {
            ...CARD_DEFAULT,
            ...card,
            brand: capitalize(card.brand),
            cardPrefilled: true,
        };
    },
);

export const paymentSelector = createStructuredSelector({
    currentUser: getCurrentUser,
    customer: customerSelector,
    customerCard: cardSelector,
    subscription: getCurrentUserSubscriptionDetail,
});

export const unsubscribeSelector = createSelector(
    getCurrentUser,
    getCurrentUserSubscription,
    (currentUser, subscription) => ({
        currentUser,
        subscription,
    }),
);

export const shouldUseCheckoutV2 = createSelector(
    getIsFeatureEnabledForCurrentUser(ExperimentId.checkoutV2),
    getCurrentUserSubscription,
    (isCheckoutV2FeatureEnabled, subscription) => {
        // If subscription not loaded yet, return undefined
        if (subscription === undefined) return;

        // If the user is on a legacy price id, we should make them use Checkout V1,
        // as some features are not supported in Checkout V2
        const priceId = getSubscriptionPlanPriceId(subscription);
        const isLegacyPriceId = LEGACY_PRICE_IDS.includes(priceId);

        return isCheckoutV2FeatureEnabled && !isLegacyPriceId;
    },
);
