// Lib
import { LOCATION_CHANGE } from 'react-router-redux';

// Constants
import { MOBILE_TOOLBAR_SET_MODE, MobileToolbarMode } from './mobileToolbarConstants';
import {
    ELEMENT_EDIT_COMPLETE,
    ELEMENT_EDIT_START,
    ELEMENTS_DESELECT_ALL,
    ELEMENTS_SELECTED,
} from '../../../../common/elements/selectionConstants';

type MobileToolbarState = {
    mode: MobileToolbarMode;
};

// TODO-MOBILE - This should probably instead be in middleware so it will only
//  action the change if there's still elements selected
const handleEditComplete = (state: MobileToolbarState): MobileToolbarState => {
    if (state.mode !== MobileToolbarMode.editing) return state;

    return {
        ...state,
        mode: MobileToolbarMode.selection,
    };
};

const initialState: MobileToolbarState = {
    mode: MobileToolbarMode.tabs,
};

export default (state = initialState, action: any) => {
    if (action.remote) return state;

    switch (action.type) {
        case ELEMENT_EDIT_START:
            return {
                ...state,
                mode: MobileToolbarMode.editing,
            };
        case ELEMENTS_SELECTED:
            return {
                ...state,
                mode: MobileToolbarMode.selection,
            };
        case ELEMENTS_DESELECT_ALL:
        case LOCATION_CHANGE:
            return {
                ...state,
                mode: MobileToolbarMode.tabs,
            };
        case MOBILE_TOOLBAR_SET_MODE:
            return {
                ...state,
                mode: action.mode,
            };
        case ELEMENT_EDIT_COMPLETE:
            return handleEditComplete(state);
        default:
            return state;
    }
};
