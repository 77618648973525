// Lib
import React from 'react';
import { DetailedCellError, ErrorType } from 'hyperformula';

// Components
import Icon from '../../../components/icons/Icon';
import TooltipSource from '../../../components/tooltips/TooltipSource';

// Styles
import './CellFormulaErrorRenderer.scss';

interface Props {
    cellError: DetailedCellError;
}

const CUSTOM_FORMULA_ERROR_MESSAGES: { [type: string]: string } = {
    [ErrorType.DIV_BY_ZERO]: "Numbers can't be divided by zero",
    [ErrorType.CYCLE]: 'Circular reference detected',
};

const trim = (str: string): string => {
    if (str.at(str.length - 1) === '.') return str.slice(0, -1);

    return str;
};

const CellFormulaErrorRenderer = (props: Props) => {
    const { cellError } = props;
    const { message, value, type } = cellError;

    const tooltipText = message && message !== '' ? trim(message) : CUSTOM_FORMULA_ERROR_MESSAGES[type] || value;

    return (
        <div className="CellFormulaErrorRenderer">
            <TooltipSource
                enabled
                className="cell-formula-error-tooltip-source"
                tooltipId="tooltip-cell-formula-error"
                tooltipText={
                    <div className="tooltip-cell-formula-error-content">
                        <Icon name="error-alert-round" />
                        {tooltipText}
                    </div>
                }
                pollPosition
                distance={10}
                delay={200}
                triggerOnMouseEnter
            >
                <Icon name="error-alert-round"></Icon>
            </TooltipSource>
        </div>
    );
};

export default CellFormulaErrorRenderer;
