import { isLine } from '../../../common/elements/utils/elementTypeUtils';
import { MNElement } from '../../../common/elements/elementModelTypes';

export const shouldShowPlaceholder = (
    measurements: any,
    elementId: string,
    element: MNElement,
    isElementVisible: boolean,
    elementFilterData: any | null,
) => measurements && !isElementVisible && !isLine(element) && !elementFilterData?.[elementId];
