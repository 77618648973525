import * as React from 'react';
import classNames from 'classnames';

import './Button.scss';

export type ButtonProps = React.ComponentPropsWithRef<'button'> & {
    onClickFn?: () => void;
    onClick?: () => void;
    onDoubleClickFn?: () => void;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <button
        ref={ref}
        disabled={props.disabled}
        className={classNames('Button', props.className)}
        style={props.style}
        type={props.type || 'button'}
        onMouseUp={props.onMouseUp}
        onContextMenu={props.onContextMenu}
        onKeyDown={props.onKeyDown}
        onKeyPress={props.onKeyPress}
        onKeyUp={props.onKeyUp}
        onPointerDown={props.onPointerDown}
        onPointerUp={props.onPointerUp}
        onMouseDown={props.onMouseDown}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClickFn || props.onClick}
        onDoubleClick={props.onDoubleClick || props.onDoubleClickFn}
        id={props.id}
    >
        {props.children}
    </button>
));

export default Button;
