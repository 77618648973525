// Lib
import { isEmpty, first } from 'lodash';

// Utils
import logger from '../../logger/logger';
import { getFullPath } from '../../../common/dataStructures/treeUtils';
import { isBoard } from '../../../common/elements/utils/elementTypeUtils';
import { getPhysicalAncestors } from '../../../common/elements/utils/elementTraversalUtils';
import { hasReadPermission } from '../../../common/permissions/elementPermissionsUtil';
import { getElementId, getLocationParentId } from '../../../common/elements/utils/elementPropertyUtils';
import { manuallyReportError } from '../../analytics/rollbarService';

// Types
import { ImMNElementMap } from '../../../common/elements/elementModelTypes';
import { IdGraph, IdTree } from '../../../common/dataStructures/graphTypes';

export type BreadcrumbResult = {
    complete: boolean;
    detached: boolean;
    fullPath?: string[];
    path?: string[];

    missingElementIds?: string[];
    protected?: boolean;
    error?: boolean;
};

const MAX_BREADCRUMB_ITERATIONS = 300;

let hasReportedError = false;

/**
 * Retrieves the Alias IDs that link to a given board ID.
 */
const getAliasIds = (boardIdToAliasIdsMap: IdGraph, linkedElementId: string): string[] | undefined =>
    boardIdToAliasIdsMap[linkedElementId];

// Find 'adjacent node' IDs.  I.e. The parent ID and alias IDs
export const findParentIds = (elements: ImMNElementMap, boardIdToAliasIdsMap: IdGraph, elementId: string): string[] => {
    const element = elements.get(elementId);

    if (!element) {
        logger.info('No element in BFS', elementId);
        return [];
    }

    const aliasIds = getAliasIds(boardIdToAliasIdsMap, elementId);
    const parentId = getLocationParentId(element);

    if (!aliasIds && !parentId) return [];
    if (!aliasIds) return [parentId];

    aliasIds.unshift(parentId);

    return aliasIds;
};

/**
 * Returns all the elements that we're expecting to have, but we don't currently have
 */
// NOTE: Not worth optimising this function as it spends very little time in here
export const getMissingElementIds = (elements: ImMNElementMap, elementIds: string[]): string[] =>
    elementIds.filter((elementId) => !elements.get(elementId));

/**
 * This is the path to the highest element that can be viewed by the specified user.
 */
// NOTE: This function isn't optimal from a performance perspective, but it's only called once
//   per invocation of getBreadcrumb, and the number of ancestors is usually less than 10,
//   so it's not worth improving
const getReadablePath = (currentBoardId: string, elements: ImMNElementMap, aclIds: string[]): string[] =>
    getPhysicalAncestors(elements, currentBoardId)
        .filter((ancestor) => hasReadPermission(elements, getElementId(ancestor), aclIds))
        .map((ancestor) => getElementId(ancestor))
        .reverse();

/**
 * Remove columns and skeletons from the breadcrumb list
 */
// NOTE: Not worth optimising this function as it spends very little time in here
const filterOutColumnsAndSkeletons =
    (elements: ImMNElementMap) =>
    (elementId: string): boolean => {
        const element = elements.get(elementId);
        return isBoard(element);
    };

const getBreadthFirstBreadcrumb = (
    currentBoardId: string,
    rootBoardId: string,
    elements: ImMNElementMap,
    aclIds: string[],
    boardIdToAliasIdsMap: IdGraph,
): BreadcrumbResult => {
    if (!currentBoardId) {
        return {
            complete: false,
            detached: false,
            error: true,
            missingElementIds: [currentBoardId],
        };
    }

    // Perform a breadth first search of elements until we find the root element or we encounter a missing element
    // Start at the current board and work backwards
    let elementId: string | null = currentBoardId;
    let queue = [elementId];
    let missingElementIds = [] as string[];
    let iterationCount = 0;

    const visitedElementIds = {} as { [key: string]: boolean };
    const previousNodes = { [elementId]: null } as IdTree;

    // Function to add an ID to the previous nodes map
    const addToPreviousNodes = (id: string) => {
        previousNodes[id] = previousNodes[id] || elementId;
    };

    // Guest users won't have a rootBoardId so they will skip this and go straight to
    // the public board calculation
    while (rootBoardId && queue.length && iterationCount < MAX_BREADCRUMB_ITERATIONS) {
        iterationCount++;

        // Get the front of the queue
        elementId = queue.shift() as string;

        // If we've already visited this element then don't check it again, otherwise there will be an infinite loop
        if (visitedElementIds[elementId]) continue;

        visitedElementIds[elementId] = true;

        // We've found what we need
        if (elementId === rootBoardId) {
            const fullPath = getFullPath(previousNodes, elementId);
            return {
                complete: true,
                detached: false,
                path: fullPath.filter(filterOutColumnsAndSkeletons(elements)),
                fullPath,
            };
        }

        // Otherwise check all 'adjacent nodes' (parents or aliases)
        const parentIds = findParentIds(elements, boardIdToAliasIdsMap, elementId);

        parentIds
            // Only use the unvisited ones
            .filter((parentId) => !visitedElementIds[parentId])
            .forEach(addToPreviousNodes);
        queue = queue.concat(parentIds);

        // Keep track of any missing element IDs in case we don't find a full path, we can fetch them
        missingElementIds = missingElementIds.concat(getMissingElementIds(elements, parentIds));
    }

    if (iterationCount >= MAX_BREADCRUMB_ITERATIONS) {
        logger.error('Max breadcrumb iterations reached', aclIds);

        if (!hasReportedError) {
            manuallyReportError({
                errorMessage: 'Max breadcrumb iterations reached',
                custom: { aclIds, currentBoardId },
            });
            hasReportedError = true;
        }

        const sharedPath = getReadablePath(currentBoardId, elements, aclIds);

        return {
            complete: true,
            error: true,
            detached: true,
            path: sharedPath.filter(filterOutColumnsAndSkeletons(elements)),
            fullPath: sharedPath,
        };
    }

    // We weren't able to find the target
    // If we have missing elements then we need to resolve them before being sure that we're 'complete'
    if (missingElementIds.length) {
        return {
            complete: false,
            detached: false,
            missingElementIds,
        };
    }

    // If the root couldn't be found and there aren't any missing element IDs then find the shared path
    // I.e. The path at which the user has permission to view the board (if one exists).
    const sharedPath = getReadablePath(currentBoardId, elements, aclIds);

    // This must be because the user is viewing a shared board but currently has not entered the password
    if (!sharedPath.length) {
        // NOTE: Might want to make sure that the current board exists in the state?

        // So there's currently no path to root, and there's currently no shared path
        // Just return the currentBoardId as the breadcrumb and render it in the detached state
        return {
            complete: true,
            detached: true,
            protected: true,
            path: [currentBoardId],
            fullPath: [currentBoardId],
        };
    }

    return {
        complete: true,
        detached: true,
        path: sharedPath.filter(filterOutColumnsAndSkeletons(elements)),
        fullPath: sharedPath,
    };
};

/**
 * This handles the two most common breadcrumb scenarios:
 *  - The current board is within the user's physical tree
 *  - The board is within a shared board, and the user has a single alias to the shared board.
 *
 * If one of these scenarios is not true, this function won't return a breadcrumb.
 */
const getPhysicalOrSingleSharedBoardBreadcrumb = (
    currentBoardId: string,
    rootBoardId: string,
    elements: ImMNElementMap,
    aclIds: string[],
    boardIdToAliasIdsMap: IdGraph,
): BreadcrumbResult | undefined => {
    const readablePath = getReadablePath(currentBoardId, elements, aclIds);

    if (isEmpty(readablePath)) return;

    const readableRootId = first(readablePath) as string;

    if (readableRootId === rootBoardId) {
        return {
            complete: true,
            detached: false,
            path: readablePath.filter(filterOutColumnsAndSkeletons(elements)),
            fullPath: readablePath,
        };
    }

    // Otherwise, get the alias ID of the readable root ID and then get its physical path.
    // This case would satisfy 90% of breadcrumb scenarios
    const aliasIds = getAliasIds(boardIdToAliasIdsMap, readableRootId);

    // If we don't have any aliases for this board, or we have multiple aliases, then bail
    // and fallback to the breadth first search
    if (!aliasIds || aliasIds.length !== 1) return;

    const aliasId = first(aliasIds) as string;

    // Get the readable path
    const aliasReadablePath = getReadablePath(aliasId, elements, aclIds);

    const aliasReadableRootId = first(aliasReadablePath);

    // We didn't find a path back to root with one alias hop from the readable root
    if (aliasReadableRootId !== rootBoardId) return;

    const fullPath = [...aliasReadablePath, ...readablePath];

    return {
        complete: true,
        detached: false,
        path: fullPath.filter(filterOutColumnsAndSkeletons(elements)),
        fullPath,
    };
};

/**
 * Retrieves the breadcrumb to use for the current board.
 */
export const getBreadcrumb = (
    currentBoardId: string,
    rootBoardId: string,
    elements: ImMNElementMap,
    aclIds: string[],
    boardIdToAliasIdsMap: IdGraph,
): BreadcrumbResult =>
    // First try to get the physical / one hop breadcrumb as this scenario is the most common
    getPhysicalOrSingleSharedBoardBreadcrumb(currentBoardId, rootBoardId, elements, aclIds, boardIdToAliasIdsMap) ||
    // Otherwise, fallback to the old method, which performs a breadth first search
    getBreadthFirstBreadcrumb(currentBoardId, rootBoardId, elements, aclIds, boardIdToAliasIdsMap);
