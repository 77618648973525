// Lib
import { defer } from 'lodash';

// Utils
import { hasShiftKey, isControlKeyCommand, hasCommandModifier } from '../../../../utils/keyboard/keyboardUtility';

// Other plugins
import handleReturn from '../editingHelper/handleReturn';

// Constants
import { HANDLER_RETURN_VALUES } from '../../draftjsConstants';
import { ELEMENT_CREATION_SOURCES } from '../../../../../common/elements/elementConstants';

export default (event, editorState, draftProps) => {
    const { getProps } = draftProps;
    const { submitTitle, createNewElement, saveCurrentContent } = getProps();

    if (createNewElement && (hasCommandModifier(event) || isControlKeyCommand(event))) {
        saveCurrentContent();

        defer(() => createNewElement({ creationSource: ELEMENT_CREATION_SOURCES.CMD_ENTER }));
        return HANDLER_RETURN_VALUES.handled;
    }

    if (submitTitle && !hasShiftKey(event)) {
        saveCurrentContent();

        defer(() => submitTitle());
        return HANDLER_RETURN_VALUES.handled;
    }

    return handleReturn(event, editorState, draftProps);
};
