// Lib
import { isNil } from 'lodash';

// Utils
import { removeNumberSymbols, validPercentageRegex } from './tableInputNumberUtils';
import { getDateFromString } from '../../utils/timeUtil';

// Types
import {
    CellTypeNames,
    CurrencyDisplayOptions,
    DateStringFormatOptions,
    TimeStringFormatOptions,
} from '../CellTypeConstants';
import {
    CellData,
    CurrencyTypeObject,
    DateTimeTypeObject,
    NumberTypeObject,
    PercentageTypeObject,
} from '../TableTypes';

/**************************
 * DATE/TIME
 **************************/

export const getFormattedDate = (date: Date, dateFormat: DateStringFormatOptions, locale: string): string => {
    let formatOptions = {};
    switch (dateFormat) {
        case DateStringFormatOptions.NONE:
            return '';
        case DateStringFormatOptions.WORDS_LONG:
            formatOptions = { dateStyle: 'long' };
            break;
        case DateStringFormatOptions.WORDS_SHORT:
            formatOptions = { dateStyle: 'medium' };
            break;
        case DateStringFormatOptions.NUMERIC_SHORT:
            formatOptions = { year: '2-digit', month: 'numeric', day: 'numeric' };
            break;
        case DateStringFormatOptions.WEEKDAY:
            formatOptions = { dateStyle: 'full' };
    }
    let dateString = new Intl.DateTimeFormat(locale, formatOptions).format(date);

    // Replace slashes/dashes if necessary for the locale
    if (dateFormat === DateStringFormatOptions.NUMERIC_DASHED) dateString = dateString.replace(/\//g, '-');
    if (dateFormat === DateStringFormatOptions.NUMERIC_LONG || dateFormat === DateStringFormatOptions.NUMERIC_SHORT) {
        dateString = dateString.replace(/-/g, '/');
    }

    return dateString;
};

export const getFormattedTime = (date: Date, timeFormat: TimeStringFormatOptions, locale: string): string => {
    let formatOptions = {};

    switch (timeFormat) {
        case TimeStringFormatOptions.NONE:
            return '';
        case TimeStringFormatOptions.TWELVE_HOUR_SECONDS:
            formatOptions = { hourCycle: 'h12', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            break;
        case TimeStringFormatOptions.TWELVE_HOUR:
            formatOptions = { hourCycle: 'h12', hour: 'numeric', minute: 'numeric' };
            break;
        case TimeStringFormatOptions.TWENTY_FOUR_HOUR_SECONDS:
            formatOptions = { hourCycle: 'h23', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            break;
        case TimeStringFormatOptions.TWENTY_FOUR_HOUR:
            formatOptions = { hourCycle: 'h23', timeStyle: 'short' };
            break;
    }
    return new Intl.DateTimeFormat(locale, formatOptions).format(date);
};

/**
 * Returns a formatted date time string for rendering the cell
 */
export const getDateFormatting = (
    normalisedHotCellValue: string | number,
    type: DateTimeTypeObject,
    locale: string,
): string => {
    if (!normalisedHotCellValue) return '';

    const date = getDateFromString(normalisedHotCellValue, locale);
    if (!date) return String(normalisedHotCellValue);

    const { dateFormat, timeFormat, timeInBrackets } = type;
    const dateString = getFormattedDate(date, dateFormat, locale);
    const timeString = getFormattedTime(date, timeFormat, locale);
    return `${dateString} ${timeInBrackets && timeString ? `(${timeString})` : `${timeString}`}`.trim();
};

/**************************
 * CURRENCY
 **************************/

export const showCurrencyDisplay = (displayOption: string, currency: string, locale: string): string =>
    new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        currencyDisplay: displayOption,
    }).format(12345);

export const getCurrencyDisplayOptions = (
    currency: string,
    locale: string,
): Array<{ option: string; value: string } | undefined> => {
    const values: string[] = [];
    const displayOptions = Object.values(CurrencyDisplayOptions).map((option) => {
        const value = showCurrencyDisplay(option, currency, locale);
        if (values.includes(value)) return;

        values.push(value);
        return { option, value };
    });
    // filter out undefined values
    return displayOptions.filter((option) => option);
};

/**************************
 * NUMBER
 **************************/

/**
 * Returns the decimal options to use when formatting a number using the Intl.NumberFormat object
 * If the user has set the number of decimals, use that number as both max and min to force that number of
 * decimal places, otherwise calculate the number of decimal places in the input so that we override the default number
 * and show the full number of decimal places entered
 */
export const getDecimalOptions = (numberValue: number | string, decimals: number | string) => {
    // If user has chosen auto or decimals is not a valid number, use the number of decimal places in the input value
    const useInputDecimalPlaces = decimals === 'auto' || isNaN(Number(decimals));
    const inputDecimalPlaces = numberValue.toString().split('.')[1]?.length || 0;

    const decimalsValue = useInputDecimalPlaces ? inputDecimalPlaces : decimals;
    return { maximumFractionDigits: decimalsValue, minimumFractionDigits: decimalsValue };
};

export const getNumberFormatting = (
    normalisedHotCellValue: number | string,
    type: NumberTypeObject | CurrencyTypeObject | PercentageTypeObject,
    locale: string,
): string | number => {
    const { name, decimals, thousandsSeparator } = type;

    let numberValue = Number(removeNumberSymbols(String(normalisedHotCellValue), false, locale));

    // If the cell value is not numeric, return value
    if (isNaN(numberValue)) return normalisedHotCellValue;

    // If the value is in a percentage format, we need to convert it to a number before formatting
    const isPercentageValue =
        typeof normalisedHotCellValue === 'string' && normalisedHotCellValue.match(validPercentageRegex);
    if (isPercentageValue) {
        numberValue = numberValue / 100;
    }

    // Shared option values, this is all we need for Number format
    let options: { [key: string]: string | number | boolean } = {
        useGrouping: thousandsSeparator,
        ...getDecimalOptions(numberValue, decimals),
    };

    // Add additional options for currency and percentage
    switch (name) {
        case CellTypeNames.CURRENCY:
            options = {
                ...options,
                style: 'currency',
                currency: type.currency || 'USD',
                currencyDisplay: type.currencyDisplay || CurrencyDisplayOptions.NARROW_SYMBOL,
                currencySign: type.accounting ? 'accounting' : 'standard',
            };
            break;

        case CellTypeNames.PERCENTAGE:
            options = {
                ...options,
                style: 'percent',
            };
            break;
    }
    return new Intl.NumberFormat(locale, options).format(numberValue);
};

/**************************
 * TABLE CELL RENDERING
 **************************/

export const getRenderCellValueFromNormalisedHotCellValue = (
    cellData: CellData,
    locale: string,
    normalisedHotCellValue: string | number,
): string | number => {
    if (!cellData) return normalisedHotCellValue;

    const { type } = cellData;

    if (!type || isNil(normalisedHotCellValue)) return normalisedHotCellValue;

    switch (type.name) {
        case CellTypeNames.TEXT:
            return normalisedHotCellValue;
        case CellTypeNames.DATE_TIME:
            return getDateFormatting(normalisedHotCellValue, type, locale);
        case CellTypeNames.CURRENCY:
        case CellTypeNames.PERCENTAGE:
        case CellTypeNames.NUMBER:
            return getNumberFormatting(normalisedHotCellValue, type, locale);
        default:
            return normalisedHotCellValue;
    }
};
