// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from '../../../../../../components/buttons/Button';

// Styles
import './CustomColorPickerContainer.scss';

// Using a proxy to determine whether the browser supports a fully functional colour picker.
// Checking for an input type color support doesn't work, because older versions of
// Safari supports this but only as a hex code text input field, which is not useful here.
const browserSupportsColorPicker = () => !!window.IntersectionObserver;

class CustomColorPickerContainer extends React.Component {
    onCustomColorButtonClick = () => {
        const { closePopup, openCustomColorPicker } = this.props;

        openCustomColorPicker();
        closePopup();
    };

    render() {
        if (!browserSupportsColorPicker()) return null;

        return (
            <div className="CustomColorPickerContainer">
                <div className="custom-color-picker-button-container">
                    <Button className="custom-color-picker" onClick={this.onCustomColorButtonClick}>
                        <div className="color-wheel" />
                        <div className="color-picker-text">Custom color...</div>
                    </Button>
                </div>
            </div>
        );
    }
}

CustomColorPickerContainer.propTypes = {
    selectedElements: PropTypes.object,
    openCustomColorPicker: PropTypes.func,
    setColor: PropTypes.func,
    closePopup: PropTypes.func,
};

export default CustomColorPickerContainer;
