export enum ElementType {
    ALIAS_TYPE = 'ALIAS',
    BOARD_TYPE = 'BOARD',
    COLUMN_TYPE = 'COLUMN',
    CARD_TYPE = 'CARD',
    LINK_TYPE = 'LINK',
    LINE_TYPE = 'LINE',
    IMAGE_TYPE = 'IMAGE',
    FILE_TYPE = 'FILE',
    COMMENT_THREAD_TYPE = 'COMMENT_THREAD',
    TASK_LIST_TYPE = 'TASK_LIST',
    TASK_TYPE = 'TASK',
    CLONE_TYPE = 'CLONE',
    SKETCH_TYPE = 'SKETCH',
    ANNOTATION_TYPE = 'ANNOTATION',
    COLOR_SWATCH_TYPE = 'COLOR_SWATCH',
    DOCUMENT_TYPE = 'DOCUMENT',
    TABLE_TYPE = 'TABLE',

    SKELETON_ELEMENT_TYPE = 'SKELETON',
    UNKNOWN_ELEMENT_TYPE = 'UNKNOWN',
}
