import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Utils
import { stopPropagationOnly } from '../../../utils/domUtil';
import { getElementStyle } from '../../../utils/grid/gridUtils';
import { getDomElementId } from '../../utils/elementUtil';
import {
    getCaption,
    getColor,
    getElementId,
    getShowCaption,
} from '../../../../common/elements/utils/elementPropertyUtils';
import rawHasText from '../../../../common/utils/editor/rawUtils/rawHasText';
import { getMainEditorId, getMainEditorKey } from '../../utils/elementEditorUtils';
import { getShowQuickLineTool } from '../../quickLine/quickLineUtil';
import platformSingleton from '../../../platform/platformSingleton';

// Components
import DetailView from '../../../components/displayMode/DetailView';
import Icon from '../../../components/icons/Icon';
import WordCount from './WordCount';
import QuickLineCreationTool from '../../quickLine/QuickLineCreationTool';
import HiddenInput from './HiddenInput';
import ColorBar from '../../../components/colors/ColorBar';
import CardCloneSection from '../../clone/cloneInstances/CardCloneSection';
import ElementReactions from '../../reactions/ElementReactions';
import AttachedCommentsDropTarget from '../../dnd/elementDropTargets/AttachedCommentsDropTarget';
import AttachedCommentsContainer from '../../attachedComment/AttachedCommentsContainer';
import ElementUserTag from '../../../user/userActivity/ElementUserTag';
import FileIcon from '../../../components/fileIcon/FileIcon';
import Caption from '../../../components/caption/Caption';
import createRichTextEditor from '../../../components/editor/richText/createRichTextEditor';

// Selectors
import { textContentSelector } from '../../clone/cloneSelector';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../../common/elements/elementConstants';

// Styles
import './DocumentDetailView.scss';

const DocumentEditor = createRichTextEditor({ clickableLinks: false });

const EmptyDocumentPreview = () => (
    <div className="EmptyDocumentPreview">
        <Icon name="content-empty" />
        <span className="preview-text">
            {platformSingleton.features.supportsMouse ? 'Double click to start typing' : 'Double tap to start typing'}
        </span>
    </div>
);

const mapEditorStateToProps = () =>
    createStructuredSelector({
        textContent: textContentSelector,
    });

const DocumentDetailView = (props) => {
    const {
        element,
        className,
        inList,
        gridSize,
        elementEvents,
        permissions,
        isPresentational,
        isEditing,
        isSelected,
        isClone,
        currentBoardId,
        textContent,
        titleElement,
        setParentHoveredChildAcceptsDrop,
        getContextZoomScale,
        getContextZoomTranslationPx,
    } = props;

    const elementId = getElementId(element);
    const colorName = getColor(element);

    const captionContent = getCaption(element);
    const captionVisible = getShowCaption(element);

    const hasContent = rawHasText(textContent);

    return (
        <div
            id={getDomElementId(elementId)}
            className={classNames('DocumentDetailView drag-handle contrast-shadow', className)}
            style={getElementStyle(ELEMENT_DEFAULT_WIDTH, gridSize, inList)}
            {...elementEvents}
        >
            {/* Disable when editing the caption so that clicking on the caption edits it immediately */}
            <HiddenInput {...props} disabled={isEditing} />
            <ElementUserTag {...props} />
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ColorBar colorName={colorName} isSelected={isSelected} />
            <DetailView
                className="document-content"
                inList={inList}
                gridSize={gridSize}
                previewElement={
                    <div className="document-preview">
                        {hasContent ? (
                            <DocumentEditor
                                {...props}
                                textContent={textContent}
                                isEditable={false}
                                editorId={getMainEditorId(props)}
                                editorKey={getMainEditorKey(props)}
                            />
                        ) : (
                            <EmptyDocumentPreview />
                        )}
                    </div>
                }
                iconElement={<FileIcon iconName="file-document" />}
                titleElement={titleElement}
                secondaryElement={<WordCount rawState={textContent} />}
            />
            <Caption
                {...props}
                onDoubleClick={stopPropagationOnly}
                placeholder="Add a description"
                textContent={captionContent}
                captionVisible={captionVisible}
            />
            <ElementReactions {...props} />
            <CardCloneSection isClone={isClone} elementId={elementId} element={element} />
            <AttachedCommentsDropTarget
                setParentHoveredChildAcceptsDrop={setParentHoveredChildAcceptsDrop}
                gridSize={gridSize}
                isPresentational={isPresentational}
                element={element}
                elementId={elementId}
                getContextZoomScale={getContextZoomScale}
            />
            <AttachedCommentsContainer
                gridSize={gridSize}
                elementId={elementId}
                element={element}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />
        </div>
    );
};

DocumentDetailView.propTypes = {
    element: PropTypes.object.isRequired,
    className: PropTypes.string,
    inList: PropTypes.string,
    gridSize: PropTypes.number,
    permissions: PropTypes.number,
    isPresentational: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isClone: PropTypes.bool,
    currentBoardId: PropTypes.string,
    elementEvents: PropTypes.object,
    textContent: PropTypes.object,
    isElementOpenInModal: PropTypes.bool,
    moveElementsToTrash: PropTypes.func,

    dispatchHandleArrowKeys: PropTypes.func,
    dispatchNavigateToElement: PropTypes.func,
    setParentHoveredChildAcceptsDrop: PropTypes.func,

    titleElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default connect(mapEditorStateToProps)(DocumentDetailView);
