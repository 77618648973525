// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { setHideCaption, setShowCaption } from './captionActions';
import { startEditingMainEditor } from '../../element/selection/selectionActions';

// Utils
import { analyticsEvent } from '../../analytics';
import { isCard, isColorSwatch } from '../../../common/elements/utils/elementTypeUtils';
import { getElementId, getElementType, getShowCaption } from '../../../common/elements/utils/elementPropertyUtils';
import { hasCommandModifier } from '../../utils/keyboard/keyboardUtility';
import { isElementBelowEditableWidth } from '../../element/utils/elementUtil';

// Constants
import { KEY_CODES, NON_CHARACTERS } from '../../utils/keyboard/keyConstants';
import { CAPTION_EDITOR_KEY } from './captionConstants';

export default (DecoratedComponent) => {
    // This component requires access to dispatch on its props
    @connect()
    class CaptionContainerDecorator extends React.Component {
        onEmptyBackspace = () => {
            const { isEditable, dispatch, element, stopEditing } = this.props;
            if (!isEditable) return;

            const elementId = getElementId(element);

            dispatch(setHideCaption({ elementId }));

            if (!isCard(element)) return stopEditing();

            dispatch(startEditingMainEditor(elementId));
        };

        captionKeyHandler = (event) => {
            const { stopEditing, startEditing, element, isEditing } = this.props;

            // Unfortunately have to handle the escape key separately as it's not causing a keypress event
            if (event.which === KEY_CODES.ESC) {
                stopEditing();
                return;
            }

            // Unfortunately also have to handle this case to prevent an issue.
            if (event.which === KEY_CODES.BACKSPACE) {
                event.preventDefault();
                return;
            }

            if (hasCommandModifier(event)) {
                return;
            }

            // don't trigger a start editing event when non-character keys are pressed
            if (NON_CHARACTERS.indexOf(event.which) !== -1) {
                return;
            }

            const showingCaption = getShowCaption(element);
            if (!showingCaption) {
                this.showCaption();
            }

            if (isEditing) {
                return;
            }

            const isBelowEditableWidth = isElementBelowEditableWidth(this.props);

            if (isBelowEditableWidth) {
                return;
            }

            startEditing({
                editorId: `${getElementId(element)}-${CAPTION_EDITOR_KEY}`,
                editorKey: CAPTION_EDITOR_KEY,
            });
        };

        saveCaptionContent = (caption, sync) => {
            const { element, isEditable, dispatchUpdateElement } = this.props;
            if (!isEditable) return;

            const changes = { caption };

            if (isColorSwatch(element)) {
                changes.captionModified = true;
            }

            dispatchUpdateElement({
                id: getElementId(element),
                changes,
                sync,
            });
        };

        showCaption = () => {
            const { element, isEditable, dispatch } = this.props;
            if (!isEditable) return;

            analyticsEvent(`added-${getElementType(element)}-caption`, { shortcut: true });

            dispatch(setShowCaption({ elementId: getElementId(element) }));
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    captionKeyHandler={this.captionKeyHandler}
                    saveCaptionContent={this.saveCaptionContent}
                    showCaption={this.showCaption}
                    onEmptyBackspace={this.onEmptyBackspace}
                />
            );
        }
    }

    CaptionContainerDecorator.propTypes = {
        element: PropTypes.object.isRequired,
        dispatchUpdateElement: PropTypes.func.isRequired,
        dispatch: PropTypes.func,
        stopEditing: PropTypes.func,
        startEditing: PropTypes.func,
        showCaption: PropTypes.func,
        isEditing: PropTypes.bool,
        isEditable: PropTypes.bool,
    };

    return CaptionContainerDecorator;
};
