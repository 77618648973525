export const BOARD_EXPORT_FORMATS = {
    canvas: 'canvas',
    linear: 'linear',
};

export const BOARD_EXPORT_QUALITY = {
    regular: 'regular',
    high: 'high',
};

export const BOARD_EXPORT_TYPES = {
    ELEMENT: 'ELEMENT',
    BOARD_MEDIA: 'BOARD_MEDIA',
};

export const BOARD_EXPORT_MEDIA = 'BOARD_EXPORT_MEDIA';

export const BOARD_EXPORT_MEDIA_RESET = 'BOARD_EXPORT_MEDIA_RESET';
