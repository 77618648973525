// Utils
import { prop, propIn } from '../utils/immutableHelper';
import { MNSubscriptionProvider } from './subscriptions/subscriptionTypes';

// Subscription
export const getSubscriptionId = propIn(['_id']);
export const getSubscriptionAdminId = propIn(['adminId']);
export const getSubscriptionProvider = (subscription) =>
    // Default to STRIPE to cater for old subscriptions (only when subscription is defined)
    subscription && (propIn(['provider'], subscription) || MNSubscriptionProvider.STRIPE);
export const getSubscriptionStatus = propIn(['status']);
export const getSubscriptionCanceled = prop('canceled');
export const getSubscriptionTeamMemberCount = propIn(['teamMemberCount']);

// Subscription Plan
export const getSubscriptionPlanName = propIn(['plan', 'name']);
export const getSubscriptionPlanQuantity = propIn(['plan', 'quantity']);
export const getSubscriptionPlanMaxSeats = propIn(['plan', 'maxSeats']);
export const getSubscriptionPlanStatus = propIn(['plan', 'status']);
export const getSubscriptionPlanActive = propIn(['plan', 'active']);
export const getSubscriptionPlanCreatedAt = propIn(['plan', 'createdAt']);
export const getSubscriptionPlanRenewAt = propIn(['plan', 'renewAt']);

export const getSubscriptionPlanCancelAtPeriodEnd = propIn(['plan', 'cancelStatus', 'cancelAtPeriodEnd']);
export const getSubscriptionPlanCancelAt = propIn(['plan', 'cancelStatus', 'cancelAt']);
export const getSubscriptionPlanCanceledAt = propIn(['plan', 'cancelStatus', 'canceledAt']);

export const getSubscriptionPlanProductPricingScheme = propIn(['plan', 'product', 'pricingScheme']);
export const getSubscriptionPlanProductHideTeamMembers = propIn([
    'plan',
    'product',
    'settings',
    'hideNonAdminTeamMembers',
]);

export const getSubscriptionPlanProductId = propIn(['plan', 'product', '_id']);
export const getSubscriptionPlanPriceId = propIn(['plan', 'price', '_id']);
export const getSubscriptionPlanPriceAmount = propIn(['plan', 'price', 'amount']);
export const getSubscriptionPlanPriceInterval = propIn(['plan', 'price', 'interval']);
export const getSubscriptionPlanPriceCurrency = propIn(['plan', 'price', 'currency']);
export const getSubscriptionPlanProviderPriceId = propIn(['plan', 'price', 'providerPriceId']);

// Subscription Team
export const getSubscriptionTeamName = propIn(['team', 'name']);
export const getSubscriptionTeamAutoAddUsers = propIn(['team', 'autoAddUsers']);
export const getSubscriptionTeamDomains = propIn(['team', 'domains']);
export const getSubscriptionTeamAutoAddDuration = propIn(['team', 'autoAddDuration']);
export const getSubscriptionTeamAutoAddMaxRenewals = propIn(['team', 'autoAddMaxRenewals']);

// Stripe Subscription Only
export const getStripeSubscriptionDetailCustomerId = propIn(['detail', 'customer']);
export const getStripeSubscriptionDetailStart = propIn(['detail', 'start']);

// Subscription Status
export const getSubscriptionStatusActive = (subscriptionStatus) => propIn(['active'], subscriptionStatus);

/**
 * Legacy methods to get the plan details for a subscription.
 * Used in legacy subscription payment forms — can be removed after CheckoutV2 is released
 * TODO-CHECKOUT: Remove these functions after CheckoutV2 is released
 */

/** @deprecated */
export const getSubscriptionPlan = propIn(['detail', 'plan']);

/** @deprecated */
// Note  - Based on stripe data, team plans are on fixed frequency
export const getPlanFixedFrequency = propIn(['metadata', 'fixed_frequency']);

/** @deprecated */
export const getPlanType = propIn(['metadata', 'plan_type']);

/** @deprecated */
export const getPlanStoreSavePercent = propIn(['metadata', 'store_save_text']);

/** @deprecated */
export const getPlanHideFromCheckout = propIn(['metadata', 'hide_from_checkout']);

/** @deprecated */
export const getSubscriptionMaxUnits = (subscription) => {
    if (!subscription) return 0;

    const planMaxUnits = getPlanMaxUnits(getSubscriptionPlan(subscription));
    if (planMaxUnits) return Number(planMaxUnits);

    return propIn(['detail', 'quantity'], subscription) || 1;
};

/** @deprecated */
export const getStripeSubscriptionStart = prop('current_period_start');

/** @deprecated */
export const getStripeSubscriptionEnd = prop('current_period_end');

/** @deprecated */
export const getStripeCancelAtPeriodEnd = prop('cancel_at_period_end');

/** @deprecated */
export const getPlanInterval = prop('interval');

/** @deprecated */
export const getPlanMaxUnits = (plan) => {
    const planMaxUnits = propIn(['metadata', 'max_units'], plan);
    if (planMaxUnits) return Number(planMaxUnits);
};

/** @deprecated */
const getPlanDisplayName = propIn(['metadata', 'display_name']);

/** @deprecated */
const getPlanDefaultName = propIn(['name']);

/** @deprecated */
export const getPlanName = (plan) => getPlanDisplayName(plan) || getPlanDefaultName(plan);
