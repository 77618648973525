// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash/fp';

// Utils
import rafThrottle from '../../../../../../common/utils/lib/rafThrottle';
import { setColorTargetTransitionEnabled } from './setTemporarySelectedElementsColorStyles';
import { hasChanged } from '../../../../../utils/react/propsComparisons';

// Components
import logger from '../../../../../logger/logger';
import { OPEN_COLOR_PICKER_EVENT_NAME } from './OpenColorPickerEvent';

const DEFAULT_COLOUR = '#ffffff';

const initialColorHasChanged = hasChanged('initialColor');
const openerEventTargetHasChanged = hasChanged('openerEventTarget');

class CustomColorInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            color: this.props.initialColor,
        };

        this.inputRef = React.createRef();
        this.props.openerEventTarget?.addEventListener(OPEN_COLOR_PICKER_EVENT_NAME, this.open);
    }

    componentWillMount() {
        this.hasChanged = false;
        this.mounted = true;
        this.allowTransition = true;
    }

    componentWillReceiveProps(nextProps) {
        if (initialColorHasChanged(this.props, nextProps)) {
            this.setState({ color: nextProps.initialColor });
        }

        if (openerEventTargetHasChanged(this.props, nextProps)) {
            this.props.openerEventTarget?.removeEventListener(OPEN_COLOR_PICKER_EVENT_NAME, this.open);
            nextProps.openerEventTarget?.addEventListener(OPEN_COLOR_PICKER_EVENT_NAME, this.open);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onInput = (event) => {
        const color = get(['target', 'value'], event);
        this.setState({ color });
        this.throttledOnColorChange(color);
    };

    onColorChange = (color) => {
        if (!color) return;

        if (this.allowTransition) {
            this.allowTransition = false;
            setColorTargetTransitionEnabled(this.allowTransition);
        }

        this.hasChanged = true;

        this.props.setTempColor && this.props.setTempColor(color);

        // Add a mousedown event listener that will trigger the commit if the value is changed
        // This is a hack around the fact that Chrome doesn't use the "onChange" event when the picker is closed.
        this.attachCommitListener();
    };

    throttledOnColorChange = rafThrottle(this.onColorChange);

    /**
     * NOTE: This is left here to avoid React warnings about having a controlled component without
     *       an onChange handler.
     */
    onChange = (event) => {
        // Do nothing here because it's not consistently supported between Firefox and Chrome
        // Firefox correctly calls this when the colour picker is dismissed.
        // Chrome calls this at the same time as onInput is called
        // Thus we need another way of determining when the
    };

    onFocus = () => {
        this.props.onFocus?.(this.state.color);
    };

    onBlur = () => {
        this.props.onBlur?.(this.state.color);
    };

    attachCommitListener = () => {
        // If already attached, ignore
        if (this.commitListenerAttached) return;

        this.commitListenerAttached = true;
        document.addEventListener('pointerdown', this.commitChange, true);
        document.addEventListener('mousedown', this.commitChange, true);
        document.addEventListener('keydown', this.commitChange, true);
    };

    removeCommitListener = () => {
        if (!this.commitListenerAttached) return;

        this.commitListenerAttached = false;
        document.removeEventListener('pointerdown', this.commitChange, true);
        document.removeEventListener('mousedown', this.commitChange, true);
        document.removeEventListener('keydown', this.commitChange, true);
    };

    commitChange = () => {
        this.removeCommitListener();

        const { color } = this.state;
        const { setColor } = this.props;

        if (!this.allowTransition) {
            this.allowTransition = true;
            setColorTargetTransitionEnabled(this.allowTransition);
        }

        if (!this.hasChanged) return;

        this.hasChanged = false;

        setColor?.(color);
    };

    open = () => {
        if (!this.inputRef || !this.inputRef.current) {
            logger.warn('The CustomColorPicker cannot be opened because its ref is null');
            return;
        }

        this.inputRef.current.focus();
        this.inputRef.current.click();

        this.props.onOpen?.();
    };

    render() {
        const { color } = this.state;
        const { className } = this.props;

        return (
            <input
                ref={this.inputRef}
                type="color"
                className={classNames('CustomColorInput', className)}
                onInput={this.onInput}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                value={color || DEFAULT_COLOUR}
            />
        );
    }
}

CustomColorInput.propTypes = {
    className: PropTypes.string,
    initialColor: PropTypes.string,
    setColor: PropTypes.func,
    setTempColor: PropTypes.func,
    onOpen: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    openerEventTarget: PropTypes.object,
};

export default CustomColorInput;
