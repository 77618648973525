import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../../../../../components/buttons/Button';
import Icon from '../../../../../../components/icons/Icon';

import { COLOR_POPUP_MODE } from './colorPopupConstants';

import './ColorPopupModeSwitcher.scss';

const ColorPopupModeSwitcher = ({ colorPopupMode, setColorPopupMode }) => (
    <div className="ColorPopupModeSwitcher">
        <Button
            className={classNames('color-mode-button', { active: colorPopupMode === COLOR_POPUP_MODE.BACKGROUND })}
            onClick={() => setColorPopupMode(COLOR_POPUP_MODE.BACKGROUND)}
        >
            <Icon name="toolbar-color-mode-background" />
            Background
        </Button>
        <Button
            className={classNames('color-mode-button', { active: colorPopupMode !== COLOR_POPUP_MODE.BACKGROUND })}
            onClick={() => setColorPopupMode(COLOR_POPUP_MODE.COLOR)}
        >
            <Icon name="toolbar-color-mode-bar" />
            Top strip
        </Button>
    </div>
);

ColorPopupModeSwitcher.propTypes = {
    colorPopupMode: PropTypes.string,
    setColorPopupMode: PropTypes.func,
};

export default ColorPopupModeSwitcher;
