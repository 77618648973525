// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { get, difference, intersection } from 'lodash/fp';

// Components
import GroupedToolList from './GroupedToolList';
import MoreTool from './MoreTool';

// Utils
import { getVisibleTools } from './collapsableToolsUtils';

// Constants
import { MORE_TOOL } from '../config/primaryToolsConstants';

// Styles
import './CollapsableTools.scss';

const CollapsableTools = (props) => {
    const { availableTools, toolsConfig, collapseOrder, gridSizeName, availableHeight, unavailableHeight } = props;

    const hasConfiguredMoreTool = availableTools.some((toolName) => toolName === MORE_TOOL);

    const visibleTools = getVisibleTools({
        availableTools,
        collapseOrder,
        toolsConfig,
        gridSizeName,
        availableHeight,
        unavailableHeight,
        hasConfiguredMoreTool,
    });

    const orderedVisibleTools = intersection(visibleTools, availableTools);
    const collapsedTools = difference(availableTools, visibleTools).filter((toolName) => {
        const hideWhenCollapsed = get([toolName, 'hideWhenCollapsed'], toolsConfig);
        return !hideWhenCollapsed;
    });

    return (
        <div className="CollapsableTools">
            <GroupedToolList
                {...props}
                collapsedTools={collapsedTools}
                toolsConfig={toolsConfig}
                className="tools"
                tools={orderedVisibleTools}
            >
                {hasConfiguredMoreTool ? null : (
                    <MoreTool {...props} toolsConfig={toolsConfig} collapsedTools={collapsedTools} />
                )}
            </GroupedToolList>
        </div>
    );
};

CollapsableTools.propTypes = {
    moreClassName: PropTypes.string,

    toolsId: PropTypes.string.isRequired,
    toolsConfig: PropTypes.object.isRequired,
    availableTools: PropTypes.array.isRequired,
    collapseOrder: PropTypes.array,

    gridSizeName: PropTypes.string.isRequired,
    unavailableHeight: PropTypes.number,
    availableHeight: PropTypes.number,
};

export default CollapsableTools;
