// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import DualColorIcon from './DualColorIcon';

// Styles
import './ColorToolIcon.scss';

const ColorToolIcon = ({
    className,
    color,
    backgroundColor,
    isActive,
    selectedType,
    selectedStatus,
    supportColorBar,
    supportBackgroundColor,
    secondaryColor,
}) => {
    const background = supportColorBar ? backgroundColor : color;

    const showColorBar = supportColorBar && color;

    // We should never have a secondary color without a background color.
    // This check is just to prevent a bug where clones don't show background colors but do show the secondary color.
    const showDualColor = !!secondaryColor && background;

    return (
        <div
            className={classNames('ColorToolIcon', selectedType, selectedStatus, className, {
                active: isActive,
                'no-color': !color,
                'show-color-bar': showColorBar,
                'show-dual-color': showDualColor,
                'support-color-bar': supportColorBar,
                'support-background-color': supportBackgroundColor,
            })}
        >
            <div
                className={classNames('color-icon-container', {
                    'background-color-target': supportBackgroundColor,
                    'color-target': !supportBackgroundColor,
                })}
                style={{ backgroundColor: background }}
            >
                {showColorBar && (
                    <div
                        className={classNames('color-bar-icon color-target', {
                            'background-color-target': !color,
                        })}
                        style={{ backgroundColor: color }}
                    ></div>
                )}

                {showDualColor && (
                    <DualColorIcon
                        secondaryColor={secondaryColor}
                        selectedType={selectedType}
                        transformStyle={'rotate(-52deg)'}
                    />
                )}
            </div>
        </div>
    );
};

ColorToolIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    secondaryColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    backgroundColor: PropTypes.string,
    backgroundColorName: PropTypes.string,
    isActive: PropTypes.bool,
    supportColorBar: PropTypes.bool,
    supportBackgroundColor: PropTypes.bool,
    selectedType: PropTypes.string,
    selectedStatus: PropTypes.string,
};

export default ColorToolIcon;
