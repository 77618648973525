// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';

// Utils
import { getEditorState } from '../../../../../../components/editor/store/reducers/editorStoreSelector';
import editorStoreConnect from '../../../../../../components/editor/store/components/editorStoreConnect';
import editorGetCurrentBlock from '../../../../../../components/editor/customRichUtils/editorState/editorGetCurrentBlock';
import { editorStyleChange } from '../../../../../../components/editor/store/editorActions';
import editorGetCurrentInlineStyle from '../../../../../../components/editor/customRichUtils/editorState/editorGetCurrentInlineStyle';
import { getAllCellsBetween } from '../../../../../../element/table/utils/tableCellSelectionUtils';
import {
    getBackgroundColor,
    getElementId,
    getSecondaryColor,
    getTableContentCellTextStyle,
} from '../../../../../../../common/elements/utils/elementPropertyUtils';
import { setCustomColorThunk } from './textColorActions';
import { getCurrentCellSelections } from '../../../../../../element/table/tableSelector';
import { getColorCssValue, getCssPropertyForTextOnColor } from '../../../../../../element/utils/elementColorStyleUtils';
import { isCard } from '../../../../../../../common/elements/utils/elementTypeUtils';

// Components
import TextColorButton from './TextColorButton';
import TextStyleEntry from './TextStyleEntry';

// Constants
import { STYLE_COMMANDS } from '../../../../../../components/editor/richText/richTextConstants';
import {
    BACKGROUND_COLOR_PREFIX,
    TEXT_COLOR_PREFIX,
} from '../../../../../../components/editor/plugins/textColor/textColorConstants';
import { TABLE_GRID_EDITOR_KEY } from '../../../../../../../common/table/tableConstants';

// Styles
import './TextStyleRichTextToolPopupContent.scss';
import HotTableManager from '../../../../../../element/table/manager/HotTableManager';

/**
 * Get default text and background colors for an element, so that we can show
 * the text colour on top of the actual background colour that it will be on.
 * @param element
 * @returns {{defaultTextColor: string, defaultBackgroundColor: string}|null}
 */
const getElementDefaultColors = (element) => {
    // Currently the only elements that have both background colours and text tools are cards
    // (and table cells, but that can be added at a later date).
    // sketch captions also have text tools, but the background colour isn't applied to the caption itself.
    if (!isCard(element)) return null;

    // If card has no background color, return null and use default values
    const backgroundColor = getBackgroundColor(element);
    if (!backgroundColor) return null;

    const backgroundColorCss = getColorCssValue(backgroundColor);
    const secondaryColor = getSecondaryColor(element);

    if (secondaryColor) {
        const secondaryColorCss = getColorCssValue(secondaryColor);
        return { defaultTextColor: secondaryColorCss, defaultBackgroundColor: backgroundColorCss };
    }

    // If card has no secondary color, use default text color based on background color
    const defaultTextColor = getCssPropertyForTextOnColor(backgroundColor, true);
    return { defaultTextColor, defaultBackgroundColor: backgroundColorCss };
};

const TextStyleRichTextToolPopupContentComponent = ({ showBlockStyleTools, ...rest }) => {
    const { defaultTextColor, defaultBackgroundColor } = getElementDefaultColors(rest.element) || {};

    return (
        <div className={classNames('TextStyleRichTextToolPopupContent', { 'color-only': !showBlockStyleTools })}>
            {showBlockStyleTools && (
                <div className="block-styles">
                    <TextStyleEntry
                        {...rest}
                        name="Large heading"
                        shortcut={['command', 'shift', '1']}
                        entryBlockType={STYLE_COMMANDS.LARGE_HEADING}
                    />
                    <TextStyleEntry
                        {...rest}
                        name="Normal heading"
                        shortcut={['command', 'shift', '2']}
                        entryBlockType={STYLE_COMMANDS.HEADING}
                    />
                    <TextStyleEntry
                        {...rest}
                        name="Normal text"
                        shortcut={['command', 'shift', '0']}
                        otherBlockTypes={[
                            STYLE_COMMANDS.ORDERED_LIST,
                            STYLE_COMMANDS.LIST,
                            STYLE_COMMANDS.CHECKLIST,
                            null,
                        ]}
                        entryBlockType={STYLE_COMMANDS.UNSTYLED}
                    />
                    <TextStyleEntry
                        {...rest}
                        name="Small text"
                        shortcut={['command', 'shift', '9']}
                        entryBlockType={STYLE_COMMANDS.SMALL_TEXT}
                    />
                    <TextStyleEntry
                        {...rest}
                        name="Code block"
                        shortcut={['command', '>']}
                        entryBlockType={STYLE_COMMANDS.CODE_BLOCK}
                    />
                    <TextStyleEntry
                        {...rest}
                        name='"Quote block"'
                        shortcut={['command', '"']}
                        entryBlockType={STYLE_COMMANDS.BLOCKQUOTE}
                    />
                </div>
            )}

            <div className="text-color-section color">
                <div className="text-color-section-title">Color</div>
                <div className="text-color-row">
                    <TextColorButton
                        {...rest}
                        preset={null}
                        customTextColor={defaultTextColor}
                        customBackgroundColor={defaultBackgroundColor}
                    />
                    <TextColorButton
                        {...rest}
                        preset={`${TEXT_COLOR_PREFIX}-blue`}
                        customBackgroundColor={defaultBackgroundColor}
                    />
                    <TextColorButton
                        {...rest}
                        preset={`${TEXT_COLOR_PREFIX}-green`}
                        customBackgroundColor={defaultBackgroundColor}
                    />
                    <TextColorButton
                        {...rest}
                        preset={`${TEXT_COLOR_PREFIX}-orange`}
                        customBackgroundColor={defaultBackgroundColor}
                    />
                    <TextColorButton
                        {...rest}
                        preset={`${TEXT_COLOR_PREFIX}-red`}
                        customBackgroundColor={defaultBackgroundColor}
                    />
                    <TextColorButton
                        {...rest}
                        preset={`${TEXT_COLOR_PREFIX}-grey`}
                        customBackgroundColor={defaultBackgroundColor}
                    />
                </div>
            </div>

            <div className="text-color-section background">
                <div className="text-color-section-title">Highlight</div>
                <div className="text-color-row">
                    <TextColorButton {...rest} preset={`${BACKGROUND_COLOR_PREFIX}-dark-grey`} />
                    <TextColorButton {...rest} preset={`${BACKGROUND_COLOR_PREFIX}-blue`} />
                    <TextColorButton {...rest} preset={`${BACKGROUND_COLOR_PREFIX}-green`} />
                    <TextColorButton {...rest} preset={`${BACKGROUND_COLOR_PREFIX}-orange`} />
                    <TextColorButton {...rest} preset={`${BACKGROUND_COLOR_PREFIX}-pink`} />
                    <TextColorButton {...rest} preset={`${BACKGROUND_COLOR_PREFIX}-grey`} />
                </div>
            </div>
        </div>
    );
};

TextStyleRichTextToolPopupContentComponent.propTypes = {
    blockType: PropTypes.string,
    onTextStyleClick: PropTypes.func,
    showBlockStyleTools: PropTypes.bool,
};

/*************************************************
 * Create enhancer for when an editor is focused
 */

const mapEditorStateToProps = (state) => {
    const editorState = getEditorState(state);

    let blockType = editorState ? editorGetCurrentBlock(editorState).getType() : null;

    return {
        blockType,
        currentStyle: editorGetCurrentInlineStyle(editorState),
    };
};

const mapEditorDispatchToProps = (dispatch) => ({
    onTextStyleClick: (styleCommand) => dispatch(editorStyleChange({ styleCommand })),
    onTextColorClick: (colorStyle) =>
        dispatch(
            setCustomColorThunk({
                colorStyle,
                addToUndoStack: true,
                showPseudoSelection: false,
            }),
        ),
});

const enhanceWithEditorState = editorStoreConnect(mapEditorStateToProps, mapEditorDispatchToProps);
const EditorTextStyleRichTextToolPopupContentComponent = enhanceWithEditorState(
    TextStyleRichTextToolPopupContentComponent,
);

/*****************************************************
 * Create enhancer for when an table grid is focused
 */

const mapTableGridStateToProps = (state, ownProps) => {
    const { element } = ownProps;

    const currentCellSelections = getCurrentCellSelections(state, ownProps);

    const selectedCellsCoords = getAllCellsBetween(currentCellSelections);
    const firstSelectedCellCoords = selectedCellsCoords.shift();

    if (!firstSelectedCellCoords) return Immutable.OrderedSet();

    const firstSelectedCellTextStyles = Immutable.OrderedSet(
        getTableContentCellTextStyle(firstSelectedCellCoords)(element),
    );

    const currentStyle = selectedCellsCoords.reduce(
        (acc, cellCoords) => acc.intersect(getTableContentCellTextStyle(cellCoords)(element)),
        firstSelectedCellTextStyles,
    );

    return { currentStyle };
};

const mapTableGridDispatchToProps = (dispatch, ownProps) => ({
    onTextColorClick: (colorStyle) => {
        const { element } = ownProps;
        const hotTableInstance = HotTableManager.getHotTableComponent(getElementId(element));

        hotTableInstance.tableOperationsRef.current.applyTextStyle(colorStyle);
    },
});

const enhanceWithTableGridState = connect(mapTableGridStateToProps, mapTableGridDispatchToProps);
const TableGridTextStyleRichTextToolPopupContentComponent = enhanceWithTableGridState(
    TextStyleRichTextToolPopupContentComponent,
);

/***************************************************************
 * Choose which enhancer to use depending on current editor key
 */

const TextStyleRichTextToolPopupContent = (props) => {
    const { editorKey, item } = props;
    const showBlockStyleTools = item?.toolSettings?.showBlockStyleTools || props.showBlockStyleTools;

    if (editorKey === TABLE_GRID_EDITOR_KEY) {
        return (
            <TableGridTextStyleRichTextToolPopupContentComponent {...props} showBlockStyleTools={showBlockStyleTools} />
        );
    }

    return <EditorTextStyleRichTextToolPopupContentComponent {...props} showBlockStyleTools={showBlockStyleTools} />;
};

TextStyleRichTextToolPopupContent.propTypes = {
    showBlockStyleTools: PropTypes.bool,
    editorKey: PropTypes.string,
    item: PropTypes.object,
};

export default TextStyleRichTextToolPopupContent;
