// Lib
import { defer, isEmpty, trim } from 'lodash';

// Utils
import { isCard } from '../../../common/elements/utils/elementTypeUtils';
import { getDisplayMode, getTextContent } from '../../../common/elements/utils/elementPropertyUtils';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';

// Selectors
import { getElements } from '../selectors/elementSelector';
import rawGetFirstLine from '../../../common/utils/editor/rawUtils/rawGetFirstLine';

// Actions
import { setElementTypeAndUpdateElement } from '../actions/elementActions';
import { startEditingElement } from '../selection/selectionActions';

// Constants
import {
    DOCUMENT_DEFAULT_TITLE,
    DOCUMENT_TITLE_EDITOR_KEY,
    DOCUMENT_TITLE_CHAR_LIMIT,
} from '../../../common/documents/documentConstants';
import { ELEMENT_DISPLAY_MODES } from '../../../common/elements/elementDisplayModeConstants';
import { UNICODE } from '../../../common/utils/stringConstants';
import { ElementType } from '../../../common/elements/elementTypes';

export const convertElementsToDocument = (elementIds) => (dispatch, getState) => {
    const state = getState();

    if (isEmpty(elementIds)) return null;

    const transactionId = getNewTransactionId();

    const elements = getElements(state);

    elementIds.forEach((elementId) => {
        const element = elements.get(elementId);
        const textContent = getTextContent(element);
        const firstLine = trim(rawGetFirstLine(textContent)) || '';

        // if the first line is longer than 60 characters,
        // use the first 59 characters and add the ellipsis character for a total of 60 chars
        const documentTitle =
            firstLine.length > DOCUMENT_TITLE_CHAR_LIMIT
                ? firstLine.substring(0, DOCUMENT_TITLE_CHAR_LIMIT - 1).concat(UNICODE.ELLIPSIS)
                : firstLine;

        if (!isCard(element)) return null;

        dispatch(
            setElementTypeAndUpdateElement({
                id: elementId,
                elementType: ElementType.DOCUMENT_TYPE,
                changes: {
                    title: documentTitle || DOCUMENT_DEFAULT_TITLE,
                    displayMode: getDisplayMode(element) || ELEMENT_DISPLAY_MODES.ICON_VIEW,
                },
                transactionId,
            }),
        );
    });

    // Start editing document title upon conversion
    defer(() =>
        dispatch(
            startEditingElement({
                id: elementIds[0],
                editorKey: DOCUMENT_TITLE_EDITOR_KEY,
            }),
        ),
    );
};
