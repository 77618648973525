// Lib
import { compose, defaultProps } from '../../../../../node_module_clones/recompose';

// Components
import poiDecorator from '../../../pointsOfInterest/poiDecorator';

// Constants
import { POI_TYPES } from '../../../pointsOfInterest/poiConstants';

export default compose(
    defaultProps({
        poiType: POI_TYPES.SEARCH,
        poiData: {
            count: 1,
        },
    }),
    poiDecorator,
);
