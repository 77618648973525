// Lib
import React from 'react';
import loadable from 'react-loadable';

// Components
import Spinner from '../../../components/loaders/Spinner';

const EmptyLoader = () => null;
const SpinnerLoader = () => <Spinner className="SettingsContentLoadingSpinner" show />;

export const LoadableAccountDetails = loadable({
    loader: () => import(/* webpackChunkName: "account-details" */ './settings/AccountDetailsSettings'),
    loading: SpinnerLoader,
});

export const LoadableAccountSettingsDeleteAccount = loadable({
    loader: () => import(/* webpackChunkName: "account-settings-delete" */ './settings/AccountSettingsDeleteAccount'),
    loading: SpinnerLoader,
});

export const LoadableAccountNotifications = loadable({
    loader: () => import(/* webpackChunkName: "account-notifications" */ './notifications/AccountNotificationSettings'),
    loading: SpinnerLoader,
});

export const LoadableAccountPlanContainer = loadable({
    loader: () => import(/* webpackChunkName: "account-plan-container" */ './plan/AccountPlanContainer'),
    loading: SpinnerLoader,
});

export const LoadableAccountPlan = loadable({
    loader: () => import(/* webpackChunkName: "account-plan-summary" */ './plan/summary/AccountPlanSummary'),
    loading: SpinnerLoader,
});

export const LoadableAccountPlanCardSettings = loadable({
    loader: () => import(/* webpackChunkName: "account-plan-card" */ './plan/card/AccountPlanCardSettings'),
    loading: SpinnerLoader,
});

export const LoadableAccountPlanPaymentSettings = loadable({
    loader: () => import(/* webpackChunkName: "account-plan-payment" */ './plan/payment/AccountPlanPaymentSettings'),
    loading: SpinnerLoader,
});

export const LoadableAccountPlanTeamSettings = loadable({
    loader: () => import(/* webpackChunkName: "account-plan-team" */ './plan/team/AccountPlanTeamSettings'),
    loading: SpinnerLoader,
});

export const LoadableAccountInvoiceListing = loadable({
    loader: () => import(/* webpackChunkName: "account-plan-team" */ './plan/invoices/AccountPlanInvoiceListing'),
    loading: SpinnerLoader,
});

export const LoadableAccountPlanUnsubscribeSettings = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "account-plan-unsubscribe" */
            './plan/unsubscribe/AccountPlanUnsubscribeSettings'
        ),
    loading: SpinnerLoader,
});

export const LoadableAccountPlanResubscribeSettings = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "account-plan-resubscribe" */
            './plan/resubscribe/AccountPlanResubscribeSettings'
        ),
    loading: SpinnerLoader,
});

export const LoadableAccountPlanImmediateDowngradeSettings = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "account-immediate-downgrade" */
            './plan/immediateDowngrade/ImmediateDowngradeSettings'
        ),
    loading: SpinnerLoader,
});

export const LoadableAccountPlanSelectTypeSettings = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "account-plan-select-type" */
            './plan/selectType/AccountPlanSelectTypeSettings'
        ),
    loading: SpinnerLoader,
});

export const LoadableAccountInterface = loadable({
    loader: () => import(/* webpackChunkName: "account-appearance" */ './interface/AccountAppearanceSettings'),
    loading: SpinnerLoader,
});

export const LoadableAccountPreferences = loadable({
    loader: () => import(/* webpackChunkName: "account-preferences" */ './preferences/AccountPreferencesSettings'),
    loading: SpinnerLoader,
});

export const LoadableLocalisationSettings = loadable({
    loader: () => import(/* webpackChunkName: "account-localisation" */ './localisation/AccountLocalisationSettings'),
    loading: SpinnerLoader,
});

export const LoadableToolbarOptions = loadable({
    loader: () => import(/* webpackChunkName: "toolbar-options" */ './toolbarOptions/ToolbarSettings'),
    loading: SpinnerLoader,
});

export const LoadablePrivacyPreferences = loadable({
    loader: () => import(/* webpackChunkName: "account-privacy" */ './privacy/AccountPrivacySettings'),
    loading: SpinnerLoader,
});

export const LoadableAccountModalRoot = loadable({
    loader: () => import(/* webpackChunkName: "account-modal" */ './AccountModalRoot'),
    loading: EmptyLoader,
});

export const LoadablePlanModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "plan-modal" */
            '../planModal/PlanModal'
        ),
    loading: EmptyLoader,
});

export const preloadAllAccountModalContent = () => {
    LoadableAccountDetails.preload();
    LoadableAccountSettingsDeleteAccount.preload();
    LoadableAccountNotifications.preload();
    LoadableAccountPlan.preload();
    LoadableAccountPlanCardSettings.preload();
    LoadableAccountPlanPaymentSettings.preload();
    LoadableAccountPlanTeamSettings.preload();
    LoadableAccountPlanUnsubscribeSettings.preload();
    LoadableAccountPlanResubscribeSettings.preload();
    LoadableAccountPlanResubscribeSettings.preload();
    LoadableAccountPlanSelectTypeSettings.preload();
    LoadableAccountInterface.preload();
    LoadableAccountPreferences.preload();
    LoadablePlanModal.preload();
    LoadableAccountInvoiceListing.preload();
    LoadableToolbarOptions.preload();
};
