import loadable from 'react-loadable';
import { preloadAllAccountModalContent } from '../accountModal/LoadableAccountModalComponents';

export const LoadableUpgradeModal = loadable({
    loader: () => import(/* webpackChunkName: "upgrade-plan" */ './upgrade/UpgradePlanModal'),
    loading: () => null,
});

export const LoadableReferModal = loadable({
    loader: () => import(/* webpackChunkName: "refer-sheet" */ './referral/ReferModal'),
    loading: () => null,
});

export const preloadAllUpgradeModalContent = () => {
    LoadableUpgradeModal.preload();
    LoadableReferModal.preload();
    preloadAllAccountModalContent();
};
