// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import Tool from './Tool';

// Styles
import './ToolbarTool.scss';

const ToolbarTool = (props) => (
    <Tool {...props} className={classNames('ToolbarTool', props.className, { open: props.isOpen })} />
);

ToolbarTool.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
};

export default ToolbarTool;
