export const CAPACITOR_MN_TOKEN_PLUGIN_NAME = 'MNToken';
export const CAPACITOR_APPLE_PENCIL_PLUGIN_NAME = 'ApplePencil';
export const CAPACITOR_EDIT_ACTION_PLUGIN_NAME = 'EditAction';
export const CAPACITOR_RICH_TEXT_TOOLBAR_PLUGIN_NAME = 'RichTextToolbar';
export const CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_APPLE_PLUGIN_NAME = 'SignInWithAppleNativeApple';
export const CAPACITOR_SIGN_IN_WITH_APPLE_NATIVE_ANDROID_PLUGIN_NAME = 'SignInWithAppleNativeAndroid';
export const CAPACITOR_SIGN_IN_WITH_GOOGLE_PLUGIN_NAME = 'SignInWithGoogle';
export const CAPACITOR_INTERCOM_PLUGIN_NAME = 'Intercom';
export const CAPACITOR_IN_APP_PURCHASES_PLUGIN_NAME = 'InAppPurchases';
export const CAPACITOR_KEYBOARD_SHORTCUT_NAME = 'KeyboardShortcut';
export const CAPACITOR_IMAGE_PRESENTER_PLUGIN = 'ImagePresenter';
