import React from 'react';
import classNames from 'classnames';

import { ElementType } from '../../../../../../common/elements/elementTypes';

import './DualColorIcon.scss';

enum IconType {
    DIAGONAL_HALF = 'diagonal-half',
    LETTER = 'letter',
}

const getIconType = (selectedType: string) => {
    if (selectedType === ElementType.CARD_TYPE) {
        return IconType.LETTER;
    }

    return IconType.DIAGONAL_HALF;
};

type DualColorSwatchIconProps = {
    secondaryColor: string;
    selectedType: string;
    transformStyle?: string;
};

const DualColorIcon = (props: DualColorSwatchIconProps) => {
    const { secondaryColor, selectedType, transformStyle } = props;

    const iconType = getIconType(selectedType);

    return (
        <div className={classNames('DualColorIcon', iconType)}>
            {iconType === IconType.DIAGONAL_HALF && (
                <div className="diagonal-half-icon-container">
                    <div
                        className="secondary-color diagonal-half-icon"
                        style={{ backgroundColor: secondaryColor, transform: transformStyle }}
                    />
                </div>
            )}

            {iconType === IconType.LETTER && (
                <div className="secondary-color letter-icon" style={{ color: secondaryColor }}>
                    A
                </div>
            )}
        </div>
    );
};

export default DualColorIcon;
