// Lib
import Handsontable from 'handsontable/base';
import { BasePlugin } from 'handsontable/plugins';
import Core from 'handsontable/core';
import { cloneDeep, isNil } from 'lodash';

// Utils
import rawGetText from '../../../../common/utils/editor/rawUtils/rawGetText';
import {
    getAllCellsBetween,
    getFirstSelection,
    getStartCol,
    getStartRow,
    isSelectingRefColHeader,
} from '../utils/tableCellSelectionUtils';
import MilanoteEditingCleanupPlugin, { MILANOTE_EDITING_CLEANUP_PLUGIN_NAME } from './MilanoteEditingCleanupPlugin';
import { getRenderCellValueFromRawHotCellValue } from '../utils/tableCellFormattingUtils';
import { getColumnCount } from '../utils/tableDataUtils';
import hyperFormulaInstance from '../manager/hyperFormulaInstance';
import { isFormula } from '../utils/tableFormulaUtils';
import { stringShouldFormatAsType } from '../../../../common/table/utils/tableInputGeneralUtils';
import { isCellTypeCurrency, isCellTypePercentage } from '../../../../common/table/utils/tableCellDataPropertyUtils';

// Types
import { STYLE_COMMANDS } from '../../../components/editor/richText/richTextConstants';
import { CellCoordsObj } from '../../../../common/table/TableTypes';
import { parseCellContentString } from '../../../../common/table/utils/tableCellContentStringUtils';

export const MILANOTE_EDITING_PLUGIN_NAME = 'milanoteEditing';
export const MILANOTE_EDITING_PLUGIN_PRIORITY = 1;
const SHORTCUTS_GROUP_MILANOTE_CELL_FORMATTING = 'MilanoteEditingPlugin.formatting';
const SHORTCUTS_GROUP_MILANOTE_GRID_UPDATE = 'MilanoteEditingPlugin.gridUpdate';
/**
 * MilanoteEditingPlugin.ts
 *
 *   - This is a custom plugin that has a high priority (called before all other plugins) used for Milanote table editing setup
 *
 *   - Overrides handsontable getSourceData*() functions. This is because we updated the editor to use a DraftJS editor
 *     (see MilanoteCellEditor.js). This means that the data that will be saved will be in the format of a stringified
 *     Draft JS object. In order for the plugins (e.g. formula plugin) to get the right content, we would need to
 *     override the getSourceData*() functions to convert the Draft JS format to a valid string content format.
 *
 *      Process:
 *      afterSetDataAtCell hook -> MilanoteEditingPlugin -> FormulaPlugin -> MilanoteEditingCleanupPlugin
 *      For diagram, see https://app.milanote.com/1Qjnx50VbE5g69/table-editing-flow
 *
 *   - Sets appropriate shortcuts for formatting and grid update
 */
class MilanoteEditingPlugin extends BasePlugin {
    getOriginalSourceData: (
        row?: number,
        col?: number,
        row2?: number,
        col2?: number,
    ) => Handsontable.CellValue[][] | Handsontable.RowObject[];
    getOriginalSourceDataArray: (
        row?: number,
        col?: number,
        row2?: number,
        col2?: number,
    ) => Handsontable.CellValue[][];
    getOriginalSourceDataAtCell: (row: number, col: number) => Handsontable.CellValue;
    getOriginalCopyableData: (row: number, col: number) => string;

    /**
     * This key will be used as a prop key when enabling this plugin in the HotTable component
     */
    static get PLUGIN_KEY(): string {
        return MILANOTE_EDITING_PLUGIN_NAME;
    }

    static get PLUGIN_PRIORITY(): number {
        return MILANOTE_EDITING_PLUGIN_PRIORITY;
    }

    constructor(hotInstance: Core) {
        super(hotInstance);

        this.getOriginalSourceData = hotInstance.getSourceData;
        this.getOriginalSourceDataArray = hotInstance.getSourceDataArray;
        this.getOriginalSourceDataAtCell = hotInstance.getSourceDataAtCell;
        this.getOriginalCopyableData = hotInstance.getCopyableData;

        hotInstance.getSourceData = this.getSourceData.bind(this);
        hotInstance.getSourceDataArray = this.getSourceDataArray.bind(this);
        hotInstance.getSourceDataAtCell = this.getSourceDataAtCell.bind(this);
        hotInstance.getCopyableData = this.getCopyableData.bind(this);
    }

    getConfig(): {
        tableOperationsRef: React.MutableRefObject<any>;
        jumpToTitle: () => void;
        isReadOnly: boolean;
    } {
        const settings = this.hot?.getSettings() as any;

        return settings?.[MILANOTE_EDITING_PLUGIN_NAME] ?? {};
    }

    /**
     * This function is needed to determine whether or not the plugin is enabled. In the case of this plugin, always
     * enable it.
     */
    isEnabled(): boolean {
        return true;
    }

    /**
     * The `enablePlugin` method is triggered on the `beforeInit` hook.
     * It should contain the plugin's initial setup and hook connections.
     * This method is run only if the `isEnabled` method returns `true`.
     */
    enablePlugin(): void {
        this.addHook('afterInit', (...args) => this.afterInit(...args));
        this.addHook('afterSetDataAtCell', (...args) => this.onAfterSetDataAtCell(...args));
        this.addHook('afterUnlisten', () => this.onAfterUnlisten());
        this.addHook('beforeOnCellMouseDown', (event) => this.onBeforeOnCellMouseDown(event));
        this.addHook('beforeInitWalkontable', (config) => this.beforeInitWalkontable(config));

        // The `super` method sets the `this.enabled` property to `true`.
        // It is a necessary step to update the plugin's settings properly.
        super.enablePlugin();
    }

    disablePlugin(): void {
        this.unregisterShortcuts();
    }

    onBeforeOnCellMouseDown = (event: MouseEvent): void => {
        // If the table is readonly, prevent all mouse events from propagating
        if (this.getConfig().isReadOnly) event.stopImmediatePropagation();
    };

    /**
     * The Formula plugin listens to the `afterSetDataAtCell` hook to update the formula cells. Since the changes might
     * be in DraftJS format, we need to convert the changes to a readable format for the formula plugin.
     *
     * This `onAfterSetDataAtCell` function:
     *  - Saves the original changes in `MilanoteEditingCleanupPlugin`, for restoration later after all the other
     *    plugins have run
     *  - Convert the changes to a readable format for the formula plugin
     *
     * The changes will be returned to its original format in `MilanoteEditingCleanupPlugin`
     */
    onAfterSetDataAtCell(changes: Handsontable.CellChange[], source?: Handsontable.ChangeSource): void {
        const milanoteEditingCleanupPlugin = this.hot.getPlugin(
            MILANOTE_EDITING_CLEANUP_PLUGIN_NAME,
        ) as MilanoteEditingCleanupPlugin;

        milanoteEditingCleanupPlugin.setOriginalChanges(cloneDeep(changes));

        // These changes will be saved to Hyperformula, and get converted back to the original format in
        // `MilanoteEditingCleanupPlugin`
        changes.forEach(([row, col, prevValue, newValue], index) => {
            if (typeof col === 'string') return;

            const prevHFValue = this.normaliseCellValueForHyperFormula(prevValue, { row, col }, source);
            const newHFValue = this.normaliseCellValueForHyperFormula(newValue, { row, col }, source);

            changes[index] = [row, col, prevHFValue, newHFValue];
        });
    }

    onAfterUnlisten(): void {
        // This is to solve an issue where undoing a table element creation (which removes the table element) will
        // persist focus on textarea.HandsontableCopyPaste, which will cause board level shortcuts to not work
        document.querySelector<HTMLTextAreaElement>('textarea.HandsontableCopyPaste')?.blur();
    }

    afterInit(): void {
        this.registerShortcuts();

        // @ts-ignore - valid property
        const mouseDownEvent = this.hot.eventListeners?.find(
            // @ts-ignore - valid property
            ({ event, element }) => event === 'mousedown' && element.nodeName === 'HTML',
        );

        if (mouseDownEvent) {
            const { eventManager, element, event: eventName, callback } = mouseDownEvent;

            eventManager.removeEventListener(element, eventName, callback);
            eventManager.addEventListener(element, eventName, (event: MouseEvent) => {
                let next = event.target as HTMLElement | null;

                while (next && next !== element) {
                    if (next.hasAttribute('data-table-util')) return;

                    next = next.parentElement;
                }

                callback(event);
            });
        }

        // Prevent Handsontable from scrolling Milanote's viewport

        // @ts-ignore - valid property
        this.hot.view.scrollViewport = () => {
            // Do nothing
        };

        // @ts-ignore - valid property
        this.hot.view.scrollViewportHorizontally = () => {
            // Do nothing
        };

        // @ts-ignore - valid property
        this.hot.view.scrollViewportVertically = () => {
            // Do nothing
        };
    }

    /**
     * Disable the default onContainerElementResize, as it will cause the table to rerender on Handsontable's side.
     * This is not applicable to Milanote's tables, as the container element's size will have limited effect on the
     * content of the table.
     */
    beforeInitWalkontable(config: any): void {
        config.onContainerElementResize = null;
    }

    /********************
     * OVERRIDE FUNCTIONS
     *  - getSourceData*()
     *  - getCopyableData()
     ********************/

    getSourceData(
        startRow?: number,
        startCol?: number,
        endRow?: number,
        endCol?: number,
    ): Handsontable.CellValue[][] | Handsontable.RowObject[] {
        return this.getOriginalSourceData(startRow, startCol, endRow, endCol).map((rowData, row) => {
            if (Array.isArray(rowData)) {
                return rowData.map((cellValue: Handsontable.CellValue, col) => {
                    return this.normaliseCellValueForHyperFormula(cellValue, { row, col });
                });
            }

            return Object.keys(rowData).reduce<Handsontable.RowObject>((acc, cellKey, col) => {
                const cellValue = rowData[cellKey];

                acc[cellKey] = this.normaliseCellValueForHyperFormula(cellValue, { row, col });

                return acc;
            }, {});
        });
    }

    getSourceDataArray(
        startRow?: number,
        startCol?: number,
        endRow?: number,
        endCol?: number,
    ): Handsontable.CellValue[][] {
        return this.getOriginalSourceDataArray(startRow, startCol, endRow, endCol).map((rowData, row) =>
            rowData.map((cellValue: Handsontable.CellValue, col) => {
                return this.normaliseCellValueForHyperFormula(cellValue, { row, col });
            }),
        );
    }

    getSourceDataAtCell(row: number, col: number): Handsontable.CellValue {
        const cellValue = this.getOriginalSourceDataAtCell(row, col);
        return this.normaliseCellValueForHyperFormula(cellValue, { row, col });
    }

    getCopyableData(row: number, col: number): string {
        const { cellData } = this.hot.getCellMeta(row, col);

        const hotCellValue = this.getOriginalCopyableData(row, col);

        // @ts-ignore - valid property
        const { locale, elementId } = this.hot?.milanoteProps || {};

        const sheet = hyperFormulaInstance.getSheetId(elementId);
        const options =
            sheet !== undefined
                ? {
                      hfType: hyperFormulaInstance.getCellType({ sheet, row, col }),
                      hfDetailedType: hyperFormulaInstance.getCellValueDetailedType({ sheet, row, col }),
                  }
                : undefined;

        return getRenderCellValueFromRawHotCellValue(cellData, locale, hotCellValue, options).toString();
    }

    /********************
     * CELL TEXT FORMATTING
     ********************/

    normaliseCellValueForHyperFormula = (
        cellValue: string,
        cellCoords: CellCoordsObj,
        source?: string,
    ): string | null => {
        const cellValueRawText = rawGetText(parseCellContentString(cellValue));

        // Formatting numbers here so that english number formatting will be saved to Hyperformula
        const isNewInput = (source as string) !== 'CellMeta.init';

        // @ts-ignore - custom property
        const { locale } = this.hot?.milanoteProps || {};

        let newHFValue =
            stringShouldFormatAsType(cellValueRawText, isNewInput, locale)?.newCellValue || cellValueRawText;

        const { cellData } = this.hot.getCellMeta(cellCoords.row, cellCoords.col) || {};

        if (!isFormula(cellValueRawText) && !isNil(cellData?.value)) {
            // If currency, prefix a $ to the value given to Hyperformula, so that it can read it as a currency
            if (isCellTypeCurrency(cellData)) {
                newHFValue = `$${newHFValue}`;
            }

            // If percentage, convert to `x%` format so that Hyperformula can read it as a percentage
            if (isCellTypePercentage(cellData) && !cellData.value.endsWith('%')) {
                newHFValue = `${newHFValue * 100}%`;
            }
        }

        return newHFValue;
    };

    /********************
     * SHORTCUTS
     ********************/

    registerShortcuts(): void {
        const shortcutManager = this.hot.getShortcutManager();
        const gridContext = shortcutManager.getContext('grid');

        if (!gridContext) return;

        // Remove Handsontable default shortcut (Ctrl/Cmd + Enter) to avoid conflict with Milanote's shortcut
        gridContext.removeShortcutsByKeys(['Control/Meta', 'Enter']);
        gridContext.removeShortcutsByKeys(['Backspace']);
        gridContext.removeShortcutsByKeys(['Delete']);

        const { tableOperationsRef } = this.getConfig();

        gridContext.addShortcuts(
            [
                {
                    keys: [['Control/Meta', 'b']],
                    callback: (event) => tableOperationsRef.current.applyTextStyle(STYLE_COMMANDS.BOLD),
                },
                {
                    keys: [['Control/Meta', 'i']],
                    callback: (event) => tableOperationsRef.current.applyTextStyle(STYLE_COMMANDS.ITALIC),
                },
                {
                    keys: [['Control/Meta', 'shift', 'x']],
                    callback: (event) => tableOperationsRef.current.applyTextStyle(STYLE_COMMANDS.STRIKETHROUGH),
                },
            ],
            // @ts-ignore - Second argument is not documented in Handsontable TS
            { group: SHORTCUTS_GROUP_MILANOTE_CELL_FORMATTING },
        );

        gridContext.addShortcuts(
            [
                {
                    keys: [['Control/Meta', 'Enter']],
                    callback: (event) => {
                        event.preventDefault();
                        event.stopImmediatePropagation();

                        const cellSelections = this.hot.getSelected();
                        if (!cellSelections) return;

                        const firstCellSelection = getFirstSelection(cellSelections);
                        if (firstCellSelection && isSelectingRefColHeader(firstCellSelection)) {
                            tableOperationsRef.current.addColsRight();
                            return;
                        }

                        tableOperationsRef.current.addRowsBelow();
                    },
                },
                {
                    keys: [['Control/Meta', 'Backspace']],
                    callback: (event) => {
                        event.preventDefault();
                        event.stopImmediatePropagation();

                        const cellSelections = this.hot.getSelected();
                        if (!cellSelections) return;

                        const firstCellSelection = getFirstSelection(cellSelections);
                        if (firstCellSelection && isSelectingRefColHeader(firstCellSelection)) {
                            tableOperationsRef.current.removeCols();
                            return;
                        }

                        tableOperationsRef.current.removeRows();
                    },
                },
                {
                    keys: [['Tab']],
                    callback: (event) => {
                        const cellSelections = this.hot.getSelected();
                        if (!cellSelections) return;

                        const nCols = getColumnCount(this.hot.getData());
                        const firstCellSelection = getFirstSelection(cellSelections);

                        const isSelectingLastColumn = getStartCol(firstCellSelection) === nCols - 1;
                        if (isSelectingLastColumn) {
                            tableOperationsRef.current.addColsRight();
                        }
                    },
                },
                {
                    keys: [['Shift', 'Tab']],
                    callback: (event) => {
                        const cellSelections = this.hot.getSelected();
                        if (!cellSelections) return;

                        const firstCellSelection = getFirstSelection(cellSelections);

                        const isSelectingFirstCell =
                            getStartRow(firstCellSelection) === 0 && getStartCol(firstCellSelection) === 0;

                        if (isSelectingFirstCell) {
                            tableOperationsRef.current.jumpToTitle();
                        }
                    },
                },
                {
                    keys: [
                        ['Backspace'],
                        ['Delete'],

                        // This is to cater to iPad, where shift key is by default enabled when the keyboard shows up
                        // to capitalise the first letter of the input.
                        ['Shift', 'Backspace'],
                    ],
                    callback: () => {
                        const cellSelections = this.hot.getSelected();
                        if (!cellSelections) return;

                        // Revert readOnly cells to be editable so that they can be cleared
                        getAllCellsBetween(cellSelections).forEach(({ row, col }) => {
                            this.hot.setCellMetaObject(row, col, { readOnly: false });
                        });

                        this.hot.emptySelectedCells();

                        // Re-render after emptying cells to ensure table headers gets properly resized
                        requestAnimationFrame(() => this.hot && !this.hot.isDestroyed && this.hot.render());
                    },
                },
                {
                    keys: [['Alt', 'ArrowUp']],
                    callback: () => {
                        tableOperationsRef.current.addRowsAbove();
                    },
                },
                {
                    keys: [['Alt', 'ArrowDown']],
                    callback: () => {
                        tableOperationsRef.current.addRowsBelow();
                    },
                },
                {
                    keys: [['Alt', 'ArrowLeft']],
                    callback: () => {
                        tableOperationsRef.current.addColsLeft();
                    },
                },
                {
                    keys: [['Alt', 'ArrowRight']],
                    callback: () => {
                        tableOperationsRef.current.addColsRight();
                    },
                },
                {
                    keys: [
                        ['ArrowUp'],
                        ['ArrowRight'],
                        ['ArrowDown'],
                        ['ArrowLeft'],
                        ['Shift', 'ArrowUp'],
                        ['Shift', 'ArrowRight'],
                        ['Shift', 'ArrowDown'],
                        ['Shift', 'ArrowLeft'],
                    ],
                    callback: () => {
                        // Do nothing. This is to prevent the element from shifting around on canvas when using
                        // arrow keys to change cell selection.
                    },
                    runOnlyIf: () => this.hot.getSelected() !== undefined,
                    stopPropagation: true,
                },
            ],
            // @ts-ignore - Second argument is not documented in Handsontable TS
            { group: SHORTCUTS_GROUP_MILANOTE_GRID_UPDATE, position: 'before', relativeToGroup: 'gridDefault' },
        );
    }

    unregisterShortcuts(): void {
        const shortcutManager = this.hot.getShortcutManager();
        const gridContext = shortcutManager.getContext('grid');

        if (!gridContext) return;

        gridContext.removeShortcutsByGroup(SHORTCUTS_GROUP_MILANOTE_CELL_FORMATTING);
        gridContext.removeShortcutsByGroup(SHORTCUTS_GROUP_MILANOTE_GRID_UPDATE);
    }
}

export default MilanoteEditingPlugin;
