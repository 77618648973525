export const PRICE_BILLING_UNITS = {
    month: 'month',
    year: 'year',
};

export const INVOICE_STATUS = {
    RECEIVED: 'RECEIVED', // step 1: recieved invoice data from stripe
    SENT: 'SENT', // step 4: send email to customer
    PAID_IN_CREDIT: 'PAID_IN_CREDIT', // Invoices that are paid in credit won't be sent to customers
};

export const COUPON_DURATION = {
    forever: 'forever',
    repeating: 'repeating',
    once: 'once',
};

export const INVOICE_DUE_DAYS = 14;

// Hardcoded legacy Stripe price ids. These are the prices that were created before
// Stripe updated their pricing structure, and would not work perfectly in CheckoutV2
//
// TODO-CHECKOUT: Remove LEGACY_PRICE_IDS once all users are migrated out of them
export const LEGACY_PRICE_IDS = ['pro-annual-v2', 'pro-monthly-v2', 'plan_HTpa5av0usbzXK', 'plan_HTpaSqeimLjWpG'];
