// Lib
import { ContentState, convertToRaw } from 'draft-js';

// Utils
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';

// Actions
import { createAndEditElement } from '../actions/elementActions';
import { setLinkElementUrl } from '../link/linkActions';
import { setElementTypeAndUpdateElement } from '../../../common/elements/elementActions';
import { setSelectedElements } from '../selection/selectionActions';
import { setElementLocalData } from '../local/elementLocalDataActions';

// Constants
import { ElementType } from '../../../common/elements/elementTypes';

const updateTypeToLink = ({ id, elementType, data }, getState, dispatch) => {
    const transactionId = getNewTransactionId();

    const { url } = data;

    const contentState = ContentState.createFromText(url);
    const rawState = convertToRaw(contentState);

    const undoChanges = { textContent: rawState, caption: null, showCaption: false, ignoreAutoType: true };

    dispatch(setElementTypeAndUpdateElement({ id, elementType, undoChanges, transactionId }));
    dispatch(setElementLocalData({ id, data: { url } }));
    dispatch(setLinkElementUrl({ id, url, transactionId }));
};

const updateTypeToTask = ({ id, elementType, data }, getState, dispatch) => {
    const transactionId = getNewTransactionId();
    dispatch(
        setElementTypeAndUpdateElement({
            id,
            elementType: ElementType.TASK_LIST_TYPE,
            transactionId,
        }),
    );
    dispatch(
        createAndEditElement({
            elementType: ElementType.TASK_TYPE,
            location: getListChildNewLocation({ listId: id }),
            content: data.content,
            transactionId,
            select: false,
        }),
    );
    dispatch(setSelectedElements({ ids: [id], transactionId }));
};

export const updateElementType =
    ({ id, elementType, data }) =>
    (dispatch, getState) => {
        switch (elementType) {
            case ElementType.LINK_TYPE:
                return updateTypeToLink({ id, elementType, data }, getState, dispatch);
            case ElementType.TASK_TYPE:
                return updateTypeToTask({ id, elementType, data }, getState, dispatch);
            default:
                return dispatch(setElementTypeAndUpdateElement({ id, elementType, changes: data }));
        }
    };
