// Lib
import axios from 'axios';
import { last } from 'lodash/fp';

// Utils
import http from '../../utils/services/http';
import { generateRenderUrlFromPresignedUrl } from '../../../common/attachment/attachmentUtils';
import { lookupTypeDefinitionsByExtension } from '../../../common/files/getFileType';
import { getFileContentType } from '../../../common/files/fileTypeInferenceUtils';

// Constants
import { HEADERS, METHODS } from '../../../common/utils/http/httpConstants';
import { TIMES } from '../../../common/utils/timeUtil';
import { ACCEPTED_FILE_TYPES, FILE_TYPES_INFO } from '../../../common/files/fileConstants';

// AWS Axios
const awsAxios = axios.create({ timeout: 2 * TIMES.DAY });
const { CancelToken } = axios;

export const getFileName = (file, contentType) => {
    const { name } = file;

    // detect if file has an extension
    const nameParts = name.split('.');
    const fileExtension = last(nameParts);
    const validExtension = lookupTypeDefinitionsByExtension(fileExtension);
    if (fileExtension && validExtension && validExtension !== FILE_TYPES_INFO.GENERIC) return name;

    // if no extension, use the content type to determine the extension
    const extension = contentType?.split('/')[1];
    // if no content type, use the file name as is
    return extension ? `${name}.${extension}` : name;
};

export const awsPresign = ({ filename, contentType, id, fileType = ACCEPTED_FILE_TYPES.FILE, imageType, fileSize }) => {
    const requestConfig = {
        url: 'elements/attachments/presign',
        method: METHODS.POST,
        timeout: TIMES.MINUTE,
        data: {
            filename,
            contentType,
            elementId: id,
            fileType,
            imageType,
            fileSize,
        },
    };

    return http(requestConfig);
};

/**
 * Generates a presigned URL and securely uploads the file to S3

 * @param {Object} uploadParams
 * @returns {Promise<string>} render url
 */
export const uploadFileToS3 = async ({ file, onUploadProgress, id, cancelExecutorFn, fileType, imageType, user }) => {
    const contentType = await getFileContentType(file);
    const fileSize = file.size;
    const filename = getFileName(file, contentType);

    // Fetch a Presigned URL
    const response = await awsPresign({ filename, fileSize, contentType, id, fileType, imageType });

    const { presignedUrl } = response.data;

    // Perform upload
    await awsAxios({
        method: METHODS.PUT,
        url: presignedUrl,
        headers: {
            [HEADERS.CONTENT_TYPE]: contentType,
        },
        cancelToken: new CancelToken(cancelExecutorFn),
        data: file,
        onUploadProgress,
    });

    return generateRenderUrlFromPresignedUrl(presignedUrl, imageType);
};

export const trackAttachmentDownload = ({ attachmentId, url }) =>
    http({
        url: 'elements/attachments/track-download',
        params: {
            attachmentId,
            url,
        },
    }).catch((error) => null);
