// Utils
import getImageDataUriDimensions from './getImageDataUriDimensions';
import getImageHasTransparency from './getImageHasTransparency';
import drawResizedImageToCanvasContext from './drawResizedImageToCanvasContext';
import { readAsDataURL } from '../../../../common/files/promisedFileReader';

// Constants
import { MIME_TYPES } from '../../../../common/media/mimeConstants';
import { IMAGE_WIDTH_LARGE } from '../../../../common/images/imageConstants';

type ImageDataResult = {
    imageDataUri: string;
    size: { width: number; height: number };
    transparent: boolean;
};

type ImageFileLoaderResult = ImageDataResult & { file: File };

/**
 * For images that don't have transparency, we can just draw them to a canvas and get the data uri.
 **/
const getImageFileData = async (inputFile: File): Promise<ImageDataResult> => {
    // create an object URL we can reference the file with
    const fileObjectURL = URL.createObjectURL(inputFile);

    // get the image dimensions, and an image element we can use to draw to a canvas
    const { size, img } = await getImageDataUriDimensions(fileObjectURL, inputFile.type);

    // resize the image if it's too big
    const resizeWidth = Math.min(size.width, IMAGE_WIDTH_LARGE);
    const resizedImageDataUri = drawResizedImageToCanvasContext(img, resizeWidth);

    // clean up the object URL so we don't leak memory
    URL.revokeObjectURL(fileObjectURL);

    return {
        imageDataUri: resizedImageDataUri,
        size,
        transparent: false,
    };
};

/**
 * PNGs and GIFs might have transparency, so this will draw the image to a canvas and check each pixel for transparency.
 */
const getImageDataWithTransparency = async (inputFile: File): Promise<ImageDataResult> => {
    const { size, imageDataUri: resizedImageDataUri } = await getImageFileData(inputFile);

    // determining if the image is transparent is expensive, so use the resized image
    // which is smaller and faster to check
    const { img: resizedImg } = await getImageDataUriDimensions(resizedImageDataUri, inputFile.type);
    const transparent = getImageHasTransparency(resizedImg);

    return {
        imageDataUri: resizedImageDataUri,
        size,
        transparent,
    };
};

/*
 * SVGs don't need to be drawn to a canvas, so just return the object URL and dimensions.
 */
const getSVGImageData = async (inputFile: File): Promise<ImageDataResult> => {
    // SVGs are just read as a data uri because we don't need to resize/draw to canvas
    const imageDataUri = await readAsDataURL(inputFile);
    const { size } = await getImageDataUriDimensions(imageDataUri, inputFile.type);

    return {
        imageDataUri,
        size,
        // assume that SVGs are transparent
        transparent: true,
    };
};

const getImageData = async (inputFile: File): Promise<ImageDataResult> => {
    switch (inputFile.type) {
        case MIME_TYPES.PNG:
        case MIME_TYPES.GIF:
            return getImageDataWithTransparency(inputFile);
        case MIME_TYPES.SVG:
            return getSVGImageData(inputFile);
        default:
            return getImageFileData(inputFile);
    }
};

export default async (inputFile: File): Promise<ImageFileLoaderResult> => {
    const result = await getImageData(inputFile);
    return { file: inputFile, ...result };
};
