// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import { sendAmplitudeEvent } from '../../../../../../analytics/amplitudeService';

// Components
import Icon from '../../../../../../components/icons/Icon';
import ShortcutKeyGroup from '../../../../../shortcuts/ShortcutKeyGroup';

// Constants
import { EVENT_TYPE_NAMES } from '../../../../../../../common/analytics/amplitudeEventTypesUtil';

const TextStyleEntry = (props) => {
    const { blockType, entryBlockType, name, shortcut, onTextStyleClick, otherBlockTypes } = props;

    const active = blockType === entryBlockType || (otherBlockTypes && otherBlockTypes.includes(blockType));

    const onPointerDown = (event) => {
        event.preventDefault();
        onTextStyleClick(entryBlockType);

        sendAmplitudeEvent({
            eventType: EVENT_TYPE_NAMES.TEXT_STYLE_TOGGLE,
            eventProperties: {
                style: entryBlockType,
            },
        });
    };

    return (
        <div className={classNames('TextStyleEntry', { active })} onPointerDown={onPointerDown}>
            <div className={`block-style-text ${entryBlockType}`}>{name}</div>
            {active ? <Icon name="block-style-tick" /> : <ShortcutKeyGroup shortcut={shortcut} preferSymbol />}
        </div>
    );
};

TextStyleEntry.propTypes = {
    blockType: PropTypes.string,
    entryBlockType: PropTypes.string,
    name: PropTypes.string,
    shortcut: PropTypes.array,
    onTextStyleClick: PropTypes.func,
    otherBlockTypes: PropTypes.array,
};
export default TextStyleEntry;
