import registerColorSwatch from '../../../common/colorSwatches/index';
import presentationalComponent from './ColorSwatch';
import containerComponent from './ColorSwatchContainer';

import platformSingleton from '../../platform/platformSingleton';
import { isPlatformApple } from '../../platform/utils/platformDetailsUtils';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerColorSwatch({
        containerComponent,
        presentationalComponent,
        // TODO: Change tooltip for color swatch once determined
        tooltip: isPlatformApple(platformSingleton) ? '⌃⌘N' : 'Ctrl+Alt+N',
        selectedTools: [
            TOOLBAR_TOOLS.CUSTOM_COLOR,
            TOOLBAR_TOOLS.COLOR_DISPLAY,
            TOOLBAR_TOOLS.CAPTION,
            TOOLBAR_TOOLS.REACTION,
            ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
        ],
        editingTools: TOOL_SETS.TEXT_EDITING_CAPTION,
    });
