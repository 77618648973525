import { isAlias, isBoard, isCard } from '../../../../../../../common/elements/utils/elementTypeUtils';

/**
 * Check for if the current selected element(s) should display the dual colors or not.
 * Currently only allowing for boards and aliases but in the future might extend to more elements like cards
 * @param {*} element
 * @returns boolean
 */
export const elementAllowsDualColors = (element) => isBoard(element) || isAlias(element) || isCard(element);

/**
 * Takes the list of suggested colors and the list of dual colors, and combines
 * them in a way that results in a more even spread of colors, avoiding similar ones together.
 * This list is used to pick from when creating new boards.
 * Example: suggested = [A0, A1, A2, A3, A4, A5, A6], dualColors = [B0, B1, B2, B3, B4, B5, B6]
 * If we were to simply alternate between the two we would end up with [A0, B0, A1, B1 ...] but in this case the colors
 * A0 and B0 tend to be the same/similar so we want to offset one of the lists. For this reason we are slicing the
 * suggested colors and starting from index 4 so suggested becomes [A4, A5, A6, A0, A1, A2, A3],
 * which results in a combined list = [B0, A4, B1, A5, B2, A6, B3, A0, B4, A1, B5, A2, B6, A3]
 */
export const combineDualColorAndSuggestionLists = (suggested, dualColors) => {
    const combined = [];
    // start the array at index 4, add the beginning items to the end
    const suggestedSliced = [...suggested.slice(4, suggested.length), ...suggested.slice(0, 4)];
    // add suggested and dualColors, alternating between the two lists
    const length = Math.min(suggested.length, dualColors.length);
    for (let index = 0; index < length; index++) {
        combined.push(dualColors[index], suggestedSliced[index]);
    }
    // add any leftovers (whichever list was longer) to the combined array
    combined.push(...suggestedSliced.slice(length), ...dualColors.slice(length));
    return combined;
};
