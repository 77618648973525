import React from 'react';
import Handsontable from 'handsontable/base';
import { convertFromRaw, convertToRaw, EditorState, Modifier } from 'draft-js';
import { cloneDeep, isEqual, capitalize, set } from 'lodash';

// Utils
import {
    getStartCol,
    isSelectingRefColHeader,
    isSelectingRefRowHeader,
    moveCellsDown,
    moveCellsRight,
    getStartRow,
    getEndRow,
    getEndCol,
    getFirstSelection,
    isSelectingMultipleSelections,
    unselectedRowCount,
    unselectedColCount,
    getAllCellsBetween,
    getNRowsInSelection,
    getNColsInSelection,
    excludeRefHeadersFromSelection,
    isSelectingSingleSelection,
} from '../utils/tableCellSelectionUtils';
import { asObject } from '../../../../common/utils/immutableHelper';
import { cellIsReadOnly, updateCellType } from '../utils/tableCellTypeUtils';
import { createTableCellEntry } from '../utils/tableInitialisationUtils';
import getSelectionStateForContent from '../../../components/editor/customRichUtils/selection/getSelectionStateForContent';
import { getCellTextAlignment } from '../utils/tableCellFormattingUtils';
import { getShortcutHtml } from '../utils/tableContextMenuUtils';
import { getNewTransactionId } from '../../../utils/undoRedo/undoRedoTransactionManager';
import { isBackgroundStyle, isColorStyle } from '../../../components/editor/editorUtils';
import {
    parseCellContentString,
    stringifyCellContent,
} from '../../../../common/table/utils/tableCellContentStringUtils';

// Constants
import {
    TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT,
    TABLE_MIN_COL_COUNT,
    TABLE_MIN_ROW_COUNT,
} from '../../../../common/table/tableConstants';
import { CellData, CellSelections, CellTypeObject } from '../../../../common/table/TableTypes';
import { CellTypeNames, TextAlignment, VerticalAlignment } from '../../../../common/table/CellTypeConstants';
import { TABLE_ELEMENT_CELL_SELECTIONS_UPDATE } from '../../../../common/elements/selectionConstants';
import {
    TEXT_BACKGROUND_PRESETS,
    TEXT_COLOR_PRESETS,
} from '../../../components/editor/plugins/textColor/textColorConstants';
import hyperFormulaInstance from '../manager/hyperFormulaInstance';

interface Props {
    elementId: string;
    locale: string;
    currencyPreference: string;
    gridSize: number;
    showTitle: boolean;
    isSingleSelected: boolean;
    isCurrentlyEditingTableCell: boolean;
    hotTableInstanceRef: React.MutableRefObject<Handsontable.Core | null>;
    jumpToTitle: () => void;
    dispatchStartEditingTitle: (elementId: string) => void;
    dispatchStartEditingTableCell: (elementId: string) => void;
    dispatchFinishEditingElement: () => void;
    dispatchUpdateTableElement: (args: object) => void;
}

class TableOperations extends React.Component<Props> {
    transactionIdForNextUpdateCellSelection: number | undefined;

    getHotTableData = (): CellData[][] | undefined => {
        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const cellMetas = hotTableInstanceRef.current.getCellsMeta();
        const data: CellData[][] = [];

        const rowCount = hotTableInstanceRef.current.countRows();
        const colCount = hotTableInstanceRef.current.countCols();

        cellMetas.forEach((cellMeta) => {
            const { row, col, cellData } = cellMeta || {};
            if (cellData && row < rowCount && col < colCount) set(data, [row, col], cellData);
        });

        return data;
    };

    getHotCurrentCellSelections = (): CellSelections | undefined => {
        const { hotTableInstanceRef } = this.props;

        return hotTableInstanceRef.current?.getSelected();
    };

    getHotColWidthsGU = (): number[] | undefined => {
        const { gridSize, hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const colWidthsGU: number[] = [];
        for (let col = 0; col < hotTableInstanceRef.current.countCols(); col++) {
            const isLastCol = col === hotTableInstanceRef.current.countCols() - 1;
            const offset = isLastCol ? 2 : 0;

            const width = (hotTableInstanceRef.current.getColWidth(col) + offset) / gridSize;

            colWidthsGU.push(width);
        }

        return colWidthsGU;
    };

    // **********************************
    // TABLE RENDER
    // **********************************

    renderAndResize = (): void => {
        const { hotTableInstanceRef } = this.props;

        // `requestAnimationFrame` is used for calling render here as it improves performance
        requestAnimationFrame(() => {
            if (!hotTableInstanceRef.current || hotTableInstanceRef.current.isDestroyed) return;

            // Render recent changes
            hotTableInstanceRef.current.render();

            // Ensure row heights are correct
            hotTableInstanceRef.current.refreshDimensions();
        });
    };

    // **********************************
    // CELL SELECTIONS
    // **********************************

    popTransactionIdForNextUpdateCellSelection = (): number | undefined => {
        const transactionId = this.transactionIdForNextUpdateCellSelection;
        this.transactionIdForNextUpdateCellSelection = undefined;
        return transactionId;
    };

    updateHotCellSelections = (cellSelections: CellSelections, transactionId?: number): void => {
        const { isSingleSelected, hotTableInstanceRef } = this.props;

        if (!isSingleSelected) return;

        const newCellSelections = asObject(cellSelections) as CellSelections;
        if (!hotTableInstanceRef.current) return;

        hotTableInstanceRef.current.deselectCell();

        if (!newCellSelections) return;

        const currentHotCellSelections = hotTableInstanceRef.current.getSelected();
        if (isEqual(newCellSelections, currentHotCellSelections)) return;

        this.transactionIdForNextUpdateCellSelection = transactionId;

        // Prioritise on selecting the first ref column/row header if it exists in cellSelections.
        // - This is because we can't select ref headers using hot.selectCells() function. So we are not able to
        //   select ref headers and normal cells at the same time. Therefore, need to prioritise selecting one.

        const selectingRefColHeader = cellSelections.find(isSelectingRefColHeader);
        if (selectingRefColHeader) {
            hotTableInstanceRef.current.selectColumns(
                Math.max(getStartCol(selectingRefColHeader), 0),
                Math.min(getEndCol(selectingRefColHeader), hotTableInstanceRef.current.countCols() - 1),
            );
            return;
        }

        const selectingRefRowHeader = cellSelections.find(isSelectingRefRowHeader);
        if (selectingRefRowHeader) {
            hotTableInstanceRef.current.selectRows(
                Math.max(getStartRow(selectingRefRowHeader), 0),
                Math.min(getEndRow(selectingRefRowHeader), hotTableInstanceRef.current.countRows() - 1),
            );
            return;
        }

        hotTableInstanceRef.current.selectCells(newCellSelections);
    };

    // **********************************
    // CELL TYPE
    // **********************************

    updateCellType = (cellType: CellTypeNames | CellTypeObject, cellSelections?: CellSelections): void => {
        const { elementId, hotTableInstanceRef, currencyPreference } = this.props;

        const hot = hotTableInstanceRef.current;

        if (typeof cellType === 'string') {
            const updateCellTypeFn = (cellData: CellData, row?: number, col?: number) =>
                updateCellType(cellData, cellType, currencyPreference, elementId, row, col, hot);

            this.setCellDataForCellSelections(updateCellTypeFn, 'CellMeta.updateCellType', cellSelections);

            return;
        }

        this.setCellDataForCellSelections(
            (cellData) => ({ ...cellData, type: cellType }),
            'CellMeta.updateCellType',
            cellSelections,
        );
    };

    updateCellTypeFormat = (typeFormatChanges: CellTypeObject): void => {
        this.setCellDataForCellSelections(
            (cellData) => ({
                ...cellData,
                type: { ...cellData.type, ...typeFormatChanges },
            }),
            'CellMeta.updateCellTypeFormat',
        );
    };

    // **********************************
    // Update Column Width
    // **********************************

    updateColWidthPx = (column: number, newColWidthPx: number): void => {
        const { hotTableInstanceRef } = this.props;
        if (!hotTableInstanceRef.current) return;

        const manualColumnResizePluginInstance =
            hotTableInstanceRef.current.getPlugin<'manualColumnResize'>('manualColumnResize');
        const newSize = manualColumnResizePluginInstance.setManualSize(column, newColWidthPx);

        // This is a hack to force the internal state of the manualColumnResize plugin to contain the size
        // that corresponds to our grid unit
        manualColumnResizePluginInstance.newSize = newSize;
    };

    updateColWidthsPx = (newColWidthsPx: number[]): void => {
        const { hotTableInstanceRef } = this.props;
        if (!hotTableInstanceRef.current) return;

        let isUpdated = false;
        newColWidthsPx.forEach((colWidthPx, index) => {
            const currColWidth = hotTableInstanceRef.current?.getColWidth(index);

            if (currColWidth === colWidthPx) return;

            this.updateColWidthPx(index, colWidthPx);
            isUpdated = true;
        });

        if (isUpdated)
            requestAnimationFrame(() => {
                if (!hotTableInstanceRef.current || hotTableInstanceRef.current.isDestroyed) return;

                hotTableInstanceRef.current.render();
            });
    };

    // **********************************
    // ADD ROWS ABOVE
    // **********************************

    addRowsAboveMenuName = (): string => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);

        const singleRowText =
            firstCellSelection &&
            (getStartRow(firstCellSelection) === getEndRow(firstCellSelection) ||
                isSelectingRefColHeader(firstCellSelection));

        return `${singleRowText ? 'Add Row Above' : 'Add Rows Above'} ${getShortcutHtml(['alt', '↑'])}`;
    };

    addRowsAboveDisabled = (): boolean => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        return isSelectingMultipleSelections(hotCurrentCellSelections);
    };

    addRowsAbove = (): void => {
        if (this.addRowsAboveDisabled()) return;

        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const transactionId = getNewTransactionId();
        const source = `addRowsAbove:${transactionId}`;

        this.transactionIdForNextUpdateCellSelection = transactionId;

        const hotCurrentCellSelections = this.getHotCurrentCellSelections();
        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!firstCellSelection) {
            // If no cell selection, add row to the start
            hotTableInstanceRef.current.alter('insert_row_above', undefined, undefined, source);
            return;
        }

        const isRefColHeaderSelected = isSelectingRefColHeader(firstCellSelection);
        if (isRefColHeaderSelected) {
            hotTableInstanceRef.current.alter('insert_row_above', 0, 1, source);

            this.updateHotCellSelections(
                [[0, getStartCol(firstCellSelection), 0, getEndCol(firstCellSelection)]],
                transactionId,
            );
            return;
        }

        const index = Math.min(getStartRow(firstCellSelection), getEndRow(firstCellSelection));
        const amount = getNRowsInSelection(firstCellSelection);

        hotTableInstanceRef.current.alter('insert_row_above', index, amount, source);

        this.updateHotCellSelections([asObject(firstCellSelection)], transactionId);
    };

    // **********************************
    // ADD ROWS BELOW
    // **********************************

    addRowsBelowMenuName = (): string => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);

        const singleRowText =
            firstCellSelection &&
            (getStartRow(firstCellSelection) === getEndRow(firstCellSelection) ||
                isSelectingRefColHeader(firstCellSelection));

        return `${singleRowText ? 'Add Row Below' : 'Add Rows Below'} ${getShortcutHtml(['alt', '↓'])}`;
    };

    addRowsBelowDisabled = (): boolean => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        return isSelectingMultipleSelections(hotCurrentCellSelections);
    };

    addRowsBelow = (): void => {
        if (this.addRowsBelowDisabled()) return;

        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const transactionId = getNewTransactionId();
        const source = `addRowsBelow:${transactionId}`;

        this.transactionIdForNextUpdateCellSelection = transactionId;
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        if (!hotCurrentCellSelections) {
            hotTableInstanceRef.current.alter('insert_row_below', undefined, undefined, source);
            return;
        }

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!firstCellSelection) return;

        const isRefColHeaderSelected = isSelectingRefColHeader(firstCellSelection);
        if (isRefColHeaderSelected) {
            const index = getNRowsInSelection(firstCellSelection) - 1;
            const amount = 1;

            hotTableInstanceRef.current.alter('insert_row_below', index, amount, source);
        } else {
            const index = Math.max(getStartRow(firstCellSelection), getEndRow(firstCellSelection));
            const amount = getNRowsInSelection(firstCellSelection);

            hotTableInstanceRef.current.alter('insert_row_below', index, amount, source);
        }

        this.updateHotCellSelections([moveCellsDown(firstCellSelection)], transactionId);
    };

    // **********************************
    // ADD COLS LEFT
    // **********************************

    addColsLeftMenuName = (): string => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);

        const singleColText =
            firstCellSelection &&
            (getStartCol(firstCellSelection) === getEndCol(firstCellSelection) ||
                isSelectingRefRowHeader(firstCellSelection));

        return `${singleColText ? 'Add Column Left' : 'Add Columns Left'} ${getShortcutHtml(['alt', '←'])}`;
    };

    addColsLeftDisabled = (): boolean => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();
        return isSelectingMultipleSelections(hotCurrentCellSelections);
    };

    addColsLeft = (): void => {
        if (this.addColsLeftDisabled()) return;

        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const transactionId = getNewTransactionId();
        const source = `addColsLeft:${transactionId}`;

        this.transactionIdForNextUpdateCellSelection = transactionId;
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!firstCellSelection) {
            // If no cell selection, add col to the start
            hotTableInstanceRef.current.alter('insert_col_start', undefined, undefined, source);
            return;
        }

        const isRefRowHeaderSelected = isSelectingRefRowHeader(firstCellSelection);
        if (isRefRowHeaderSelected) {
            const index = 0;
            const amount = 1;

            hotTableInstanceRef.current.alter('insert_col_start', index, amount, source);
            this.updateHotCellSelections(
                [[getStartRow(firstCellSelection), 0, getEndRow(firstCellSelection), 0]],
                transactionId,
            );
            return;
        }

        const index = Math.min(getStartCol(firstCellSelection), getEndCol(firstCellSelection));
        const amount = getNColsInSelection(firstCellSelection);

        hotTableInstanceRef.current.alter('insert_col_start', index, amount, source);

        this.updateHotCellSelections([asObject(firstCellSelection)], transactionId);
    };

    // **********************************
    // ADD COLS RIGHT
    // **********************************

    addColsRightMenuName = (): string => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);

        const singleColText =
            firstCellSelection &&
            (getStartCol(firstCellSelection) === getEndCol(firstCellSelection) ||
                isSelectingRefRowHeader(firstCellSelection));

        return `${singleColText ? 'Add Column Right' : 'Add Columns Right'} ${getShortcutHtml(['alt', '→'])}`;
    };

    addColsRightDisabled = (): boolean => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        return isSelectingMultipleSelections(hotCurrentCellSelections);
    };

    addColsRight = (): void => {
        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const transactionId = getNewTransactionId();
        const source = `addColsRight:${transactionId}`;
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        if (!hotCurrentCellSelections) {
            hotTableInstanceRef.current.alter('insert_col_end', undefined, undefined, source);
            return;
        }

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!firstCellSelection) return;

        const isRefRowHeaderSelected = isSelectingRefRowHeader(firstCellSelection);
        if (isRefRowHeaderSelected) {
            const index = getNColsInSelection(firstCellSelection) - 1;
            const amount = 1;

            hotTableInstanceRef.current.alter('insert_col_start', index, amount, source);
        } else {
            const index = Math.max(getStartCol(firstCellSelection), getEndCol(firstCellSelection)) + 1;
            const amount = getNColsInSelection(firstCellSelection);

            hotTableInstanceRef.current.alter('insert_col_start', index, amount, source);
        }

        this.updateHotCellSelections([moveCellsRight(firstCellSelection)], transactionId);
    };

    // **********************************
    // REMOVE ROWS
    // **********************************

    removeRowsMenuName = (): string => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);

        const singleRowText =
            firstCellSelection &&
            (getStartRow(firstCellSelection) === getEndRow(firstCellSelection) ||
                isSelectingRefColHeader(firstCellSelection));

        return singleRowText ? 'Delete Row' : 'Delete Rows';
    };

    removeRowsDisabled = (): boolean => {
        const hotTableData = this.getHotTableData();
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        if (!hotTableData || isSelectingMultipleSelections(hotCurrentCellSelections)) return true;

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!firstCellSelection) return true;

        return unselectedRowCount(hotTableData, firstCellSelection) < TABLE_MIN_ROW_COUNT;
    };

    removeRows = (): void => {
        if (this.removeRowsDisabled()) return;

        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const transactionId = getNewTransactionId();
        const source = `removeRows:${transactionId}`;

        this.transactionIdForNextUpdateCellSelection = transactionId;
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!firstCellSelection) return;

        const index = Math.min(getStartRow(firstCellSelection), getEndRow(firstCellSelection));
        const amount = getNRowsInSelection(firstCellSelection);

        hotTableInstanceRef.current.alter('remove_row', index, amount, source);
    };

    // **********************************
    // ADD COLS RIGHT
    // **********************************

    removeColsMenuName = (): string => {
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);

        const singleColText =
            firstCellSelection &&
            (getStartCol(firstCellSelection) === getEndCol(firstCellSelection) ||
                isSelectingRefRowHeader(firstCellSelection));

        return singleColText ? 'Delete Column' : 'Delete Columns';
    };

    removeColsDisabled = (): boolean => {
        const hotTableData = this.getHotTableData();
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!hotTableData || !firstCellSelection) return true;

        return (
            isSelectingMultipleSelections(hotCurrentCellSelections) ||
            unselectedColCount(hotTableData, firstCellSelection) < TABLE_MIN_COL_COUNT
        );
    };

    removeCols = (): void => {
        if (this.removeColsDisabled()) return;

        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const transactionId = getNewTransactionId();
        const source = `removeCols:${transactionId}`;

        this.transactionIdForNextUpdateCellSelection = transactionId;
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!firstCellSelection) return;

        const index = Math.min(getStartCol(firstCellSelection), getEndCol(firstCellSelection));
        const amount = getNColsInSelection(firstCellSelection);

        hotTableInstanceRef.current.alter('remove_col', index, amount, source);
    };

    // **********************************
    // FORMULA
    // **********************************

    addFormulaToCell = (): void => {
        const { hotTableInstanceRef } = this.props;
        const hotCurrentCellSelections = this.getHotCurrentCellSelections();

        if (!hotCurrentCellSelections || !isSelectingSingleSelection(hotCurrentCellSelections)) return;

        const cellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!cellSelection) return;

        requestAnimationFrame(() => {
            if (!hotTableInstanceRef.current || hotTableInstanceRef.current.isDestroyed) return;

            hotTableInstanceRef.current?.getActiveEditor()?.beginEditing('=');
        });
    };

    // **********************************
    // BACKGROUND COLOR
    // **********************************

    setCellBackground = (color: string): void => {
        this.setCellDataForCellSelections(
            (cellData) => ({ ...cellData, background: color }),
            'CellMeta.updateCellBackground',
        );
    };

    getCellBackground = (currentCellSelections: CellSelections): string | null | undefined => {
        const { hotTableInstanceRef } = this.props;

        if (!currentCellSelections) return null;

        const colors = new Set<string>();
        getAllCellsBetween(currentCellSelections).forEach((cell): void => {
            const { row, col } = cell;
            colors.add(hotTableInstanceRef.current?.getCellMeta(row, col)?.cellData?.background || null);
        });

        const values = Array.from(colors);

        // We need to return something other than null if multiple cells are selected
        if (values.length > 1) return undefined;

        // If only one cell is selected, return the color
        // otherwise return null - this is considered the first color in the color picker which
        // is the element background color
        return values.length === 1 ? values[0] : null;
    };

    setTempColor = (color: string): void => {
        // this function is used to set the temporary cell color when the user is using the custom
        // color picker because it can update many times very quickly, for that reason we
        // are updating the hotTable instance directly to render the new colour, but not saving to redux
        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const hotCurrentCellSelections = this.getHotCurrentCellSelections();
        if (!hotCurrentCellSelections) return;

        getAllCellsBetween(hotCurrentCellSelections).forEach((cell) => {
            if (!hotTableInstanceRef.current) return;

            const { row, col } = cell;
            const currentCellData = hotTableInstanceRef.current.getCellMeta(row, col)?.cellData || {};
            hotTableInstanceRef.current.setCellMetaObject(row, col, {
                cellData: { ...currentCellData, background: color },
            });
        });

        hotTableInstanceRef.current.render();
    };

    // **********************************
    // APPLY TEXT STYLE
    // **********************************

    hasInlineStyle(cellData: CellData, inlineStyle: string): boolean {
        if (cellData.textStyle?.includes(inlineStyle)) return true;

        if (!cellData.value) return false;

        const content = parseCellContentString(cellData.value);

        if (typeof content !== 'object') return false;

        const editorState = EditorState.createWithContent(convertFromRaw(content));
        return editorState.getCurrentInlineStyle().includes(inlineStyle);
    }

    /**
     * Applies/removes a text style to all selected cells
     *
     * @param textStyle - The text style to apply/remove, or null to remove all color/background text styling
     */
    applyTextStyle(textStyle: string | null): void {
        const { elementId, hotTableInstanceRef, dispatchUpdateTableElement } = this.props;

        if (!hotTableInstanceRef.current) return;

        const hotCurrentCellSelections = this.getHotCurrentCellSelections();
        const hotColWidthsGU = this.getHotColWidthsGU();
        if (!hotCurrentCellSelections || !hotColWidthsGU) return;

        const hotTableData = this.getHotTableData();
        if (!hotTableData) return;

        // ****************************************************
        // Determine whether to add/remove inline style based on the first cell selection

        const firstCellSelection = getFirstSelection(hotCurrentCellSelections);
        if (!firstCellSelection) return;

        // Remove any header cells from the selection
        const validCellSelection = excludeRefHeadersFromSelection(firstCellSelection);

        const cellData = hotTableData[getStartRow(validCellSelection)][getStartCol(validCellSelection)];

        const shouldAddInlineStyle = textStyle ? !this.hasInlineStyle(cellData, textStyle) : true;
        const isColorOrBackgroundTextStyleChange =
            !textStyle || isColorStyle(textStyle) || isBackgroundStyle(textStyle);

        // ****************************************************
        // Apply/remove inline style to all selected cells

        const newData = cloneDeep(hotTableData);

        getAllCellsBetween(hotCurrentCellSelections).forEach((cell) => {
            const { row, col } = cell;

            // Add/remove text style from cellData.textStyle

            let newTextStyle = newData[row][col].textStyle || [];
            if (shouldAddInlineStyle) {
                newTextStyle = newTextStyle.filter((currentTextStyle) => {
                    if (isColorOrBackgroundTextStyleChange) {
                        return !(isColorStyle(currentTextStyle) || isBackgroundStyle(currentTextStyle));
                    }
                    return currentTextStyle !== textStyle;
                });

                textStyle && newTextStyle.push(textStyle);
            } else {
                newTextStyle = newTextStyle.filter((style) => style !== textStyle);
            }

            newData[row][col].textStyle = newTextStyle;

            // Add/remove text style from inline style of cellData.value

            const cellValue = hotTableData[row][col].value;
            const content = cellValue && parseCellContentString(cellValue);

            if (content && typeof content === 'object') {
                // If a color/background text style is added, remove all other existing color/background inline text styles
                if (shouldAddInlineStyle && isColorOrBackgroundTextStyleChange) {
                    let contentState = convertFromRaw(content);
                    let newEditorState = EditorState.createWithContent(contentState);

                    const selectionState = getSelectionStateForContent(contentState);

                    [...Object.values(TEXT_COLOR_PRESETS), ...Object.values(TEXT_BACKGROUND_PRESETS)].forEach(
                        (style) => {
                            contentState = Modifier.removeInlineStyle(contentState, selectionState, style);
                        },
                    );

                    newEditorState = EditorState.push(newEditorState, contentState, 'change-inline-style');

                    newData[row][col].value = stringifyCellContent(convertToRaw(newEditorState.getCurrentContent()));
                }

                // If the style needs to be removed, ensure that the styling is removed from Draft format as well
                if (!shouldAddInlineStyle && textStyle) {
                    const prevContentState = convertFromRaw(content);

                    let newEditorState = EditorState.createWithContent(prevContentState);

                    const selectionState = getSelectionStateForContent(prevContentState);

                    const newContentState = Modifier.removeInlineStyle(prevContentState, selectionState, textStyle);
                    newEditorState = EditorState.push(newEditorState, newContentState, 'change-inline-style');

                    newData[row][col].value = stringifyCellContent(convertToRaw(newEditorState.getCurrentContent()));
                }
            }

            this.setCellMetaObject(row, col, { cellData: newData[row][col] });
        });

        this.renderAndResize();

        const undoRedoCellSelectionAction = {
            type: TABLE_ELEMENT_CELL_SELECTIONS_UPDATE,
            id: elementId,
            cellSelections: hotTableInstanceRef.current.getSelected(),
        };

        dispatchUpdateTableElement({
            id: elementId,
            changes: {
                tableContent: {
                    colWidthsGU: hotColWidthsGU,
                    data: newData,
                },
            },

            // On undo/redo, make sure to go to previous cell selections
            batchUndoActions: [undoRedoCellSelectionAction],
            batchRedoActions: [undoRedoCellSelectionAction],
        });
    }

    // **********************************
    // CELL ALIGNMENT
    // **********************************

    textAlignmentMenuName(textAlignment: TextAlignment) {
        return (): string => {
            const { hotTableInstanceRef, locale } = this.props;

            const hotCurrentCellSelections = this.getHotCurrentCellSelections();

            const isTextAlignmentApplied = !getAllCellsBetween(hotCurrentCellSelections).some(({ row, col }) => {
                if (!hotTableInstanceRef.current) return false;

                const { cellData = createTableCellEntry() } = hotTableInstanceRef.current.getCellMeta(row, col);
                const hotCellValue = hotTableInstanceRef.current.getDataAtCell(row, col); // For formulas, will return formula result

                return getCellTextAlignment(cellData, locale, hotCellValue) !== textAlignment;
            });

            return `${capitalize(textAlignment)}${isTextAlignmentApplied ? '<span class="tick">✔︎</span>' : ''}`;
        };
    }
    verticalAlignmentMenuName(verticalAlignment: VerticalAlignment) {
        return (): string => {
            const { hotTableInstanceRef } = this.props;

            const hotCurrentCellSelections = this.getHotCurrentCellSelections();

            const isVerticalAlignmentApplied = !getAllCellsBetween(hotCurrentCellSelections).some(({ row, col }) => {
                if (!hotTableInstanceRef.current) return false;

                const { cellData = createTableCellEntry() } = hotTableInstanceRef.current.getCellMeta(row, col);

                if (!cellData.verticalAlignment) return verticalAlignment !== TABLE_CELL_DEFAULT_VERTICAL_ALIGNMENT;

                return cellData.verticalAlignment !== verticalAlignment;
            });

            return `${capitalize(verticalAlignment)}${
                isVerticalAlignmentApplied ? '<span class="tick">✔︎</span>' : ''
            }`;
        };
    }

    updateCellTextAlignment(textAlignment: TextAlignment) {
        return (): void =>
            this.setCellDataForCellSelections(
                (cellData) => ({ ...cellData, textAlignment }),
                'CellMeta.updateCellTextAlignment',
            );
    }

    updateCellVerticalAlignment(verticalAlignment: VerticalAlignment) {
        return (): void =>
            this.setCellDataForCellSelections(
                (cellData) => ({ ...cellData, verticalAlignment }),
                'CellMeta.updateCellVerticalAlignment',
            );
    }

    // **********************************
    // EDIT STATE
    // **********************************

    jumpToTitle(): void {
        const { elementId, showTitle, dispatchStartEditingTitle } = this.props;
        showTitle && requestAnimationFrame(() => dispatchStartEditingTitle(elementId));
    }

    // **********************************
    // HELPER FUNCTIONS
    // **********************************

    setCellData(
        cellDataChanges: Array<[number, number, (cellData: CellData, row?: number, col?: number) => CellData]>,
        source = 'CellMeta.update',
        transactionId?: number,
    ): void {
        const { elementId, hotTableInstanceRef, dispatchUpdateTableElement } = this.props;

        if (!hotTableInstanceRef.current) return;

        const hotTableData = this.getHotTableData();
        const hotColWidthsGU = this.getHotColWidthsGU();
        if (!hotTableData || !hotColWidthsGU) return;

        const newData = cloneDeep(hotTableData);

        hotTableInstanceRef.current.suspendRender();

        const referencedCellUpdates: Array<[number, number, Handsontable.CellValue]> = [];
        const sheet = hyperFormulaInstance.getSheetId(elementId);

        cellDataChanges.forEach((change) => {
            const [row, col, getCellDataUpdatesFn] = change;

            const cellDataUpdates = getCellDataUpdatesFn(newData[row][col], row, col);

            newData[row][col] = {
                ...newData[row][col],
                ...cellDataUpdates,
            };

            this.setCellMetaObject(row, col, { cellData: newData[row][col] });

            // If the current cell has dependants, we need to update the values so that afterChange can run
            if (sheet !== undefined) {
                const cellDependents = hyperFormulaInstance.getCellDependents({ sheet, row, col });
                if (cellDependents.length) referencedCellUpdates.push([row, col, newData[row][col].value]);
            }
        });

        hotTableInstanceRef.current.resumeRender();
        this.renderAndResize();

        const undoRedoCellSelectionAction = {
            type: TABLE_ELEMENT_CELL_SELECTIONS_UPDATE,
            id: elementId,
            cellSelections: hotTableInstanceRef.current.getSelected(),
        };

        dispatchUpdateTableElement({
            id: elementId,
            changes: {
                tableContent: {
                    colWidthsGU: hotColWidthsGU,
                    data: newData,
                },
            },

            // On undo/redo, make sure to go to previous cell selections
            batchUndoActions: [undoRedoCellSelectionAction],
            batchRedoActions: [undoRedoCellSelectionAction],

            transactionId,
        });

        if (referencedCellUpdates.length === 0) return;

        // Update cells that are referenced so that cell type changes can be applied to the dependents
        // This must run after the table element has been updated, because any dependent cell type
        // changes through setDataAtCell > afterChange will also be updated through this function recursively,
        // and we don't want the inner table data updates to happen before the original one
        hotTableInstanceRef.current?.setDataAtCell(referencedCellUpdates, source);
    }

    setCellDataForCellSelections(
        getCellDataUpdatesFn: (cellData: CellData, row?: number, col?: number) => CellData,
        source: string,
        cellSelections = this.getHotCurrentCellSelections(),
    ): void {
        const cellDataChanges = getAllCellsBetween(cellSelections).map<
            [number, number, (cellData: CellData) => CellData]
        >((cell) => {
            const { row, col } = cell;

            return [row, col, getCellDataUpdatesFn];
        });

        return this.setCellData(cellDataChanges, source);
    }

    setCellMetaObject(row: number, col: number, cellMeta: Partial<Handsontable.CellMeta>): void {
        const { hotTableInstanceRef } = this.props;

        if (!hotTableInstanceRef.current) return;

        const newCellMeta = cloneDeep(cellMeta);
        if (newCellMeta.cellData) {
            newCellMeta.readOnly = cellIsReadOnly(newCellMeta.cellData);
        }

        hotTableInstanceRef.current.setCellMetaObject(row, col, newCellMeta);
    }

    render(): null {
        return null;
    }
}

export default TableOperations;
