// Lib
import * as Immutable from 'immutable';

// Utils
import { getUserIdFromAction } from '../../../../common/actionUtils';

// Constants
import { COMMENTS_TYPING_PING, COMMENTS_TYPING_STOP } from '../../../../common/comments/commentConstants';

/**
 * This structure is a map of threadIds to maps of user IDs to timestamps:
 *
 * threadId1:  {
 *    userId1: timestamp1,
 *    userId2...
 * },
 * threadId2...
 */

const initialState = Immutable.Map();

const recordTypingPing = (state, action) => {
    const userId = getUserIdFromAction(action);

    if (!userId) return state;

    return state.setIn([action.threadId, userId], action.timestamp);
};

const removeTypingActivity = (state, action) => {
    const userId = getUserIdFromAction(action);

    if (!userId) return state;

    return state.deleteIn([action.threadId, userId]);
};

export default (state = initialState, action) => {
    if (!action.remote) return state;

    switch (action.type) {
        case COMMENTS_TYPING_PING:
            return recordTypingPing(state, action);
        case COMMENTS_TYPING_STOP:
            return removeTypingActivity(state, action);
        default:
            return state;
    }
};
