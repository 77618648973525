// Lib
import * as Immutable from 'immutable';
import { omit } from 'lodash/fp';

// Constants
import { POI_REMOVE, POI_MULTIPLE_SEEN, POI_SET } from './poiConstants';

const initialState = Immutable.Map();

const mergePoiData = (state, action) => {
    const newVal = Immutable.fromJS(omit('type', action));

    return state.update(action.id, (originalVal) => (originalVal ? originalVal.merge(newVal) : newVal));
};

const markMultiplePoisAsSeen = (state, action) =>
    state.withMutations((mutableState) => action.ids.forEach((id) => mutableState.setIn([id, 'seen'], true)));

/**
 * Stores a map of points of interest.
 */
export default function elementMeasurementsMapReducer(state = initialState, action) {
    switch (action.type) {
        case POI_SET:
            return mergePoiData(state, action);
        case POI_REMOVE:
            return state.delete(action.id);
        case POI_MULTIPLE_SEEN:
            return markMultiplePoisAsSeen(state, action);
        default:
            return state;
    }
}
