// Utils
import {
    createCustomEqualSmartSelector,
    createDeepSelector,
    createShallowSelector,
} from '../../utils/milanoteReselect/milanoteReselect';

// Selectors
import { getDeepChildrenModCache } from '../../reducers/cache/deepChildrenModCacheSelector';
import { getElementSummariesSelector } from '../reducers/elementSummariesSelector';
import { calculateElementGraphSelectorData } from './elementGraphSelectorUtils';

// Board hierarchies
import { getBoardHierarchiesMapSelector } from '../board/hierarchy/boardHierarchiesSelector';
import { getBoardHierarchiesParentGraph } from '../board/hierarchy/boardHierarchyGraphUtil';
import { areBoardHierarchiesInitialised } from '../../app/initialisation/initialisationSelector';

// The graph should only be calculated once per render
// The graph should only be recalculated if the deepChildMod changes
const elementGraphEqualityChecker = (
    [prevElements, prevDeepChildrenModCache],
    [nextElements, nextDeepChildrenModCache],
) => prevDeepChildrenModCache === nextDeepChildrenModCache;

const createCustomElementChildrenSelector = createCustomEqualSmartSelector(elementGraphEqualityChecker);

/**
 * This is executed only once for every time the state slice has changed, and then its result
 * is shared between the graph selectors so that they don't need to perform very similar calculations again.
 */
// NOTE: This uses the custom smart selector - but the re-computation is almost guaranteed to return a different
//  result each time. The shallow equality check in this case is so quick (7 keys) that it's not really worth
//  creating an object equality only version just for this
const elementGraphDataSelector = createCustomElementChildrenSelector(
    getElementSummariesSelector,
    getDeepChildrenModCache,
    calculateElementGraphSelectorData,
);

// TOP DOWN
/**
 * This selector gets the complete element graph of all elements currently in the client store.
 * This maps an element ID to its immediate children.
 *
 * NOTE: The elementGraphDataSelector selector will always return a new element graph when it executed, so there's
 *          no point using a selector
 */
export const elementGraphSelector = (state) => elementGraphDataSelector(state).elementGraph;

export const aliasIdToPhysicalIdMapSelector = createShallowSelector(
    elementGraphDataSelector,
    (elementGraphData) => elementGraphData.aliasIdToPhysicalIdMap,
);

// BOTTOM UP
/**
 * This is a map of element IDs to their physical parent ID.
 * It basically works as an index to speed up the retrieval of an element's parent ID and ancestors.
 *
 * NOTE: The elementGraphDataSelector selector will always return a new parentIdMap when it executed, so there's
 *          no point using a selector
 */
export const parentIdMapSelector = (state) => elementGraphDataSelector(state).parentIdMap;

/**
 * This maps an element ID to its visible descendants.
 * Visible descendants are all elements that would be visible when viewing that element.
 * So the children of columns, task lists, tasks etc will be associated to the parent board.
 *
 * NOTE: This might not change occasionally, but it would require a deep equals comparison which would take
 *          more time than it would be worth.  Instead - perform a shallow comparison on the individual element's entry
 */
export const boardVisibleElementGraphSelector = (state) => elementGraphDataSelector(state).boardVisibleElementGraph;

/**
 * Maps an element ID to its ancestor board ID.
 *
 * NOTE: The elementGraphDataSelector selector will always return a new parentIdMap when it executed, so there's
 *          no point using a selector
 */
export const elementIdToAncestorBoardIdMapSelector = (state) =>
    elementGraphDataSelector(state).elementIdToAncestorBoardIdMap;

/**
 * Maps a column ID to its parent board ID.
 */
export const columnIdToParentBoardIdMapSelector = createShallowSelector(
    elementGraphDataSelector,
    (elementGraphData) => elementGraphData.columnIdToParentBoardIdMap,
);

export const elementIdToVirtualParentIdsMapSelector = createDeepSelector(
    elementGraphDataSelector,
    (elementGraphData) => elementGraphData.elementIdToVirtualParentIdsMap,
);

/**
 * Maps a board ID to alias IDs that link to it.
 */
export const boardIdToAliasIdsMapSelector = createShallowSelector(
    elementGraphDataSelector,
    (elementGraphData) => elementGraphData.boardIdToAliasIdsMap,
);

// BOARD SUMMARY GRAPHS
const EMPTY_OBJECT = {};

// It returns a virtual parent board graph for every BOARD.  This is useful for doing bottom up
// traversals, like we do in the activity
export const fullVirtualBoardParentGraphSelector = createDeepSelector(
    areBoardHierarchiesInitialised,
    getBoardHierarchiesMapSelector,
    columnIdToParentBoardIdMapSelector,
    elementIdToVirtualParentIdsMapSelector,
    (isReady, boardHierarchies, columnIdToParentBoardIdMap, elementIdToVirtualParentIdsMap) => {
        if (!isReady) return EMPTY_OBJECT;

        return getBoardHierarchiesParentGraph(
            boardHierarchies,
            columnIdToParentBoardIdMap,
            elementIdToVirtualParentIdsMap,
        );
    },
);
