import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore No react-router v3.2.1 types
import { push, goBack } from 'react-router-redux';

// Components
import RouteModalRootAuthenticated from '../../../components/modal/RouteModalRootAuthenticated';
import AccountPlanContainer from '../accountModal/plan/AccountPlanContainer';
import { preloadAllCheckoutModals } from './loadableCheckoutComponents';

// Context
import { CheckoutContextProvider } from './context/useCheckoutContext';

// Selectors
import { getCurrentUser, getCurrentUserSubscription } from '../../currentUserSelector';
import { shouldUseCheckoutV2 } from '../accountModal/plan/payment/paymentSelector';

// Actions
import { fetchCurrentUserSubscription } from '../../currentUserSubscriptionActions';
import { intercomShowMessages } from '../../../analytics/analyticsActions';
import { redirectTo } from '../../../reducers/navigationActions';

// Constants
import { CHECKOUT_V2_TO_V1_PATH_MAPPINGS } from './checkoutConstants';

// Styles
import './CheckoutModal.scss';
import Spinner from '../../../components/loaders/Spinner';

type Route = {
    childRoutes?: Route[];
    path: string;
    component: React.ReactElement;
};

type CheckoutModalProps = {
    children: React.ReactNode;
    route: Route;
    location: {
        pathname: string;
    };
};

const CheckoutModal = ({ children, ...props }: CheckoutModalProps): React.ReactElement => {
    const { location } = props;

    const currentUser = useSelector(getCurrentUser);
    const subscription = useSelector(getCurrentUserSubscription);
    const useCheckoutV2 = useSelector(shouldUseCheckoutV2);

    const dispatch = useDispatch();

    const dispatchOpenIntercomMessages = () => dispatch(intercomShowMessages());
    const dispatchFetchSubscriptionDetails = () => dispatch(fetchCurrentUserSubscription());
    const dispatchNavigateTo = (path: string) => dispatch(push(path));
    const dispatchNavigateBrowserBack = () => dispatch(goBack());
    const dispatchRedirectTo = (path: string) => dispatch(redirectTo(path));

    useEffect(() => {
        const checkoutV1Pathname = CHECKOUT_V2_TO_V1_PATH_MAPPINGS[location.pathname];
        if (useCheckoutV2 === false && checkoutV1Pathname) {
            dispatchRedirectTo(checkoutV1Pathname);
        }
    }, [useCheckoutV2]);

    useEffect(() => {
        preloadAllCheckoutModals();
    }, []);

    // If we haven't determined whether to use checkout v2 yet, show a spinner
    if (useCheckoutV2 === undefined) {
        return (
            <RouteModalRootAuthenticated {...props} className="CheckoutModal auto-width no-padding">
                <Spinner show />
            </RouteModalRootAuthenticated>
        );
    }

    return (
        <RouteModalRootAuthenticated {...props} className="CheckoutModal auto-width no-padding">
            <AccountPlanContainer>
                <CheckoutContextProvider
                    currentUser={currentUser}
                    subscription={subscription}
                    dispatchOpenIntercomMessages={dispatchOpenIntercomMessages}
                    dispatchFetchSubscriptionDetails={dispatchFetchSubscriptionDetails}
                    location={location}
                    dispatchNavigateTo={dispatchNavigateTo}
                    dispatchNavigateBrowserBack={dispatchNavigateBrowserBack}
                >
                    {children}
                </CheckoutContextProvider>
            </AccountPlanContainer>
        </RouteModalRootAuthenticated>
    );
};

export default CheckoutModal;
