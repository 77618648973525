// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { compose, branch } from '../../../node_module_clones/recompose';
import { identity, constant } from 'lodash/fp';
import classNames from 'classnames';

// Selectors
import { getCurrentUserId } from '../../user/currentUserSelector';
import { makeGetOrderedThreadComments } from './store/commentsSelector';
import { makeGetCommentThreadActiveUsers } from './store/commentThreadActivitySelector';

// Utils
import * as pointLib from '../../../common/maths/geometry/point';
import { prop } from '../../../common/utils/immutableHelper';
import { isFullAccess } from '../../../common/permissions/permissionUtil';
import { elementClassNames, getDomElementId } from '../utils/elementUtil';
import { isLocationCanvas } from '../../../common/elements/utils/elementLocationUtils';
import { displayCommentAsCollapsed } from '../../../common/comments/commentModelUtil';
import {
    getColor,
    getCreatorId,
    getElementId,
    getIsCollapsed,
    getMaxHeight,
    getThreadId,
    getWidth,
} from '../../../common/elements/utils/elementPropertyUtils';

// Hooks
import useKeepScrolledToBottom from '../../utils/dom/useKeepScrolledToBottom';

// Components
import ColorBar from '../../components/colors/ColorBar';
import Thread from './Thread';
import CommentForm from './CommentForm';
import CommentThreadStartIndicator from './CommentThreadStartIndicator';
import CommentActivity from './CommentActivity';
import ElementUserTag from '../../user/userActivity/ElementUserTag';
import QuickLineCreationTool from '../quickLine/QuickLineCreationTool';
import ElementResizeHandle from '../resizing/ElementResizeHandle';
import elementResizeDecorator from '../resizing/elementResizeDecorator';
import commentThreadSaveHeightResizeDecorator from './commentThreadSaveHeightResizeDecorator';

// Helpers
import { getElementStyle } from '../../utils/grid/gridUtils';

// Constants
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';
import { IN_LIST_MODE } from '../../../common/inList/listTypes';

// Styles
import './CommentThread.scss';

const getThreadContentStyle = (props) => {
    const { element, tempSize, gridSize, isDragPreview } = props;

    const displayingAsCollapsed = isDragPreview ? getIsCollapsed(element) : displayCommentAsCollapsed(element);

    if (displayingAsCollapsed) return;

    if (tempSize) {
        const scaledSize = pointLib.scale(gridSize, tempSize);
        return {
            maxHeight: scaledSize.height,
        };
    }

    const maxHeight = getMaxHeight(element);
    if (maxHeight) {
        return {
            maxHeight: maxHeight === 'none' ? maxHeight : `${maxHeight}rem`,
        };
    }
};

const mapStateToProps = () => {
    const getOrderedThreadComments = makeGetOrderedThreadComments();
    const getCommentThreadActiveUsers = makeGetCommentThreadActiveUsers();

    return createSelector(
        getCurrentUserId,
        (state, { threadId }) => getOrderedThreadComments(state, { threadId }),
        (state, { element }) =>
            getCommentThreadActiveUsers(state, {
                threadId: getThreadId(element),
                creatorId: getCreatorId(element),
            }),
        (currentUserId, commentList, activeUsers) => ({ currentUserId, commentList, activeUsers }),
    );
};

const CommentThreadExpanded = (props) => {
    const {
        elementId,
        currentBoardId,
        element,
        gridSize,
        inList,
        isLocked,
        isEditable,
        elementEvents,
        addComment,
        currentUserId,
        commentList,
        activeUsers,
        isSelected,
        isSingleSelected,
        permissions,
        tempSize,
        setElementSize,
        isDragging,
        style,
        isInPopup,
        hideEditing,
        hideComments,
        getContextZoomScale,
        getContextZoomTranslationPx,
    } = props;

    const contentContainerRef = React.useRef();
    const contentRef = React.useRef();

    const onSetElementSize = React.useCallback((size) => {
        setElementSize && setElementSize(size, contentContainerRef.current, contentRef.current);
    }, []);

    useKeepScrolledToBottom(contentContainerRef, contentRef, isDragging);

    const colorName = getColor(element);
    const threadId = getThreadId(element);
    const creatorId = getCreatorId(element);

    const commentThreadClassNames = classNames('CommentThread drag-handle', {
        'has-comments': commentList && commentList.size > 0,
        'hide-comments': hideComments,
        'hide-replies': hideEditing,
    });
    const classes = elementClassNames(commentThreadClassNames, props);

    const isCollapsed = displayCommentAsCollapsed(element);

    const showQuickLineCreationTool =
        isSingleSelected &&
        (isLocationCanvas(element) || inList === IN_LIST_MODE.IN_LIST_COLUMN) &&
        isFullAccess(permissions) &&
        !isCollapsed;

    const savedCardWidth = getWidth(element);
    const cardWidth = isInPopup
        ? ELEMENT_DEFAULT_WIDTH
        : prop('width', tempSize) || savedCardWidth || ELEMENT_DEFAULT_WIDTH;

    const threadStyle = {
        ...style,
        ...getElementStyle(cardWidth, gridSize, inList, style),
    };

    const isOverflowingInList =
        inList && ((getMaxHeight(element) && getMaxHeight(element) !== 'none') || commentList.size > 4);

    const showResizeHandle = !isLocked && isEditable && !isCollapsed && (!inList || isOverflowingInList);

    return (
        <div id={getDomElementId(getElementId(element))} className={classes} {...elementEvents} style={threadStyle}>
            <ColorBar colorName={colorName} isSelected={isSelected} />
            <QuickLineCreationTool
                show={showQuickLineCreationTool}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementUserTag {...props} />
            <div
                ref={contentContainerRef}
                data-ignore-touch-scroll="true"
                className="comment-thread-content-container"
                style={getThreadContentStyle(props)}
            >
                <div ref={contentRef} className="comment-thread-content">
                    <CommentThreadStartIndicator
                        currentUserId={currentUserId}
                        creatorId={creatorId}
                        threadId={threadId}
                        commentList={commentList}
                    />
                    <Thread {...props} commentList={commentList} />
                    <CommentActivity commentList={commentList} activeUsers={activeUsers} />
                    <CommentForm {...props} threadId={threadId} onSubmit={addComment} />
                    <div className="resize-handle-background" />
                    <ElementResizeHandle
                        {...props}
                        elementId={elementId}
                        defaultMaxWidth={ELEMENT_DEFAULT_WIDTH * 2 * gridSize}
                        showHandle={showResizeHandle}
                        setElementSize={onSetElementSize}
                    />
                </div>
            </div>
        </div>
    );
};

const resizeDecorators = compose(
    elementResizeDecorator({
        getMinWidth: constant(ELEMENT_DEFAULT_WIDTH),
        enableHeight: true,
    }),
    commentThreadSaveHeightResizeDecorator,
);

const enhancer = compose(
    connect(mapStateToProps),
    branch(({ isPresentational }) => !isPresentational, resizeDecorators, identity),
);

CommentThreadExpanded.propTypes = {
    elementId: PropTypes.string,
    currentBoardId: PropTypes.string,
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    isDragging: PropTypes.bool,
    inList: PropTypes.string,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEditable: PropTypes.bool,
    isLocked: PropTypes.bool,
    isHovered: PropTypes.bool,
    hideComments: PropTypes.bool,
    hideEditing: PropTypes.bool,
    onEmptyBackspace: PropTypes.func,
    elementEvents: PropTypes.object,
    filterQuery: PropTypes.string,
    addComment: PropTypes.func,
    currentUserId: PropTypes.string,
    commentList: PropTypes.object,
    activeUsers: PropTypes.object,
    permissions: PropTypes.number,
    tempSize: PropTypes.object,
    setElementSize: PropTypes.func,
    style: PropTypes.object,
    isInPopup: PropTypes.bool,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
};

export default enhancer(CommentThreadExpanded);
