// Lib
import { compact } from 'lodash';

// Selectors
import currentBoardSuggestedColorSelectorOnlyColors from '../currentBoardSuggestedColorsSelector';
import {
    createCustomEqualDeepSelector,
    createDeepSelector,
} from '../../../../../../utils/milanoteReselect/milanoteReselect';
import {
    getCurrentBoardVisibleDescendantIds,
    getCurrentBoardVisibleDescendants,
} from '../../../../../../element/selectors/currentBoardSelector';

// Utils
import { elementAllowsDualColors } from './dualColorListUtils';
import {
    getBackgroundColor,
    getColor as getPrimaryColor,
    getSecondaryColor,
} from '../../../../../../../common/elements/utils/elementPropertyUtils';
import { generateDualColorList, MAX_PALETTE_SIZE } from '../../../../../../../common/colors/dualColorUtils';
import { hasNotChanged } from '../../../../../../utils/react/propsComparisons';
import { removeDuplicateObjects } from '../../../../../../../common/utils/arrayUtils';
import { isCard } from '../../../../../../../common/elements/utils/elementTypeUtils';

// Constants
import { DEFAULT_BOARD_COLORS_HEX_ARRAY } from '../../../../../../../common/colors/colorConstants';
import { MNElement } from '../../../../../../../common/elements/elementModelTypes';

const visibleDescendantIdsHasNotChanged = hasNotChanged(0);
const usedDualColorsSelectorEqualityChecker = (prevArgs: unknown, nextArgs: unknown) =>
    visibleDescendantIdsHasNotChanged(prevArgs, nextArgs);

const createCustomUsedDualColorsSelector = createCustomEqualDeepSelector(usedDualColorsSelectorEqualityChecker);

/**
 * Return an object with the primary and secondary colors for the current element.
 * Colors may be saved in different properties depending on the element type.
 * See elementAllowsDualColors function for all possible elements
 * @param element
 */
const getElementDualColor = (element: MNElement): { primary: string; secondary: string } => {
    const primary = isCard(element) ? getBackgroundColor(element) : getPrimaryColor(element);
    const secondary = getSecondaryColor(element);

    return { primary, secondary };
};

// Using a custom selector so that it only recalculates when the visibleDescendantIds change (elements added or removed).
// The visibleDescendants are used to calculate the used dual colors, but if we recalculate based on the elements
// then it will update more frequently than necessary (e.g. when using the color picker, if it causes a recalculation
// the user will see the list of dual colors change). By checking equality based on IDs we can update less frequently.
const currentBoardUsedDualColorsSelector = createCustomUsedDualColorsSelector(
    getCurrentBoardVisibleDescendantIds,
    getCurrentBoardVisibleDescendants,
    (_, visibleDescendants) => {
        const dualColors: { primary: string; secondary: string }[] = [];
        visibleDescendants.forEach((element) => {
            if (!elementAllowsDualColors(element) || !getSecondaryColor(element)) return;

            dualColors.push(getElementDualColor(element));
        });
        return removeDuplicateObjects(dualColors);
    },
);

export const currentBoardDualColorListSelector = createDeepSelector(
    currentBoardSuggestedColorSelectorOnlyColors,
    currentBoardUsedDualColorsSelector,
    (suggestedColors, usedDualColors) => {
        const colorList = compact(suggestedColors?.length ? suggestedColors : DEFAULT_BOARD_COLORS_HEX_ARRAY);

        const dualColorList = generateDualColorList(colorList);

        const combinedList = removeDuplicateObjects([...dualColorList, ...usedDualColors]);
        // if the combined array is greater than the maximum allowed number of dualColors then remove
        // from the front. This will result in keeping as many already used dualColors available as possible
        return combinedList.slice(Math.max(combinedList.length - MAX_PALETTE_SIZE, 0));
    },
);
