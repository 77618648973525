import MoreTool from '../../components/moreTool/MoreTool';

// Types
import { MoreToolsSettings, ToolbarItemConfig } from '../../toolbarTypes';

import { COLLAPSE_ORDER } from '../toolbarToolConfigConstants';
import ToolSeparator from '../../components/ToolSeparator';

export const MetaToolIds = {
    MORE: 'META_MORE',
    DIVIDER: 'META_DIVIDER',
};

const META_MORE: ToolbarItemConfig = {
    id: MetaToolIds.MORE,
    name: 'More',
    height: {
        large: 16,
        medium: 16,
        small: 14,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    component: MoreTool,
    toolSettings: {
        groupSize: 3,
        items: [],
        popupId: '',
    } as MoreToolsSettings,
};

const META_DIVIDER: ToolbarItemConfig = {
    id: MetaToolIds.DIVIDER,
    name: 'Divider',
    height: {
        large: 1,
        medium: 1,
        small: 1,
    },
    collapseOrder: COLLAPSE_ORDER.NEVER,
    collapseBehavior: 'hide',
    component: ToolSeparator,
};

export default {
    META_MORE,
    META_DIVIDER,
};
