// Utils
import { getTimestamp } from '../utils/timeUtil';

// Constants
import { COMMENT_ID_PREFIX, COMMENTS_ADD, COMMENTS_DELETE, COMMENTS_LOAD, COMMENTS_UPDATE } from './commentConstants';
import { getGlobalClientId } from '../uid/ClientIdInstance';

export const getNewCommentId = (id = getGlobalClientId().getElementId()) => `${COMMENT_ID_PREFIX}${id}`;

export const commentsLoad = ({ comments }) => ({
    type: COMMENTS_LOAD,
    comments,
});

export const commentsAdd = ({ id, text, threadId, sync = true, userId, transactionId }) => ({
    _id: getNewCommentId(id),
    type: COMMENTS_ADD,
    createdAt: getTimestamp(),
    userId,
    threadId,
    text,
    transactionId,
    sync,
});

export const commentsUpdate = ({ _id, text, contentUpdate, userId, sync = true, transactionId }) => ({
    _id,
    type: COMMENTS_UPDATE,
    text,
    contentUpdate,
    userId,
    transactionId,
    sync,
});

export const commentsDelete = ({ _id, userId, threadId, sync = true, transactionId }) => ({
    _id,
    type: COMMENTS_DELETE,
    threadId,
    userId,
    transactionId,
    sync,
});
