/**
 * Builds a data URI string.
 *
 * @param mediaType {string} E.g. 'image/svg+xml'
 * @param extension {string} E.g. 'base64'
 * @param data {string}
 * @return {string} 'data:image/svg+xml;base64,......'
 */
export const getDataUri = ({ mediaType = 'image/svg+xml', extension, data }) =>
    `data:${mediaType}${extension ? `;${extension}` : ''},${data}`;
